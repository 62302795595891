import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DiscountDetailsInterface } from 'src/appv2/interfaces/discount-details.interface';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { SearchchangeSearchName } from 'src/appv2/reducers/search/search.actions';
import { SearchState } from 'src/appv2/reducers/search/search.models';
import { CacheService } from 'src/appv2/services/cache.service';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { urlToCacheKeyMapping } from 'src/appv2/utils/constants/discount.const';


@Component({
  selector: 'appv2-editorial-page',
  templateUrl: './editorial-page.component.html',
  styleUrls: ['./editorial-page.component.scss'],
})
export class EditorialPageComponent implements OnInit {

  constructor(private landingPage: LandingPageService, private store$: Store<any>, private route: ActivatedRoute, private router: Router, private cacheService: CacheService) {
    // Get Promotion Details
    this.promotionCacheKey = urlToCacheKeyMapping[this.promotionCacheKeyName];
    if (this.promotionCacheKey) {
      const promotion = this.cacheService.getData(this.promotionCacheKey);
      if (typeof promotion !== 'undefined') {
        this.promotion = promotion;
      } else {
        this.getPromotion();
      }
    }
  }

  promotion: any;
  page_type: string = 'editorial_page';
  discountDetailsResponse: any;
  searchtext: string;
  elasticSuggestions: any;
  openClass: string = '';
  mainPageType: string = 'editorial';
  visibleTrendingSearch: boolean = false;
  showTrendSerach: boolean = false;
  recentSearches = [];
  trendingKeyword: any;
  cacheKey: string;
  page: string;

  // display pricing component header text
  displayPricingHeader = true;
  public galleryData = null;
  public galleryStoryData = null;
  public galleryStoryDataLen = 0;
  public discountDetails = new DiscountDetailsInterface();
  promotionCacheKeyName: string = 'editorial-page-banner';
  promotionCacheKey: string;

  ngOnInit(): void {
    const urlSegments = this.route.snapshot.url;
    // Get the last segment (the segment at the end of the URL)
    const lastSegment = urlSegments[urlSegments.length - 1];
    // Access the last segment's path
    const lastSegmentPath = lastSegment.path;
    // Now you can use the lastSegmentPath variable as needed

    this.page = lastSegmentPath;

    this.page_type =  'editorial_page';

    // Get Discount Details
    this.cacheKey = urlToCacheKeyMapping[this.page];
    this.landingPage.isDiscountDetailsCalled.subscribe(
      (isDiscountCalled: boolean) => {
        if (isDiscountCalled) {
          this.discountDetails = this.cacheService.getData(this.cacheKey);
        }
      }
    );

    this.recentSearches = JSON.parse(localStorage.getItem('recent_search'));
    this.getEditorialList();
    this.getEditorialStoryList();
  }

  getPromotion() {
    this.loading(true);
    this.landingPage.getPromotionDetails(this.page_type).subscribe((data) => {
      this.loading(false);
      this.promotion = data.data;
      if (this.promotion) {
        this.cacheService.setData(this.promotionCacheKey, this.promotion);
        this.landingPage.isEditorialBannerDetailsCalled.next(true);
      }
    });
  }

  // For Story Data
  getEditorialStoryList() {
    this.landingPage.getEditorialStoryList().subscribe((response) => {
      if (response.status == 'success') {
        this.galleryStoryData = response.data;
        this.galleryStoryDataLen = response.data.length;        
      }
    });
  }

  // For collection Data
  getEditorialList() {
    this.landingPage.getEditorialList().subscribe((response) => {
      if (response.status == 'success') {
        this.galleryData = response.data;
      }
    });
  }

  openEditorial(productTitle,productApiIds) {
    const queryParams = { search: productTitle };
    const data = productApiIds;
    this.landingPage.setProductApiData(data);

    const navigationExtras: NavigationExtras = {
      queryParams: queryParams,
    };
    this.router.navigate(['/editorials'], navigationExtras);
  }

  public loading(status: boolean) {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: status })
    );
    return;
  }

  receivePricingHeaderDisplayEvent(response: string) {
    this.displayPricingHeader = response == 'No' ? false : true;
  }

  /**
    * Gets suggestion keyword list by elastic search
    * @param searchText
    */
  getSuggestionByElasticSearch(searchText) {
    this.searchtext = searchText;
    this.landingPage
      .getSuggestionByElasticSearch(this.searchtext)
      .subscribe((data) => {
        if (data) {
          this.elasticSuggestions = data.data;
        }
      });
  }

  public onInput(event: any) {
    const inputValue = event.target.value;
    // Define a regular expression to match letters, numbers, and spaces
    const regex = /^[a-zA-Z0-9\s]*$/;

    if (!regex.test(inputValue)) {
      // If the input contains characters other than letters, numbers, and spaces,
      // remove those characters from the input value
      event.target.value = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
    }
  }

  searchEditorial(page) {
    this.mainPageType = page;

    if (this.searchtext && page == 'image') {
      const imageQueryParams = { search: this.searchtext };
      const navigationExtras: NavigationExtras = {
        queryParams: imageQueryParams,
      };
      this.router.navigate(['/images'], navigationExtras);
    } else if (this.searchtext && page == 'footage') {
      const footageQueryParams = { search: this.searchtext };
      const navigationExtras: NavigationExtras = {
        queryParams: footageQueryParams,
      };
      this.router.navigate(['/footages'], navigationExtras);
    } else if (this.searchtext && page == 'editorial') {
      const editorialQueryParams = { search: this.searchtext };
      const navigationExtras: NavigationExtras = {
        queryParams: editorialQueryParams,
      };
      this.router.navigate(['/editorials'], navigationExtras);
    } else {
      this.store$.dispatch(
        new SearchchangeSearchName(<SearchState>{ searchType: page })
      );
    }
  }

  openCategory(catName) {
    this.searchtext = catName;
    this.searchEditorial(this.mainPageType);
  }

  clickOpenMenu() {
    this.visibleTrendingSearch = false;
    this.showTrendSerach = false;
    if (this.openClass === 'open') {
      this.openClass = '';
    } else {
      this.openClass = 'open';
    }
  }

  onEnterSearch() {
    // save to local storage
    let recentSearchData = JSON.parse(localStorage.getItem('recent_search'));
    this.recentSearches = recentSearchData;
    if (this.searchtext) {
      if (!recentSearchData) {
        recentSearchData = [];
      }
      if (recentSearchData.length > 4) {
        recentSearchData.shift();
      }
      if (this.searchtext) {
        recentSearchData.push(this.searchtext);
      }
      localStorage.setItem('recent_search', JSON.stringify(recentSearchData));
      if (this.page_type == 'image' || this.mainPageType == 'image') {
        const queryParams = { search: this.searchtext };

        const navigationExtras: NavigationExtras = {
          queryParams: queryParams,
        };
        this.router.navigate(['/images'], navigationExtras);
        // this.router.navigate(['/images'], { state: navigationData });
      } else {
        this.storeKeywordInElasticSearch(this.searchtext);
        this.openCategory(this.searchtext);
      }
    }
  }

  onMouseInSearchBox() {
    this.visibleTrendingSearch = true;
  }

  /**
   * Store keyword in elastic search api
   * @param keyword
   */
  storeKeywordInElasticSearch(keyword) {
    const bodyParams = {
      search_field: keyword,
    };
    // this.loading(true);
    this.landingPage
      .storeKeywordInElasticSearch(bodyParams)
      .subscribe((data) => {
        this.loading(false);
      });
  }

  onKeyPress(event: KeyboardEvent) {
    this.openClass = '';
    this.searchtext = (event.target as HTMLInputElement).value;
    this.getSuggestionByElasticSearch(this.searchtext);
    if (this.searchtext) {
      this.showTrendSerach = true;
      this.visibleTrendingSearch = false;
    } else {
      this.showTrendSerach = false;
      this.visibleTrendingSearch = true;
    }
    if (event.keyCode == 13 && this.searchtext) {
      this.showTrendSerach = false;
      this.onEnterSearch();
    }
  }

  onMouseOutSearchBox() {
    this.visibleTrendingSearch = false;
    this.showTrendSerach = false;
  }
}
