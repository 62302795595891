<section class="are_you_team_section">
    <div class="container">
        <div class="are_you_team_section_inner">
            <div class="are_you_team_section_content">
                <h2 class="h2">Didn’t get any options? Are you a Team?</h2>
                <p>{{appName}} is brought to you by Adobe the company behind some of the world’s leading marketplaces
                    for creative assets and creative people.
                </p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
                    Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software like Aldus PageMaker including versions
                    of Lorem Ipsum.
                </p>
                <a href="javascript:void(0)" class="sales_team" (click)="openEmail()">Take to Sales Team</a>
            </div>
            <div class="are_you_team_section_image">
                <picture>
                    <source srcset="assets/images/are_you_team.webp" alt="are-you-team-image" width="922"
                        height="595" />
                    <source srcset="assets/images/are_you_team.png" alt="are-you-team-image" width="922" height="595" />
                    <img src="assets/images/are_you_team.png" alt="are-you-team-image" width="922" height="595" />
                </picture>
            </div>
        </div>
    </div>
</section>
