import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import $ from 'jquery';
import { messages } from 'src/appv2/appv2.constants';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';
import { DownloadService } from 'src/appv2/services/download.service';
import { ToastService } from 'src/appv2/services/toast.service';
import { WishlistService } from 'src/appv2/services/wishlist.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'appv2-my-wishlist-inner',
  templateUrl: './my-wishlist-inner.component.html',
  styleUrls: ['./my-wishlist-inner.component.scss'],
})
export class MyWishlistInnerComponent implements OnInit {
  constructor(
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private download: DownloadService,
    private accountActions: AccountService,
    private wishlist: WishlistService,
    private toastService: ToastService,
    private userData: AccountService,
    private store$: Store<any>
  ) {}

  wishlistId: any = '';
  emptyUserPlansList = true;
  wishlistData: any = [];
  wishlistLink: String;
  private baseUrl = environment.front_url;
  isClick: boolean = false;
  selectedProductsId: any = [];
  selectedImageProductsId: any = [];
  user = '';
  showPreConfirmModal: boolean = false;
  preconfirmPopupConfigData: any = {
    popup_message:
      'Are you certain you want to delete this product from the wishlist?',
  };
  showWishlistModal: boolean = false;
  planId = 0;
  downloadData = [];
  plansChecked: boolean = false;
  userLogined: boolean = false;
  showNoPlanPopup: boolean = false;
  showHighResolutionPopup: boolean = false;
  userSubscriptionPlans: Array<object> = [];
  userAllInfo: object = {};
  selectedPlanId = 0;

  /**
   * Initializes the component when it is first created.
   * Retrieves user data and wishlist ID from local storage and route parameters.
   * Loads user wishlists if a valid wishlist ID is present.
   */
  ngOnInit(): void {
    this.user = localStorage.getItem('user_data');
    this.wishlistId = this.activatedRouter.snapshot.paramMap.get('wishlist-id');
    if (this.wishlistId) {
      this.loadUserWishlists();
    }
    this.userLogined = localStorage.getItem('user_data') != null;
    if (this.userLogined) {
      this.getUserInfo();
    }
  }

  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.wishlistInnerLoading}))
    return
  }

  /**
   * Tracks the items in a ngFor loop using a unique identifier.
   * @param index - The index of the item in the array.
   * @param item - The item object being tracked.
   * @returns The unique identifier for the tracked item.
   */
  trackByFn = (index: number, item: any): any => {
    return item.id;
  };

  /**
   * Opens the share popup and generates a share link for the wishlist.
   * Retrieves the share link from the backend and displays it in the popup.
   */
  openSharePopup() {
    let payload = {};
    payload['wishlist_id'] = this.wishlistId;
    this.accountActions.getShareLink(payload).subscribe((data) => {
      this.wishlistLink =
        this.baseUrl + '#/added-wishlist-own-account/' + data.data;
    });

    $('.download_popup').toggle();
    $('.overlay').toggle();
    $('body').addClass('overflow_hidden');
  }

  /**
   * Closes the share popup and resets the UI state.
   */
  onCancel() {
    $('.download_popup').toggle();
    $('.overlay').toggle();
    $('body').removeClass('overflow_hidden');
  }

  /**
   * Copies the share link to the user's clipboard.
   * @param inputElement - The input element containing the share link.
   */
  copyLink(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.isClick = true;
  }

  /* ======= Start: Edit Wishlist Name ======= */
  /**
   * Initiates the process of editing the wishlist name by displaying the wishlist modal.
   * Sets the flag to show the wishlist modal.
   */
  editWishlistName = () => {
    this.showWishlistModal = true;
  };

  /**
   * Updates the wishlist name using the provided new wishlist name.
   * Makes a request to the backend to update the wishlist name.
   * Displays success or error messages using the toast service.
   * Reloads the user wishlists upon successful update.
   * @param wishlistName - The new wishlist name to be updated.
   */
  updateWishlistName = (wishlistName) => {
    // Prepare data for the API request
    let postData = JSON.parse(this.user);
    postData['wishlist_id'] = this.wishlistId;
    postData['wishlist_name'] = wishlistName;

    // Make the API request to update the wishlist name
    this.wishlist.createOrUpdateWishlist(postData).subscribe(
      (res) => {
        if (!res['error']) {
          // Display success message and reload user wishlists
          this.toastService.success(res['message']);
          this.loadUserWishlists();
        } else {
          // Display error message
          this.toastService.error(res['message']);
        }
      },
      (error) => {
         console.log('error : ', error);
      }
    );
  };
  /* ======= End: Edit Wishlist Name ======= */

  /* ======= Start: Remove Products to Wishlist ======= */
  /**
   * Adds the given product ID to the selected products list if it's not already included.
   * @param {string} product_id - The ID of the product to be added to the selection.
   */
  addProductToSelection = (product_id: string, product_type: string) => {
    if (!this.selectedProductsId.includes(product_id)) {
      this.selectedProductsId.push(product_id);
      if (product_type == 'Image') {
        this.selectedImageProductsId.push(product_id);
      }
    }
  };

  /**
   * Removes the given product ID from the selected products list if it exists.
   * @param {string} product_id - The ID of the product to be removed from the selection.
   */
  removeProductFromSelection = (product_id: string) => {
    const index = this.selectedProductsId.indexOf(product_id);
    if (index !== -1) {
      this.selectedProductsId.splice(index, 1);
      this.selectedImageProductsId.splice(index, 1);
    }
  };

  /**
   * Handles the change event when a product's selection status is toggled.
   * Calls either `addProductToSelection` or `removeProductFromSelection` based on isSelected parameter.
   * @param {string} product_id - The ID of the product being changed.
   * @param {boolean} isSelected - The new selection status of the product.
   */
  onChange = (product_id, isSelected, product_type) => {
    if (isSelected) {
      this.addProductToSelection(product_id, product_type);
    } else {
      this.removeProductFromSelection(product_id);
    }
  };

  /**
   * Checks if the given product ID is present in the selected products list.
   * @param {string} product_id - The ID of the product to check.
   * @returns {boolean} - Whether the product is selected.
   */
  isSelectedProduct = (product_id) => {
    return this.selectedProductsId.includes(product_id);
  };

  /**
   * Removes selected products from the wishlist by sending a request to the backend.
   * Uses the selectedProductsId array to specify the products to be removed.
   * Reloads user wishlists upon successful removal and displays success or error messages using the toast service.
   * Hides the pre-confirm modal upon completion.
   */
  removeProductsFromWishList = () => {
    // Prepare data for the API request
    let postData = JSON.parse(this.user);
    postData['wishlist_id'] = this.wishlistId;
    postData['product_id'] = this.selectedProductsId;

    // Make the API request to remove selected products
    this.wishlist.removeProductFromWishlist(postData).subscribe(
      (res) => {
        if (!res['error']) {
          // Display success message, reload user wishlists, and hide modal
          this.loadUserWishlists();
          this.toastService.success(res['message']);
          this.selectedImageProductsId = []
          this.selectedProductsId = [];
        } else {
          // Display error message and hide modal
          this.toastService.error(res['message']);
          this.selectedProductsId = [];
        }
        this.showPreConfirmModal = false;
      },
      (error) => {
         console.log('error : ', error);
        this.showPreConfirmModal = false;
      }
    );
  };

  /**
   * Prepares data for pre-confirm modal and displays it for product deletion.
   * Updates preconfirmPopupConfigData to include information about the product to be deleted.
   * @param single_product_deletion - Flag to indicate if it's a single product deletion
   * @param product_id - ID of the product to be deleted
   */
  preConfirmForProductDeletion = (
    single_product_deletion = false,
    product_id = ''
  ) => {
    this.showPreConfirmModal = true;
    this.preconfirmPopupConfigData['parent_passed_data'] = {};
    this.preconfirmPopupConfigData['parent_passed_data'][
      'single_product_deletion'
    ] = single_product_deletion;
    this.preconfirmPopupConfigData['parent_passed_data']['product_id'] =
      product_id;
  };

  /**
   * Removes a single product from the wishlist by sending a request to the backend.
   * Displays success or error messages using the toast service.
   * Removes the product from selection if already selected.
   * Reloads user wishlists upon successful removal.
   * Hides the pre-confirm modal upon completion.
   * @param product_id - ID of the product to be removed
   */
  removeSingleProductFromWishList = (product_id) => {
    // Prepare data for the API request
    let postData = JSON.parse(this.user);
    postData['wishlist_id'] = this.wishlistId;
    postData['product_id'] = [product_id];

    // Make the API request to remove the single product
    this.wishlist.removeProductFromWishlist(postData).subscribe(
      (res) => {
        if (!res['error']) {
          // Display success message, reload user wishlists, and hide modal
          this.loadUserWishlists();
          this.toastService.success(res['message']);
        } else {
          // Display error message and hide modal
          this.toastService.error(res['message']);
        }
        this.showPreConfirmModal = false;
      },
      (error) => {
         console.log('error : ', error);
        this.showPreConfirmModal = false;
      }
    );
  };
  /* ======= End: Remove Products to Wishlist ======= */

  /**
   * This function is responsible for refreshing the user's wishlists.
   * It fetches the wishlist data for the current user from the API,
   * updates the user's wishlist data in the account actions service,
   * and then filters the wishlist data to find the specific wishlist
   * matching the provided `wishlistId`.
   *
   * If the wishlist is found, it updates the `wishlistData` property
   * to the matched wishlist data. If the wishlist is not found (likely
   * due to deletion), it navigates the user to the "All Wishlist" page.
   *
   * Note: This function assumes that the `wishlist` service and the
   * `accountActions` service are available within the component's scope.
   */
  loadUserWishlists = () => {
    if (this.user) {
      this.loading(true);
      this.wishlist.getWishlist(this.user).subscribe((data) => {
        this.accountActions.updateUserWishlist(data['data']['wishlists']);
        this.accountActions.userWishLists.subscribe((allWishlistData) => {
          if (allWishlistData && Array.isArray(allWishlistData)) {
            this.wishlistData = allWishlistData.filter((wishlist) => {
              if (wishlist.id == this.wishlistId) {
                return true;
              }
            });
            if (this.wishlistData && this.wishlistData[0]) {
              this.wishlistData = this.wishlistData[0];
            } else {
              // If wishlist deleted then navigate to all wishlist page
              this.router.navigate(['/mywishlist']);
            }
          }
          this.showWishlistModal = false;
          this.loading(false);
        });
      });
    }
  };

  /* ======= Start: Handling children Component events ======= */
  /**
   * Handles the event emitted by the pre-confirm modal.
   * Depending on the event parameters, either removes a single product or multiple selected products from the wishlist,
   * or cancels the operation by hiding the pre-confirm modal.
   * @param event - Event object emitted by the pre-confirm modal
   */
  handlePreConfirmModalEvent = (event) => {
    if (event['confirm']) {
      if (event['parent_passed_data']) {
        if (
          event['parent_passed_data']['single_product_deletion'] &&
          event['parent_passed_data']['product_id']
        ) {
          // Remove Single Product from wishlist
          this.removeSingleProductFromWishList(
            event['parent_passed_data']['product_id']
          );
        } else {
          // Remove Multiple selected products from wishlist
          this.removeProductsFromWishList();
        }
      }
    } else {
      // Cancel the operation by hiding the pre-confirm modal
      this.showPreConfirmModal = false;
    }
  };

  /**
   * Handles the event emitted by the wishlist modal.
   * If the event indicates success, reloads user wishlists to reflect changes.
   * If the event indicates failure, hides the wishlist modal.
   * @param event - Event object emitted by the wishlist modal
   */
  handleWishlistModalEvent = (event) => {
    if (event['success']) {
      // Reload user wishlists to reflect changes
      this.loadUserWishlists();
    } else {
      // Hide the wishlist modal
      this.showWishlistModal = false;
    }
  };
  /* ======= End: Handling children Component events ======= */

  getUserInfo() {
    this.userData
      .getUserInformation(
        JSON.parse(localStorage.getItem('user_data'))['Utype']
      )
      .subscribe((data) => {
        for (let i = 0; i < data['data']['plans'].length; i++) {
          if (data['data']['plans'][i]['package_type'] == 'Image') {
            this.userSubscriptionPlans.push(data['data']['plans'][i]);
          }
        }
      });
    this.plansChecked = true;
  }

  multiDownload() {
    if (this.selectedImageProductsId.length > 0) {
      if (this.userSubscriptionPlans.length < 1) {
        $('.download_popup_no_plan').toggle();
      } else {
        $('.download_popup_high_res_plan').toggle();
      }
    } else {
      this.toastService.error(messages.wishlistMultiDownload);
    }
  }

  downloadWithSubscriptionPlan(planId) {
    alert(planId);
    this.selectedPlanId = planId;
  }

  onNoPlanCancel() {
    $('.download_popup_no_plan').toggle();
  }

  upgradePlan() {
    this.router.navigate(['/pricing']);
  }

  downloadNow() {
    const userData = localStorage.getItem('user_data');
    this.userAllInfo = {
      selectedValues: this.selectedImageProductsId,
      userData: userData,
      planId: this.selectedPlanId,
    };

    this.download
      .downloadMultipleProducts(this.userAllInfo)
      .subscribe((data) => {
        if (data.status == "wrong_plan" || data.status == 'download_limit') {
          this.toastService.error(data.message);
        } else if (data.status == 'download_success') {
          this.toastService.success(data.message);
        }
      });
  }

  onHighResolutionCancel() {
    $('.download_popup_high_res_plan').toggle();
  }

  ngDoCheck() {
    this.userLogined = localStorage.getItem('user_data') != null;
    this.emptyUserPlansList = this.userSubscriptionPlans.length == 0;
    if (this.userLogined && !this.plansChecked) {
      this.getUserInfo();
    }
  }

  openItem(item) {

    const product_type = item.product_main_type.toLowerCase();
    const product_slug = item.slug;
    this.router.navigate(['/'+ product_type + '/'+ product_slug]);
  }
}
