<section class="image_description_section"  *ngIf="!invalidData">
  <div class="container">
    <div class="image_description_info">
      <div class="footage_info_image_section">
        <div class="footage_image">
          <picture>
          <img
            *ngIf="itemData && !itemData.video"
            src="{{ itemData?.product_thumbnail }}"
            alt=""
          />
        </picture>
          <div class="image_icon">
            <ul>
              <li class="like">
                <a
                  class="btn btn-primary"
                  (click)="showModal()"
                  data-toggle="modal"
                  data-target="#likeModal"
                  class="btn_background"
                  style="cursor: pointer;"
                >
                  <img
                    *ngIf="!userWishlistedData.includes(itemData?.product_thumbnail)"
                    src="assets/images/heart-icon.svg"
                    alt="like"
                    width="24"
                    height="24"
                    class="white_heart"
                  />
                  <img
                    *ngIf="userWishlistedData.includes(itemData?.product_thumbnail)"
                    src="assets/images/red_heart.svg"
                    alt="red_heart"
                    width="24"
                    height="24"
                    class="red_heart"
                  />
                </a>
              </li>
              <li>
                <a
                  *ngIf="
                    !getAlreadyDownloadedImageFlag &&
                    !emptyUserPlansList &&
                    userChoosedItem &&
                    userLogined && displayDownload
                  "
                  type="button"
                  data-toggle="modal"
                  (click)="openSelectedPlans()"
                  class="downloadForTry btn_background"
                  style="cursor: pointer;"
                >
                  <img
                    src="assets/images/download_plan.svg"
                    alt="download"
                    width="24"
                    height="24"
                  />
                </a>
                <a
                  *ngIf="getAlreadyDownloadedImageFlag && userLogined"
                  type="button"
                  (click)="reDownload(itemData?.mediaId)"
                  class="downloadForTry btn_background"
                >
                  <img
                    src="assets/images/download_plan.svg"
                    alt="download"
                    width="24"
                    height="24"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="footage_image_content">
          <div class="footage_image_content_heading">
            <h4 class="h4">Description</h4>
            <p>
              {{ itemData?.product_description }}
            </p>
          </div>
          <div class="footage_image_keywords">
            <h4 class="h4">Keywords:</h4>
            <div class="keywords">
              <div
                class="keywords_btn"
                *ngFor="let item of itemData?.product_keywords?.split(',') | slice:0:10"
              >
                <a
                  id="key_{{ item }}"
                  (click)="onClickKeyword(item)"
                  >{{ item }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="music_info_content_section">
        <form
          class="col-md-5 buy-info"
          [formGroup]="AddToCartForm"
          (ngSubmit)="onSubmit()"
          *ngIf="this.AddToCartForm"
        >
          <div class="music_info_heading">
            <div *ngIf="!isFullTitleDisplayed" (click)="toggleTitle()" style="cursor: pointer;">
              <h2 class="h2">{{ truncatedTitle }}...</h2>
            </div>
            <div *ngIf="isFullTitleDisplayed" (click)="toggleTitle()" style="cursor: pointer;">
              <h2 class="h2">{{ itemData?.product_title }}</h2>
            </div>
            <p>Credit: {{itemData?.author_username !== null ? itemData?.author_username :''}} | Product ID: {{ itemData?.product_id }}</p><br>
            <i class="fa fa-info-circle" aria-hidden="true" *ngIf="itemData?.editorial ==1"></i>&nbsp;<span class="h3" *ngIf="itemData?.editorial ==1">This is editorial product</span>
          </div>

          <div class="music_info_licence_type" >
            <h3 class="h3">Licence type</h3>

            <div class="licence_type" *ngFor="let licence of licenceData; let i = index;" >
              <input
                type="radio"
                name="radio-group"
                aria-label="radio-btn"
                [checked]="i == licenceCheck"
                [formControlName]="choosenLicense"
                name="choosenLicense"
                value="{{licence.id}}"
                (click)="hideShowExtended(licence.licence_name,licence.id)"
              />
              <label for="radio-group">
                <span class="licence_name">{{licence.licence_name}}</span>
                <span class="info_image extended-license"
                (click)="hideShowExtendedInfo(licence)"
                  ><img
                    src="assets/images/info.svg"
                    alt="info"
                    width="16"
                    height="16"
                    class="extended-img"
                /></span>
              </label>
              <div class="extended-license_pop_up" *ngIf="extendedInfo &&  selectedLicense?.id === licence.id" [innerHTML]="selectedLicense?.description">
              </div>
            </div>
          </div>
          <div class="price" *ngIf="displayCart">
            <h3 class="h3">Price</h3>
            <div class="price_box_inner" *ngIf="filterVersion.length > 0">
              <ng-container *ngFor="let version of filterVersion;let i = index;">
                <a
                  class="price_box"
                  *ngIf="version.label != 'Large'"
                  id="{{ version?.label }}"
                >
                  <input
                    *ngIf="itemData?.video"
                    type="radio"
                    formControlName="choosenItem"
                    name="choosenItem"
                    value="{{ stringify(version) }}"
                    id="version_{{ version?.size }}"
                  />
                  <span *ngIf="itemData?.video" class="rupees"
                    >₹{{ version.price }}</span
                  >
                  <span *ngIf="itemData?.video" class="minutes">{{
                    version.label
                  }}</span>
                  <input
                    *ngIf="!itemData?.video"
                    type="radio"
                    [formControlName]="choosenItem"
                    name="choosenItem"
                    value="{&quot;version&quot;: &quot;{{
                      version.version
                    }}&quot;,&quot;price&quot;: &quot;{{
                      version.price
                    }}&quot;,&quot;is_premium&quot;: &quot;{{
                      version.is_premium
                    }}&quot;}"
                    id="version_{{ version?.version }}"
                    [checked]="i == 0"
                    (change)="changePrice($event, i)"
                  />
                  <span *ngIf="!itemData?.video" class="rupees"
                    >₹{{ version.price }}</span
                  >
                  <span *ngIf="!itemData?.video" class="minutes"></span
                  >{{ version.version }}
                </a>
              </ng-container>
            </div>
          </div>
          <div class="music_info_btn">
            <button *ngIf="userLogined && displayCart" type="submit" class="add_to_cart">
              Add To Cart
            </button>
            <a
              *ngIf="!userLogined"
              type="button"
              class="add_to_cart"
              routerLink="/signin"
              >Add To Cart</a
            >
            <a
              *ngIf="
                !getAlreadyDownloadedImageFlag && displayDownload && !hideDownloadPanther
              "
              type="button"
              data-toggle="modal"
              class="btn btn-primary download"
              (click)="openSelectedPlans()"
              >Download
            </a>

            <a
              *ngIf="hideDownloadPanther
              "
              type="button"
              data-toggle="modal"
              class="btn btn-primary download"
              (click)="openToastr('This image is not downloaded you need to purchase download pack for this')"
              >Download
            </a>

            <a
              *ngIf="getAlreadyDownloadedImageFlag && userLogined"
              type="button"
              class="btn btn-primary download"
              (click)="reDownload()"
              >Re Download
            </a>
          </div>
          <div class="cart_pop_up" *ngIf="showCartPopup">
            <div class="cart_top">
              <h4 class="h4">Item Added To a Cart</h4>
              <!-- <span class="add_items">1/1</span> -->
            </div>
            {{ addedToCartData }}
            <div class="cart_middle">
              <div class="pop_up_image">
                <img
                  src="{{
                    itemData?.product_thumbnail
                  }}"
                  alt="cart-popup-image"
                  width="339"
                  height="140"
                />
              </div>
              <div class="plan_details">
                <div class="plan_details_left">
                  <h4 class="h4">
                    {{

                      (itemData?.product_title.length>10)? (itemData?.product_title | slice:0:10)+'...':(itemData?.product_title)

                    }}
                  </h4>
                </div>
                <div class="plan_details_right">
                  <span class="value">₹{{ addedToCartData }}</span>
                </div>
              </div>
            </div>
            <div class="cart_bottom">
              <h4 class="h4">Cart Subtotal: ₹{{ addedToCartData }}</h4>
              <div class="checkout">
                <a routerLink="/payment-method">Checkout</a>
              </div>
              <div class="view_cart">
                <a routerLink="/cart">View My Cart</a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<section class="description_similar_images" *ngIf="relatedData?.length> 0 && !invalidData">
  <div class="container">
    <h2 class="h2">Similar Images</h2>
    <ng-opengallery class="gallery-row"	[datasource]="relatedDataAuthor" [config]="config" (selection)="openItemGallerySimilar($event)"></ng-opengallery>
    <!-- <div class="image_grid">
      <div class="image_grid_1 upper_grid">
        <ng-container *ngFor="let item of relatedData; let i = index">
          <div *ngIf="i == 0">
            <div class="landscape_big_img main_image">
              <img
                src="{{ item?.product_thumbnail }}"
                alt=""
                (click)="
                  openItem(
                    item.slug
                  )
                "
              />
            </div>
          </div>
        </ng-container>

        <div class="first_image_grid">
          <div class="landscape_image_grid">
            <ng-container *ngFor="let item of relatedData; let id = index">
              <div class="main_image" *ngIf="id == 1 || id == 2">
                <img
                  src="{{ item?.product_thumbnail }}"
                  alt=""
                  (click)="
                    openItem(
                      item.slug
                    )
                  "
                />
              </div>
            </ng-container>
          </div>
          <div class="landscape_image_grid">
            <ng-container *ngFor="let item of relatedData; let is = index">
              <div class="main_image" *ngIf="is == 3 || is == 4">
                <img
                  src="{{ item?.product_thumbnail }}"
                  alt=""
                  (click)="
                    openItem(
                      item.slug
                    )
                  "
                />
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="custom-grid lower_grid">
        <ng-container *ngFor="let item of relatedData | slice:0:9; let ip = index">
          <div class="landscape_image_grid main_image" *ngIf="ip > 4">
            <img
              src="{{ item?.product_thumbnail }}"
              (click)="
                openItem(
                  item.slug
                )
              "
            />
          </div>
        </ng-container>
      </div>
    </div> -->
  </div>
</section>

<!-- Similar Images section end -->

<!-- Images from Same Contributor section start -->
<section class="description_same_author" *ngIf="isRelatedAuthorImages.length > 0 && !invalidData">
  <div class="container">
    <h2 class="h2">Images from Same Contributor</h2>
    <ng-opengallery class="gallery-row"	[datasource]="similarImagesData" [config]="config" (selection)="openItemGalleryauthor($event)"></ng-opengallery>
    <!-- <div class="image_grid">
      <div class="image_grid_1 first_grid">

        <div class="landscape_image_1 main_image"  *ngFor="let item of isRelatedAuthorImages | slice:0:2; let i = index" >
          <picture *ngIf="i==0 || i==1">

            <img src="{{item?.product_thumbnail}}" alt="image-description-image" width="581" height="414" (click)="
            openItem(
              item.slug
            )
          "  style="cursor: pointer;"/>
          </picture>
        </div>

      </div>


      <div class="image_grid_2 main_image" *ngIf="isRelatedAuthorImages.length > 0 && isRelatedAuthorImages[2]" >
        <picture>
          <img src="{{isRelatedAuthorImages[2] && isRelatedAuthorImages[2].product_thumbnail}}" alt="image-description-image" width="791" height="844"  (click) ="openItem(
            isRelatedAuthorImages[2].slug
          )" style="cursor: pointer;"/>
        </picture>

      </div>


      <div class="image_grid_1 third_grid" >
        <ng-container *ngFor="let item of isRelatedAuthorImages | slice:0:6;  let ip = index">
        <div class="landscape_image_2 main_image" *ngIf="ip>2" >

          <p>{{i}}</p>
          <picture>
            <img src="{{item?.product_thumbnail}}" alt="image-description-image" width="418" height="271" (click)=" openItem(
              item.slug
            )" style="cursor: pointer;"/>
          </picture>

        </div>
        </ng-container>
      </div>

    </div> -->
  </div>
</section>

<!-- User Plan list Modal start -->
<user-plan-modal
  [userSubscriptionPlans]="userSubscriptionPlans"
  (dataEvent)="handleUserPlanModalEvent($event)"
  [licenseId] = "AddToCartForm.value['choosenLicense']"
  [versionId] = ""
  *ngIf="showUserPlans"
>
</user-plan-modal>

<!-- User Plan list Modal end -->

<!-- Add To Wishlist Modal -->
<add-to-wishlist
  [productData]="itemData"
  (dataEvent)="handleWishlistModalEvent($event)"
  *ngIf="showAddToWishListModal"
>
</add-to-wishlist>
<!-- ./Add To Wishlist Modal -->

<!--For Invali Data-->
<appv2-static-product-data [type] = "2" *ngIf="invalidData"></appv2-static-product-data>


