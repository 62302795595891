export class DiscountDetailsInterface {
    id: number;
    page_type: string;
    title: string;
    description: string;
    link: string;
    button_text: string;
    status: number;
    created_at: string;
    updated_at: string;
}