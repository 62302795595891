import { createFeatureSelector, createSelector } from '@ngrx/store';
import { loadingNode } from './loading.reducer';
import { LoadingState } from './loading.models';
export const selectLoadingFeature = createFeatureSelector<LoadingState>(loadingNode);

// Here we create selectors to be able to show data from store in our components.

// selector fo text
export const selectLoadingStatus = createSelector(
  selectLoadingFeature,
  (state: LoadingState): any => state
);
