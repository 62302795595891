import { ChangeDetectorRef, Component, DoCheck, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  NavigationExtras,
  ParamMap,
  Router
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import $ from 'jquery';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { messages } from 'src/appv2/appv2.constants';
import { User } from 'src/appv2/models/user';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { selectCurrentRoute } from 'src/appv2/reducers/router/router.selector';
import {
  SearchChangeVisibility,
  SearchWriteAction,
  SearchchangeSearchName,
} from 'src/appv2/reducers/search/search.actions';
import { SearchState } from 'src/appv2/reducers/search/search.models';
import { selectSearchVisibility } from 'src/appv2/reducers/search/search.selectors';
import { AccountService } from 'src/appv2/services/account.service';
import { CacheService } from 'src/appv2/services/cache.service';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { urlToCacheKeyMapping } from 'src/appv2/utils/constants/discount.const';


@Component({
  selector: 'appv2-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, DoCheck {
  @Input('params') params;

  mainPageType: string = 'image';
  loggedinuser: User;
  world = 'My world';
  withCategories: boolean;

  pageName = '';
  searchType: number = 0;
  prevSearchType: number = 0;

  user: string = null;
  userName: string;

  destroy = new Subject();
  destroy$ = this.destroy.asObservable();

  isSearchVisible: boolean;
  isSearchPage: boolean;

  isUserLogined = false;
  visibleTrendingSearch: boolean;
  showTrendSerach: boolean = false;
  showMusicTrendSerach: boolean = false;

  recentSearches = [];
  searchtext: any;
  searchText;
  elasticSuggestions: any;
  musicSuggestions: any;
  trendingKeyword: any;
  page_type: string;
  imageQueryParams: any = '';
  footageQueryParams: any = '';
  musicQueryParams: any = '';
  searchquery;
  filterElasticSuggestion : any = [];
  filterElasticMusicSuggestion : any = []

  @ViewChild('searchButton') searchButton: ElementRef;

  /**
   * Open class of header component.
   */
  openClass: string = '';
  timeout: any = null;
  isHeaderPart: boolean = true;
  trendingCacheKeyName: string = 'search-trending-keyword';
  trendingCacheKey: string;
  isDisplayed = false;


  constructor(
    private landingPage: LandingPageService,
    private router: Router,
    private accountActions: AccountService,
    private store$: Store<any>,
    private route: ActivatedRoute,
    private cacheService: CacheService,
    private cdr: ChangeDetectorRef
  ) {
    this.loggedinuser = this.accountActions.userValue;
    this.getPageType();
    // Get Search bar trending api details
    this.trendingCacheKey = urlToCacheKeyMapping[this.trendingCacheKeyName];
    if (this.trendingCacheKey) {
      const trendingKeyword = this.cacheService.getData(this.trendingCacheKey);
      if (typeof trendingKeyword !== 'undefined') {
        this.trendingKeyword = trendingKeyword;
      } else {
        this.getTrendingKeywords();
      }
    }
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((queryParams: ParamMap) => {
      this.searchtext = queryParams.get('search');
    });

    this.getTrendingKeywords();
    fromEvent(window, 'scroll')
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: Event) => {
        this.store$.dispatch(
          new SearchChangeVisibility(<SearchState>{
            searchVisibile: window.pageYOffset >= 800,
          })
        );
      });
    this.user = localStorage.getItem('user_data');

    this.store$.pipe(select(selectSearchVisibility)).subscribe((data) => {
      this.isSearchVisible = data;
    });
    this.store$.pipe(select(selectCurrentRoute)).subscribe((data) => {
      if (data != undefined) {
        document.querySelector('.navbar')?.classList.remove('show');
        this.isSearchPage = false;
        switch (data.routeConfig.path) {
          case '':
            this.pageName = 'home';
            break;

          case 'search':
            this.pageName = data.queryParams.type;
            this.isSearchPage = true;
            break;
          case 'image':
            this.pageName = data.routeConfig.path;
            break;
          case 'footage':
            this.pageName = data.routeConfig.path;
            break;
          case 'editorial':
            this.pageName = data.routeConfig.path;
            break;
          case 'item':
            this.isSearchPage = true;
            this.pageName = (<string>(
              data.queryParams.itemMainType
            )).toLowerCase();
            break;

          default:
            this.pageName = 'nonMain';
            break;
        }
      }
      window.scrollTo(0, 0);
    });

    this.recentSearches = JSON.parse(localStorage.getItem('recent_search'));
    if (this.params != undefined) {
      this.isHeaderPart = this.params.isHeaderPart;
    }
  }

  ngDoCheck() {
    this.cdr.detectChanges();
    //this.trendingKeyword = this.landingPage.getSearchTrendingData()
  }

  toggleDisplay() {
    this.isDisplayed = !this.isDisplayed;
    this.searchButton.nativeElement.style.display = this.isDisplayed ? 'block' : 'none';
  }


  getPageType() {
    const urlSegments = this.route.snapshot.url;
    const lastSegment = urlSegments[urlSegments.length - 1];
    const lastSegmentPath = lastSegment?.path;
    this.page_type = lastSegmentPath;
    if (this.page_type != undefined) {
      if (this.page_type == 'home' || this.page_type == 'pricing') {
        this.mainPageType = 'image';
      } else {
        this.mainPageType = this.page_type;
      }
    }
  }

  clickOpenMenu() {
    if(!this.isHeaderPart && this.page_type != '' && this.page_type != 'home') {
      // on listing pages disable click button
      return;
    }
    this.visibleTrendingSearch = false;
    this.showTrendSerach = false;
    if (this.openClass === 'open') {
      this.openClass = '';
    } else {
      this.openClass = 'open';
    }
  }

  logout() {
    this.accountActions.logout();
  }

  /**
   * Redirect to the home page.
   */
  redirectToHomePage(): void {
    this.router.navigate(['/home']);
  }

  /**
   * Get trending keyword list
   * @param searchText
   */
  getTrendingKeywords(searchText: string = '') {
    this.searchText = searchText;
    this.landingPage
      .getTrendingKeywordList(this.searchText)
      .subscribe((data) => {
        this.trendingKeyword = data.data;
        this.cacheService.setData(this.trendingCacheKey, this.trendingKeyword);
        this.landingPage.isTrendingSearchCalled.next(true);
        this.landingPage.setSearchTrendingData(data.data);
        this.landingPage.setTrendingDataForFooter(data.data);
      });
  }

  onEnterSearch() {
    // save to local storage
    let recentSearchData = JSON.parse(localStorage.getItem('recent_search'));
    this.recentSearches = recentSearchData;
      this.searchtext= this.searchtext.trim()
      if (this.searchtext !=="") {
      if (!recentSearchData) {
        recentSearchData = [];
      }
      if (recentSearchData.length > 4) {
        recentSearchData.shift();
      }
      const isStringInArray = recentSearchData.some(item => item.toLowerCase() === this.searchtext.toLowerCase());

      if (this.searchtext && !isStringInArray) {
        recentSearchData.push(this.searchtext);
      }
      localStorage.setItem('recent_search', JSON.stringify(recentSearchData));

      if (
        this.page_type == 'image' ||
        this.mainPageType == 'image' ||
        this.page_type == 'images'
      ) {

        this.store$.dispatch(
          new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.imagePageLoading })
        );
        const queryParams = { search: this.searchtext };

        const navigationExtras: NavigationExtras = {
          queryParams: queryParams,
        };
        this.router.navigate(['/images'], navigationExtras);
      }else if ($.inArray(this.page_type, ['footage', 'footages']) > -1) {
        this.store$.dispatch(
          new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.footagePageLoading })
        );
        const queryParams = { search: this.searchtext };

        const navigationExtras: NavigationExtras = {
          queryParams: queryParams,
        };
        this.router.navigate(['/footages'], navigationExtras);
      }else if ($.inArray(this.page_type, ['music', 'musics']) > -1) {
        this.store$.dispatch(
          new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.musicPageLoading })
        );
        const queryParams = { search: this.searchtext };

        const navigationExtras: NavigationExtras = {
          queryParams: queryParams,
        };
        this.router.navigate(['/musics'], navigationExtras);
      } else {
        this.openCategory(this.searchtext);
      }
    } else {
      if (this.page_type == 'image' || this.page_type == 'images') {

        this.router.navigate(['/images']);
      } else if (this.page_type == 'footage' || this.page_type == 'footages' ) {
        this.router.navigate(['/footages']);
      } else if (this.page_type == 'music' || this.page_type == 'musics' ) {
        this.router.navigate(['/musics']);
      }
    }
  }

  public loading(status: boolean) {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: status })
    );
    return;
  }

  /**
   * Store keyword in elastic search API
   * @param keyword
   */
  storeKeywordInElasticSearch(keyword) {
    const bodyParams = {
      search_field: keyword,
    };
    // this.loading(true);
    this.landingPage
      .storeKeywordInElasticSearch(bodyParams)
      .subscribe((data) => {
        this.loading(false);
      });
  }

  /**
   * Determines whether key press on
   * @param event
   */
  onKeyPress(event: any) {
    clearTimeout(this.timeout);
    this.openClass = '';
    this.showMusicTrendSerach = false;
    this.timeout = setTimeout(() => {
      this.searchtext = event.target.value;
      if (this.searchtext && this.searchtext.trim().length != 0 ) {
       if(event.keyCode != 13){
          this.getSuggestionByElasticSearch(this.searchtext);
       }

      } else {
        this.showTrendSerach = false;
        this.visibleTrendingSearch = true;
      }
    }, 1000);
    if (event.keyCode == 13) {
      this.showTrendSerach = false;
      this.storeKeywordInElasticSearch(this.searchtext);
      setTimeout(()=>{
        this.onEnterSearch();
      },1500)
    }
  }

  /**
   * Gets suggestion keyword list by elastic search
   * @param searchText
   */
  getSuggestionByElasticSearch(searchText) {
    this.searchtext = searchText;
    if (this.mainPageType == 'music' || this.mainPageType == 'musics') {
      this.landingPage
        .getSuggestionByMusicSearch(this.searchtext)
        .subscribe((data) => {
          if (data.data.length !=0) {
            this.musicSuggestions = data.data;
            const uniqueNamesSet = new Set();
            this.filterElasticMusicSuggestion = []

            this.musicSuggestions.forEach(obj => {
              const lowerCaseName = obj.toLowerCase();
              if (!uniqueNamesSet.has(lowerCaseName)) {
                uniqueNamesSet.add(lowerCaseName);
                this.filterElasticMusicSuggestion.push(obj);
              }
            });

            this.showTrendSerach = false;
            this.showMusicTrendSerach = true;
          }else{
            this.showTrendSerach = false;
            this.showMusicTrendSerach = false;
          }
        });
    } else {
      this.landingPage
        .getSuggestionByElasticSearch(this.searchtext)
        .subscribe((data) => {

          if (data.data.length !== 0) {
            this.elasticSuggestions = data.data; // Commom

            const uniqueNamesSet = new Set();
            this.filterElasticSuggestion = []
            this.showTrendSerach = true;
            this.visibleTrendingSearch = false;

            this.elasticSuggestions.forEach(obj => {
              const lowerCaseName = obj._source.search.toLowerCase();
              if (!uniqueNamesSet.has(lowerCaseName)) {
                uniqueNamesSet.add(lowerCaseName);
                this.filterElasticSuggestion.push(obj);
              }
            });
            }else{
              this.showTrendSerach = false;
              this.visibleTrendingSearch = true;
            }
          });
    }
  }

  public onInput(event: any) {
    const inputValue = event.target.value;
    // Define a regular expression to match letters, numbers, and spaces
    const regex = /^[a-zA-Z0-9\s]*$/;

    if (!regex.test(inputValue)) {
      // If the input contains characters other than letters, numbers, and spaces,
      // remove those characters from the input value
      event.target.value = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
    }
  }

  openCategory(catName) {
    this.searchtext = catName;
    this.searchEditorial(this.mainPageType,catName);
  }

  onMouseInSearchBox() {
    this.visibleTrendingSearch = true;
  }

  onMouseOutSearchBox() {
    this.visibleTrendingSearch = false;
    this.showTrendSerach = false;
  }

  searchEditorial(page,search='') {

    this.mainPageType = page;
    if(search != ''){
      this.searchtext = search
    }
    if (this.searchtext && (page == 'image' || page == 'images')) {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.imagePageLoading })
      );
      this.showMusicTrendSerach = false
      this.showTrendSerach = false
      this.imageQueryParams = { search: this.searchtext };
      const navigationExtras: NavigationExtras = {
        queryParams: this.imageQueryParams,
      };
      if (this.page_type == 'images') {
        this.store$.dispatch(
          new SearchWriteAction(<SearchState>{
            searchText: 'category ' + this.searchtext,
          })
        );
        this.router.navigate(['/images'], {
          queryParams: { search: this.searchtext },
        });
      } else {
        this.router.navigate(['/images'], navigationExtras);
      }
    } else if (
      this.searchtext &&
      (page == 'footage' || page == 'footages')
    ) {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.footagePageLoading })
      );
      this.showMusicTrendSerach = false
      this.showTrendSerach = false
      this.footageQueryParams = { search: this.searchtext };
      const navigationExtras: NavigationExtras = {
        queryParams: this.footageQueryParams,
      };
      if (this.page_type == 'footages') {
        this.store$.dispatch(
          new SearchWriteAction(<SearchState>{
            searchText: 'category ' + this.searchtext,
          })
        );
        this.router.navigate(['/footages'], {
          queryParams: { search: this.searchtext },
        });
      } else {
        this.router.navigate(['/footages'], navigationExtras);
      }
    } else if (
      (this.searchtext && page == 'editorial') ||
      page == 'editorials'
    ) {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.editorialPageLoading })
      );
      this.showMusicTrendSerach = false
      this.showTrendSerach = false
      const editorialQueryParams = { search: this.searchtext };
      const navigationExtras: NavigationExtras = {
        queryParams: editorialQueryParams,
      };
      if (this.page_type == 'editorials') {
        this.store$.dispatch(
          new SearchWriteAction(<SearchState>{
            searchText: 'category ' + this.searchtext,
          })
        );
        this.router.navigate(['/editorials'], {
          queryParams: { search: this.searchtext },
        });
      } else {
        this.router.navigate(['/editorials'], navigationExtras);
      }
    } else if (
      this.searchtext &&
      (page == 'music' || page == 'musics')
    ) {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.musicPageLoading })
      );
      this.showMusicTrendSerach = false
      this.showTrendSerach = false
      this.musicQueryParams = { search: this.searchtext };
      const navigationExtras: NavigationExtras = {
        queryParams: this.musicQueryParams,
      };
      if (this.page_type == 'musics') {
        this.store$.dispatch(
          new SearchWriteAction(<SearchState>{
            searchText: 'category ' + this.searchtext,
          })
        );
        this.router.navigate(['/musics'], {
           queryParams: { search: this.searchtext },
         });
      } else {
        this.router.navigate(['/musics'], navigationExtras);
      }
    } else {
      this.store$.dispatch(
        new SearchchangeSearchName(<SearchState>{ searchType: page })
      );
    }
  }
}
