import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { timer, of } from 'rxjs';
import { tap, mergeMap, catchError } from 'rxjs/operators';
import { SetError } from '../errors/errors.actions';
import { LoadingActions, loadingActionsType } from './loading.actions';

@Injectable()
export class SearchEffects {
  constructor(
    private actions$: Actions
  ) { }

  @Effect()
  addTodoAsync$ = this.actions$.pipe(
    ofType<LoadingActions>(loadingActionsType.loadingUpdated),
    mergeMap(action => timer(2000).pipe(
      tap(() => {
        throw (new Error(`${action.payload.loadingStatus} failed`))
      }),
      //map(() => {
      //return new AddTodo(action.payload);
      //}),
      catchError(error => of(new SetError({ error })))
    ))
   )

}