import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgOpengalleryModule } from 'ng-opengallery';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxPaginationModule } from 'ngx-pagination';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { environment } from 'src/environments/environment';
import { AddToWishlistComponent } from '../common/add-to-wishlist/add-to-wishlist.component';
import { CategoryDataComponent } from '../common/category-data/category-data.component';
import { FooterComponent } from '../common/footer/footer.component';
import { HeaderComponent } from '../common/header/header.component';
import { PaginationComponent } from '../common/pagination/pagination.component';
import { PreConfirmModalComponent } from '../common/pre-confirm-modal/pre-confirm-modal.component';
import { UserPlanModalComponent } from '../common/user-plan-modal/user-plan-modal.component';
import { FormModalComponent } from '../common/wishlist-form-modal/wishlist-form-modal.component';
import { RotatePipe } from '../pipes/rotate.pipe';
import { AboutUsComponent } from './about-us/about-us.component';
import { AddedWishlistOwnAccountComponent } from './added-wishlist-own-account/added-wishlist-own-account.component';
import { CartComponent } from './cart/cart.component';
import { CategoryDetailsComponent } from './category-details/category-details.component';
import { CategoryListingComponent } from './category-listing/category-listing.component';
import { CategoryComponent } from './category/category.component';
import { CmsPageComponent } from './cms-page/cms-page.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditorialGalleryListingComponent } from './editorial-gallery-listing/editorial-gallery-listing.component';
import { EditorialListingComponent } from './editorial-listing/editorial-listing.component';
import { EditorialPageComponent } from './editorial-page/editorial-page.component';
import { FilterComponent } from './filter/filter.component';
import { FootageDetailsComponent } from './footage-details/footage-details.component';
import { FootageFilterComponent } from './footage-filter/footage-filter.component';
import { FootageHomeComponent } from './footage-home/footage-home.component';
import { FootageListingV1Component } from './footage-listing-v1/footage-listing-v1.component';
import { FootageListingComponent } from './footage-listing/footage-listing.component';
import { FootagePageComponent } from './footage-page/footage-page.component';
import { GalleryComponent } from './gallery/gallery.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ImageDescriptionComponent } from './image-description/image-description.component';
import { ImageFilterComponent } from './image-filter/image-filter.component';
import { ImageListingComponent } from './image-listing/image-listing.component';
import { ImagePageComponent } from './image-page/image-page.component';
import { ImagePopupComponent } from './image-popup/image-popup.component';
import { LicenceAgreementComponent } from './licence-agreement/licence-agreement.component';
import { LoaderComponent } from './loader/loader.component';
import { MusicDetailsComponent } from './music-details/music-details.component';
import { MusicFilterComponent } from './music-filter/music-filter.component';
import { MusicListingComponent } from './music-listing/music-listing.component';
import { MusicPageComponent } from './music-page/music-page.component';
import { MyPlanComponent } from './my-plan/my-plan.component';
import { MyWishlistInnerComponent } from './my-wishlist-inner/my-wishlist-inner.component';
import { MyWishlistComponent } from './my-wishlist/my-wishlist.component';
import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { PaymentMethodPageComponent } from './payment-method-page/payment-method-page.component';
import { PricingFilterComponent } from './pricing-filter/pricing-filter.component';
import { PricingPageComponent } from './pricing-page/pricing-page.component';
import { QuestionsComponent } from './pricing-page/questions/questions.component';
import { SecureInfoPricingComponent } from './pricing-page/secure-info-pricing/secure-info-pricing.component';
import { SimplePricingComponent } from './pricing-page/simple-pricing/simple-pricing.component';
import { TeamPricingComponent } from './pricing-page/team-pricing/team-pricing.component';
import { PricingPlanpageComponent } from './pricing-planpage/pricing-planpage.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DeleteAccountComponent } from './profile-page/delete-account/delete-account.component';
import { DownloadHistoryComponent } from './profile-page/download-history/download-history.component';
import { MyProfileComponent } from './profile-page/my-profile/my-profile.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { PurchaseHistoryComponent } from './profile-page/purchase-history/purchase-history.component';
import { SecurityComponent } from './profile-page/security/security.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { StaticProductDataComponent } from '../common/static-product-data/static-product-data.component';
import { MusicPlayerComponent } from '../common/music-player/music-player.component';



@NgModule({
  declarations: [
    PagesComponent,
    CartComponent,
    DashboardComponent,
    ImagePageComponent,
    FootagePageComponent,
    EditorialPageComponent,
    PricingPageComponent,
    MusicPageComponent,
    HeaderComponent,
    FooterComponent,
    PaymentMethodPageComponent,
    ContactUsComponent,
    CmsPageComponent,
    ImagePopupComponent,
    LoaderComponent,
    ProfilePageComponent,
    PricingPlanpageComponent,
    MyWishlistComponent,
    MyWishlistInnerComponent,
    MyPlanComponent,
    MusicListingComponent,
    MusicFilterComponent,
    MusicDetailsComponent,
    ImageListingComponent,
    EditorialListingComponent,
    ImageFilterComponent,
    ImageDescriptionComponent,
    FootageListingComponent,
    FootageListingV1Component,
    FootageFilterComponent,
    FootageHomeComponent,
    FootageDetailsComponent,
    HomePageComponent,
    AboutUsComponent,
    LicenceAgreementComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    MyProfileComponent,
    PurchaseHistoryComponent,
    SecurityComponent,
    GalleryComponent,
    RotatePipe,
    QuestionsComponent,
    SimplePricingComponent,
    TeamPricingComponent,
    SecureInfoPricingComponent,
    FilterComponent,
    SearchBarComponent,
    DeleteAccountComponent,
    AddedWishlistOwnAccountComponent,
    PricingFilterComponent,
    AddToWishlistComponent,
    PreConfirmModalComponent,
    FormModalComponent,
    CategoryListingComponent,
    EditorialGalleryListingComponent,
    UserPlanModalComponent,
    DownloadHistoryComponent,
    PaginationComponent,
    CategoryComponent,
    CategoryDetailsComponent,
    CategoryDataComponent,
    StaticProductDataComponent,
    MusicPlayerComponent
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    SlickCarouselModule,
    FormsModule, ReactiveFormsModule,
    RecaptchaModule, RecaptchaFormsModule,
    NgxPaginationModule,
    NgOpengalleryModule,
    DateRangePickerModule,
    ColorPickerModule,
    NgxSliderModule
  ],
  entryComponents: [
    MyProfileComponent,
    PurchaseHistoryComponent,
    SecurityComponent,
    DeleteAccountComponent
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
    DatePipe
  ]

})
export class PagesModule { }
