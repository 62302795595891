import { Component, Input,Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from 'src/appv2/models/user';
import { AccountService } from 'src/appv2/services/account.service';
import $ from 'jquery';

@Component({
  selector: 'pre-confirm-modal',
  templateUrl: './pre-confirm-modal.component.html',
  styleUrls: ['./pre-confirm-modal.component.scss']
})
export class PreConfirmModalComponent implements OnInit, OnDestroy {
  user: User;
  @Input() popupConfiguration: any; // Define an input property to receive data
  @Output() dataEvent = new EventEmitter<any>(); // Define an output property to emit events

  popupMessage:string = "Are you sure?";
  confirmButtonText:string = "Yes";
  cancelButtonText:string = "Cancel";

  constructor(
    private store$: Store<any>,
    private userAccount: AccountService,
    private accountActions: AccountService
  ) {
    this.user = this.accountActions.userValue;
  }

  /**
   * Lifecycle hook that gets called when the component is initialized.
   * Reads configuration options from 'popupConfiguration' input and sets them for the popup.
   * Also triggers the opening of the delete popup.
   */
  ngOnInit(): void {
    // Check if there is a popup configuration
    if (this.popupConfiguration) {
      // Set the popup message if provided in the configuration
      if (this.popupConfiguration['popup_message']) {
        this.popupMessage = this.popupConfiguration['popup_message'];
      }
      // Set the confirm button text if provided in the configuration
      if (this.popupConfiguration['confirm_button_text']) {
        this.confirmButtonText = this.popupConfiguration['confirm_button_text'];
      }
      // Set the cancel button text if provided in the configuration
      if (this.popupConfiguration['cancel_button_text']) {
        this.cancelButtonText = this.popupConfiguration['cancel_button_text'];
      }
    }
    // Open the delete popup
    this.showModalStyling();
  }

  /**
   * Opens the delete popup by displaying it, showing the overlay, and adding a CSS class to the body.
   */
  showModalStyling = () => {
    $(".preconfirm_popup").show();
    $(".overlay").show();
    $("body").addClass("overflow_hidden");
  }

  /**
   * Hides the styling of the delete popup by hiding it, hiding the overlay, and removing a CSS class from the body.
   */
  hideModelStyling = () => {
    $(".preconfirm_popup").hide();
    $(".overlay").hide();
    $("body").removeClass("overflow_hidden");
  }

  /**
   * Handles the action when the cancel button is clicked.
   * Emits data with 'confirm' set to false to indicate cancellation.
   * Also emits any parent passed data from the configuration.
   * Finally, hides the styling of the popup.
   */
  onCancel = () => {
    let data = {};
    data['confirm'] = false;
    if (this.popupConfiguration['parent_passed_data']) {
      data['parent_passed_data'] = this.popupConfiguration['parent_passed_data'];
    }
    this.emitDataToParent(data);
    this.hideModelStyling();
  }

  /**
   * Handles the action when the confirm button is clicked.
   * Emits data with 'confirm' set to true to indicate confirmation.
   * Also emits any parent passed data from the configuration.
   * Finally, hides the styling of the popup.
   */
  onConfirm = () => {
    let data = {};
    data['confirm'] = true;
    if (this.popupConfiguration['parent_passed_data']) {
      data['parent_passed_data'] = this.popupConfiguration['parent_passed_data'];
    }
    this.emitDataToParent(data);
    this.hideModelStyling();
  }

  /**
   * Emits data to the parent component using the dataEvent output property.
   * Used to communicate user actions or information back to the parent component.
   * @param {object} data - The data to be emitted to the parent component.
   */
  emitDataToParent = (data = {}) => {
    this.dataEvent.emit(data);
  }

  /**
   * Lifecycle hook that gets called when the component is about to be destroyed.
   * Calls the hideModelStyling function to hide modal styling.
   */
  ngOnDestroy(): void {
    this.hideModelStyling();
  }
}
