import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { Store } from '@ngrx/store';
import { AccountService } from 'src/appv2/services/account.service';
import { ConfirmPasswordValidator } from 'src/appv2/validators/confirm-password.validator';
import { ToastService } from 'src/appv2/services/toast.service';
import { messages } from 'src/appv2/appv2.constants';
const passwordRegex = /^(?=.*?[a-zA-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&+*-.=<>()|^]).{8,}$/;

@Component({
  selector: 'appv2-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  //message: any;
  email: any;
  otp: any;
  cpasswordShow: boolean;
  passwordShow: boolean;
  submitted = false;
  token:any;


  constructor(private store$: Store<any>, private accountActions: AccountService, private router: Router, private route: ActivatedRoute, private toastService: ToastService) { }

  ngOnInit(): void {
    this.route.pathFromRoot[1].url.subscribe(val => this.token = val[1].path);

    this.resetPasswordForm = new FormGroup({
      'password': new FormControl('', [Validators.compose([
        Validators.required,
        Validators.pattern(passwordRegex)
      ])]),
      'passwordConfirm': new FormControl('', [Validators.required]),
    }, {
      validators: ConfirmPasswordValidator.mustMatch("password", "passwordConfirm")
    });
  }

  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.resetPasswordLoading }))
    return
  }

  public onSubmit() {
    this.submitted = true;
    this.resetPassword(this.resetPasswordForm.value);
  }

  public resetPassword(formdata) {

    if (this.resetPasswordForm.invalid) {
      for (const control of Object.keys(this.resetPasswordForm.controls)) {
        this.resetPasswordForm.controls[control].markAsTouched();
      }
      return;
    }

    if (formdata.password != '' && formdata.passwordConfirm) {

      let password = formdata.password;
      let cpassword = formdata.passwordConfirm;
      let token =this.token

      this.loading(true);
      this.accountActions.resetPassword({ password, cpassword, token }).subscribe((data) => {
        if (data.status == 1) {
          this.loading(false);
          setTimeout(() => {
            this.router.navigate(['/resetpasswordsuccess'])
          }, 4000);
          this.toastService.success(data['message'])
        } else {
          if (data.cpassword[0]) {
            this.toastService.warning(data.cpassword[0])
          } else {
            this.toastService.warning(messages.resetPasswordFail);
          }

        }
      });
    }
  }

  get f() { return this.resetPasswordForm.controls; }

  public hideShowPassword() {
    this.passwordShow = !this.passwordShow;
  }

  public hideShowConfirmPassword() {
    this.cpasswordShow = !this.cpasswordShow;
  }

}
