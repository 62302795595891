import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeaturesState } from './features.models';
import { featuresNode } from './features.reducer';
export const selectFeaturesFeature = createFeatureSelector<FeaturesState>(featuresNode);

// Here we create selectors to be able to show data from store in our components.

// selector fo text
export const selectCartCount = createSelector(
  selectFeaturesFeature,
  (state: FeaturesState): number => state.cartCount
);


// selector for search submenu
export const selectWishlistCount = createSelector(
  selectFeaturesFeature,
  (state: FeaturesState): number => state.wishlistCount
);