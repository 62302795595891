import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastService: HotToastService) { }

  success(message: any = '') {
    this.toastService.success(message, {
      position: 'top-right',
      theme: 'snackbar',
      style: {
        padding: '10px',
        fontSize: '14px'
      },
    })
  }

  info(message: any = '') {
    this.toastService.info(message, {
      position: 'top-right',
      theme: 'snackbar',
      style: {
        padding: '10px',
        fontSize: '14px'
      },
    })
  }

  warning(message: any = '') {
    this.toastService.warning(message, {
      position: 'top-right',
      theme: 'snackbar',
      style: {
        padding: '10px',
        fontSize: '14px'
      },
    })
  }

  error(message: any = '') {
    this.toastService.error(message, {
      position: 'top-right',
      theme: 'snackbar',
      style: {
        padding: '10px',
        fontSize: '14px'
      },
    })
  }
}
