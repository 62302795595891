import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { searchNode, searchReducer } from './search/search.reducer';
import { SearchState } from "./search/search.models";
import { DEFAULT_ROUTER_FEATURENAME, routerReducer, RouterReducerState } from '@ngrx/router-store';
import { loadingNode, loadingReducer } from './loading/loading.reducer';
import { LoadingState } from './loading/loading.models';
import { featuresNode, featuresReducer } from './features/features.reducer';
import { FeaturesState } from './features/features.models';


export interface State {
  [searchNode]: SearchState;
  [loadingNode]:LoadingState
  [featuresNode]:FeaturesState
  [DEFAULT_ROUTER_FEATURENAME]: RouterReducerState
}

// Prepare reducers to import in module file
export const reducers: ActionReducerMap<State> = {
  [searchNode]: searchReducer,
  [loadingNode]: loadingReducer,
  [featuresNode]: featuresReducer,
  [DEFAULT_ROUTER_FEATURENAME]: routerReducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
