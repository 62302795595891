<!-- banner section start -->
<section class="banner-sec" *ngIf="promotion">
  <picture>
    <source
      class="banner-img dec-img"
      srcset="{{ promotion?.desktop_banner_image }}"
      alt="Images"
      width="1920"
      height="524"
    />
    <source
      class="banner-img dec-img"
      srcset="{{ promotion?.desktop_banner_image }}"
      alt="Images"
      width="1920"
      height="524"
    />
    <img
      class="banner-img dec-img"
      src="{{ promotion?.desktop_banner_image }}"
      alt="Images"
      width="1920"
      height="524"
    />

    <source
      class="banner-img mob-img"
      srcset="{{ promotion?.mobile_banner_image }}"
      alt="Images"
      width="575"
      height="380"
    />
    <source
      class="banner-img mob-img"
      srcset="{{ promotion?.mobile_banner_image }}"
      alt="Images"
      width="575"
      height="380"
    />
    <img
      class="banner-img mob-img"
      src="{{ promotion?.mobile_banner_image }}"
      alt="Images"
      width="575"
      height="380"
    />
  </picture>
  <div class="container">
    <div class="banner-content">
      <h1 class="h1">{{ promotion?.event_name }}</h1>
      <p>{{ promotion?.event_des }}</p>
      <appv2-search-bar class="categories-seach-block-banner" [params]="{isHeaderPart: false}"></appv2-search-bar>
    </div>
  </div>
</section>

<!-- Information Line section start -->
<section class="information_line" *ngIf="discountDetails">
  <div class="container" *ngIf="discountDetails?.status == 1">
    <div class="information_line_inner">
      <p>{{ discountDetails?.title }}</p>
      <a target="_blank" href="{{ discountDetails?.link }}">{{
        discountDetails?.button_text
      }}</a>
    </div>
  </div>
</section>
<!-- Information Line section end -->

<!-- biggest stories section start -->
<section class="biggest-stories-sec"  *ngIf="galleryStoryDataLen > 0">
  <div class="container">
    <div class="title-heading">
      <h2 class="h2">Explore today's biggest stories</h2>
    </div>
    <div class="stories-main-row">
        <div class="stories-col" *ngFor="let story of galleryStoryData">
          <div class="stories-img">
            <div class="stories-img-inner">
              <picture *ngIf="story.main_image_upload">
                <img
                  class="img-tag"
                  src="{{ story.main_image_upload }}"
                  alt="static img"
                  width="415"
                  height="315"
                />
              </picture>
              <picture *ngIf="story.main_image_selected_values">
                <img
                  class="img-tag"
                  src="{{ story.main_image_selected_values }}"
                  alt="static img"
                  width="415"
                  height="315"
                />
              </picture>
            </div>
          </div>
          <div class="stories-content">
            <h3 class="h3">
              <a (click)="openEditorial(story.search_term)" style="cursor: pointer;">
                {{story.title}}
              </a>
            </h3>
          </div>
        </div>
    </div>
  </div>
</section>
<!-- biggest stories section end -->

<!-- collection section start -->
<section class="collection-sec">
  <div class="container">
    <div class="title-heading">
      <h2 class="h2">Collection loved by community</h2>
    </div>
    <div class="collection-main-row">
      <div class="collection-col">
        <div class="collection-img">
          <picture>
            <source
              class="img-tag"
              srcset="assets/images/collection-img1.webp"
              alt="collection img"
              width="900"
              height="870"
            />
            <source
              class="img-tag"
              srcset="assets/images/collection-img1.jpg"
              alt="collection img"
              width="900"
              height="870"
            />
            <img
              class="img-tag"
              src="assets/images/collection-img1.jpg"
              alt="collection img"
              width="900"
              height="870"
            />
          </picture>
        </div>
        <div class="collection-content">
          <h3 class="h3">{{ promotion?.event_name }}</h3>
          <a
            href="javascript:void(0)"
            routerLink="/musics"
            class="thame-btn"
            >Explore Music</a
          >
        </div>
      </div>
      <div class="collection-col">
        <div class="collection-img">
          <picture>
            <source
              class="img-tag"
              srcset="assets/images/collection-img2.webp"
              alt="collection img"
              width="900"
              height="870"
            />
            <source
              class="img-tag"
              srcset="assets/images/collection-img2.jpg"
              alt="collection img"
              width="900"
              height="870"
            />
            <img
              class="img-tag"
              src="assets/images/collection-img2.jpg"
              alt="collection img"
              width="900"
              height="870"
            />
          </picture>
        </div>
        <div class="collection-content">
          <h3 class="h3">{{ promotion?.event_name }}</h3>
          <a
            href="javascript:void(0)"
            routerLink="/musics"
            class="thame-btn"
            >Explore Music</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
<!-- collection section end -->

<!-- editorial section start -->
<section class="editorial-sec" *ngIf="galleryData?.length > 0">
  <div class="container">
    <div class="title-heading">
      <h2 class="h2">Experience ImageFootage Editorial</h2>
    </div>
    <!-- layout of image grid position start -->
    <div class="image_grid">
      <!-- image first row start -->
      <div class="image_grid_1">
        <div>
          <div
            class="landscape_big_image main_image"
            *ngIf="galleryData?.length > 0"
          >
            <a (click)="openEditorial(galleryData[0].search_term,galleryData[0].selected_api_product_values)" style="cursor: pointer;">
              <picture *ngIf="galleryData[0].main_image_upload">
                <img
                  class="img-tag"
                  src="{{ galleryData[0].main_image_upload}}"
                  alt="{{ galleryData[0].title }}"
                  width="904"
                  height="716"
                />
              </picture>
              <picture *ngIf="galleryData[0].main_image_selected_values">
                <img
                    class="img-tag"
                    src="{{ galleryData[0].main_image_selected_values }}"
                    alt="{{ galleryData[0].title }}"
                    width="456"
                    height="356"
                  />
              </picture>
              <div class="editorial-content">
                <div class="video-photo-btn">
                  {{ galleryData[0].selected_values_count }} Photos
                </div>
                <div>
                  <span>{{
                    galleryData[0].created_at | date : "longDate"
                  }}</span>
                  <h3 class="h3">
                    {{ galleryData[0].title }}
                  </h3>
                </div>
              </div>
            </a>
          </div>
        </div>


        <div class="first_image_grid" *ngIf="galleryData?.length > 1">
          <div class="landscape_image_grid" *ngIf="galleryData?.length > 1">
            <div class="main_image" *ngIf="galleryData?.length > 1">
              <a (click)="openEditorial(galleryData[1].search_term,galleryData[1].selected_api_product_values)" style="cursor: pointer;">
                <picture *ngIf="galleryData[1].main_image_upload">
                <img
                  class="img-tag"
                  src="{{ galleryData[1].main_image_upload}}"
                  alt="{{ galleryData[1].title }}"
                  width="904"
                  height="716"
                />
              </picture>
              <picture *ngIf="galleryData[1].main_image_selected_values">
                <img
                    class="img-tag"
                    src="{{ galleryData[1].main_image_selected_values }}"
                    alt="{{ galleryData[1].title }}"
                    width="456"
                    height="356"
                  />
              </picture>
                <div class="editorial-content">
                  <div class="video-photo-btn">
                    {{ galleryData[1].selected_values_count }} Photos
                  </div>
                  <div>
                    <span>{{
                      galleryData[1].created_at | date : "longDate"
                    }}</span>
                    <h3 class="h3">{{ galleryData[1].title }}</h3>
                  </div>
                </div>
              </a>
            </div>
            <div class="main_image" *ngIf="galleryData?.length > 2">
              <a (click)="openEditorial(galleryData[2].search_term,galleryData[2].selected_api_product_values)" style="cursor: pointer;">
                <picture *ngIf="galleryData[2].main_image_upload">
                <img
                  class="img-tag"
                  src="{{ galleryData[2].main_image_upload}}"
                  alt="{{ galleryData[2].title }}"
                  width="904"
                  height="716"
                />
              </picture>
              <picture *ngIf="galleryData[2].main_image_selected_values">
                <img
                    class="img-tag"
                    src="{{ galleryData[2].main_image_selected_values }}"
                    alt="{{ galleryData[2].title }}"
                    width="456"
                    height="356"
                  />
              </picture>
                <div class="editorial-content">
                  <div class="video-photo-btn">
                    {{ galleryData[2].selected_values_count }} Photos
                  </div>
                  <div>
                    <span>{{
                      galleryData[2].created_at | date : "longDate"
                    }}</span>
                    <h3 class="h3">{{ galleryData[2].title }}</h3>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="landscape_image_grid" *ngIf="galleryData?.length > 3">
            <div class="main_image" *ngIf="galleryData?.length > 3">
              <a (click)="openEditorial(galleryData[3].search_term,galleryData[3].selected_api_product_values)" style="cursor: pointer;">
                <picture *ngIf="galleryData[3].main_image_upload">
                <img
                  class="img-tag"
                  src="{{ galleryData[3].main_image_upload}}"
                  alt="{{ galleryData[3].title }}"
                  width="904"
                  height="716"
                />
              </picture>
              <picture *ngIf="galleryData[3].main_image_selected_values">
                <img
                    class="img-tag"
                    src="{{ galleryData[3].main_image_selected_values }}"
                    alt="{{ galleryData[3].title }}"
                    width="456"
                    height="356"
                  />
              </picture>
                <div class="editorial-content">
                  <div class="video-photo-btn">
                    {{ galleryData[3].selected_values_count }} Photos
                  </div>
                  <div>
                    <span>{{
                      galleryData[3].created_at | date : "longDate"
                    }}</span>
                    <h3 class="h3">{{ galleryData[3].title }}</h3>
                  </div>
                </div>
              </a>
            </div>
            <div class="main_image" *ngIf="galleryData?.length > 4">
              <a (click)="openEditorial(galleryData[4].search_term,galleryData[4].selected_api_product_values)" style="cursor: pointer;">
                <picture *ngIf="galleryData[4].main_image_upload">
                <img
                  class="img-tag"
                  src="{{ galleryData[4].main_image_upload}}"
                  alt="{{ galleryData[4].title }}"
                  width="904"
                  height="716"
                />
              </picture>
              <picture *ngIf="galleryData[4].main_image_selected_values">
                <img
                    class="img-tag"
                    src="{{ galleryData[4].main_image_selected_values }}"
                    alt="{{ galleryData[4].title }}"
                    width="456"
                    height="356"
                  />
              </picture>
                <div class="editorial-content">
                  <div class="video-photo-btn">
                    {{ galleryData[4].selected_values_count }} Photos
                  </div>
                  <div>
                    <span>{{
                      galleryData[4].created_at | date : "longDate"
                    }}</span>
                    <h3 class="h3">{{ galleryData[4].title }}</h3>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- layout of image grid position end -->
  </div>
</section>
<!-- editorial section end -->

<!-- editorial plans section start -->

