<section class="still_have_questions_pricing_page">
  <div class="container">
    <h2 class="h2">Still have questions?</h2>
    <div class="still_have_questions_accordian">
      <div class="accordian_border" *ngFor="let question of questions; let i = index">
        <button class="course-accordion" (click)="toggleAccordion(i)" [class.active]="i === activeIndex">
          {{ question.question }}
        </button>
        <div class="course-panel" [class.active]="i === activeIndex">
          <p>{{ question.answer }}</p>
        </div>
      </div>
    </div>
  </div>
</section>