import { Injectable } from '@angular/core';
import { SafeRequestService } from './safe-request.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlanService {

  constructor(private safeRequest: SafeRequestService) { }

  getPaymentLink(paymentData) {
    const url = `paymentPlan`;
    const body = paymentData
    return this.safeRequest.sendRequest('post', url, body)
  }

  getRazorResponse(paymentRes, tokenData) {
    const url = 'razor_plan_response'
    const body = { paymentRes, tokenData }
    return this.safeRequest.sendRequest('post', url, body)
  }
}
