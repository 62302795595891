import { SearchActions, searchActionsType, SearchChangeVisibility } from './search.actions';
import { SearchState,SearchSubMenu } from './search.models';

export const searchNode = 'search';


// Varibles for store if we initialize store in the first time.
const initialState: SearchState = {
    searchText: '',
    searchType: 'image',
    isWithDropdown: true,
    searchVisibile: false,
    isEditorial: false
};

// In this part we manipulate data and return result
export const searchReducer = (state = initialState, action: SearchActions) => {
  switch (action.type) {
    case searchActionsType.write:
      return <SearchState> {
        ...state,
        searchText: action.payload.searchText,
      };
      case searchActionsType.changeSearchType:
        return <SearchState> {
          ...state,
          isWithDropdown: action.payload.isWithDropdown,
      };
      case searchActionsType.changeSearchName:
        return <SearchState> {
          ...state,
          searchType: action.payload.searchType,
      };
      case searchActionsType.changeSearchVisibility:
        return <SearchState> {
          ...state,
          searchVisibile: action.payload.searchVisibile,
        }
      case searchActionsType.searchWithEditorial:
        return <SearchState> {
          ...state,
          isEditorial: action.payload.isEditorial,
        }
    default:
      return state;
  }
}
