import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Options } from '@angular-slider/ngx-slider';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';

@Component({
  selector: 'appv2-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  page_type: string;
  isClassAdded = false;
  public filterData:string = '';

  @Output() dataEmitted = new EventEmitter<any>();
  @Input() isChecked: boolean;
  @Output() checkboxChange = new EventEmitter<boolean>();
  @Output() toggleActive = new EventEmitter<void>();
  @Input() fromCategory: Boolean;

  constructor(private router: Router,private route: ActivatedRoute, private accountActions: AccountService,private location: Location,private store$: Store<any>) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let url = event.url.split('/')[2];
        if(event.url.split('/')[3]){
          this.filterData = event.url.split('/')[3]

        }
        if(url == undefined){
           url = event.url.split('/')[1];
        }

        if (url && url.includes('?')) {
          let onlySlug = url.split('?');
          url = onlySlug[0];
        }
        this.page_type = url;
        //this.page_type = this.page_type.split('-')[0];
      }
    });
  }

  appliedFilterArray: any = [];
  filterDetails: any = [];
  filterMenu: string = '';
  active_footage = '';
  filter_active: string = '';
  value: number = 400;
  highValue: number = 700;
  options: Options = {
    floor: 0,
    ceil: 1000,
  };
  showDiv = true;
  selectedOptionId: any = [];
  selectedFilters: any = [];

  ngOnInit(): void {
    this.getFilterOptions('');
    this.toggleFootageSideBar();
    this.active_footage = this.location.path();
    this.active_footage = this.active_footage.substring(1);
    if (this.active_footage.includes('footages')) {
      this.active_footage = 'footages';
      this.filter_active = '';
    }
  }

  openMenu(filterName: string) {
    if (this.filterMenu === filterName) {
      this.filterMenu = '';
    } else {
      this.filterMenu = filterName;
    }
  }

  openFilters(type) {
    if (this.filter_active === type) {
      this.filter_active = '';
    } else {
      this.filter_active = type;
    }
    this.toggleActive.emit();
    localStorage.setItem('filter_active', this.filter_active);
    if(type == 'footage-filter'){
      this.toggleFootageSideBar();
    }
  }

  /**
   * Gets filter category and its value with name(for display purpose) from filter
   * @param type
   * @param id
   * @param [name]
   */
  getCategoriesFromFilter(
    type,
    id,
    name = '',
    isRadio,
    filterName,
    optionName,
    hasMultipleValues,
    option
  ) {


    let changeType = false
    if (this.selectedOptionId.includes(id) && isRadio == 1) {
      return;


    } else if(this.selectedFilters.includes(type) && isRadio == 1){
      const index = this.selectedFilters.indexOf(type);
      this.selectedFilters.splice(index, 1);
      this.selectedOptionId.splice(index, 1);


      this.selectedOptionId.push(id);
      this.selectedFilters.push(type);
    }
    else {
      this.selectedOptionId.push(id);
      this.selectedFilters.push(type);
    }

    if(type == 'product_type'){
      this.loading(true)
      if(optionName == 'Footage' || optionName == 'Music' || optionName == 'Image'){
        this.router.navigate(['/categories/' + name + '/' + this.filterData])
        .then(() => {
          setTimeout(function() {
            window.location.reload();
          }, 300);
          this.loading(false)
        });
        return
      }
      this.loading(false)
    }
    if (!this.appliedFilterArray[type]) {
      this.appliedFilterArray[type] = [];
      this.appliedFilterArray[`${type}_name`] = [];
      this.appliedFilterArray[`${type}_filtername`] = [];
      this.appliedFilterArray[`${type}_optionname`] = [];
      this.appliedFilterArray[`${type}_hasMultipleValues`] = [];
      this.appliedFilterArray[`${type}_isGroupValues`] = [];
    }
    const index = this.appliedFilterArray[type].indexOf(id);

    if (isRadio == 1) {
      this.toggleFilter(option);
      delete this.appliedFilterArray[type];
      this.appliedFilterArray[type] = [];
      this.appliedFilterArray[`${type}_name`] = [];
      this.appliedFilterArray[`${type}_filtername`] = [];
      this.appliedFilterArray[`${type}_optionname`] = [];
      this.appliedFilterArray[`${type}_hasMultipleValues`] = [];
      this.appliedFilterArray[`${type}_isGroupValues`] = [];
    }
    if (index === -1) {
      this.appliedFilterArray[type].push(id);
      this.appliedFilterArray[`${type}_name`].push(name);
      this.appliedFilterArray[`${type}_filtername`].push(filterName);
      this.appliedFilterArray[`${type}_optionname`].push(optionName);
      this.appliedFilterArray[`${type}_hasMultipleValues`].push(
        hasMultipleValues
      );
      this.appliedFilterArray[`${type}_isGroupValues`].push(
        option.is_group_value
      );
    } else {
      this.appliedFilterArray[type].splice(index, 1);
      this.appliedFilterArray[`${type}_name`].splice(index, 1);
      this.appliedFilterArray[`${type}_filtername`].splice(index, 1);
      this.appliedFilterArray[`${type}_optionname`].splice(index, 1);
      this.appliedFilterArray[`${type}_hasMultipleValues`].splice(index, 1);
      this.appliedFilterArray[`${type}_isGroupValues`].splice(index, 1);
    }
    if (changeType) {
      this.getFilterOptions(optionName);
    }

    this.dataEmitted.emit(this.appliedFilterArray);
  }

  toggleFilter(filterItem: any) {
    filterItem.isChecked = !filterItem.isChecked;
  }

  /**
   * Remove specific filters
   * @param filter_type
   */
  removeFilters(filter_type, index) {
    this.appliedFilterArray[filter_type].splice(index, 1);
    const deletedValues = this.appliedFilterArray[`${filter_type}_name`].splice(
      index,
      1
    );
    this.appliedFilterArray[`${filter_type}_filtername`].splice(index, 1);
    this.appliedFilterArray[`${filter_type}_optionname`].splice(index, 1);
    this.appliedFilterArray[`${filter_type}_hasMultipleValues`].splice(
      index,
      1
    );
    this.appliedFilterArray[`${filter_type}_isGroupValues`].splice(index, 1);

    const filter_default = this.filterDetails.find(
      (item) => item.value === filter_type
    );

    if (filter_default) {
      const valueToUncheck = deletedValues[0];

      const optionToUncheck = filter_default.options.find(
        (option) => option.value === valueToUncheck
      );
      if (optionToUncheck) {
        optionToUncheck.isChecked = false;
      }
      let indexOfElementToRemove = this.selectedOptionId.indexOf(optionToUncheck.id);
      if (indexOfElementToRemove !== -1) {
        // Use splice to remove the element at the specified index
        this.selectedOptionId.splice(indexOfElementToRemove, 1);
        this.selectedFilters.splice(indexOfElementToRemove,1)
      }
    }

    this.dataEmitted.emit(this.appliedFilterArray);
  }

  /**
   * Reset filter
   */
  resetFilter() {
    this.appliedFilterArray = [];
    const filter = this.filterDetails;

    if (filter) {
      filter.forEach((filterItem) => {
        if (filterItem.options) {
          filterItem.options.forEach((option) => (option.isChecked = false));
        }
      });
      this.selectedOptionId = []
      this.selectedFilters = []
    }
    this.dataEmitted.emit(this.appliedFilterArray);
  }

  getFilterOptions(type) {
    let payload = {};
    if(type !== '' && type !== undefined ){
      payload['type'] = type
    } else{
      payload['type'] = this.page_type;

    }
    if(payload['type'] == 'category' || payload['type'] == 'images' || payload['type'] == 'image'){
      payload['type'] = 'image'
    } else if(payload['type'] == 'footages' || payload['type'] == 'footage' || payload['type'] == 'Footage'){
      payload['type'] = 'footage'
    }else if(payload['type'] == 'editorials' || payload['type'] == 'Editorial'){
      payload['type'] = 'editorial'
    }
    else{
      payload['type'] = 'music'
    }

    this.filterDetails = [];
    this.accountActions.getFilterOptions(payload).subscribe((data) => {
       this.filterDetails = data.data;
     // this.filterDetails.push(...allDetails);
    });
  }

  public loading(status: boolean) {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: status })
    );
    return;
  }

  toggleFootageSideBar(){
    if(this.filter_active == 'footage-filter'){
      window.addEventListener('scroll', function () {
        let footerElement: HTMLElement | null = document.querySelector(".footage-list");
        let windowHeight: number = window.innerHeight;
        if(footerElement){
            let footerOffset: number | DOMRect = footerElement.getBoundingClientRect().top;
            let filterActive: HTMLCollectionOf<Element> = document.getElementsByClassName("footage-filter-listing");
            let activeImageList: HTMLCollectionOf<Element> = document.getElementsByClassName("image-list-sec-footage");

            if (filterActive[0].classList.contains('active')) {
                if (footerOffset < windowHeight) {
                    filterActive[0].classList.remove('active');
                }
            } else if (windowHeight < (footerOffset as number)) {
                filterActive[0].classList.remove('active');
                if (activeImageList[0].classList.contains('active')) {
                    filterActive[0].classList.add('active');
                }
            }
        }
    });
  }
  }



}
