<!-- popup start -->
<div class="overlay" (click)="hideModelStyling()"></div>

<div class="user_plan_modal_popup">
    <div class="user_plan_modal_popup_inner">
        <div class="wishlist_image_section">
            <div class="wishlist_image_heading">
                <h3 class="h4">Choose Subscription Plan</h3>
                <select class="form-control custom-select" type="text" require (change)="changeValue($event.target.value)">
                    <option *ngIf="!selectedPlanId">Choose Plan</option>
                    <ng-container *ngFor="let plan of userSubscriptionPlans">
                        <option *ngIf="plan.licence.id == licenseId && versionId == ''" [value]="plan.id"  [selected]="plan.order_type == 3 ? true : null">
                          {{ plan.package_name }}
                        </option>
                        <option *ngIf="plan.licence.id == licenseId && plan.pacage_size == versionId" [value]="plan.id" [selected]="plan.order_type == 3 ? true : null">
                            {{ plan.package_name }}
                          </option>
                      </ng-container>
                  </select>
                  <button (click)="submitForm()" class="thame-btn">Submit</button>

            </div>
        </div>
    </div>
</div>
<!-- popup end -->
