<section class="my_profile_page">
    <div class="container">
        <div class="my_profile_page_tab">
            <div class="tabs">
                <div class="tab_bar">
                    <ul class="tabs-nav">
                        <li [ngClass]="{'tab_active': openTab === 'profile'}">
                            <a (click)="clicked('profile')">
                                <img [src]="openTab === 'profile' ? 'assets/images/tab_user_active.svg' : 'assets/images/tab_user.svg' "
                                    alt="Profile" width="28" height="28" />
                                <span [ngClass]="{'active': openTab === 'profile'}">My Profile</span>
                            </a>
                        </li>
                        <li [ngClass]="{'tab_active': openTab === 'security'}">
                            <a (click)="clicked('security')">
                                <img [src]="openTab === 'security' ? 'assets/images/tab_password_active.svg' : 'assets/images/tab_password.svg' "
                                    alt="Security" width="28" height="28" />
                                <span [ngClass]="{'active': openTab === 'security'}">Security</span>
                            </a>
                        </li>
                        <li [ngClass]="{'tab_active': openTab === 'history'}">
                            <a (click)="clicked('history')">
                                <img [src]="openTab === 'history' ? 'assets/images/tab_history_active.svg' : 'assets/images/tab_history.svg' "
                                    src="" alt="History" width="28" height="28" />
                                <span [ngClass]="{'active': openTab === 'history'}">Purchase History</span>
                            </a>
                        </li>
                        <li>
                            <a routerLink="/myplan">
                                <img src="assets/images/tab_card.svg" alt="My Plan" width="28" height="28" />
                                <span>My Plan</span>
                            </a>
                        </li>
                        <li [ngClass]="{'tab_active': openTab === 'download-history'}">
                            <a (click)="clicked('download-history')">
                                <img [src]="openTab === 'download-history' ? 'assets/images/download_plan.svg' : 'assets/images/download_plan.svg' "
                                    src="" alt="Download History" width="28" height="28" />
                                <span [ngClass]="{'active': openTab === 'download-history'}">Download History</span>
                            </a>
                        </li>
                        <li [ngClass]="{'tab_active': openTab === 'delete-account'}">
                            <a (click)="clicked('delete-account')">
                                <img [src]="openTab === 'delete-account' ? 'assets/images/tab_delete.svg' : 'assets/images/tab_delete_inactive.svg' "
                                    alt="Delete Account" width="28" height="28" />
                                <span [ngClass]="{'active': openTab === 'delete-account'}">Delete Account</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="tabs-stage">
                <ng-container *ngIf="openTab === 'profile'">
                    <appv2-my-profile></appv2-my-profile>
                </ng-container>
                <ng-container *ngIf="openTab === 'security'">
                    <appv2-security (setTabName)="getTabName($event)"></appv2-security>
                </ng-container>
                <ng-container *ngIf="openTab === 'history'">
                    <appv2-purchase-history></appv2-purchase-history>
                </ng-container>
                <ng-container *ngIf="openTab === 'download-history'">
                    <appv2-download-history></appv2-download-history>
                </ng-container>
                <ng-container *ngIf="openTab === 'delete-account'">
                    <appv2-delete-account></appv2-delete-account>
                </ng-container>
            </div>
        </div>
    </div>
</section>