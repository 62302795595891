           <div class="input-image-popup">
                        <div class="upper-section">
                            <span>Visual Search</span>
                            <span><a href="javascript:void(0)">Need help?</a></span>
                        </div>
                        <div class="middle-section">
                            <div class="popup-image-box">
                                <a href="javascript:void()">
                                    <input type="file">
                                    <div class="popup-image">
                                        <picture>
                                            <source srcset="assets/images/input-popup-image.webp" alt="input-popup-image" width="236" height="178" />
                                            <source srcset="assets/images/input-popup-image.png" alt="input-popup-image" width="236" height="178" />
                                            <img src="assets/images/input-popup-image.png" alt="input-popup-image" width="236" height="178" />
                                        </picture>
                                    </div>
                                    <div class="popup-content">
                                        <p>Drag and drop your image here or <span class="browse">Browse</span> to choose a file.
                                        </p>
                                    </div>
                                </a>
                            </div>
                            <div class="or">or</div>
                            <div class="popup-input">
                                <form action="">
                                    <button><img src="assets/images/attach-file.svg" alt="attach-file" width="20"
                                height="20"></button>
                                    <input type="text" placeholder="Paste an image or a URL">
                                </form>
                            </div>
                        </div>
                        <div class="bottom-image-section">
                            <span>Or try one of the examples below:</span>
                            <div class="bottom-image">
                                <div class="image-example">
                                    <a href="javascript:void(0)">
                                        <picture>
                                            <source srcset="assets/images/wishlist-collection3.webp" alt="input-popup-image" width="82" height="56" />
                                            <source srcset="assets/images/wishlist-collection3.jpg" alt="input-popup-image" width="82" height="56" />
                                            <img src="assets/images/wishlist-collection3.jpg" alt="input-popup-image" width="82" height="56" />
                                        </picture>
                                    </a>
                                </div>
                                <div class="image-example">
                                    <a href="javascript:void(0)">
                                        <picture>
                                            <source srcset="assets/images/wishlist-collection19.webp" alt="input-popup-image" width="82" height="56" />
                                            <source srcset="assets/images/wishlist-collection19.jpg" alt="input-popup-image" width="82" height="56" />
                                            <img src="assets/images/wishlist-collection19.jpg" alt="input-popup-image" width="82" height="56" />
                                        </picture>
                                    </a>
                                </div>
                                <div class="image-example">
                                    <a href="javascript:void(0)">
                                        <picture>
                                            <source srcset="assets/images/similar_footages_11.webp" alt="input-popup-image" width="82" height="56" />
                                            <source srcset="assets/images/similar_footages_11.jpg" alt="input-popup-image" width="82" height="56" />
                                            <img src="assets/images/similar_footages_11.jpg" alt="input-popup-image" width="82" height="56" />
                                        </picture>
                                    </a>
                                </div>
                                <div class="image-example">
                                    <a href="javascript:void(0)">
                                        <picture>
                                            <source srcset="assets/images/list-img4.webp" alt="input-popup-image" width="82" height="56" />
                                            <source srcset="assets/imageslist-img4.jpg" alt="input-popup-image" width="82" height="56" />
                                            <img src="assets/images/list-img4.jpg" alt="input-popup-image" width="82" height="56" />
                                        </picture>
                                    </a>
                                </div>
                            </div>
                        </div>
                        </div>
