<!-- add_to_wishlist popup start -->
<div class="add_to_wishlist">
    <div class="add_to_wishlist_inner">
        <div class="wishlist_image_section">
            <div class="wishlist_image">
                <picture>
                    <source [srcset]="productData['itemPoster'] ? productData['itemPoster'] : ''"
                        alt="productData['description'] ? productData['description'] : ''" width="627" height="625" />
                    <source [srcset]="productData['itemPoster'] ? productData['itemPoster'] : ''"
                        alt="productData['description'] ? productData['description'] : ''" width="627" height="625" />
                    <img [src]="productData['product_thumbnail'] ? productData['product_thumbnail'] : ''"
                        [alt]="productData['description'] ? productData['description'] : ''" width="627" height="625" />
                </picture>
            </div>
            <div class="wishlist_image_heading">
                <h3 class="h3">{{ productData['title'] ? productData['title'] : '' }}</h3>
                <p>Credit: {{ productData['author_username'] ? productData['author_username'] : '' }} | Product ID: {{
                    productData['product_id'] ? productData['product_id'] : '' }}</p>
            </div>
        </div>
        <div class="wishlist_content_section">
            <div class="wishlist_topic_section">
                <h3 class="h3">Add to my Wishlist<span class="close-icon" (click)="closeModal()">x</span></h3>
                <ul class="wishlist_topic">
                    <ng-container *ngFor="let userWishList of userWishlistsData; trackBy: trackByFn;">
                        <li [class]="isProductOfWishlist(userWishList) ? 'hide_wishlist' : ''"
                            (click)="isProductOfWishlist(userWishList) ? removeProductFromWishList(userWishList['id']) : addProductToWishList(userWishList['id'])">
                            <span class="wishlist">{{ userWishList.name ? userWishList.name : "" }}</span>
                            <div class="wishlist_icon">
                                <img src="assets/images/check_light.svg" alt="right" width="16" height="16"
                                    class="check_light">
                                <img src="assets/images/check_black.svg" alt="right" width="16" height="16"
                                    class="check_black">
                            </div>
                        </li>
                    </ng-container>
                    <!-- <li>
                    <span class="wishlist">Flowers</span>
                    <div class="wishlist_icon">
                        <img src="assets/images/check_light.svg" alt="right" width="16" height="16" class="check_light">
                        <img src="assets/images/check_black.svg" alt="right" width="16" height="16" class="check_black">
                    </div>
                </li>
                <li>
                    <span class="wishlist">Festivals</span>
                    <div class="wishlist_icon">
                        <img src="assets/images/check_light.svg" alt="right" width="16" height="16" class="check_light">
                        <img src="assets/images/check_black.svg" alt="right" width="16" height="16" class="check_black">
                    </div>
                </li>
                <li>
                    <span class="wishlist">Abstract Banner</span>
                    <div class="wishlist_icon">
                        <img src="assets/images/check_light.svg" alt="right" width="16" height="16" class="check_light">
                        <img src="assets/images/check_black.svg" alt="right" width="16" height="16" class="check_black">
                    </div>
                </li>
                <li>
                    <span class="wishlist">Super Cars</span>
                    <div class="wishlist_icon">
                        <img src="assets/images/check_light.svg" alt="right" width="16" height="16" class="check_light">
                        <img src="assets/images/check_black.svg" alt="right" width="16" height="16" class="check_black">
                    </div>
                </li>
                <li>
                    <span class="wishlist">Mordana : A Real Estate Project</span>
                    <div class="wishlist_icon">
                        <img src="assets/images/check_light.svg" alt="right" width="16" height="16" class="check_light">
                        <img src="assets/images/check_black.svg" alt="right" width="16" height="16" class="check_black">
                    </div>
                </li>
                <li>
                    <span class="wishlist">Graphics UI Social Media Post</span>
                    <div class="wishlist_icon">
                        <img src="assets/images/check_light.svg" alt="right" width="16" height="16" class="check_light">
                        <img src="assets/images/check_black.svg" alt="right" width="16" height="16" class="check_black">
                    </div>
                </li>
                <li>
                    <span class="wishlist">Footages 1080 HD | ImageFootage UI</span>
                    <div class="wishlist_icon">
                        <img src="assets/images/check_light.svg" alt="right" width="16" height="16" class="check_light">
                        <img src="assets/images/check_black.svg" alt="right" width="16" height="16" class="check_black">
                    </div>
                </li>
                <li>
                    <span class="wishlist">Music | energetic 320 BPM</span>
                    <div class="wishlist_icon">
                        <img src="assets/images/check_light.svg" alt="right" width="16" height="16" class="check_light">
                        <img src="assets/images/check_black.svg" alt="right" width="16" height="16" class="check_black">
                    </div>
                </li> -->
                </ul>
            </div>
            <div class="add_collection">
                <form [formGroup]="wishlistForm" (ngSubmit)="onSubmit()">
                    <input type="text" placeholder="Add to collection" formControlName="wishlistName">
                    <div *ngIf="(wishlistForm.get('wishlistName').hasError('required') && wishlistForm.get('wishlistName').touched) || showValidationError"
                        class="error">
                        {{ errorMessage }}
                    </div>
                    <button type="submit">Add Wishlist Collection</button>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="overlay" (click)="closeModal()"></div>
