import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { messages } from 'src/appv2/appv2.constants';
import { DiscountDetailsInterface } from 'src/appv2/interfaces/discount-details.interface';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { CacheService } from 'src/appv2/services/cache.service';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { SearchService } from 'src/appv2/services/search.service';
import { urlToCacheKeyMapping } from 'src/appv2/utils/constants/discount.const';

@Component({
  selector: 'appv2-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  isProfessionallyCuratedShow: boolean = false;

  constructor(private landingPage: LandingPageService, private store$: Store<any>, private route: ActivatedRoute, private router: Router, private mysearch: SearchService, private http: HttpClient, private elementRef: ElementRef, private cacheService: CacheService) {

    this.getPageType();
    // Get Promotion Details
    this.promotionCacheKey = urlToCacheKeyMapping[this.promotionCacheKeyName];
    if (this.promotionCacheKey) {
      const promotion = this.cacheService.getData(this.promotionCacheKey);
      if (typeof promotion !== 'undefined') {
        this.promotion = promotion;
      } else {
        this.getPromotion();
      }
    }
  }

  promotion: any;
  discountDetailsResponse: any;
  public discountDetails = new DiscountDetailsInterface();
  page_type: string;
  searchtext: string
  mainPageType: string = 'footage';
  elasticSuggestions: any;
  openClass: string = '';
  visibleTrendingSearch: boolean = false
  showTrendSerach: boolean = false;
  recentSearches = []
  trendingKeyword: any;
  timeout: any = null;
  cacheKey: string;
  // display pricing component header text
  displayPricingHeader = true;
  page: string;
  promotionCacheKeyName: string = 'footage-page-banner';
  promotionCacheKey: string;
  public categoryData =[]

  ngOnInit(): void {

  }

  getPageType() {
    const urlSegments = this.route.snapshot.url;
    // Get the last segment (the segment at the end of the URL)
    const lastSegment = urlSegments[urlSegments.length - 1];
    // Access the last segment's path
    const lastSegmentPath = lastSegment.path;
    this.page = lastSegmentPath;

    // Now you can use the lastSegmentPath variable as needed
    this.page_type = lastSegmentPath + '_page';
    this.page_type = 'category_page';
  }

  getPromotion() {
    this.loading(true);
    this.landingPage.getPromotionDetails(this.page_type).subscribe(data => {
      this.loading(false);
      this.promotion = data.data
      if(this.promotion){
        this.cacheService.setData(this.promotionCacheKey, this.promotion);
        this.landingPage.isFootageBannerDetailsCalled.next(true);
      }
    })
  }
  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.categoryPageLoading}))
    return
  }
  openCategory(catName) {
  }

}
