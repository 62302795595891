<header class="header-sec">
  <div class="container">
    <nav id="site-navigation" class="main-navigation navbar">
      <button
        class="menu-toggle"
        aria-controls="primary-menu"
        aria-expanded="false"
      >
        Primary Menu
      </button>
      <div class="menu-container">
        <div class="header-wrap">
          <div class="logo-wrap">
            <img
              (click)="redirectToHomePage()"
              class="desc-logo"
              src="assets/images/logo.svg"
              alt="site logo"
              height="56"
              width="310"
            />
          </div>

          <appv2-search-bar class="categories-seach-block" *ngIf="showHeaderInSearch"></appv2-search-bar>

          <div class="menu-wrap-main">
            <div class="menu-wrap">
              <ul class="menu nav-menu" [class.active]="isMenu">
                <li class="menu-item">
                  <a routerLinkActive="active" routerLink="/image" [ngClass]="{ 'active': currentUrl.includes('/images?search') || currentUrl.includes('/categories/image') }">Image</a>
                </li>
                <li class="menu-item">
                  <a routerLinkActive="active" routerLink="/footage" [ngClass]="{ 'active': currentUrl.includes('/footages?search') || currentUrl.includes('/categories/footage') }">Footage</a>
                </li>
                <li class="menu-item">
                  <a routerLinkActive="active" routerLink="/editorial"
                    >Editorial</a
                  >
                </li>

                <li class="menu-item">
                  <a routerLinkActive="active" routerLink="/music" [ngClass]="{ 'active': currentUrl.includes('/musics?search') || currentUrl.includes('/categories/music') }">Music</a>
                </li>
                <li class="menu-item">
                  <a routerLinkActive="active" routerLink="/pricing">Pricing</a>
                </li>
              </ul>
            </div>
            <ul class="user-dateils-right">
              <li class="lock-icon-block">
                <a routerLink="/cart"
                  ><img
                    src="assets/images/lock-icon.svg"
                    alt="lock icon"
                    width="21"
                    height="30"
                  />
                  <span class="notif" *ngIf="cartCount > 0">{{ cartCount }}</span></a
                >
              </li>
              <li>
                <div class="btn-right-head" *ngIf="!loggedinuser">
                  <a class="s-btn" routerLink="/signin">
                    <span>Sign In</span
                    ><img
                      class="mob-icon"
                      src="assets/images/user-icon.svg"
                      alt="user icon"
                      width="15"
                      height="15"
                    />
                  </a>
                </div>
                <div class="user-login-right" *ngIf="loggedinuser">
                  <a class="user-icon-btn" (click)="clickNavUserIcon()"
                    ><img
                      class="mob-icon"
                      src="assets/images/user-icon.svg"
                      alt="user icon"
                      width="15"
                      height="15"
                    />
                  </a>
                  <div class="login-dateils-block" [ngClass]="toggleMobileClass ? 'login-detail-mobile' : ''">
                    <div class="login-dateils-inner">
                      <div class="login-user-dateils">
                        <div class="user-icon-btn" href="#">
                          <img
                            class="mob-icon"
                            src="assets/images/user-icon.svg"
                            alt="user icon"
                            width="15"
                            height="15"
                          />
                        </div>
                        <div class="use-name">
                          <span>{{ loggedinuser.userdata.user }}</span>
                          <a href="#">{{ loggedinuser.userdata.email }}</a>
                        </div>
                      </div>
                      <ul class="login-dateils-list">
                        <li>
                          <a routerLink="/profile" (click)="clickNavUserIcon()"
                            ><img
                              src="assets/images/user-profile.svg"
                              alt="profile icon"
                              width="24"
                              height="24"
                            /><span>My Profile</span></a
                          >
                        </li>
                        <li>
                          <a routerLink="/myplan" (click)="clickNavUserIcon()"
                            ><img
                              src="assets/images/my-plan-icon.svg"
                              alt="my-plan icon"
                              width="24"
                              height="24"
                            /><span>My Plan</span></a
                          >
                        </li>
                        <li>
                          <a (click)="navigateToHistoryTab()" (click)="clickNavUserIcon()"
                            ><img
                              src="assets/images/history-icons.svg"
                              alt="history icon"
                              width="24"
                              height="24"
                            /><span>Purchase History</span></a
                          >
                        </li>
                        <li>
                          <a routerLink="/mywishlist" (click)="clickNavUserIcon()"
                            ><img
                              src="assets/images/heart-icon.svg"
                              alt="heart icon"
                              width="24"
                              height="24"
                            /><span> My Wishlist</span></a
                          >
                        </li>
                        <li>
                          <a (click)="logout()"
                            ><img
                              src="assets/images/logout-icon.svg"
                              alt="logout icon"
                              width="24"
                              height="24"
                            /><span>Logout</span></a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="hamburger" [class.active]="isActive" (click)="toggleActiveClass()">
              <span class="bar"></span>
              <span class="bar"></span>
              <span class="bar"></span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</header>
