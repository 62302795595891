import { Injectable } from '@angular/core';
import { SafeRequestService } from './safe-request.service';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(private safeRequest:SafeRequestService) { }

  getCountryList() {
    const url = 'getCountyStatesCityList'
    return this.safeRequest.sendRequest('get', url)
  }
  getRegionList(country) {
    const url = 'getCountyStatesList/' + country
    return this.safeRequest.sendRequest('get', url)
  }
  getCityList(region) {
    const url = 'getStateCityList/' + region
    return this.safeRequest.sendRequest('get', url)
  }
}
