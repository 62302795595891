import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, DoCheck, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Media } from 'ng-opengallery';
import { Subject } from 'rxjs';
import { messages } from 'src/appv2/appv2.constants';
import { DiscountDetailsInterface } from 'src/appv2/interfaces/discount-details.interface';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { SearchchangeSearchName } from 'src/appv2/reducers/search/search.actions';
import { SearchState } from 'src/appv2/reducers/search/search.models';
import { CacheService } from 'src/appv2/services/cache.service';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { SearchService } from 'src/appv2/services/search.service';
import { urlToCacheKeyMapping } from 'src/appv2/utils/constants/discount.const';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'appv2-image-page',
  templateUrl: './image-page.component.html',
  styleUrls: ['./image-page.component.scss']
})
export class ImagePageComponent implements OnInit, DoCheck {

  constructor(private landingPage: LandingPageService, private store$: Store<any>, private route: ActivatedRoute, private router: Router, private mysearch: SearchService, private http: HttpClient, private elementRef: ElementRef, private cacheService: CacheService, private cdr: ChangeDetectorRef) {
    // Get Promotion Details
    this.promotionCacheKey = urlToCacheKeyMapping[this.promotionCacheKeyName];
    if (this.promotionCacheKey) {
      const promotion = this.cacheService.getData(this.promotionCacheKey);
      if (typeof promotion !== 'undefined') {
        this.promotion = promotion;
      } else {
        this.getPromotion();
      }
    }
  }

  visibleTrendingSearch: boolean = false
  page: string = 'home'
  search: HTMLInputElement
  searchType: string
  isSearchVisible: boolean
  recentSearches = []
  searchtext: string
  endIndex = 4;
  startIndex = 0;
  mainPageType: string = 'image';
  showTrendSerach: boolean = false;
  elasticSuggestions: any;
  timeout: any = null;

  galleryData: Array<any>

  searchKey: string = ''
  categories = []
  private baseUrl = environment.url;
  getTrendingData: any = null;

  /**
  * Open class of header component.
  */
  openClass: string = '';
  promotion: any;
  discountDetailsResponse: any;
  public discountDetails = new DiscountDetailsInterface();
  page_type: string = "image_page";
  trendingKeyword: any;
  destroy = new Subject();
  destroy$ = this.destroy.asObservable();
  cacheKey: string;
  // display pricing component header text
  displayPricingHeader = true;
  promotionCacheKeyName: string = 'image-page-banner';
  promotionCacheKey: string;

  ngOnInit(): void {

    const urlSegments = this.route.snapshot.url;
    // Get the last segment (the segment at the end of the URL)
    const lastSegment = urlSegments[urlSegments.length - 1];
    // Access the last segment's path
    const lastSegmentPath = lastSegment.path;
    // Now you can use the lastSegmentPath variable as needed

    this.page = lastSegmentPath;
    this.page_type = lastSegmentPath + '_page';

    // Get Discount Details
    this.cacheKey = urlToCacheKeyMapping[this.page];
    this.landingPage.isDiscountDetailsCalled.subscribe((isDiscountCalled: boolean) => {
      if (isDiscountCalled) {
        this.discountDetails = this.cacheService.getData(this.cacheKey);
      }
    })
    this.getGalleryContent();
    this.recentSearches = JSON.parse(localStorage.getItem('recent_search'));

  }

  ngDoCheck() {
    this.cdr.detectChanges();
    this.categories = this.landingPage.getCategoriesSlider()
  }

  /**
   * Gets suggestion keyword list by elastic search
   * @param searchText
   */
  getSuggestionByElasticSearch(searchText) {
    this.searchtext = searchText;
    this.landingPage.getSuggestionByElasticSearch(this.searchtext).subscribe(data => {
      if (data) {
        this.elasticSuggestions = data.data
      }
    })
  }

  public onInput(event: any) {
    const inputValue = event.target.value;
    // Define a regular expression to match letters, numbers, and spaces
    const regex = /^[a-zA-Z0-9\s]*$/;

    if (!regex.test(inputValue)) {
      // If the input contains characters other than letters, numbers, and spaces,
      // remove those characters from the input value
      event.target.value = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
    }
  }

  /**
   * Get promotion details
   */
  getPromotion() {
    this.loading(true);
    this.landingPage.getPromotionDetails(this.page_type).subscribe(data => {
      this.loading(false);
      this.promotion = data.data
      if(this.promotion){
        this.cacheService.setData(this.promotionCacheKey, this.promotion);
        this.landingPage.isImageBannerDetailsCalled.next(true);
      }
    })
  }

  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.imagePageLoading}))
    return
  }

  openCategory(catName) {
    this.searchtext = catName;
    this.router.navigate(['/categories/image',catName])
  }

  clickOpenMenu() {
    this.visibleTrendingSearch = false;
    this.showTrendSerach = false;
    if (this.openClass === 'open') {
      this.openClass = '';
    } else {
      this.openClass = 'open'
    }
  }

  onEnterSearch() {
    // save to local storage
    let recentSearchData = JSON.parse(localStorage.getItem('recent_search'));
    this.recentSearches = recentSearchData
    if (this.searchtext) {
      if (!recentSearchData) {
        recentSearchData = [];
      }
      if (recentSearchData.length > 4) {
        recentSearchData.shift();
      }
      if (this.searchtext) {
        recentSearchData.push(this.searchtext);
      }
      localStorage.setItem('recent_search', JSON.stringify(recentSearchData));
      if (this.page_type == 'image' || this.mainPageType == "image") {
        const queryParams = { search: this.searchtext };

        const navigationExtras: NavigationExtras = {
          queryParams: queryParams
        };
        this.router.navigate(['/images'], navigationExtras);
        // this.router.navigate(['/images'], { state: navigationData });
      } else {
        this.openCategory(this.searchtext);
      }
    }
  }

  onMouseInSearchBox() {
    this.visibleTrendingSearch = true
  }

  /**
   * Store keyword in elastic search api
   * @param keyword
   */
  storeKeywordInElasticSearch(keyword) {
    const bodyParams = {
      search_field: keyword
    };
    // this.loading(true);
    this.landingPage.storeKeywordInElasticSearch(bodyParams).subscribe(data => {
      this.loading(false);
    });
  }



  /**
     * Determines whether key press on
     * @param event
     */
  onKeyPress(event: any) {
    clearTimeout(this.timeout);
    this.openClass = '';
    this.timeout = setTimeout(() => {
      this.searchtext = event.target.value;
      if (this.searchtext && this.searchtext.trim().length != 0) {
        this.getSuggestionByElasticSearch(this.searchtext);
        this.showTrendSerach = true;
        this.visibleTrendingSearch = false;
      } else {
        this.showTrendSerach = false;
        this.visibleTrendingSearch = true;
      }
    }, 1000);
    if (event.keyCode == 13) {
      this.showTrendSerach = false;
      this.storeKeywordInElasticSearch(this.searchtext);
      this.onEnterSearch();
    }

  }

  onMouseOutSearchBox() {
    this.visibleTrendingSearch = false
    this.showTrendSerach = false;
  }

  searchEditorial(page) {
    this.mainPageType = page;

    if (this.searchtext && page == "image") {
      const imageQueryParams = { search: this.searchtext };
      const navigationExtras: NavigationExtras = {
        queryParams: imageQueryParams
      };
      this.router.navigate(['/images'], navigationExtras);
    } else if (this.searchtext && page == "footage") {
      const footageQueryParams = { search: this.searchtext };
      const navigationExtras: NavigationExtras = {
        queryParams: footageQueryParams
      };
      this.router.navigate(['/footages'], navigationExtras);
    } else {
      this.store$.dispatch(new SearchchangeSearchName(<SearchState>{ searchType: page }));
    }
  }


  getGalleryContent(currPage = 1) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: true, loadingMessage: messages.imagePageLoading}))
    this.mysearch.getSearchResult(1, currPage).subscribe((data) => {
      this.galleryData = []
      this.getTrendingData = data.imgfootage;
      for (let i = 0; i < data.imgfootage.length; i++) {
        if (data.imgfootage[i]['product_main_type'] == 'Image') {
          this.galleryData.push(new Media(data.imgfootage[i]['product_thumbnail'], data.imgfootage[i]['product_title']))
        }
      }
      this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: false }))
    })
  }

  receivePricingHeaderDisplayEvent(response: string) {
    this.displayPricingHeader = response == 'No' ? false : true;
  }

  scrollTo(section: string): void {
    const targetSection = this.elementRef.nativeElement.querySelector(`#${section}`);
    if (targetSection) {
      const headerHeight = this.getHeaderHeight();
      window.scrollTo({
        top: targetSection.offsetTop - headerHeight,
        behavior: 'smooth'
      });
    }
  }

  private getHeaderHeight(): number {
    const header = document.querySelector('.header-sec');
    return header ? header.clientHeight : 0;
  }

  openItem(item) {
    for (let i = 0; i < this.getTrendingData.length; i++) {
      if (
        this.getTrendingData[i]['product_thumbnail'] == item.url ||
        this.getTrendingData[i]['product_main_image'] == item.url
      ) {
        this.router.navigate(['/image/'+this.getTrendingData[i]['slug']]);
        break;
      }
    }
  }

  next() {
    this.startIndex++;
    if (this.startIndex === this.categories.length) {
      this.startIndex = 0;
    }
  }

  prev() {
    if (this.startIndex <= 0) {
      this.startIndex = this.categories.length;
    }
    this.startIndex--;
  }
}
