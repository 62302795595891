import { LoadingState } from './loading.models';
import { loadingActionsType, LoadingActions } from './loading.actions';

export const loadingNode = 'loading';


// Varibles for store if we initialize store in the first time.
const initialState: LoadingState = {
    loadingStatus: false,
    loadingMessage: 'hi'
};

// In this part we manipulate data and return result
export const loadingReducer = (state = initialState, action: LoadingActions) => {
  switch (action.type) {
    case loadingActionsType.loadingUpdated:
      return <LoadingState> {
        ...state,
        loadingStatus: action.payload.loadingStatus,
        loadingMessage: action.payload.loadingMessage,
      };
    default:
      return state;
  }
}
