import { Action } from '@ngrx/store';
import { FeaturesState } from './features.models';

// Create varibles to detect on what stage we are in store debug.
export enum featuresActionsType {
  writeCart = '[Feature] FeatureCart',
  writeWishlist = '[Feature] FeatureWishlist',
  increaseWishlistCount = '[Feature] increaseWishlistCount',
  decreaseWishlistCount = '[Feature] decreaseWishlistCount',
  increaseCartCount = '[Feature] increaseCartCount',
  decreaseCartCount = '[Feature] decreaseCartCount',
}
 
// Action for handle if user change text in search menu
export class FeatureWriteCartAction implements Action {
  readonly type = featuresActionsType.writeCart;

  constructor(public payload: FeaturesState) {
  }
}
export class FeatureIncreaseCartCountAction implements Action {
  readonly type = featuresActionsType.increaseCartCount;
}
export class FeatureDecreaseCartCountAction implements Action {
  readonly type = featuresActionsType.decreaseCartCount;
}
export class FeatureWriteWishlistAction implements Action {
  readonly type = featuresActionsType.writeWishlist;

  constructor(public payload: FeaturesState) {
  }
}
export class FeatureIncreaseWishlistCountAction implements Action {
  readonly type = featuresActionsType.increaseWishlistCount;
}
export class FeatureDecreaseWishlistCountAction implements Action {
  readonly type = featuresActionsType.decreaseWishlistCount;
}
// Exporting all user actions
export type FeaturesAction = FeatureWriteCartAction|
FeatureWriteWishlistAction|
FeatureIncreaseCartCountAction|
FeatureDecreaseCartCountAction|
FeatureIncreaseWishlistCountAction|
FeatureDecreaseWishlistCountAction ;
