import { Component, OnInit } from '@angular/core';
import { CmsPageService } from 'src/appv2/services/cms-page.service';
import $ from 'jquery';

@Component({
  selector: 'appv2-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(private cmsPage: CmsPageService) { }

  page: string = 'about-us';

  pageData: any;

  ngOnInit() {
    $('html, body').animate({ scrollTop: '0' }, 800);
    // this.getCmsPage(this.page);
  }


  getCmsPage(page) {
    this.cmsPage.getpagelist(page).subscribe(data => {
      this.pageData = data.data
    })
  }

}
