<section class="popular_categories">
    <div class="container">
      <h2 class="h2">Popular Categories</h2>
      <div class="popular_categories_image">
        <div class="popular_categories_image_section" >
          <div class="catagory_image" *ngFor="let category of categoryData">
            <div class="catagory_image_picture">
              <a class="catagory_image_inner" [href]="'#/categories/' + (category.type == 1 ? 'image' : category.type == 2 ? 'footage' : 'music') +'/'+ category.category_slug">
                <picture>
                  <span *ngIf="category.image_path">
                    <source srcset="{{category.image_path}}" alt="popular-category-image" width="284"
                      height="157" />
                    <source srcset="{{category.image_path}}" alt="popular-category-image" width="284"
                      height="157" />
                      <img src="{{category.image_path}}" alt="popular-category-image" width="284" height="157" />
                  </span>
                  <span *ngIf="category.image_path == undefined || category.image_path == '' || category.image_path == null">
                    <source srcset="assets/images/popular_category.webp" alt="popular-category-image" width="284"
                      height="157" />
                    <source srcset="assets/images/popular_category.jpg" alt="popular-category-image" width="284"
                      height="157" />
                      <img src="assets/images/popular_category.jpg" alt="popular-category-image" width="284" height="157" />
                  </span>
                   
                 
                </picture>
                <span class="catagory_image_picture_name">{{category.category_name}}</span>
              </a>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </section>
