import { FeaturesAction, featuresActionsType } from './features.actions';
import { FeaturesState } from './features.models';

export const featuresNode = 'feature';


// Varibles for store if we initialize store in the first time.
const initialState: FeaturesState = {
    wishlistCount: 0,
    cartCount: 0
};

// In this part we manipulate data and return result
export const featuresReducer = (state = initialState, action: FeaturesAction) => {
  switch (action.type) {
    case featuresActionsType.writeWishlist:
      return <FeaturesState> {
        ...state,
        wishlistCount: action.payload.wishlistCount,
      };
      case featuresActionsType.increaseWishlistCount:
      return <FeaturesState> {
        ...state,
        wishlistCount: state.wishlistCount + 1,
      };
      case featuresActionsType.decreaseWishlistCount:
      return <FeaturesState> {
        ...state,
        wishlistCount: state.wishlistCount - 1,
      };
      case featuresActionsType.writeCart:
        return <FeaturesState> {
          ...state,
          cartCount: action.payload.cartCount,
        };
        case featuresActionsType.increaseCartCount:
      return <FeaturesState> {
        ...state,
        cartCount: state.cartCount + 1,
      };
      case featuresActionsType.decreaseCartCount:
      return <FeaturesState> {
        ...state,
        cartCount: state.cartCount - 1,
      };
    default:
      return state;
  }
}
