<!-- content block START -->
<div class="gallery-iterator">
    <div class="image_grid">
      <section class="gallery-section">
        <div class="gallery-iterator">
          <div *ngFor="let item of galleryData">
          </div>
        </div>
        <ng-opengallery class="gallery-row"	[datasource]="galleryData" [config]="config" (selection)="openItemGallery($event)"></ng-opengallery>
      </section>
        <ng-container *ngIf="totalRecords > 0">
            <appv2-pagination [itemsPerPage]="itemsPerPage" [currentPage]="currPage" [totalPages]="totalPages"
                            (pageChanged)="onPageChange($event)">
            </appv2-pagination>
        </ng-container>
    </div>
