<section class="footer" [ngClass]="{
  'footage-list': addClassForFootage
}">
  <div class="container">
    <div
      [class]="'footer_top ' + page_type + '_footer_top'"
      *ngIf="
        (page_type === 'image' ||
          page_type === 'footage' ||
          page_type === 'home' ||
          page_type === 'editorial' ||
          page_type === 'image/779879' ||
          page_type === 'pricing' ||
          page_type === '' ||
          page_type === 'music') &&
        discountDetails?.status == 1
      "
    >
      <div class="footer_information_line_inner">
        <p>{{ discountDetails?.title }}</p>
        <a href="{{ discountDetails?.link }}">{{
          discountDetails?.button_text
        }}</a>
      </div>
    </div>
    <div class="footer_middle">
      <div class="footer_left_side">
        <div class="footer_logo_img">
          <a href="index.html"
            ><img
              src="assets/images/Logo.png"
              alt="logo"
              width="216px"
              height="39px"
          /></a>
        </div>
        <p>
          We are a privately owned diversified and deeply leveraged group,
          offering a variety of creative stock imagery & stock footage content.
        </p>
        <div class="footer_icon">
          <div>
            <a href="https://facebook.com"
              ><img
                src="assets/images/facebook.svg"
                alt="facebook"
                width="40px"
                height="40px"
            /></a>
          </div>
          <div>
            <a href="https://instagram.com"
              ><img
                src="assets/images/instagram.svg"
                alt="instagram"
                width="40px"
                height="40px"
            /></a>
          </div>
          <div>
            <a href="https://linkedin.com"
              ><img
                src="assets/images/linkedin.svg"
                alt="linkedin"
                width="40px"
                height="40px"
            /></a>
          </div>
          <div>
            <a href="https://youtube.com"
              ><img
                src="assets/images/youtube.svg"
                alt="youtube"
                width="40px"
                height="40px"
            /></a>
          </div>
        </div>
      </div>
      <div class="quick_links">
        <h3 class="h4">Quick Links</h3>
        <ul class="footer-menu-list">
          <li class="footer__link">
            <a routerLink="about-us">About Us</a>
          </li>
          <li class="footer__link">
            <a routerLink="contact-us">Contact Us</a>
          </li>
          <li class="footer__link">
            <a routerLink="licence-agreement">Licence Agreement</a>
          </li>
          <li class="footer__link">
            <a routerLink="/terms-and-conditions">Terms & Conditions</a>
          </li>
          <li class="footer__link">
            <a routerLink="privacy-policy">Privacy Policy</a>
          </li>
        </ul>
      </div>
      <div class="trending">
        <h3 class="h4">Trending</h3>
        <ul class="footer-menu-list">
          <li *ngFor="let trendingWord of trendingKeywords">
            <a (click)="openTrending(trendingWord.name)">{{trendingWord.name}}</a>
          </li>
        </ul>
      </div>
      <div class="contact_info">
        <h3 class="h4">Contact info</h3>
        <div class="contact_info_inner">
          <span class="contact_icon"
            ><a href="tel:18004198123">
              <img
                src="assets/images/ic_round-call.svg"
                alt="call-icon"
                width="20"
                height="20" /></a
          ></span>
          <span class="contact_us"
            ><a href="tel:18004198123">Toll free: 1800 419 8123</a></span
          >
        </div>
        <div class="contact_info_inner">
          <span class="contact_icon"
            ><a href="mailto:info@imagefootage.com">
              <img
                src="assets/images/material-symbols_mail-outline.svg"
                alt="mail-icon"
                width="20"
                height="20" /></a
          ></span>
          <span class="contact_us"
            ><a href="mailto:info@imagefootage.com"
              >info@imagefootage.com</a
            ></span
          >
        </div>
        <div class="contact_info_inner">
          <span class="contact_icon"
            ><a href="mailto:admin@imagefootage.com">
              <img
                src="assets/images/material-symbols_mail-outline.svg"
                alt="mail-icon"
                width="20"
                height="20" /></a
          ></span>
          <span class="contact_us"
            ><a href="mailto:admin@imagefootage.com"
              >admin@imagefootage.com</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="footer_bottom">
    <div class="container">
      <p>Copyright @ {{currentYear}} {{appName}}. All Rights Reserved.</p>
    </div>
  </div>
</section>
