import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private cache: { [key: string]: any } = {};

  getData(cacheKey: string): any {
    return this.cache[cacheKey];
  }

  setData(cacheKey: string, data: any): void {
    this.cache[cacheKey] = data;
  }
}
