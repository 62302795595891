import * as fromRouter from '@ngrx/router-store';
import { DEFAULT_ROUTER_FEATURENAME } from '@ngrx/router-store';
import { createSelector, createFeatureSelector } from '@ngrx/store';
// Here we create selectors to be able to manipulate data from path and 
// data via POST and GET.

export interface State {
  [DEFAULT_ROUTER_FEATURENAME]: fromRouter.RouterReducerState<any>;
}

export const selectRouter = createFeatureSelector<
  State,
  fromRouter.RouterReducerState<any>
>(DEFAULT_ROUTER_FEATURENAME);
 
// list of avalible router selector
// export const {
//   selectCurrentRoute,   // select the current route
//   selectFragment,       // select the current route fragment
//   selectQueryParams,    // select the current route query params
//   selectQueryParam,     // factory function to select a query param
//   selectRouteParams,    // select the current route params
//   selectRouteParam,     // factory function to select a route param
//   selectRouteData,      // select the current route data
//   selectUrl,            // select the current url
// } = fromRouter.getSelectors(selectRouter);


// Create main router selector
const routerSelectors = fromRouter.getSelectors(selectRouter);

// initiialize router selector
export const selectQueryParam = routerSelectors.selectQueryParam
export const selectQueryParams = routerSelectors.selectQueryParams
export const selectRouteParam = routerSelectors.selectRouteParam
export const selectCurrentRoute = routerSelectors.selectCurrentRoute
//export const selectSearchType = selectQueryParam('type')

// create router selectors
export const selectCategoryRouterId = selectRouteParam('catname')
export const selectSimplePage = selectRouteParam('simplepage')
export const selectSearchKey = selectQueryParam('key')
export const selectItemId = selectQueryParam('itemId')
export const selectItemType = selectQueryParam('itemType')
export const selectItemMainType = selectQueryParam('itemMainType')
export const selectSearchType_s = selectQueryParam('type')
export const selectTransactionId = selectRouteParam('orderId')

export const selectOtp = selectRouteParam('otp')
export const selectEmail = selectRouteParam('email')

//export const selectCurrentRouteName = selectCurrentRoute()