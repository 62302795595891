<section class="banner-sec" *ngIf="promotion">
  <picture>
    <source
      class="banner-img dec-img"
      srcset="{{ promotion?.desktop_banner_image }}"
      alt="Images"
      width="1920"
      height="524"
    />
    <source
      class="banner-img dec-img"
      srcset="{{ promotion?.desktop_banner_image }}"
      alt="Images"
      width="1920"
      height="524"
    />
    <img
      class="banner-img dec-img"
      src="{{ promotion?.desktop_banner_image }}"
      alt="Images"
      width="1920"
      height="524"
    />
    <source
      class="banner-img mob-img"
      srcset="{{ promotion?.mobile_banner_image }}"
      alt="Images"
      width="575"
      height="380"
    />
    <source
      class="banner-img mob-img"
      srcset="{{ promotion?.mobile_banner_image }}"
      alt="Images"
      width="575"
      height="380"
    />
    <img
      class="banner-img mob-img"
      src="{{ promotion?.mobile_banner_image }}"
      alt="Images"
      width="575"
      height="380"
    />
  </picture>
  <div class="container">
    <div class="banner-content">
      <h1 class="h1">{{ promotion?.event_name }}</h1>
      <p>{{ promotion?.event_des }}</p>

      <appv2-search-bar class="categories-seach-block-banner" [params]="{isHeaderPart: false}"></appv2-search-bar>
    </div>
  </div>
</section>
<!-- Information Line section start -->
<section class="information_line" *ngIf="discountDetails">
  <div class="container" *ngIf="discountDetails?.status == 1">
    <div class="information_line_inner">
      <p>{{ discountDetails?.title }}</p>
      <a target="_blank" href="{{ discountDetails?.link }}">{{
        discountDetails?.button_text
      }}</a>
    </div>
  </div>
</section>
<!-- Information Line section end -->

<!-- Discover image section start -->
<section class="discover_image">
  <div class="container">
    <div class="image_grid_tab" *ngIf="categories.length != 0">
      <div class="cata-sub-nav">
        <div
          class="nav-prev arrow"
          (click)="prev()"
          *ngIf="categories.length > 10"
        >
          <img
            src="assets/images/angle-left-solid.svg"
            alt="left-arrow"
            width="24px"
            height="24px"
          />
        </div>
        <ul [ngClass]="{'slider-nav': categories.length > 10}">
          <li
            *ngFor="
              let item of categories | keyvalue | rotate : startIndex;
              let in = index
            "
          >
            <a
              class="category-link"
              (click)="openCategory(item.value.category_slug)"
              >{{ item.value.category_name }}</a
            >
          </li>
        </ul>
        <div
          class="nav-next arrow"
          (click)="next()"
          *ngIf="categories.length > 10"
        >
          <img
            src="assets/images/angle-right-solid.svg"
            alt="right-arrow"
            width="24px"
            height="24px"
          />
        </div>
      </div>
      <div class="image_grid_tab_btn">
        <a routerLink="/categories">View All</a>
      </div>
    </div>
    <!-- layout of image grid position start -->
    <app-gallery></app-gallery>
    <!-- layout of image grid position end -->
    <div class="image_grid_btn">
      <a *ngIf="mainPageType == 'image'" routerLink="/images"
        >Discover More</a
      >
      <a *ngIf="mainPageType == 'footage'" routerLink="/footage"
        >Discover More</a
      >
      <a *ngIf="mainPageType == 'editorial'" routerLink="/editorial"
        >Discover More</a
      >
    </div>
  </div>
</section>

<!-- Royalty free stock images section start -->
<section class="royalty_free_stock_images_section">
  <div class="container">
    <div class="royalty_free_stock_images_section_inner">
      <div class="royalty_free_stock_images_section_content">
        <h2 class="h2">
          Royalty free stock images, photos, vectors and videos
        </h2>
        <p>
          Discover the best royalty free stock images, photos, vectors, footage,
          videos and music files for your designs and projects by talented
          artists and contributors worldwide right here. Everything you need is
          just a search away.
        </p>
      </div>
      <div class="royalty_free_stock_images_section_img">
        <img
          src="assets/images/royalty_free_section_image.png"
          alt="cartoon-character"
          width="416px"
          height="398px"
        />
      </div>
    </div>
  </div>
</section>
<!-- Royalty free stock images section end -->
<!-- Premium collection section start -->
<section class="premium_collection" *ngIf="isPremiumCollectionsShow">
  <div class="container">
    <h2 class="h2">Premium Collections</h2>
    <div class="premium_collection_card_section">
      <div class="premium_collection_card">
        <div class="premium_collection_card_image">
          <picture
            ><img
              src="assets/images/Placehoder_Gray S2.jpg"
              alt="premium-image"
              width="447px"
              height="375px"
          /></picture>
        </div>
        <div class="premium_collection_card_person">
          <div class="premium_collection_card_person_image">
            <img
              src="assets/images/premium_person_image.png"
              alt="premium-person-image"
              width="74px"
              height="74px"
            />
          </div>
          <h3 class="h3">AndreyPopov</h3>
        </div>
      </div>
      <div class="premium_collection_card">
        <div class="premium_collection_card_image">
          <picture
            ><img
              src="assets/images/premium_image1.webp"
              alt="premium-image"
              width="447px"
              height="375px"
          /></picture>
        </div>
        <div class="premium_collection_card_person">
          <div class="premium_collection_card_person_image">
            <img
              src="assets/images/premium_person_image1.png"
              alt="premium-person-image"
              width="74px"
              height="74px"
            />
          </div>
          <h3 class="h3">Theresa Webb</h3>
        </div>
      </div>
      <div class="premium_collection_card">
        <div class="premium_collection_card_image">
          <picture>
            <img
              src="assets/images/premium_image2.webp"
              alt="premium-image"
              width="447px"
              height="375px"
            />
          </picture>
        </div>
        <div class="premium_collection_card_person">
          <div class="premium_collection_card_person_image">
            <img
              src="assets/images/premium_person_image2.png"
              alt="premium-person-image"
              width="74px"
              height="74px"
            />
          </div>
          <h3 class="h3">Courtney Henry</h3>
        </div>
      </div>
      <div class="premium_collection_card">
        <div class="premium_collection_card_image">
          <picture
            ><img
              src="assets/images/premium_image3.webp"
              alt="premium-image"
              width="447px"
              height="375px"
          /></picture>
        </div>
        <div class="premium_collection_card_person">
          <div class="premium_collection_card_person_image">
            <img
              src="assets/images/premium_person_image3.png"
              alt="premium-person-image"
              width="74px"
              height="74px"
            />
          </div>
          <h3 class="h3">Ralph Edwards</h3>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Premium collection section end -->
<!-- Discover creative section start -->
<section class="discover_creative">
  <div class="container">
    <div class="discover_creative_inner">
      <div class="discover_creative_content">
        <h2 class="h2">
          Discover Creative Royalty-free Images & Illustrations
        </h2>
        <p>
          If you are an Artist and dare to start a business with a lot of money,
          then it is a good business opportunity to make a good profit.
          Must-Have an Image Click on the skills, ideas, or desire to monetize
          images and Satisfy Customers and then your Business.
        </p>
        <a routerLink="/images">Discover More</a>
      </div>
      <div class="discover_creative_image">
        <img
          src="../../../assets/images/creative_image.png"
          alt="Creative-image"
          width="852px"
          height="672px"
        />
      </div>
    </div>
  </div>
</section>
<!-- Discover creative section end -->
<!-- Trending photos section start -->
<section class="trending_photos" *ngIf="isTrendingSectionShow">
  <div class="container">
    <h2 class="h2">Trending Photos</h2>
    <div class="trending_photos_slider">
      <div class="photo-slider">
        <ngx-slick-carousel
          class="carousel"
          #slickModal="slick-carousel"
          [config]="slideConfig"
          (init)="slickInit($event)"
          (breakpoint)="breakpoint($event)"
          (afterChange)="afterChange($event)"
          (beforeChange)="beforeChange($event)"
        >
          <div
            ngxSlickItem
            *ngFor="let slide of [0, 1, 2, 3, 4, 5, 6]"
            class="slide"
          >
            <div class="two-images">
              <div class="main-image">
                <picture>
                  <source
                    srcset="assets/images/trending-photos.webp"
                    alt="trending-photos-image"
                    width="453"
                    height="273"
                  />
                  <source
                    srcset="assets/images/trending-photos.jpg"
                    alt="trending-photos-image"
                    width="453"
                    height="273"
                  />
                  <img
                    src="assets/images/trending-photos.jpg"
                    alt="catrending-photos-image"
                    width="453"
                    height="273"
                  />
                </picture>
                <div class="like">
                  <a href="javascript:void(0)">
                    <img
                      src="assets/images/heart-icon.svg"
                      alt="like"
                      width="24"
                      height="24"
                      class="white_heart"
                    />
                    <img
                      src="assets/images/homepage-like.svg"
                      alt="red_heart"
                      width="24"
                      height="24"
                      class="red_heart"
                    />
                  </a>
                </div>
                <a href="#" class="image_name">Image name</a>
                <div class="licence_btn">
                  <a href="javascript:void(0)">License</a>
                </div>
              </div>

              <div class="main-image">
                <picture>
                  <source
                    srcset="assets/images/trending-photos-1.webp"
                    alt="trending-photos-image"
                    width="453"
                    height="273"
                  />
                  <source
                    srcset="assets/images/trending-photos-1.jpg"
                    alt="ctrending-photos-image"
                    width="453"
                    height="273"
                  />
                  <img
                    src="assets/images/trending-photos-1.jpg"
                    alt="trending-photos-image"
                    width="453"
                    height="273"
                  />
                </picture>
                <div class="like">
                  <a href="javascript:void(0)">
                    <img
                      src="assets/images/heart-icon.svg"
                      alt="like"
                      width="24"
                      height="24"
                      class="white_heart"
                    />
                    <img
                      src="assets/images/homepage-like.svg"
                      alt="red_heart"
                      width="24"
                      height="24"
                      class="red_heart"
                    />
                  </a>
                </div>
                <a href="#" class="image_name">Image name</a>
                <div class="licence_btn">
                  <a href="javascript:void(0)">License</a>
                </div>
              </div>
            </div>
          </div>
        </ngx-slick-carousel>
        <div class="two-images">
          <div class="main-image">
            <picture>
              <source
                srcset="assets/images/trending-photos.webp"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
              <source
                srcset="assets/images/trending-photos.jpg"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
              <img
                src="assets/images/trending-photos.jpg"
                alt="catrending-photos-image"
                width="453"
                height="273"
              />
            </picture>
            <div class="like">
              <a href="javascript:void(0)">
                <img
                  src="assets/images/heart-icon.svg"
                  alt="like"
                  width="24"
                  height="24"
                  class="white_heart"
                />
                <img
                  src="assets/images/homepage-like.svg"
                  alt="red_heart"
                  width="24"
                  height="24"
                  class="red_heart"
                />
              </a>
            </div>
            <a href="#" class="image_name">Image name</a>
            <div class="licence_btn">
              <a href="javascript:void(0)">License</a>
            </div>
          </div>
          <div class="main-image">
            <picture>
              <source
                srcset="assets/images/trending-photos-1.webp"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
              <source
                srcset="assets/images/trending-photos-1.jpg"
                alt="ctrending-photos-image"
                width="453"
                height="273"
              />
              <img
                src="assets/images/trending-photos-1.jpg"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
            </picture>
            <div class="like">
              <a href="javascript:void(0)">
                <img
                  src="assets/images/heart-icon.svg"
                  alt="like"
                  width="24"
                  height="24"
                  class="white_heart"
                />
                <img
                  src="assets/images/homepage-like.svg"
                  alt="red_heart"
                  width="24"
                  height="24"
                  class="red_heart"
                />
              </a>
            </div>
            <a href="#" class="image_name">Image name</a>
            <div class="licence_btn">
              <a href="javascript:void(0)">License</a>
            </div>
          </div>
        </div>
        <div class="single-image main-image">
          <picture>
            <source
              srcset="assets/images/trending-photos-2.webp"
              alt="trending-photos-image"
              width="453"
              height="550"
            />
            <source
              srcset="assets/images/trending-photos-2.jpg"
              alt="trending-photos-image"
              width="453"
              height="550"
            />
            <img
              src="assets/images/trending-photos-2.jpg"
              alt="trending-photos-image"
              width="453"
              height="550"
            />
          </picture>
          <div class="like">
            <a href="javascript:void(0)">
              <img
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                src="assets/images/homepage-like.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </div>
          <a href="#" class="image_name">Image name</a>
          <div class="licence_btn">
            <a href="javascript:void(0)">License</a>
          </div>
        </div>
        <div class="single-image main-image">
          <picture>
            <source
              srcset="assets/images/trending-photos-3.webp"
              alt="trending-photos-image"
              width="453"
              height="550"
            />
            <source
              srcset="assets/images/trending-photos-3.jpg"
              alt="trending-photos-image"
              width="453"
              height="550"
            />
            <img
              src="assets/images/trending-photos-3.jpg"
              alt="trending-photos-image"
              width="453"
              height="550"
            />
          </picture>
          <div class="like">
            <a href="javascript:void(0)">
              <img
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                src="assets/images/homepage-like.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </div>
          <a href="#" class="image_name">Image name</a>
          <div class="licence_btn">
            <a href="javascript:void(0)">License</a>
          </div>
        </div>
        <div class="two-images">
          <div class="main-image">
            <picture>
              <source
                srcset="assets/images/trending-photos-4.webp"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
              <source
                srcset="assets/images/trending-photos-4.jpg"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
              <img
                src="assets/images/trending-photos-4.jpg"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
            </picture>
            <div class="like">
              <a href="javascript:void(0)">
                <img
                  src="assets/images/heart-icon.svg"
                  alt="like"
                  width="24"
                  height="24"
                  class="white_heart"
                />
                <img
                  src="assets/images/homepage-like.svg"
                  alt="red_heart"
                  width="24"
                  height="24"
                  class="red_heart"
                />
              </a>
            </div>
            <a href="#" class="image_name">Image name</a>
            <div class="licence_btn">
              <a href="javascript:void(0)">License</a>
            </div>
          </div>
          <div class="main-image">
            <picture>
              <source
                srcset="assets/images/trending-photos-5.webp"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
              <source
                srcset="assets/images/trending-photos-5.jpg"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
              <img
                src="assets/images/trending-photos-5.jpg"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
            </picture>
            <div class="like">
              <a href="javascript:void(0)">
                <img
                  src="assets/images/heart-icon.svg"
                  alt="like"
                  width="24"
                  height="24"
                  class="white_heart"
                />
                <img
                  src="assets/images/homepage-like.svg"
                  alt="red_heart"
                  width="24"
                  height="24"
                  class="red_heart"
                />
              </a>
            </div>
            <a href="#" class="image_name">Image name</a>
            <div class="licence_btn">
              <a href="javascript:void(0)">License</a>
            </div>
          </div>
        </div>
        <div class="single-image main-image">
          <picture>
            <source
              srcset="assets/images/trending-photos-6.webp"
              alt="trending-photos-image"
              width="453"
              height="550"
            />
            <source
              srcset="assets/images/trending-photos-6.jpg"
              alt="trending-photos-image"
              width="453"
              height="550"
            />
            <img
              src="assets/images/trending-photos-6.jpg"
              alt="trending-photos-image"
              width="453"
              height="550"
            />
          </picture>
          <div class="like">
            <a href="javascript:void(0)">
              <img
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                src="assets/images/homepage-like.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </div>
          <a href="#" class="image_name">Image name</a>
          <div class="licence_btn">
            <a href="javascript:void(0)">License</a>
          </div>
        </div>
        <div class="single-image main-image">
          <picture>
            <source
              srcset="assets/images/trending-photos-7.webp"
              alt="trending-photos-image"
              width="453"
              height="550"
            />
            <source
              srcset="assets/images/trending-photos-7.jpg"
              alt="trending-photos-image"
              width="453"
              height="550"
            />
            <img
              src="assets/images/trending-photos-7.jpg"
              alt="trending-photos-image"
              width="453"
              height="550"
            />
          </picture>
          <div class="like">
            <a href="javascript:void(0)">
              <img
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                src="assets/images/homepage-like.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </div>
          <a href="#" class="image_name">Image name</a>
          <div class="licence_btn">
            <a href="javascript:void(0)">License</a>
          </div>
        </div>
        <div class="two-images">
          <div class="main-image">
            <picture>
              <source
                srcset="assets/images/trending-photos-8.webp"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
              <source
                srcset="assets/images/trending-photos-8.jpg"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
              <img
                src="assets/images/trending-photos-8.jpg"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
            </picture>
            <div class="like">
              <a href="javascript:void(0)">
                <img
                  src="assets/images/heart-icon.svg"
                  alt="like"
                  width="24"
                  height="24"
                  class="white_heart"
                />
                <img
                  src="assets/images/homepage-like.svg"
                  alt="red_heart"
                  width="24"
                  height="24"
                  class="red_heart"
                />
              </a>
            </div>
            <a href="#" class="image_name">Image name</a>
            <div class="licence_btn">
              <a href="javascript:void(0)">License</a>
            </div>
          </div>
          <div class="main-image">
            <picture>
              <source
                srcset="assets/images/trending-photos-9.webp"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
              <source
                srcset="assets/images/trending-photos-9.jpg"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
              <img
                src="assets/images/trending-photos-9.jpg"
                alt="trending-photos-image"
                width="453"
                height="273"
              />
            </picture>
            <div class="like">
              <a href="javascript:void(0)">
                <img
                  src="assets/images/heart-icon.svg"
                  alt="like"
                  width="24"
                  height="24"
                  class="white_heart"
                />
                <img
                  src="assets/images/homepage-like.svg"
                  alt="red_heart"
                  width="24"
                  height="24"
                  class="red_heart"
                />
              </a>
            </div>
            <a href="#" class="image_name">Image name</a>
            <div class="licence_btn">
              <a href="javascript:void(0)">License</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
