import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'appv2-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {

  openTab: string = 'profile';

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      // Check if the 'tab' query parameter is set and assign it to openTab
      if (params.tab) {
        this.openTab = params.tab;
      }
    });
  }

  clicked($tab) {
    if ($tab == 'security') {
      this.openTab = 'security';
    } else if ($tab == 'history') {
      this.openTab = 'history'
    } else if ($tab == 'delete-account') {
      this.openTab = 'delete-account'
    } else if ($tab == 'download-history') {
      this.openTab = 'download-history'
    } else {
      this.openTab = 'profile'
    }
  }

  public getTabName(tabName) {
    this.openTab = tabName
  }
}
