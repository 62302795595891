import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Media } from 'ng-opengallery';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import {
  SearchWriteAction
} from 'src/appv2/reducers/search/search.actions';
import { SearchState } from 'src/appv2/reducers/search/search.models';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { SearchService } from 'src/appv2/services/search.service';
import { FilterComponent } from '../filter/filter.component';


@Component({
  selector: 'appv2-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss'],
})
export class CategoryDetailsComponent implements OnInit {
  receivedProductCount: string;

  receiveDataFromChild(data: string) {
    this.receivedProductCount = data;
  }
  filters;
  showReset = false;
  openClass: string = '';
  searchKey: string = '';
  visibleTrendingSearch: boolean;
  showTrendSerach: boolean = false;
  mainPageType: string = 'image';
  recentSearches = [];
  page_type: string;
  elasticSuggestions: any;
  trendingKeyword: any;
  searchtext: string = '';
  timeout: any = null;
  selectedOption = 'Popular';
  dropdownOpen = false;
  galleryData: Array<any>;
  itemDataFromSorting: Array<any>;
  totalRecords: Number;
  totalFromSorting: Number;
  options = [ 'Popular', 'Recent'];
  processedFilters: { key: string; value: string; hasMultipleValue: string }[] =
    [];
  fileExtension: string;
  recordsPerPage: number;
  itemsPerPage = Number;
  recordsFromSorting = Number;
  @ViewChild(FilterComponent) childComponentReference: FilterComponent;
  shouldDisplayUl: boolean = false;
  category_id: string = '';
  public fromCategory: Boolean;
  public productType: string = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private landingPage: LandingPageService,
    private mysearch: SearchService,
    private location: Location,
    private activatedRouter: ActivatedRoute,
    private store$: Store<any>
  ) {
    this.searchKey = '';
    this.fromCategory = true;
  }

  ngOnInit(): void {

    let type = this.activatedRouter.snapshot.paramMap.get('type');
    this.productType = type;

    this.recentSearches = JSON.parse(localStorage.getItem('recent_search'));
    this.category_id = this.activatedRouter.snapshot.paramMap.get('slug') != '' &&  this.activatedRouter.snapshot.paramMap.get('slug') != undefined ? this.capitalizeFirstLetter(this.activatedRouter.snapshot.paramMap.get('slug')) : 'Category Details';
  }

   capitalizeFirstLetter(str) {
    str = str.replace(/-/g, ' ');
    return str[0].toUpperCase() + str.slice(1);
  }

  getFilterData(filterData: any) {
    const processedFilters: {
      key: string;
      value: string;
      namekey: string;
      valuekey: string;
      hasMultipleValue: string;
    }[] = [];

    for (const key in filterData) {
      if (
        filterData.hasOwnProperty(key) &&
        key.endsWith('_name') &&
        Array.isArray(filterData[key])
      ) {
        const filterName = key.replace('_name', '');
        const filterValues = filterData[key].join(', ');

        const filterShowName = filterName + '_filtername';
        const filterOptionName = filterName + '_optionname';
        const hasMultipleValue = filterName + '_hasMultipleValues';

        const nameValue = filterData[filterShowName][0];

        const optionValues = filterData[filterOptionName].join(', ');
        const hasMultipleValuedata = filterData[hasMultipleValue][0];

        if (filterName == 'product_type') {
          this.productType = filterValues;
        } else {
          processedFilters.push({
            key: filterName,
            value: filterValues,
            namekey: nameValue,
            valuekey: optionValues,
            hasMultipleValue: hasMultipleValuedata,
          });
        }
      }

      this.processedFilters = processedFilters;

      const hasNonEmptyValue = this.processedFilters.some(filter => filter.value !== undefined && filter.value.trim() !== '');
      this.showReset = hasNonEmptyValue;

      this.processedFilters = this.processedFilters.filter(
        (filter) => filter.value !== ''
      );

      this.shouldDisplayUl = this.processedFilters.some(
        (filter) => filter.value !== ''
      );
    }
    const navigationExtras: NavigationExtras = {
      queryParams: { search: this.searchtext },
    };

    if (this.searchtext === '') {
      this.router.navigate(
        [
          '/categories/' +this.activatedRouter.snapshot.paramMap.get('type')+'/'+
            this.activatedRouter.snapshot.paramMap.get('slug'),
        ],
        navigationExtras
      );
      this.router.navigate([
        '/categories/' +this.activatedRouter.snapshot.paramMap.get('type')+'/'+
          this.activatedRouter.snapshot.paramMap.get('slug'),
      ]);
    } else {
      this.router.navigate([
        '/categories/' +this.activatedRouter.snapshot.paramMap.get('type')+'/'+
          this.activatedRouter.snapshot.paramMap.get('slug'),
      ]);
      this.router.navigate(
        [
          '/categories/' +this.activatedRouter.snapshot.paramMap.get('type')+'/'+
            this.activatedRouter.snapshot.paramMap.get('slug'),
        ],
        navigationExtras
      );
    }
  }

  removeSpecificFilter(filter_type, index) {
    this.childComponentReference.removeFilters(filter_type, index);
    this.childComponentReference.checkboxChange.emit(false);
  }
  resetFilter() {
    this.childComponentReference.resetFilter();
    this.processedFilters = []
  }

  storeKeywordInElasticSearch(keyword) {
    const bodyParams = {
      search_field: keyword,
    };
    // this.loading(true);
    this.landingPage
      .storeKeywordInElasticSearch(bodyParams)
      .subscribe((data) => {
        this.loading(false);
      });
  }

  onMouseInSearchBox() {
    this.visibleTrendingSearch = true;
  }

  onMouseOutSearchBox() {
    this.visibleTrendingSearch = false;
    this.showTrendSerach = false;
  }

  /**
   * Determines whether key press on
   * @param event
   */
  onKeyPress(event: any) {
    clearTimeout(this.timeout);
    this.openClass = '';
    this.timeout = setTimeout(() => {
      this.searchtext = event.target.value;
      if (this.searchtext && this.searchtext.trim().length != 0) {
        this.getSuggestionByElasticSearch(this.searchtext);
        this.showTrendSerach = true;
        this.visibleTrendingSearch = false;
      } else {
        this.showTrendSerach = false;
        this.visibleTrendingSearch = true;
      }
    }, 1000);
    if (event.keyCode == 13) {
      this.showTrendSerach = false;
      this.storeKeywordInElasticSearch(this.searchtext);
      this.onEnterSearch();
    }
  }

  /**
   * Gets suggestion keyword list by elastic search
   * @param searchText
   */
  getSuggestionByElasticSearch(searchText) {
    this.searchtext = searchText;
    this.landingPage
      .getSuggestionByElasticSearch(this.searchtext)
      .subscribe((data) => {
        if (data) {
          this.elasticSuggestions = data.data;
        }
      });
  }

  public onInput(event: any) {
    const inputValue = event.target.value;
    // Define a regular expression to match letters, numbers, and spaces
    const regex = /^[a-zA-Z0-9\s]*$/;

    if (!regex.test(inputValue)) {
      // If the input contains characters other than letters, numbers, and spaces,
      // remove those characters from the input value
      event.target.value = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
    }
  }

  onEnterSearch() {
    // save to local storage
    let recentSearchData = JSON.parse(localStorage.getItem('recent_search'));
    this.recentSearches = recentSearchData;
    if (this.searchtext) {
      if (!recentSearchData) {
        recentSearchData = [];
      }
      if (recentSearchData.length > 4) {
        recentSearchData.shift();
      }
      if (this.searchtext) {
        recentSearchData.push(this.searchtext);
      }
      localStorage.setItem('recent_search', JSON.stringify(recentSearchData));
      if (this.page_type == 'image' || this.mainPageType == 'image') {
        const queryParams = { search: this.searchtext };
        const navigationExtras: NavigationExtras = {
          queryParams: queryParams,
        };
        this.router.navigate(['/images'], navigationExtras);
      } else {
        this.openCategory(this.searchtext);
      }
    } else {
      const queryParams = { search: this.searchtext };
      const navigationExtras: NavigationExtras = {
        queryParams: queryParams,
      };
      this.router.navigate(['/images']);
    }
  }

  clickOpenMenu() {
    this.visibleTrendingSearch = false;
    this.showTrendSerach = false;
    if (this.openClass === 'open') {
      this.openClass = '';
    } else {
      this.openClass = 'open';
    }
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }
  sampleOption(option){
    this.selectedOption = option
    this.dropdownOpen = false
    const navigationExtras: NavigationExtras = {
      queryParams: { search: this.searchtext },
    };
     this.router.navigate(['/categories/'+this.activatedRouter.snapshot.paramMap.get('type')+'/'+this.activatedRouter.snapshot.paramMap.get('slug')], navigationExtras);
          this.router.navigate(['/categories/'+this.activatedRouter.snapshot.paramMap.get('type')+'/'+this.activatedRouter.snapshot.paramMap.get('slug')]);

  }

  selectOption(option: string) {
    this.selectedOption = option;
    this.dropdownOpen = false;

    this.filters = {
      type: this.productType,
      product_editorial: 0,
      sort: this.selectedOption,
    };
    let currentPage = 0;
    this.mysearch
      .getSearchWithFiltersResult(
        this.filters,
        currentPage,
        this.searchKey,
        this.processedFilters,
        this.category_id
      )
      .subscribe((data) => {
        if (data.imgfootage != null && data.imgfootage != undefined) {
          this.galleryData = [];
          let filtereddata = data.imgfootage.filter(function (tag) {
            return tag.api_product_id;
          });
          for (let i = 0; i < filtereddata.length; i++) {
            if (this.filters['type'] == 'image') {
              const parts = filtereddata[i]['product_thumbnail'].split('.');
              // Get the last part of the array (the file extension)
              this.fileExtension = parts[parts.length - 1];
              this.galleryData.push(
                new Media(
                  filtereddata[i]['product_thumbnail'],
                  filtereddata[i]['product_title']
                )
              );
            }
          }
          this.itemDataFromSorting = filtereddata;
          this.recordsFromSorting =
            this.recordsPerPage =
            this.itemsPerPage =
              data.perpage;
          this.totalFromSorting = this.totalRecords = data.total;
          const navigationExtras: NavigationExtras = {
            queryParams: { search: this.searchtext },
          };
          // this.router.navigate(['/categories/'+this.activatedRouter.snapshot.paramMap.get('type')+'/'+this.activatedRouter.snapshot.paramMap.get('slug')], navigationExtras);
          // this.router.navigate(['/categories/'+this.activatedRouter.snapshot.paramMap.get('type')+'/'+this.activatedRouter.snapshot.paramMap.get('slug')]);
        }
      });
  }

  reloadComponent() {
    this.location.replaceState(this.location.path());
  }

  /**
   * Open category.
   * @param catName
   */
  openCategory(catName) {
    this.searchtext = catName;
    this.store$.dispatch(
      new SearchWriteAction(<SearchState>{ searchText: 'category ' + catName })
    );
  }

  public loading(status: boolean) {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: status })
    );
    return;
  }

  async getCategoryIdByName(type) {

    let category_name = this.activatedRouter.snapshot.paramMap.get('slug');
    if(type == 'image'){
      type = '1'
    }else if(type == 'footage'){
      type = '2'
    }else{
      type = '3'
    }
    try {
      const data = await this.landingPage.getCategoryDetails(category_name,type).toPromise();
      this.loading(false);
      this.category_id = data.data.category_id;
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed.
    }
  }
}
