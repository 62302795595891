<div class="filter-block-left" [class.footage-filter-listing]="active_footage === 'footages'" [class.active]="filter_active === 'footage-filter'">
    <h2 class="h4" (click)="openFilters('footage-filter')">Filters</h2>
    <div class="still_have_questions_accordian">
        <ng-container *ngFor="let filter of filterDetails">
            <!-- For Mutiple selection -->
            <div class="accordian_border" *ngIf="filter.filter_type == 2 && filter.filter_type !==1&& filter.filter_type !==3 ">
                <button class="course-accordion" (click)="openMenu(filter.value)"
                    [class.active]="filterMenu === filter.value">{{filter.name}}</button>
                <div class="course-panel" [class.range-value]="filterMenu === filter.value">
                    <div class="accordian-inner">
                        <ul class="custom-checkbox-list">
                            <li *ngFor="let option of filter.options">
                                <input class="custom-checkbox" [id]="option.id" type="checkbox" [value]="option.value"
                                    [checked]="option.isChecked" (change)="toggleFilter(option)"
                                    (click)="getCategoriesFromFilter(filter.value, option.id, option.value,0, filter.name, option.option_name, filter.has_multiple_values,option)">
                                <label [for]="option.id">{{option.option_name}}</label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="accordian_border" *ngIf=" filter.default_filter_type === 1 && filter.filter_type !==2 && filter.filter_type !==1">
                <button class="course-accordion" (click)="openMenu(filter.value)"
                    [class.active]="filterMenu === filter.value">{{filter.name}}</button>
                <div class="course-panel" [class.range-value]="filterMenu === filter.value">
                    <div class="accordian-inner">
                        <ul class="custom-checkbox-list">
                            <li *ngFor="let option of filter.options">
                                <input class="custom-checkbox" [id]="option.id" type="checkbox" [value]="option.value"
                                    [checked]="option.isChecked" (change)="toggleFilter(option)"
                                    (click)="getCategoriesFromFilter(filter.value, option.id, option.value,0, filter.name, option.option_name, filter.has_multiple_values,option)">
                                <label [for]="option.id">{{option.option_name}}</label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
            <div class="accordian_border" *ngIf="filter.filter_type == 1 && filter.filter_type !==2 && filter.filter_type !==3">
                <button class="course-accordion" (click)="openMenu(filter.value)"
                    [class.active]="filterMenu === filter.value">{{filter.name}}</button>
                <div class="course-panel" [class.range-value]="filterMenu === filter.value">
                    <div class="accordian-inner">
                        <ul class="custom-radio">
                            <li *ngFor="let option of filter.options">
                                <input type="radio" [id]="option.id" [name]="filter.value"                                  
                                    [checked]="selectedOptionId.includes(option.id)"
                                    (click)="getCategoriesFromFilter(filter.value, option.id, option.value,1 , filter.name, option.option_name, filter.has_multiple_values, option)">
                                <label [for]="option.id">{{option.option_name}}</label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordian_border" *ngIf="filter.filter_type == 3 && filter.filter_type !==2 && filter.filter_type !==1 && fromCategory">
                <button class="course-accordion" (click)="openMenu(filter.value)"
                    [class.active]="filterMenu === filter.value">{{filter.name}}</button>
                <div class="course-panel" [class.range-value]="filterMenu === filter.value">
                    <div class="accordian-inner">
                        <ul class="custom-radio">
                            <li *ngFor="let option of filter.options">
                                <input type="radio" [id]="option.id" [name]="filter.value"
                                    [checked]="option.value ===page_type"
                                    (click)="getCategoriesFromFilter(filter.value, option.id, option.value,1 , filter.name, option.option_name, filter.has_multiple_values, option)">
                                <label [for]="option.id">{{option.option_name}}</label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </ng-container>
    </div>
</div>