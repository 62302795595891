<div id="tab-4">
    <h2 class="h2">Delete Account</h2>
    <br/>
    <div class="csm-content-inner">
    <p>Are you sure you want to delete your account? Please be aware that this action is irreversible and will result in the permanent deletion of the following records associated with your account?</p><br><br>
        <div class="list-disc">
        <ul>

            <li><b>Invoices:</b> All your billing and payment records.</li>
            <li><b>Cart Items:</b> Items currently in your shopping cart.</li>
            <li><b>Subscription Plans and Packages:</b> Any active subscriptions and package details.</li>
            <li><b>Orders:</b> Details of your past orders.</li>
            <li><b>Payments:</b> Records of your payment transactions.</li>
            <li><b>Wishlist:</b> Items you've saved for future purchases.</li>
            <li><b>Downloads:</b> Files or content you've downloaded.</li>


        </ul>
        <br/>
        </div>
        <p>Once your account is deleted, this information cannot be recovered. If you have any concerns or questions, please contact our support team before proceeding.</p>
        <br/>
        <p>To confirm the account deletion, please click on the Delete Account button below.</p>
    <br>
    <div class="information_btn">
        <a (click)="openDeletePopup()">Delete Account</a>
    </div>
    </div>
    <div class="overlay"></div>
    <!-- popup start -->
    <div class="download_popup">
        <div class="download_popup_inner">
            <div class="wishlist_image_section">
                <div class="wishlist_image_heading">
                    <h3 class="h4">Are you sure want to delete your account ?
                    </h3>
                    <ul>
                        <li><button class="thame-btn cancel-btn" (click)="onCancel()">Cancel</button></li>
                        <li><a class="thame-btn" (click)="deleteAccount()">Delete</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- popup end -->
</div>
