
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CmsPageComponent } from './pages/cms-page/cms-page.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';

const routes: Routes = [
  {
    path:'Auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  { path: 'cms', component: CmsPageComponent },
  { path: 'pages/about-us', component: AboutUsComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class Appv2RoutingModule { }
