import { ChangeDetectorRef, Component, DoCheck, OnInit } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { messages } from 'src/appv2/appv2.constants';
import { DiscountDetailsInterface } from 'src/appv2/interfaces/discount-details.interface';
import { CacheService } from 'src/appv2/services/cache.service';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { urlToCacheKeyMapping } from 'src/appv2/utils/constants/discount.const';

@Component({
  selector: 'appv2-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, DoCheck {

  page_type: string;
  discountDetails: any;
  currentYear: any;
  trendingKeywords: any;
  imageQueryParams: any = "";
  cacheKey: string = "";
  urlToCacheKeyMapping: any;
  addClassForFootage: boolean = false;
  appName: string;

  constructor(private router: Router, private landingPage: LandingPageService, private cdr: ChangeDetectorRef, private cacheService: CacheService) {
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {
        let url = event.url.split('/')[1];
        if (url && url.includes('?')) {
          let onlySlug = url.split('?');
          url = onlySlug[0];
        }
        if(url == 'footages'){
          this.addClassForFootage = true
        }else{
          this.addClassForFootage = false
        }
        this.page_type = url;

        this.cacheKey = urlToCacheKeyMapping[url];

        if (this.cacheKey) {
          // Use the cacheKey to retrieve data from the cache
          const discountDetails = this.cacheService.getData(this.cacheKey);
          if (typeof discountDetails !== 'undefined') {
            // Data is available in the cache, use it
            this.discountDetails = discountDetails;
          } else {
            this.getDiscountDetails(this.page_type + '_page');
          }
        }
      }
    })
  }

  ngOnInit(): void {

    this.router.url
    this.currentYear = (new Date()).getFullYear();
    this.appName = messages.appName
  }

  ngDoCheck() {
    this.cdr.detectChanges();
    this.trendingKeywords = this.landingPage.getTrendingDataForFooter()
  }

  goToCmsPage(page): void {
    this.router.navigate(['/cms', page]);
  }

  getDiscountDetails(url: string): void {
    this.landingPage.getDiscountDetails(url).subscribe(
      (response: any) => {
        this.discountDetails = response.data as DiscountDetailsInterface;
        // Store data in the cache
        this.cacheService.setData(this.cacheKey, this.discountDetails);
        this.landingPage.isDiscountDetailsCalled.next(true);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  openTrending(word: any) {
    this.imageQueryParams = { search: word };
    const navigationExtras: NavigationExtras = {
      queryParams: this.imageQueryParams
    };
    this.router.navigate(['/images'], navigationExtras);
  }
}
