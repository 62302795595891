import { Component, OnInit,Input,Output, EventEmitter,  OnChanges, SimpleChanges,AfterViewInit,OnDestroy} from '@angular/core';

@Component({
  selector: 'appv2-music-player',
  templateUrl: './music-player.component.html',
  styleUrls: ['./music-player.component.scss']
})
export class MusicPlayerComponent implements OnInit,OnChanges,OnDestroy {
  isMuted: boolean = false;
  public audio: HTMLAudioElement;

  public currentMusicPlay : any ;
  @Input() currentMusicIndex:number
  @Input() musicList: any = [];
  @Input() isPlaying: boolean
  @Output() dataEvent = new EventEmitter<any>()
  @Input() userWishlistedData:string[] = []
  @Input() displayDownload:boolean
  public currentTime: number = 0;
  public totalTime: number = 0;
  public wishListData : boolean =false
  user = '';
  isPlayingChangedInternally: boolean = false;
  private audioState: { isPlaying: boolean; src: string } = {
    isPlaying: false,
    src:''
  };
  public hidePlayer:boolean = false

  constructor() { }



  ngOnInit(): void {
    this.user = localStorage.getItem('user_data');
    if (!this.audio) {
      this.audio = new Audio();
    }
    this.loadCurrentMusic()
    this.audio.addEventListener('ended', () => {
      this.next();
    });

    this.audio.addEventListener('timeupdate', () => {
      this.currentTime = this.audio?.currentTime;
      if (isFinite(this.audio?.duration)) {
        this.totalTime = this.audio?.duration;
      }
    });
  }

  ngOnDestroy(): void {
    // Ensure that the audio is stopped when the component is destroyed

    if (this.audio) {
      this.audio.pause();
      this.audio = null; // Optional: Set audio to null to release resources
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isPlaying && changes.currentMusicIndex && !changes.musicList && changes.currentMusicIndex.currentValue !== -1) {

      this.loadCurrentMusic()
    }else if(changes.isPlaying && changes.currentMusicIndex && changes.currentMusicIndex.currentValue == -1){
      this.hidePlayer =  true
      this.isPlaying = false
      if(this.audio){
        this.audio.pause();
      }
      this.emitDataToParent()
    }else if(changes.currentMusicIndex && !changes.isPlaying && !changes.musicList){
      this.loadCurrentMusic()
    }
    else{
      this.wishListData =false
      if(this.audio){
        if(this.isPlaying){
          this.playOnclick()
        }else{
          this.audio.pause();
        }
      }
    }
}
  toggleMute() {
    this.isMuted = !this.isMuted;
    this.audio.muted = this.isMuted;
  }
  parentPlayevent(){

    if(this.audio){
      if(!this.isPlaying){
        this.audio.pause();
      }else{
        this.audio.play();
      }
    }
  }

  loadCurrentMusic() {
    if(!this.hidePlayer){
      this.wishListData = false
      if(this.musicList){
        this.currentMusicPlay = this.musicList[this.currentMusicIndex];
        if(this.audio){
          this.audio.src = this.currentMusicPlay?.product_main_image;
          localStorage.setItem('audio',this.audio.src)
          this.audioState.src = this.audio.src
          this.audioState.isPlaying = this.isPlaying

          if(this.isPlaying){
            this.playOnclick()
          }else{
            this.audio.pause();
          }
        }

      }
    }else{

     let audioSrc =  localStorage.getItem('audio')
     this.audio.src = audioSrc
      this.audio.pause()
      this.emitDataToParent()
    }


  }
  playOnclick(){
    this.audio.play();
  }

  playPause() {
    this.wishListData =false
    if (this.isPlaying) {
      this.audio.pause();
    } else {
      this.audio.play();
    }
    this.isPlaying = !this.isPlaying;
    this.emitDataToParent()

  }

  previous() {
    this.currentMusicIndex = (this.currentMusicIndex - 1 + this.musicList.length) % this.musicList.length;

    this.loadCurrentMusic();
    if (this.isPlaying) {
      this.audio.play();
    }
    this.emitDataToParent()
  }

  next() {
    this.currentMusicIndex = (this.currentMusicIndex + 1) % this.musicList.length;
    this.loadCurrentMusic();
    if (this.isPlaying) {
      this.audio.play();
    }
    this.emitDataToParent()
  }

  calculateProgress(): number {
    return (this.currentTime / this.totalTime) * 100;
  }

  formatTime(time: number): string {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  addWishlist(){
    this.wishListData = false
    this.wishListData = !this.wishListData
    this.emitDataToParent()
  }
  seekTo(value: number): void {
    // Ensure that this.audio is defined before setting currentTime
    if (this.audio) {
      this.audio.currentTime = value;
    }
  }

  emitDataToParent() {
    let data = {}
    data['playing'] = this.isPlaying
    data['index']  = this.currentMusicIndex
    data['wishlist'] = this.wishListData
    data['hidePlayer'] =this.hidePlayer

    this.dataEvent.emit(data);
  }


}
