import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';
import { CacheService } from 'src/appv2/services/cache.service';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { SubscriptionPlanService } from 'src/appv2/services/subscription-plan.service';
import { ToastService } from 'src/appv2/services/toast.service';
import { urlToCacheKeyMapping } from 'src/appv2/utils/constants/discount.const';
import { ItemDetailsService } from 'src/appv2/services/item-details.service';
import { messages } from 'src/appv2/appv2.constants';
declare var Razorpay: any;

@Component({
  selector: 'appv2-pricing-filter',
  templateUrl: './pricing-filter.component.html',
  styleUrls: ['./pricing-filter.component.scss']
})
export class PricingFilterComponent implements OnInit {

  @Input('params') params;

  @Output() pricingHeaderDisplayEvent = new EventEmitter<string>();
  openTab: string = ''
  singleTab: string = ''
  subscriptionPlansImages: object = {}
  filteredPlansImage: any = []
  imageData: any = [];
  filteredPlansImageDownload: any = []
  imageDataDownload: any = [];
  //Footage
  subscriptionPlansFootages: any = []
  filteredPlansFootages: any = []
  filteredHdPlansFootages: any = []
  footageData: any = [];
  hdFootageData: any = [];
  footageDataCount: number = 0;
  //Music
  subscriptionPlansMusic: object = {}
  filteredPlansMusic: any = []
  musicData: any = [];
  filteredPlansMusicDownload: any = []
  musicDataDownload: any = [];
  //
  selectedImageOption: string
  selectedFootageOption: string
  selectedMusicOption: string

  packagesCacheKey: string;
  activeDownloadId: string | null = null;
  activeSubscriptionId: string | null = null;
  activeFootage4kId: string | null = null;
  activeFootageHdId: string | null = null;
  activeMusicSubscriptionId: string | null = null;
  activeMusicDownloadId: string | null = null;

  userChoosedItem: string = 'disabled'
  packagesCacheKeyName: string = 'pricing-page-packages';
  public licenceData:any
  public selectedLicenceImage:number = 1
  public selectedtoggleImage:number  =1



  constructor(private store$: Store<any>, private accountActions: AccountService, private router: Router, private pricing: SubscriptionPlanService, private formBuilder: FormBuilder, private toastService: ToastService, private cacheService: CacheService, private landingPage: LandingPageService, private itemDetails: ItemDetailsService, ) {

  }
  isUserLogined = localStorage.getItem('user_data') != undefined

  ImageSubscriptionPlanForm: FormGroup;
  ImageDownloadPlanForm: FormGroup;
  FootageDownloadFirstForm: FormGroup;
  FootageDownloadSecondForm: FormGroup;
  MusicSubscriptionPlanForm: FormGroup;
  MusicDownloadPlanForm: FormGroup;

  ngDoCheck() {
    if (!this.isUserLogined) {
      this.isUserLogined = localStorage.getItem('user_data') != undefined
      this.choosenPlan['tokenData'] = JSON.parse(localStorage.getItem('user_data'))
    }

  }
  onImgSubscription: Boolean = false;
  onImgDownload: Boolean = false;
  onFootageDownloadFirst: Boolean = false;
  onFootageDownloadSecond: Boolean = false;
  onMusicSubscriptionPlan: boolean = false;
  onMusicDownloadPlan: boolean = false;
  order_msg = "Please complete your profile, after that you can move forward with orders!";

  ngOnInit(): void {
    if (this.isUserLogined) {
      this.choosenPlan = {
        tokenData: JSON.parse(localStorage.getItem('user_data'))
      }
    }

    // For Image plan types
    this.ImageSubscriptionPlanForm = this.formBuilder.group({
      imageSubscriptionPlan: ['', Validators.required],
      toggle: [false],
    });

    this.ImageDownloadPlanForm = this.formBuilder.group({
      imageDownloadPlan: ['', Validators.required],
    });

    // For Music plan types
    this.MusicSubscriptionPlanForm = this.formBuilder.group({
      musicSubscriptionPlan: ['', Validators.required],
      toggle: [false],
    });

    this.MusicDownloadPlanForm = this.formBuilder.group({
      musicDownloadPlan: ['', Validators.required],
    });

    // For Footage plan types
    this.FootageDownloadSecondForm = this.formBuilder.group({
      footageDownloadSecond: ['', Validators.required],
      toggle: [false],
    });

    this.FootageDownloadFirstForm = this.formBuilder.group({
      footageDownloadFirst: ['', Validators.required],
    });


    // this.ImageDownloadPlanForm = new FormGroup({
    //   'imageDownloadPlan': new FormControl(null, Validators.required),
    // });

    this.openTab = (this.params && this.params.openTab != '') ? this.params.openTab : 'Image'
    this.singleTab = (this.params && this.params.singleTab != '') ? this.params.singleTab : ''
    this.getPackages()
    this.licenceDetails()
  }

  /**
   * Determines whether image subscription plan form submit on
   */
  onImageSubscriptionPlanFormSubmit() {
    const user = JSON.parse(localStorage.getItem('user_data'))
    if (user.profile_completed) {
      this.onImgSubscription = true;
      if (this.ImageSubscriptionPlanForm.valid) {
        this.choosenPlan['plan'] = JSON.parse(this.ImageSubscriptionPlanForm.value.imageSubscriptionPlan)
        this.choosePaymentMethod('rozerpay');
      }
    } else {
      this.toastService.warning(this.order_msg);
      this.router.navigate(['/profile']);
    }
  }


  /**
   * Determines whether image download plan form submit on
   */
  onImageDownloadPlanFormSubmit() {
    const user = JSON.parse(localStorage.getItem('user_data'))
    if (user.profile_completed) {
      this.onImgDownload = true;
      if (this.ImageDownloadPlanForm.valid) {
        this.choosenPlan['plan'] = JSON.parse(this.ImageDownloadPlanForm.value.imageDownloadPlan)
        this.choosePaymentMethod('rozerpay');
        }
    } else {
      this.toastService.warning(this.order_msg);
      this.router.navigate(['/profile']);
    }

  }

  /**
   * Determines whether music subscription plan form submit on
   */
  onMusicSubscriptionPlanFormSubmit() {
    const user = JSON.parse(localStorage.getItem('user_data'))
    if (user.profile_completed) {
      this.onMusicSubscriptionPlan = true;
      if (this.MusicSubscriptionPlanForm.valid) {
        this.choosenPlan['plan'] = JSON.parse(this.MusicSubscriptionPlanForm.value.musicSubscriptionPlan)
        this.choosePaymentMethod('rozerpay');
      }
    } else {
      this.toastService.warning(this.order_msg);
      this.router.navigate(['/profile']);
    }
  }

  /**
   * Determines whether music download plan form submit on
   */
  onMusicDownloadPlanFormSubmit() {
    const user = JSON.parse(localStorage.getItem('user_data'))
    if (user.profile_completed) {
      this.onMusicDownloadPlan = true;
      if (this.MusicDownloadPlanForm.valid) {
        this.choosenPlan['plan'] = JSON.parse(this.MusicDownloadPlanForm.value.musicDownloadPlan)
        this.choosePaymentMethod('rozerpay');
      }
    } else {
      this.toastService.warning(this.order_msg);
      this.router.navigate(['/profile']);
    }
  }

  /**
   * Determines whether footage download first form submit on
   */
  onFootageDownloadFirstFormSubmit() {
    const user = JSON.parse(localStorage.getItem('user_data'))
    if (user.profile_completed) {
      this.onFootageDownloadFirst = true;
      if (this.FootageDownloadFirstForm.valid) {
        this.choosenPlan['plan'] = JSON.parse(this.FootageDownloadFirstForm.value.footageDownloadFirst)
        this.choosePaymentMethod('rozerpay');
      }
    } else {
      this.toastService.warning(this.order_msg);
      this.router.navigate(['/profile']);
    }
  }

  /**
   * Determines whether footage download second form submit on
   */
  onFootageDownloadSecondFormSubmit() {
    const user = JSON.parse(localStorage.getItem('user_data'))
    if (user.profile_completed) {
      this.onFootageDownloadSecond = true;
      if (this.FootageDownloadSecondForm.valid) {
        this.choosenPlan['plan'] = JSON.parse(this.FootageDownloadSecondForm.value.footageDownloadSecond)
        this.choosePaymentMethod('rozerpay');
      }
    } else {
      this.toastService.warning(this.order_msg);
      this.router.navigate(['/profile']);
    }
  }

  routeToSignIn() {
    this.toastService.warning(messages.orderSignin);
    this.router.navigate(['/signin']);
  }

  clicked($tab) {
    this.openTab = $tab
    this.selectedImageOption = ''
    this.selectedFootageOption = ''
    this.selectedMusicOption = ''

    this.activeDownloadId = null;
    this.activeSubscriptionId = null;
    this.activeFootage4kId = null;
    this.activeFootageHdId = null;
    this.activeMusicSubscriptionId = null;
    this.activeMusicDownloadId = null;
    this.getPackages()
  }

  getPackages(): void {
    this.packagesCacheKey = urlToCacheKeyMapping[this.packagesCacheKeyName];
    if (this.packagesCacheKey) {
      const data = this.cacheService.getData(this.packagesCacheKey);
      if (typeof data !== 'undefined') {
          if (data.status) {
            switch (this.openTab) {
              case 'Footage': {
                this.getFootagePackages(data)
                return;
              }
              case 'Music': {
                this.getMusicPackages(data)
                return;
              }
              default: {
                this.getImagePackages(data)
                return;
              }
            }
          }
      } else {
        this.accountActions.getPackages().subscribe((data) => {
          if (data.status) {
            this.cacheService.setData(this.packagesCacheKey, data);
            this.landingPage.isPricingPagePackagesCalled.next(true);
            switch (this.openTab) {
              case 'Footage': {
                this.getFootagePackages(data)
                return;
              }
              case 'Music': {
                this.getMusicPackages(data)
                return;
              }
              default: {
                this.getImagePackages(data)
                return;
              }
            }
          }
        });
      }
    }

  }

  getImagePackages(data) {
    if (data['data']?.['Image']) {
      for (const key in data['data']['Image']) {
        this.subscriptionPlansImages[key] = {}
        this.subscriptionPlansImages[key]['plans'] = data['data']['Image'][key]

        switch (key) {
          case 'download_pack':
            this.subscriptionPlansImages[key]['order'] = 3
            this.subscriptionPlansImages[key]['name'] = 'Download Within A Year'
            break;

          case 'subscription':
            this.subscriptionPlansImages[key]['order'] = this.subscriptionPlansImages[key]['package_expiry_yearly'] == 1 ? 2 : 1;
            this.subscriptionPlansImages[key]['name'] = this.subscriptionPlansImages[key]['package_expiry_yearly'] == 1 ? 'Annual Packs' : 'Monthly Packs'
            break;

          default:
            this.subscriptionPlansImages[key]['name'] = key
            break;
        }
        for (const plan in data['data']['Image'][key]) {
          this.subscriptionPlansImages[key]['plans'][plan]['plans_stringify'] = JSON.stringify(data['data']['Image'][key][plan])

        }
      }
      this.filteredPlansImage = this.subscriptionPlansImages["subscription"];
      if (this.filteredPlansImage) {
        this.filteredPlansImage["key"] = this.subscriptionPlansImages["subscription"]["name"];
        // default yearly data display
        this.imageData = this.subscriptionPlansImages["subscription"].plans;
        this.imageData = this.imageData.filter(item => item.footage_tier == 1)
        this.imageData = this.imageData.filter(item => item.package_expiry_yearly !== null && item.package_expiry_yearly != 0)
        this.selectedLicenceImage = 1
        this.selectedtoggleImage = 1


      }
      this.filteredPlansImageDownload = this.subscriptionPlansImages["download_pack"];
      if (this.filteredPlansImageDownload) {
        this.filteredPlansImageDownload["key"] = this.subscriptionPlansImages["download_pack"]["name"];
        this.imageDataDownload = this.subscriptionPlansImages["download_pack"].plans;
        this.imageDataDownload = this.imageDataDownload.filter(item => item.footage_tier == 1)
      }
    }
    this.checkNoData()
  }

  getFootagePackages(data) {
    if (data['data']?.['Footage']?.['download_pack']) {
      for (const key in data['data']['Footage']['download_pack']) {
        this.subscriptionPlansFootages[key] = {}
        this.subscriptionPlansFootages[key]['plans'] = data['data']['Footage']['download_pack'][key]
        switch (key) {
          case 'HD':
            this.subscriptionPlansFootages[key]['order'] = 1
            this.subscriptionPlansFootages[key]['name'] = 'HD(1080) Footages'
            break;
          case '4K':
            this.subscriptionPlansFootages[key]['order'] = 2
            this.subscriptionPlansFootages[key]['name'] = '4K Footages'
            break;
          default:
            this.subscriptionPlansFootages[key]['name'] = key
            break;
        }
        for (const plan in data['data']['Footage']['download_pack'][key]) {
          this.subscriptionPlansFootages[key]['plans'][plan]['plans_stringify'] = JSON.stringify(data['data']['Footage']['download_pack'][key][plan])
        }
      }
      this.filteredPlansFootages = this.subscriptionPlansFootages["4K"];
      if (this.filteredPlansFootages) {
        this.filteredPlansFootages["key"] = "4K";
        this.footageData = this.subscriptionPlansFootages["4K"].plans;
        this.footageData = this.footageData.filter(item => item.footage_tier == 3)
        this.footageDataCount += this.footageData.length;
      }
      this.filteredHdPlansFootages = this.subscriptionPlansFootages["HD"];
      if (this.filteredHdPlansFootages) {
        this.filteredHdPlansFootages["key"] = "HD";
        this.hdFootageData = this.subscriptionPlansFootages["HD"].plans;
        this.hdFootageData = this.hdFootageData.filter(item => item.footage_tier == 3)
        this.footageDataCount += this.hdFootageData.length;
      }
    }
    this.checkNoData()
  }

  getMusicPackages(data) {
    if (data['data']?.['Music']) {
      for (const key in data['data']['Music']) {
        this.subscriptionPlansMusic[key] = {}
        this.subscriptionPlansMusic[key]['plans'] = data['data']['Music'][key]

        switch (key) {
          case 'download_pack':
            this.subscriptionPlansMusic[key]['order'] = 3
            this.subscriptionPlansMusic[key]['name'] = 'Download Within A Year'
            break;

          case 'subscription':
            this.subscriptionPlansMusic[key]['order'] = this.subscriptionPlansMusic[key]['package_expiry_yearly'] == 1 ? 2 : 1;
            this.subscriptionPlansMusic[key]['name'] = this.subscriptionPlansMusic[key]['package_expiry_yearly'] == 1 ? 'Annual Packs' : 'Monthly Packs'
            break;

          default:
            this.subscriptionPlansMusic[key]['name'] = key
            break;
        }
        for (const plan in data['data']['Music'][key]) {
          this.subscriptionPlansMusic[key]['plans'][plan]['plans_stringify'] = JSON.stringify(data['data']['Music'][key][plan])
        }
      }

      this.filteredPlansMusic = this.subscriptionPlansMusic["subscription"];
      if (this.filteredPlansMusic) {
        this.filteredPlansMusic["key"] = this.subscriptionPlansMusic["subscription"]["name"];
        // default yearly data display
        this.musicData = this.subscriptionPlansMusic["subscription"].plans.filter(item => item.package_expiry_yearly == 1);
      }
      this.filteredPlansMusicDownload = this.subscriptionPlansMusic["download_pack"];
      if (this.filteredPlansMusicDownload) {
        this.filteredPlansMusicDownload["key"] = this.subscriptionPlansMusic["download_pack"]["name"];
        this.musicDataDownload = this.subscriptionPlansMusic["download_pack"].plans;
        this.musicDataDownload = this.musicDataDownload.filter(item => item.footage_tier == 7)

      }
    }
    this.checkNoData()
  }
  //Image
  filterYearly(event, type: string) {
    if ((event.target as HTMLInputElement).checked) {
      this.imageData = this.filteredPlansImage.plans.filter(item =>{
        if((item.package_expiry != 0 && item.package_expiry != null) && item.footage_tier == this.selectedLicenceImage){
        return true
      }else{
        return false
      }
      });
      this.selectedtoggleImage = 0
    } else {
      this.imageData = this.filteredPlansImage.plans.filter(item =>{
        if((item.package_expiry_yearly != 0 && item.package_expiry_yearly != null )  && item.footage_tier == this.selectedLicenceImage){
        return true
      }else{
        return false
      }
      });
      this.selectedtoggleImage = 1
    }
  }
  //For subscription
  imageFilterFootageTier(value:string){
    this.ImageSubscriptionPlanForm.controls['imageSubscriptionPlan'].patchValue('')
    this.activeSubscriptionId = null
    this.selectedLicenceImage = parseInt(value)
    if(this.selectedtoggleImage == 1){
      this.imageData = this.filteredPlansImage.plans.filter(item =>{
        if((item.package_expiry_yearly != 0 && item.package_expiry_yearly != null )  && item.footage_tier == value){
        return true
      }else{
        return false
      }
      });
    }else {
      this.imageData = this.filteredPlansImage.plans.filter(item =>{
        if((item.package_expiry != 0 && item.package_expiry != null) && item.footage_tier == value){
        return true
      }else{
        return false
      }
      });
    }

  }

  //For download pack
  imageFilterDownloadTier(value:string){
    this.ImageDownloadPlanForm.controls['imageDownloadPlan'].patchValue('')
    this.activeDownloadId = null
    this.imageDataDownload = this.filteredPlansImageDownload.plans.filter(item => item.footage_tier == value);
  }

  // Footage
  filterFootageTier(type: string, value: number) {
    this.FootageDownloadFirstForm.controls['footageDownloadFirst'].patchValue('')
    this.activeFootage4kId = null
    this.footageData = this.filteredPlansFootages.plans.filter(item => item.footage_tier == value);
  }

  hdFilterFootageTier(type: string, value: number) {
    this.FootageDownloadSecondForm.controls['footageDownloadSecond'].patchValue('')
    this.activeFootageHdId = null
    this.hdFootageData = this.filteredHdPlansFootages.plans.filter(item => item.footage_tier == value);
  }

  // Music
  filterYearlyMusic(event, type: string) {
    if ((event.target as HTMLInputElement).checked) {
      this.musicData = this.filteredPlansMusic.plans.filter(item => item.package_expiry == 1);
    } else {
      this.musicData = this.filteredPlansMusic.plans.filter(item => item.package_expiry_yearly == 1);
    }
  }

  filterLicenceTypeMusic(value:string){
    this.MusicDownloadPlanForm.controls['musicDownloadPlan'].patchValue('')
    this.activeMusicDownloadId = null
    this.musicDataDownload = this.filteredPlansMusicDownload.plans.filter(item => item.footage_tier == value);
  }

  imagePlanSelect(event, selectedID: string) {
    this.selectedImageOption = selectedID
  }
  selcetPricing(id) {
    this.activeSubscriptionId = id;
  }
  selcetDownloadPricing(id) {
    this.activeDownloadId = id;
  }
  selcetFootagePricing(id) {
    this.activeFootage4kId = id;
  }
  selcetFootageHdPricing(id) {
    this.activeFootageHdId = id;
  }
  selcetMusicDownPricing(id) {
    this.activeMusicDownloadId = id;
  }
  selcetMusicSubPricing(id) {
    this.activeMusicSubscriptionId = id;
  }

  footagePlanSelect(event, selectedID: string) {
    this.selectedFootageOption = selectedID
  }
  musicPlanSelect(event, selectedID: string) {
    this.selectedMusicOption = selectedID
  }

  // If no data then hide heading text
  checkNoData() {
    var output = "";
    if (this.openTab == "Image" && this.singleTab == "Image") {
      var isData = this.filteredPlansImage?.plans?.length > 0 || this.filteredPlansImageDownload?.plans?.length > 0
      var output = isData ? 'Yes' : 'No';
      this.pricingHeaderDisplayEvent.emit(output);
    }
    if (this.openTab == "Footage" && this.singleTab == "Footage") {
      var isData = this.filteredPlansFootages?.plans?.length > 0
      var output = isData ? 'Yes' : 'No';
      this.pricingHeaderDisplayEvent.emit(output);
    }
    if (this.openTab == "Music" && this.singleTab == "Music") {
      var isData = this.filteredPlansMusic?.plans?.length > 0 || this.filteredPlansMusicDownload?.plans?.length > 0
      var output = isData ? 'Yes' : 'No';
      this.pricingHeaderDisplayEvent.emit(output);
    }
    if (this.singleTab == "") {
      var isData = (this.filteredPlansImage?.plans?.length > 0 || this.filteredPlansImageDownload?.plans?.length > 0 || this.filteredPlansFootages?.plans?.length > 0 || this.filteredPlansMusic?.plans?.length > 0 || this.filteredPlansMusicDownload?.plans?.length > 0)
      var output = isData ? 'Yes' : 'No';
      this.pricingHeaderDisplayEvent.emit(output);
    }
  }

  choosenPlan: object = {}

  choosePaymentMethod(paymentType) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: true, loadingMessage: messages.pricingFilterLoading}))
    this.choosenPlan['type'] = paymentType
    this.pricing.getPaymentLink(this.choosenPlan).subscribe(data => {
      this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: false }))
      let options = data
      options.handler = this.razorpayRes.bind(this)
      // Boolean whether to show image inside a white frame. (default: true)
      options.theme.image_padding = false;
      options.modal = {
        ondismiss: function () {
        },
        // Boolean indicating whether pressing escape key
        // should close the checkout form. (default: true)
        escape: true,
        // Boolean indicating whether clicking translucent blank
        // space outside checkout form should close the form. (default: false)
        backdropclose: false
      };

      var rzp = new Razorpay(options);
      rzp.open();
      event.preventDefault();
    })
  }

  razorpayRes(payres) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: true, loadingMessage: messages.razorpayLoading}))
    this.pricing.getRazorResponse(payres, this.choosenPlan['tokenData'])
      .subscribe(data => {
        this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: false }))
        if (data.url) {
          this.toastService.success(messages.planPurchase)
          const urlWithQueryParam = data.url;
          window.location.href = urlWithQueryParam;
        } else {
          this.toastService.warning("Something went wrong!")
        }
      });
  }

  public licenceDetails(){
    const payload = ''
    this.itemDetails.getLicenceDetails(payload).subscribe((data) => {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: false })
      );
      if(data.status == 'success'){
        this.licenceData = data.data
        this.licenceData = this.licenceData.filter(item =>{
          if(item.slug == 'digital' && item.product_type == 3){
          return false
        }else{
          return true
        }
      })

      }else{
        this.licenceData = []
      }


    });
  }

}
