import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SafeRequestService } from './safe-request.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private safeRequest: SafeRequestService) {}
  getSearchResult(
    type,
    pagenumber = 1,
    keyword = '',
    sort = '',
    all_filters = []
  ): Observable<any> {
    const url = 'search';
    const body = {
      productType: type,
      search: keyword,
      pagenumber: pagenumber,
      sort: sort,
      all_filters: all_filters,
    };
    return this.safeRequest.sendRequest('post', url, body);
  }

  // reimpliment search request to valid
  getSearchWithFiltersResult(
    filters,
    pagenumber = 1,
    searchKey,
    all_filters,
    category_id = ''
  ): Observable<any> {
    const url = 'search';
    let temp = {
      search: searchKey,
      pagenumber: pagenumber,
      limit: 100,
    };
    if (searchKey == null) {
      temp['search'] = '';
    }
    if (filters.type == 'image') {
      for (const key in filters) {
        temp[key] = filters[key];
      }
      if (temp.hasOwnProperty('search')) {
        temp['search'] = temp['search'];
        delete temp['key'];
      } else if (!temp.hasOwnProperty('search')) {
        temp['search'] = '';
      }

      temp['productType'] = 1;
      delete temp['type'];
    } else if (filters.type == 'footage') {
      for (const key in filters) {
        temp[key] = filters[key];
      }
      if (temp.hasOwnProperty('search')) {
        temp['search'] = temp['search'];
        delete temp['key'];
      } else if (!temp.hasOwnProperty('search')) {
        temp['search'] = '';
      }
      temp['productType'] = 2;
      delete temp['type'];
    } else if (filters.type == 'editorial') {
      for (const key in filters) {
        temp[key] = filters[key];
      }
      if (temp.hasOwnProperty('search')) {
        temp['search'] = temp['search'];
        delete temp['key'];
      } else if (!temp.hasOwnProperty('search')) {
        temp['search'] = '';
      }

      temp['productType'] = 4;
      temp['product_editorial'] = 1;
      delete temp['type'];
    } else if (filters.type == 'music') {
      temp['limit'] = 100;
      for (const key in filters) {
        temp[key] = filters[key];
      }
      if (temp.hasOwnProperty('search')) {
        temp['search'] = temp['search'];
        delete temp['key'];
      } else if (!temp.hasOwnProperty('search')) {
        temp['search'] = '';
      }

      temp['productType'] = 3;
      delete temp['type'];
    }
    for (const filter of all_filters) {
      if (filter.isGroupValue) {
        const groupValues = filter.isGroupValue.split(', ');
        const valueValues = filter.value.split(', ');
        for (let i = 0; i < groupValues.length; i++) {
          const filterg = {
            key: groupValues[i],
            value: valueValues[i],
          };

          temp[filterg.key] = {
            value: filterg.value,
          };
        }
      } else {
        temp[filter.key] = {
          value: filter.value,
        };
      }

    }
    temp['category_id'] = category_id

    const body = temp;
    return this.safeRequest.sendRequest('post', url, body);
  }

  getSearchKeywords() {
    const url = 'search-keywords/get';
    return this.safeRequest.sendRequest('get', url);
  }
}
