<section class="secure_payment_section">
    <div class="container">
        <h2 class="h2">Easy and secure payment</h2>
        <div class="secure_payment_box">
            <div class="secure_payment_box_inner">
                <div class="secure_payment_image">
                    <ul>
                        <li class="secure_payment_badge_img">
                            <a href="javascript:void(0)"><img src="assets/images/visa.svg" alt="visa-card" width="52.77"
                                    height="16"></a>
                        </li>
                        <li class="secure_payment_badge_img">
                            <a href="javascript:void(0)"><img src="assets/images/mastercard.svg" alt="mastercard"
                                    width="25.33" height="16"></a>
                        </li>
                        <li class="secure_payment_badge_img">
                            <a href="javascript:void(0)"><img src="assets/images/american_express.svg"
                                    alt="american-express" width="43.69" height="16"></a>
                        </li>
                    </ul>
                </div>
                <div class="secure_payment_content">
                    <h3 class="h3">Trusted payment methods</h3>
                    <p>Pay with Visa, Mastercard, PayPal or American Express</p>
                </div>
            </div>
            <div class="secure_payment_box_inner">
                <div class="secure_payment_image">
                    <a href="javascript:void(0)"><img src="assets/images/lock.svg" alt="lock-image" width="38"
                            height="38"></a>
                </div>
                <div class="secure_payment_content">
                    <h3 class="h3">Secure payments</h3>
                    <p>Processed in a Level 1 PCI compliant environment</p>
                </div>
            </div>
            <div class="secure_payment_box_inner">
                <div class="secure_payment_image">
                    <a href="javascript:void(0)"><img src="assets/images/cancel.svg" alt="visa-card" width="38"
                            height="38"></a>
                </div>
                <div class="secure_payment_content">
                    <h3 class="h3">Cancel any time</h3>
                    <p>Canceling your subscription is quick and simple</p>
                </div>
            </div>
        </div>
    </div>
</section>