import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'appv2-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {  
  
  questions = [
    { question: "What's the difference between a Subscription Plan and a Credit Pack?", answer: "The Subscription Plans usually comes at a lower price per image.Download quotas for subscription plan that resets monthly. Client with a subscription plan may download stock photos and vectors; all downloads will come with a Standard End User License." },
    { question: "Can I cancel the auto-renewal on my subscription plan?", answer: "The Subscription Plans usually comes at a lower price per image.Download quotas for subscription plan that resets monthly. Clients with a subscription plan may download stock photos and vectors; all downloads will come with a Standard End User License." },
    { question: "Do I need to cite Imagefootage as a reference?", answer: "The Subscription Plans usually comes at a lower price per image.Download quotas for subscription plan that resets monthly. Clients with a subscription plan may download stock photos and vectors; all downloads will come with a Standard End User License." },
    { question: "How do I know what’s the best image size for my usage?", answer: "The Subscription Plans usually comes at a lower price per image. Download quotas for subscription plan that resets monthly. Clients with a subscription plan may download stock photos and vectors; all downloads will come with a  Standard End User License." }
  ];

  activeIndex: number | -1 = -1;

  constructor() { }

  ngOnInit(): void {
  }

  toggleAccordion(index: number): void {
    this.activeIndex = (this.activeIndex === index) ? -1 : index;
  }


}
