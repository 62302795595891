import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccountService } from 'src/appv2/services/account.service';

@Component({
  selector: 'appv2-pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss']
})
export class PricingPageComponent implements OnInit {

  openTab: string = 'Image'
  subscriptionPlansImages: object = {}
  subscriptionPlansFootages: object = {}
  subscriptionPlansMusic: object = {}

  // display pricing component header text
  displayPricingHeader = true;

  constructor(private store$: Store<any>, private accountActions: AccountService, private router: Router) {

  }

  ngOnInit(): void {
   
  }

  receivePricingHeaderDisplayEvent(response: string) {
    this.displayPricingHeader = response == 'No' ? false : true;
  }

}