<section class="main-content-sec-footage">
    <div class="container">
        <div class="custom-row">
            <div class="filter-block-left">
                <h2 class="h4">Filters</h2>
                <div class="still_have_questions_accordian">
                    <div class="accordian_border">
                        <button class="course-accordion">Pricing</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <div class="price-range-slider">
                                    <div id="slider-range" class="range-bar"></div>
                                    <div class="range-value">
                                        <div class="input-main">
                                            <span> From</span>
                                            <input type="text" id="amount" readonly>
                                        </div>
                                        <div class="input-main">
                                            <span> To</span>
                                            <input type="text" id="amount1" readonly>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">Duration</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <div class="price-range-slider">
                                    <div id="slider-range-v1" class="range-bar"></div>
                                    <div class="range-value">
                                        <div class="input-main">
                                            <span> From</span>
                                            <input type="text" id="amount-v1" readonly>
                                        </div>
                                        <div class="input-main">
                                            <span> To</span>
                                            <input type="text" id="amount1-v1" readonly>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">Resolution</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="custom-checkbox-list">
                                    <li>
                                        <input class="custom-checkbox" id="resolution-1" type="checkbox" value="value1">
                                        <label for="resolution-1">5K</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="resolution-2" type="checkbox" value="value2">
                                        <label for="resolution-2">4K</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="resolution-3" type="checkbox" value="value3">
                                        <label for="resolution-3">HD (1080)</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="resolution-4" type="checkbox" value="value4">
                                        <label for="resolution-4">HD (720)</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="resolution-5" type="checkbox" value="value5">
                                        <label for="resolution-5">SD</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">Usage</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="custom-checkbox-list">
                                    <li>
                                        <input class="custom-checkbox" id="usage-1" type="checkbox" value="value1">
                                        <label for="usage-1">Editorial</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="usage-2" type="checkbox" value="value2">
                                        <label for="usage-2">Commercial</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">People</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="custom-radio">
                                    <li>
                                        <input type="radio" id="featured-1" name="featured" checked>
                                        <label for="featured-1">With People</label>
                                    </li>
                                    <li>
                                        <input type="radio" id="featured-2" name="featured">
                                        <label for="featured-2">Without People</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">FPS</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="custom-checkbox-list">
                                    <li>
                                        <input class="custom-checkbox" id="fps-1" type="checkbox" value="value1">
                                        <label for="fps-1">23.98</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="fps-2" type="checkbox" value="value2">
                                        <label for="fps-2">24</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="fps-3" type="checkbox" value="value3">
                                        <label for="fps-3">25</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="fps-4" type="checkbox" value="value4">
                                        <label for="fps-4">29.87</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="fps-5" type="checkbox" value="value5">
                                        <label for="fps-5">30</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="fps-6" type="checkbox" value="value6">
                                        <label for="fps-6">50</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="fps-7" type="checkbox" value="value7">
                                        <label for="fps-7">59.94</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="fps-8" type="checkbox" value="value8">
                                        <label for="fps-8">60</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="fps-9" type="checkbox" value="value9">
                                        <label for="fps-9">Other</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">Author</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="author-list">
                                    <li><a href="#">Albert Flores</a><span>(12)</span></li>
                                    <li><a href="#">Annette Black</a><span>(02)</span></li>
                                    <li><a href="#">Arlene McCoy</a><span>(16)</span></li>
                                    <li><a href="#">Bessie Cooper</a><span>(100)</span></li>
                                    <li><a href="#">Brooklyn Simmons</a><span>(32)</span></li>
                                    <li><a href="#">Cameron Williamson</a><span>(23)</span></li>
                                    <li><a href="#">Cody Fisher</a><span>(67)</span></li>
                                    <li><a href="#">Courtney Henry</a><span>(190)</span></li>
                                    <li><a href="#">Darlene Robertson</a><span>(122)</span></li>
                                    <li><a href="#">Darrell Steward</a><span>(09)</span></li>
                                    <li><a href="#">Devon Lane</a><span>(07)</span></li>
                                    <li><a href="#">Dianne Russell</a><span>(31)</span></li>
                                    <li><a href="#">Eleanor Pena</a><span>(24)</span></li>
                                    <li><a href="#">Esther Howard</a><span>(04)</span></li>
                                    <li><a href="#">Floyd Miles</a><span>(89)</span></li>
                                    <li><a href="#">Guy Hawkins</a><span>(90)</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-block-right">
                <div class="banner-form-sec">
                    <div class="categories-seach-block-banner">
                        <div class="search-filter-form-main-banner">
                            <form class="search-filter-form">
                                <div class="custom-select-wrapper">
                                    <div class="custom-select">
                                        <div class="custom-select__trigger"><span><img src="assets/images/footages-icon.svg"
                                      alt="Footages" width="16" height="16" /> Footage</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="search-input-block" id="search-input-wrapper">
                                    <button class="btn-search"><img src="assets/images/search-icon.svg" alt="search icon"
                                width="13" height="13" /></button>
                                    <input class="input-search" type="text" name="searchtext" placeholder="Search for images, footages etc..." autocomplete="off" >
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="cart-title-row">
                        <div class="cart-col-left">
                            <h1 class="h2">Footage</h1>
                            <span>435,459 Results</span>
                        </div>
                        <div class="cart-col-right">
                            <div class="toggle-main-block">
                                <span>Safe Search</span>
                                <label class="label toggle">
                          <input type="checkbox" class="toggle_input" />
                          <div class="toggle-control"></div>
                       </label>
                            </div>
                            <div class="select-lice">
                                <select>
                          <option>Best Match</option>
                          <option>Popular</option>
                          <option>Recent</option>
                          <option>Price: Low to High</option>
                          <option>Price: High to Low</option>
                          <option>Price: High to Low</option>
                          <option>Duration: Long to Short</option>
                       </select>
                            </div>
                        </div>
                    </div>
                    <div class="reset-filter-row">
                        <ul>
                            <li>Price: ₹1000 - ₹4000 <span></span></li>
                            <li>Duration: 1:30 Min <span></span></li>
                            <li>Resolution: HD 1080, HD 720 <span></span></li>
                            <li>People: With People <span></span></li>
                        </ul>
                        <button class="thame-btn">Reset Filter</button>
                    </div>
                </div>
                <!-- image list section start -->
                <div class="image-list-sec-footage">
                    <!-- layout of image grid position start -->
                    <div class="image_grid">
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/similar_footages_1.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/similar_footages_1.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/similar_footages_1.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Storm in California</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/similar_footages_2.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/similar_footages_2.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/similar_footages_2.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">A car prototype showcase mode..</a>
                            </div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/similar_footages_3.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/similar_footages_3.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/similar_footages_3.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">It’s party time</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/similar_footages_4.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/similar_footages_4.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/similar_footages_4.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Apartment</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/similar_footages_5.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/similar_footages_5.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/similar_footages_5.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Party Girl</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/similar_footages_6.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/similar_footages_6.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/similar_footages_6.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Ocean</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/similar_footages_7.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/similar_footages_7.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/similar_footages_7.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Storm in California</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/similar_footages_8.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/similar_footages_8.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/similar_footages_8.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Party Girl</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/similar_footages_9.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/similar_footages_9.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/similar_footages_9.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Folk Dance on Beach</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/similar_footages_10.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/similar_footages_10.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/similar_footages_10.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Apartment</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/similar_footages_11.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/similar_footages_11.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/similar_footages_11.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Party Girl</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/similar_footages_12.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/similar_footages_12.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/similar_footages_12.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Ocean</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/footage_author.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/footage_author.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/footage_author.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Apartment</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/footage_author_1.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/footage_author_1.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/footage_author_1.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Party Girl</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/footage_autho_2.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/footage_autho_2.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/footage_autho_2.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Ocean</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/footage_autho_3.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/footage_autho_3.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/footage_autho_3.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Apartment</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/footage_autho_4.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/footage_autho_4.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/footage_autho_4.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Party Girl</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/footage_autho_5.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/footage_autho_5.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/footage_autho_5.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Ocean</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/footage_autho_6.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/footage_autho_6.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/footage_autho_6.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Party Girl</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/footage_autho_7.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/footage_autho_7.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/footage_autho_7.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Storm in California</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                        <div class="filter_listpage_image">
                            <picture>
                                <source class="img-tag" srcset="assets/images/footage_author_8.webp" alt="list img" width="457" height="265" />
                                <source class="img-tag" srcset="assets/images/footage_author_8.jpg" alt="list img" width="457" height="265" />
                                <img class="img-tag" src="assets/images/footage_author_8.jpg" alt="list img" width="457" height="265" />
                            </picture>
                            <div class="hd_resolution">
                                <a href="javascript:void(0)">
                                    <div class="video_play_icon"><img src="assets/images/play_button.svg" alt="play-button">
                                    </div>
                                    <span class="resolution">HD 1020</span>
                                </a>
                            </div>
                            <div class="rupees">₹5000</div>
                            <div class="image_name"><a href="javascript:void(0)">Storm in California</a></div>
                            <div class="like">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart">
                                    <img src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart">
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- layout of image grid position end -->
                </div>
                <!-- image list section end -->
                <div class="pagination">
                    <div>Items per Page</div>
                    <div><input type="number" value="20"></div>
                    <div>Page</div>
                    <div><input type="number"></div>
                    <div>of 454</div>
                </div>
            </div>
        </div>
    </div>
</section>
