import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rotate'
})
export class RotatePipe implements PipeTransform {
  transform(value: any[], startIndex?: any, count = 4): any {

    startIndex = startIndex % value.length;
    return value.slice(startIndex, value.length).concat(value.slice(0, startIndex)).slice(0,11);
   
  }
}
