<section class="main-content-sec">
  <div class="container">
    <div class="custom-row">
      <appv2-filter class="filter-block-left" (dataEmitted)="getFilterData($event)" [fromCategory]="fromCategory">
      </appv2-filter>
      <div class="content-block-right">
        <div class="banner-form-sec">
          <appv2-search-bar class="categories-seach-block-banner" [params]="{isHeaderPart: false}"></appv2-search-bar>
          <div class="cart-title-row">
            <div class="cart-col-left">
              <h1 class="h2">Editorial</h1>
              <span>{{receivedTotalRecords}} Results</span>
            </div>
            <div class="cart-col-right">
              <div class="select-lice">                
              </div>
            </div>
          </div>
          <div class="reset-filter-row">
            <ul>
              <li *ngFor="let filter of processedFilters">
                <ng-container *ngIf="filter.valuekey">
                  {{ filter.namekey }}:
                  <ng-container *ngFor="let value of filter.valuekey.split(','); let index=index">
                    {{ value }}
                    <span (click)="removeSpecificFilter(filter.key,index)"></span>
                  </ng-container>
                </ng-container>
              </li>
            </ul>
            <button class="thame-btn" (click)="resetFilter()" *ngIf="showReset">
              Reset Filter
            </button>
          </div>
        </div>
        <!-- image list section start -->
        <div class="image-list-sec">
          <!-- layout of image grid position start -->
          
            <appv2-editorial-gallery-listing [processedFilters]="processedFilters" (dataEmitter)="receiveTotalRecords($event)"></appv2-editorial-gallery-listing>
          
        </div>
        <!-- image list section end -->
      </div>
    </div>
  </div>
</section>
