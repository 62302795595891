import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import $ from 'jquery';
import { ToastService } from 'src/appv2/services/toast.service';

@Component({
  selector: 'user-plan-modal',
  templateUrl: './user-plan-modal.component.html',
  styleUrls: ['./user-plan-modal.component.scss']
})
export class UserPlanModalComponent implements OnInit {

  @Input() userSubscriptionPlans: any = []; // Declare an input property
  @Input() licenseId: any = []; // Declare an input property
  @Input() versionId : any = [];
  @Output() dataEvent = new EventEmitter<any>(); // Define an output property to emit events
  selectedPlanId= ''
  selectedPlan = ''


  constructor(
    private toastService: ToastService,
  ) {
  }

  ngOnInit(): void {
    this.selectedPlanId = this.userSubscriptionPlans.find(plan => plan.order_type == 3)?.id || null;
    if(this.selectedPlanId){
      this.selectedPlan = this.selectedPlanId
    }
    this.showModalStyling();

  }
  /**
     * Opens the delete popup by displaying it, showing the overlay, and adding a CSS class to the body.
     */
  showModalStyling = () => {
    $('body').find('.wrapper').first().css("position", "static");
    $(".user_plan_modal_popup").show();
    $(".overlay").show();
    // Uncomment the next line to prevent scrolling when the modal is open
    $("body").addClass("overflow_hidden");
  }

  /**
  * Hides the styling of the delete popup by hiding it, hiding the overlay, and removing a CSS class from the body.
  */
  hideModelStyling = () => {
    $('body').find('.wrapper').first().css("position", "static");
    $(".user_plan_modal_popup").hide();
    $(".overlay").hide();
    // Uncomment the next line to prevent scrolling when the modal is open
    $("body").removeClass("overflow_hidden");
    let data = {};
    data['close_modal'] = true;
    this.emitDataToParent( data)
  }


  /**
   * Closes the "add to wishlist" modal and emits an event to the parent component
   * to indicate that the modal should be closed. Optionally passes data to the parent component.
   */
  closeModal = () => {
    let data = {};
    data['close_modal'] = true;
    if (this.userSubscriptionPlans) {
      data['parent_passed_data'] = this.userSubscriptionPlans;
    }
    this.emitDataToParent(data);
  }

  /**
   * Lifecycle hook that gets called when the component is about to be destroyed.
   * Calls the hideModelStyling function to hide modal styling.
   */
  ngOnDestroy(): void {
    this.hideModelStyling();
  }

  /**
   * Emits data to the parent component using the dataEvent output property.
   * Used to communicate user actions or information back to the parent component.
   * @param {object} data - The data to be emitted to the parent component.
   */
  emitDataToParent = (data={}) => {
    this.selectedPlan = ''
    this.dataEvent.emit(data);

  }
  changeValue(data){
    this.selectedPlan = data
  }

  submitForm(){
    if(this.selectedPlan){
      this.emitDataToParent(this.selectedPlan)
    }
  }
}
