import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { PagesModule } from './pages/pages.module';
import { Appv2Component } from './appv2.component';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './reducers';

import { APP_INITIALIZER, NgModule } from '@angular/core';

import { Appv2RoutingModule } from './appv2-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.intercepter';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { SearchEffects } from './reducers/loading/loading.effects';
import { environment } from 'src/environments/environment';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { HotToastModule } from '@ngneat/hot-toast';
import { appInitializer } from './helpers/app.initializer';
import { AccountService } from './services/account.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';


@NgModule({
  declarations: [Appv2Component],
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    Appv2RoutingModule,
    BrowserModule,
    PagesModule,
    AuthModule,
    CoreModule,
    HttpClientModule,
    EffectsModule.forRoot([SearchEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreRouterConnectingModule.forRoot(),
    AutocompleteLibModule,
    HotToastModule.forRoot()

  ], providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    SearchEffects
  ], bootstrap: [Appv2Component]
})
export class Appv2Module { }
