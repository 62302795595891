import { Component,Input } from '@angular/core';


@Component({
  selector: 'appv2-static-product-data',
  templateUrl: './static-product-data.component.html',
  styleUrls: ['./static-product-data.component.scss']
})
export class StaticProductDataComponent {
  @Input() type: any = '';
  public dynamic_link : '/home'

  ngOnInit() : void{


  }
  get dynamicLink(): string {
    // Determine the dynamic link based on the 'type' value
    switch (this.type) {
      case 2:
        return '/images';
      case 3:
        return '/footages';
      case 4 :
        return '/musics'
      default:
        return '/home'; // You can set a default link if needed
    }
  }

}
