<div id="tab-2">
  <form [formGroup]="SecurityForm" (ngSubmit)="onSecurityFormSubmit()">
    <div class="security_heading">
      <h2 class="h2">Security</h2>
      <div class="back_save_btn">
        <button class="back" (click)="emitTabName('profile')" type="button">
          Back
        </button>
        <button class="save">Save</button>
      </div>
    </div>
    <div formGroupName="userData" class="security_form">
      <div
        class="user_input"
        [ngClass]="
          submittedSecurityForm &&
          SecurityForm.get('userData.old_pass').errors?.required
            ? 'error_field'
            : ''
        "
      >
        <label for="text">Old Password</label>
        <div class="eye_icon">
          <input
            type="{{ isOldPasswordVisible ? 'text' : 'password' }}"
            placeholder="Enter your password"
            formControlName="old_pass"
            required=""
          />
          <a href="javascript:void(0)" (click)="toggleOldPasswordVisibility()"
            ><img
              src="{{
                isOldPasswordVisible
                  ? 'assets/images/eyeopen.svg'
                  : 'assets/images/eyeclose.svg'
              }}"
              class="{{ isOldPasswordVisible ? 'eyeopen' : 'eyeclose' }}"
              alt="{{ isOldPasswordVisible ? 'eyeopen' : 'eyeclose' }}"
              width="{{ isOldPasswordVisible ? '21' : '21' }}"
              height="{{ isOldPasswordVisible ? '21' : '11' }}"
          /></a>
        </div>
        <p
          class="error"
          *ngIf="
            submittedSecurityForm &&
            SecurityForm.get('userData.old_pass').errors?.required
          "
        >
          Please enter valid old password
        </p>
      </div>
      <div
        class="user_input"
        [ngClass]="
          submittedSecurityForm && SecurityForm.get('userData.password').errors
            ? 'error_field'
            : ''
        "
      >
        <label for="text">New Password</label>
        <div class="eye_icon">
          <input
            type="{{ isNewPasswordVisible ? 'text' : 'password' }}"
            placeholder="Enter your password"
            formControlName="password"
            required=""
          />
          <a href="javascript:void(0)" (click)="toggleNewPasswordVisibility()"
            ><img
              src="{{
                isNewPasswordVisible
                  ? 'assets/images/eyeopen.svg'
                  : 'assets/images/eyeclose.svg'
              }}"
              class="{{ isNewPasswordVisible ? 'eyeopen' : 'eyeclose' }}"
              alt="{{ isNewPasswordVisible ? 'eyeopen' : 'eyeclose' }}"
              width="{{ isNewPasswordVisible ? '21' : '21' }}"
              height="{{ isNewPasswordVisible ? '21' : '11' }}"
          /></a>
        </div>
        <p
          class="error"
          *ngIf="
            submittedSecurityForm &&
            SecurityForm.get('userData.password').errors?.required
          "
        >
          Please enter valid new password
        </p>
        <p
          class="error"
          *ngIf="
            submittedSecurityForm &&
            SecurityForm.get('userData.password').errors?.pattern
          "
        >
          Please enter valid new password
        </p>
      </div>
      <div
        class="user_input"
        [ngClass]="
          submittedSecurityForm &&
          SecurityForm.get('userData.cpassword').errors?.required
            ? 'error_field'
            : ''
        "
      >
        <label for="text">Confirm Password</label>
        <div class="eye_icon">
          <input
            type="{{ isCPasswordVisible ? 'text' : 'password' }}"
            placeholder="Enter your password"
            formControlName="cpassword"
            required=""
            (blur)="onConfirmPasswordTouched()"
          />
          <a href="javascript:void(0)" (click)="toggleCPasswordVisibility()"
            ><img
              src="{{
                isCPasswordVisible
                  ? 'assets/images/eyeopen.svg'
                  : 'assets/images/eyeclose.svg'
              }}"
              class="{{ isCPasswordVisible ? 'eyeopen' : 'eyeclose' }}"
              alt="{{ isCPasswordVisible ? 'eyeopen' : 'eyeclose' }}"
              width="{{ isCPasswordVisible ? '21' : '21' }}"
              height="{{ isCPasswordVisible ? '21' : '11' }}"
          /></a>
        </div>
        <p
          class="error"
          *ngIf="
            submittedSecurityForm &&
            SecurityForm.get('userData.cpassword').errors?.required
          "
        >
          Please enter valid confirm password
        </p>
        <p
          class="error"
          *ngIf="
            passwordsDoNotMatch && SecurityForm.get('userData.cpassword').dirty
          "
        >
          Password and Confirm Password must be match.
        </p>
      </div>
    </div>
  </form>
</div>
