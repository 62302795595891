import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SafeRequestService } from './safe-request.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private safeRequest:SafeRequestService) { }

  addToCart(data):Observable <any> {
    const url = 'add_to_cart'
    const body = data
    return this.safeRequest.sendRequest('post', url, body)
  }
  getCartList(user):Observable<any> {
    const url = 'user_cart_list'
    const body = user
    return this.safeRequest.sendRequest('post', url, body)
  }
  removeFromCart(itemId) {
    const url = 'delete_cart_item'
    const body = itemId
    return this.safeRequest.sendRequest('post', url, body)
  }
  getUserAddress(tokenData) {
    const url = 'getuseraddress';
    const body = tokenData
    return this.safeRequest.sendRequest('post', url, body)
  }
  getPaymentLink(paymentData) {
    const url = 'payment';
    const body = paymentData
    return this.safeRequest.sendRequest('post', url, body)
  }
  getRazorResponse(paymentRes, tokenData):Observable<any>{
    const url = 'razor_response'
    const body = {paymentRes, tokenData}
    return this.safeRequest.sendRequest('post', url, body)
  }
  editToCart(data):Observable <any> {
    const url = 'edit_to_cart'
    const body = data
    return this.safeRequest.sendRequest('post', url, body)
  }
}
