<section class="simple_plan_section">
    <div class="container">
        <h1 class="h1">Simple and Transparent Pricing for All</h1>
        <div class="simple_plan_image_setion">
            <div class="simple_plan_image_box">
                <div class="simple_plan_image">
                    <picture>
                        <source srcset="assets/images/pricing_section_img_1.webp" alt="pricing-section-image" width="397" height="324" />
                        <source srcset="assets/images/pricing_section_img_1.jpg" alt="pricing-section-image" width="397" height="324" />
                        <img src="assets/images/pricing_section_img_1.jpg" alt="pricing-section-image" width="397" height="324" />
                    </picture>
                </div>
                <div class="simple_plan_image_content">
                    <a href="javascript:void(0)">You can download Images</a>
                </div>
            </div>
            <div class="simple_plan_image_box">
                <div class="simple_plan_image">
                    <picture>
                        <source srcset="assets/images/pricing_section_img_2.webp" alt="pricing-section-image" width="397" height="324" />
                        <source srcset="assets/images/pricing_section_img_2.jpg" alt="pricing-section-image" width="397" height="324" />
                        <img src="assets/images/pricing_section_img_2.jpg" alt="pricing-section-image" width="397" height="324" />
                    </picture>
                </div>
                <div class="simple_plan_image_content">
                    <a href="javascript:void(0)">Let’s move to Footages</a>
                </div>
            </div>
            <div class="simple_plan_image_box">
                <div class="simple_plan_image">
                    <picture>
                        <source srcset="assets/images/pricing_section_img_3.webp" alt="pricing-section-image" width="397" height="324" />
                        <source srcset="assets/images/pricing_section_img_3.jpg" alt="pricing-section-image" width="397" height="324" />
                        <img src="assets/images/pricing_section_img_3.jpg" alt="pricing-section-image" width="397" height="324" />
                    </picture>
                </div>
                <div class="simple_plan_image_content">
                    <a href="javascript:void(0)">Add some Music in your projects</a>
                </div>
            </div>
            <div class="simple_plan_image_box">
                <div class="simple_plan_image">
                    <picture>
                        <source srcset="assets/images/pricing_section_img_4.webp" alt="pricing-section-image" width="397" height="324" />
                        <source srcset="assets/images/pricing_section_img_4.jpg" alt="pricing-section-image" width="397" height="324" />
                        <img src="assets/images/pricing_section_img_4.jpg" alt="pricing-section-image" width="397" height="324" />
                    </picture>
                </div>
                <div class="simple_plan_image_content">
                    <a href="javascript:void(0)">Choose Custom Packs as needs</a>
                </div>
            </div>
        </div>
        </div>
</section>
<!-- Simple and Transparent Pricing for All section end -->
<!-- Subscription plan section start -->
<section class="pricing_subscription_plan">
    <div class="container">
        <div class="pricing_subscription_tabs">
            <div class="tabs">
                <div class="tab_bar">
                    <ul class="tabs-nav">
                        <li><a href="#tab-1">Image</a></li>
                        <li><a href="#tab-2">Footage</a></li>
                        <li><a href="#tab-3">Music</a></li>
                    </ul>
                </div>
                <div class="tabs-stage">
                    <div id="tab-1">
                        <div class="subscribe-mian">
                            <div class="subscribe-col">
                                <div>
                                    <div class="subscribe-col-top">
                                        <div class="subscribe-plan-left">
                                            <h2 class="h3">Subscription Plan</h2>
                                            <span>Royalty Free Images</span>
                                        </div>
                                        <div class="subscribe-plan-right">
                                            <div class="toggle-inner">
                                                <input id="ce-toggle" type="checkbox" aria-label="custom-toggle">
                                                <span class="custom-toggle"></span>
                                                <label class="t-month" for="ce-toggle">Yearly</label>
                                                <label class="t-year" for="ce-toggle">Monthly</label>
                                            </div>
                                        </div>
                                    </div>
                                    <form action="#">
                                        <ul class="subscribe-list">
                                            <li class="active">
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn1" name="radio-group" checked>
                                                    <label for="checkbtn1">10 Images/month</label>
                                                </div>
                                                <span class="plan-price">₹2,000.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn2" name="radio-group">
                                                    <label for="checkbtn2">25 Images/month</label>
                                                </div>
                                                <span class="plan-price">₹3,750.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn3" name="radio-group">
                                                    <label for="checkbtn3">50 Images/month</label>
                                                </div>
                                                <span class="plan-price">₹6,000.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn4" name="radio-group">
                                                    <label for="checkbtn4">100 Images/month</label>
                                                </div>
                                                <span class="plan-price">₹9,000.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn5" name="radio-group">
                                                    <label for="checkbtn5">200 Images/month</label>
                                                </div>
                                                <span class="plan-price">₹8,000.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn6" name="radio-group">
                                                    <label for="checkbtn6">780 Images/month</label>
                                                </div>
                                                <span class="plan-price">₹15,600.00</span>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                                <a class="btn-thame" href="#">Order Now</a>
                            </div>
                            <div class="subscribe-col">
                                <div>
                                    <div class="subscribe-col-top">
                                        <div class="subscribe-plan-left">
                                            <h3 class="h3">Download Pack</h3>
                                            <span>Royalty Free Images</span>
                                        </div>
                                        <div class="subscribe-plan-right col-right-tp">
                                            <a href="#">Download Within a Year</a>
                                        </div>
                                    </div>
                                    <form action="#">
                                        <ul class="subscribe-list">
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn7" name="radio-group">
                                                    <label for="checkbtn7">5 Images/Year</label>
                                                </div>
                                                <span class="plan-price">₹3,550.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn8" name="radio-group">
                                                    <label for="checkbtn8">25 Images/Year</label>
                                                </div>
                                                <span class="plan-price">₹16,500.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn9" name="radio-group">
                                                    <label for="checkbtn9">50 Images/Year</label>
                                                </div>
                                                <span class="plan-price">₹31,500.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn10" name="radio-group">
                                                    <label for="checkbtn10">100 Images/Year</label>
                                                </div>
                                                <span class="plan-price">₹56,800.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn11" name="radio-group">
                                                    <label for="checkbtn11">200Images/Year</label>
                                                </div>
                                                <span class="plan-price">₹99,400.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn12" name="radio-group">
                                                    <label for="checkbtn12">500 Images/year</label>
                                                </div>
                                                <span class="plan-price">₹195,500.00</span>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                                <a class="btn-thame" href="#">Order Now</a>
                            </div>
                        </div>
                    </div>
                    <div id="tab-2">
                        <div class="subscribe-main">
                            <div class="subscribe-col">
                                <div>
                                    <div class="subscribe-col-top subscribe_border">
                                        <div class="subscribe-plan-left">
                                            <h3 class="h3">Download Pack</h3>
                                            <span>HD Footages</span>
                                        </div>
                                        <div class="subscribe-plan-right col-right-tp">
                                            <a href="#">Download Within a Year</a>
                                        </div>
                                    </div>
                                    <div class="select_licence_type">
                                        <h4 class="h4">Select Licence Type</h4>
                                        <form action="">
                                            <ul class="licence_type_list">
                                                <li>
                                                    <div class="licence_plan">
                                                        <input type="radio" name="radio-group" aria-label="custom_plan_choose" id="plan9">
                                                        <label for="plan9">Full RF License</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="licence_plan">
                                                        <input type="radio" name="radio-group" aria-label="custom_plan_choose" id="plan10" checked>
                                                        <label for="plan10">Commercial</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="licence_plan">
                                                        <input type="radio" name="radio-group" aria-label="custom_plan_choose" id="plan11">
                                                        <label for="plan11">Media Non-commercial (Editorial)</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="licence_plan">
                                                        <input type="radio" name="radio-group" aria-label="custom_plan_choose" id="plan12">
                                                        <label for="plan12">Digital</label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </form>
                                    </div>
                                    <form action="#">
                                        <ul class="subscribe-list">
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn13" name="radio-group" aria-label="custom_plan_choose">
                                                    <label for="checkbtn13">5 Footages/year</label>
                                                </div>
                                                <span class="plan-price">₹3,550.00</span>
                                            </li>
                                            <li class="active">
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn14" name="radio-group" aria-label="custom_plan_choose" checked>
                                                    <label for="checkbtn14">25 Footages/year</label>
                                                </div>
                                                <span class="plan-price">₹16,500.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn15" name="radio-group" aria-label="custom_plan_choose">
                                                    <label for="checkbtn15">50 Footages/year</label>
                                                </div>
                                                <span class="plan-price">₹31,500.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn16" name="radio-group" aria-label="custom_plan_choose">
                                                    <label for="checkbtn16">99 Footages/year</label>
                                                </div>
                                                <span class="plan-price">₹56,800.00</span>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                                <a class="btn-thame" href="#">Order Now</a>
                            </div>
                            <div class="subscribe-col">
                                <div>
                                    <div class="subscribe-col-top subscribe_border">
                                        <div class="subscribe-plan-left">
                                            <h3 class="h3">Download Pack</h3>
                                            <span>4K Footages</span>
                                        </div>
                                        <div class="subscribe-plan-right col-right-tp">
                                            <a href="#">Download Within a Year</a>
                                        </div>
                                    </div>
                                    <div class="select_licence_type">
                                        <h4 class="h4">Select Licence Type</h4>
                                        <form action="">
                                            <ul class="licence_type_list">
                                                <li>
                                                    <div class="licence_plan">
                                                        <input type="radio" name="radio-group" aria-label="custom_plan_choose" id="plan13">
                                                        <label for="plan13">Full RF License</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="licence_plan">
                                                        <input type="radio" name="radio-group" aria-label="custom_plan_choose" id="plan14">
                                                        <label for="plan14">Commercial</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="licence_plan">
                                                        <input type="radio" name="radio-group" aria-label="custom_plan_choose" id="plan15">
                                                        <label for="plan15">Media Non-commercial (Editorial)</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="licence_plan">
                                                        <input type="radio" name="radio-group" aria-label="custom_plan_choose" id="plan16">
                                                        <label for="plan16">Digital</label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </form>
                                    </div>
                                    <form action="#">
                                        <ul class="subscribe-list">
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn17" name="radio-group" aria-label="custom_plan_choose">
                                                    <label for="checkbtn17">5 Footages/year</label>
                                                </div>
                                                <span class="plan-price">₹3,550.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn18" name="radio-group" aria-label="custom_plan_choose">
                                                    <label for="checkbtn18">25 Footages/year</label>
                                                </div>
                                                <span class="plan-price">₹16,500.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn19" name="radio-group" aria-label="custom_plan_choose">
                                                    <label for="checkbtn19">50 Footages/year</label>
                                                </div>
                                                <span class="plan-price">₹31,500.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn20" name="radio-group" aria-label="custom_plan_choose">
                                                    <label for="checkbtn20">99 Footages/year</label>
                                                </div>
                                                <span class="plan-price">₹56,800.00</span>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                                <a class="btn-thame" href="#">Order Now</a>
                            </div>
                        </div>
                    </div>
                    <div id="tab-3">
                        <div class="subscribe-mian">
                            <div class="subscribe-col">
                                <div>
                                    <div class="subscribe-col-top">
                                        <div class="subscribe-plan-left">
                                            <h3 class="h3">Subscription Plan</h3>
                                            <span>Music Tracks</span>
                                        </div>
                                        <div class="subscribe-plan-right">
                                            <div class="toggle-inner">
                                                <input id="ce-toggle" type="checkbox">
                                                <span class="custom-toggle"></span>
                                                <label class="t-month" for="ce-toggle">Yearly</label>
                                                <label class="t-year" for="ce-toggle">Monthly</label>
                                            </div>
                                        </div>
                                    </div>
                                    <form action="#">
                                        <ul class="subscribe-list">
                                            <li class="active">
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn21" name="radio-group" checked>
                                                    <label for="checkbtn21">10 Tracks/month</label>
                                                </div>
                                                <span class="plan-price">₹2,000.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn22" name="radio-group">
                                                    <label for="checkbtn22">25 Tracks/month</label>
                                                </div>
                                                <span class="plan-price">₹3,750.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn23" name="radio-group">
                                                    <label for="checkbtn23">50 Tracks/month</label>
                                                </div>
                                                <span class="plan-price">₹6,000.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn24" name="radio-group">
                                                    <label for="checkbtn24">100 Tracks/month</label>
                                                </div>
                                                <span class="plan-price">₹9,000.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn25" name="radio-group">
                                                    <label for="checkbtn25">200 Tracks/month</label>
                                                </div>
                                                <span class="plan-price">₹8,000.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn26" name="radio-group">
                                                    <label for="checkbtn26">780 Tracks/month</label>
                                                </div>
                                                <span class="plan-price">₹15,600.00</span>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                                <a class="btn-thame" href="#">Order Now</a>
                            </div>
                            <div class="subscribe-col">
                                <div>
                                    <div class="subscribe-col-top">
                                        <div class="subscribe-plan-left">
                                            <h3 class="h3">Download Pack</h3>
                                            <span>Music Tracks</span>
                                        </div>
                                        <div class="subscribe-plan-right col-right-tp">
                                            <a href="#">Download Within a Year</a>
                                        </div>
                                    </div>
                                    <form action="#">
                                        <ul class="subscribe-list">
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn27" name="radio-group">
                                                    <label for="checkbtn27">5 Tracks/Year</label>
                                                </div>
                                                <span class="plan-price">₹3,550.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn28" name="radio-group">
                                                    <label for="checkbtn28">25 Tracks/Year</label>
                                                </div>
                                                <span class="plan-price">₹16,500.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn29" name="radio-group">
                                                    <label for="checkbtn29">50 Tracks/Year</label>
                                                </div>
                                                <span class="plan-price">₹31,500.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn30" name="radio-group">
                                                    <label for="checkbtn30">100 Tracks/Year</label>
                                                </div>
                                                <span class="plan-price">₹56,800.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn31" name="radio-group">
                                                    <label for="checkbtn31">200Tracks/Year</label>
                                                </div>
                                                <span class="plan-price">₹99,400.00</span>
                                            </li>
                                            <li>
                                                <div class="plan-select">
                                                    <input type="radio" id="checkbtn32" name="radio-group">
                                                    <label for="checkbtn32">500 Tracks/year</label>
                                                </div>
                                                <span class="plan-price">₹195,500.00</span>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                                <a class="btn-thame" href="#">Order Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

 <!-- Are you a team section start -->
 <section class="are_you_team_section">
    <div class="container">
        <div class="are_you_team_section_inner">
            <div class="are_you_team_section_content">
                <h2 class="h2">Didn’t get any options? Are you a Team?</h2>
                <p>{{appName}} is brought to you by Adobe the company behind some of the world’s leading marketplaces for creative assets and creative people.
                </p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <a href="javascript:void(0)" class="sales_team" (click)="openEmail()">Take to Sales Team</a>
            </div>
            <div class="are_you_team_section_image">
                <picture>
                    <source srcset="assets/images/are_you_team.webp" alt="are-you-team-image" width="922" height="595" />
                    <source srcset="assets/images/are_you_team.png" alt="are-you-team-image" width="922" height="595" />
                    <img src="assets/images/are_you_team.png" alt="are-you-team-image" width="922" height="595" />
                </picture>
            </div>
        </div>
    </div>
</section>
<!-- Are you a team section end -->

  <!-- Easy and secure payment section start -->
  <section class="secure_payment_section">
    <div class="container">
        <h2 class="h2">Easy and secure payment</h2>
        <div class="secure_payment_box">
            <div class="secure_payment_box_inner">
                <div class="secure_payment_image">
                    <ul>
                        <li class="secure_payment_badge_img">
                            <a href="javascript:void(0)"><img src="assets/images/visa.svg" alt="visa-card" width="52.77" height="16"></a>
                        </li>
                        <li class="secure_payment_badge_img">
                            <a href="javascript:void(0)"><img src="assets/images/mastercard.svg" alt="mastercard" width="25.33" height="16"></a>
                        </li>
                        <li class="secure_payment_badge_img">
                            <a href="javascript:void(0)"><img src="assets/images/american_express.svg" alt="american-express" width="43.69" height="16"></a>
                        </li>
                    </ul>
                </div>
                <div class="secure_payment_content">
                    <h3 class="h3">Trusted payment methods</h3>
                    <p>Pay with Visa, Mastercard, PayPal or American Express</p>
                </div>
            </div>
            <div class="secure_payment_box_inner">
                <div class="secure_payment_image">
                    <a href="javascript:void(0)"><img src="assets/images/lock.svg" alt="lock-image" width="38" height="38"></a>
                </div>
                <div class="secure_payment_content">
                    <h3 class="h3">Secure payments</h3>
                    <p>Processed in a Level 1 PCI compliant environment</p>
                </div>
            </div>
            <div class="secure_payment_box_inner">
                <div class="secure_payment_image">
                    <a href="javascript:void(0)"><img src="assets/images/cancel.svg" alt="visa-card" width="38" height="38"></a>
                </div>
                <div class="secure_payment_content">
                    <h3 class="h3">Cancel any time</h3>
                    <p>Canceling your subscription is quick and simple</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Easy and secure payment section end -->

 <!-- Still have questions section start -->
 <appv2-questions></appv2-questions>
