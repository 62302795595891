<div class="gallery-iterator">
  <div
    *ngFor="
      let item of galleryData
        | paginate
          : {
              id: 'listing_pagination',
              itemsPerPage: 30,
              currentPage: currPage,
              totalItems: totalRecords
            }
    "
  ></div>
</div>
<div class="main-page">
  <!-- main content sec footage start -->
  <section class="footage-banner-sec-v2">
    <div class="container">
      <div class="custom-row">
        <appv2-filter
          class="filter-block-left" (toggleActive)="toggleSectionActive()"
          (dataEmitted)="getFilterData($event)" [fromCategory] = "fromCategory"
        ></appv2-filter>
        <div class="content-block-right">
          <div class="banner-form-sec">
            <appv2-search-bar class="categories-seach-block-banner" [params]="{isHeaderPart: false}"></appv2-search-bar>
            <div class="reset-filter-row">
            <ul>
              <li *ngFor="let filter of processedFilters">
                <ng-container *ngIf="filter.valuekey">
                  {{ filter.namekey }}:
                  <ng-container *ngFor="let value of filter.valuekey.split(','); let index=index">
                    {{ value }}
                    <span (click)="removeSpecificFilter(filter.key,index)"></span>
                  </ng-container>
                </ng-container>
              </li>
            </ul>
            <button class="thame-btn" (click)="resetFilter()" *ngIf="showReset">
              Reset Filter
            </button>
          </div>
          </div>
          <!-- image list section start -->
        </div>
      </div>
    </div>
  </section>
  <section [ngClass]="{'active': isSectionActive}" class="image-list-sec-footage">
    <!-- [class.active]="filter_active != ''" -->
    <div class="container">
      <div class="image-list-bloc-in">
        <div class="cart-title-row">
          <div class="cart-col-left">
            <h1 class="h2">Footage</h1>
            <span>{{totalRecords}} Results</span>
          </div>
           <div class="cart-col-right">
              <div class="select-lice">
                <div class="select">
                  <select [(ngModel)]="selectedOption">
                    <option>Popular</option>
                    <option>Recent</option>
                  </select>
                  <div class="custom-select" (click)="toggleDropdown()">{{ selectedOption }}</div>
                  <ul class="select-options" [class.open]="dropdownOpen">
                    <li *ngFor="let option of options" (click)="selectOption(option)">{{ option }} </li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
        <!-- layout of image grid position start -->

        <div class="data-not-found" *ngIf="totalRecords == 0">
          <p>No Data Found</p>
        </div>
        <div class="image_grid">
          <div class="filter_listpage_image" *ngFor="let item of itemData">
            <!-- {{item | json}} -->
            <picture  (mouseenter)="playVideo(item)" (mouseleave)="resetVideo(item)">
              <img
              *ngIf="!item.videoLoaded"
                  class="img-tag"
                  src="{{item.product_thumbnail}}"
                  alt="list img"
                  width="457"
                  height="265"
              />
              <video
              *ngIf="item.videoLoaded"
                class="img-tag"
                #videoElement
                src="{{ item.product_main_image}}"
                width="457"
                height="265"
                autoplay
                (click)="openItem(item?.product_main_image)"
                style="cursor: pointer;"
              ></video>
            </picture>
            <div class="hd_resolution">
              <a href="javascript:void(0)">
                <!-- <div (click)="openItem(item?.product_main_image)" class="video_play_icon" style="cursor: pointer;">
                  <img
                    src="assets/images/play_button.svg"
                    alt="play-button"
                    width="14"
                    height="14"
                  />
                </div> -->
                <span class="resolution">{{ item?.product_size }}</span>
              </a>
            </div>
            <div class="rupees">{{ item?.product_price_small }}</div>
            <div class="image_name">
              <a (click)="openItem(item?.product_main_image)" style="cursor: pointer;">{{ item.product_title }}</a>
            </div>
            <div class="like">
              <li class="like">
                <a
                  class="btn btn-primary"
                  (click)="showModal(item)"
                  data-toggle="modal"
                  data-target="#likeModal"
                  class="btn_background"
                  style="cursor: pointer;"
                >
                  <img *ngIf="!userWishlistedData.includes(item.product_thumbnail)" src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart" />
                      <img *ngIf="userWishlistedData.includes(item.product_thumbnail)" src="assets/images/red_heart.svg" alt="red_heart" width="24" height="24" class="red_heart"/>
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="totalRecords > 0">
        <appv2-pagination [itemsPerPage]="itemsPerPage" [currentPage]="currPage" [totalPages]="totalPages"
                        (pageChanged)="onPageChange($event)"></appv2-pagination>
      </ng-container>
    </div>

  </section>
</div>
<!-- Add To Wishlist Modal -->
<add-to-wishlist
  [productData]="wishlistData"
  (dataEvent)="handleWishlistModalEvent($event)"
  *ngIf="showAddToWishListModal"
>
</add-to-wishlist>
<!-- ./Add To Wishlist Modal -->
