import { Injectable } from '@angular/core';
import { SafeRequestService } from './safe-request.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DiscountDetailsInterface } from '../interfaces/discount-details.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LandingPageService {
  /**
   * Data of data service.
   */
  private data = {
    key: null,
    value: null,
  };
  private trendingKeywords: any = [];
  private trendingKeyword: any = [];
  private categories: any = [];

  private sharedDataSubject = new BehaviorSubject<string>(''); // Initial value can be set as needed
  sharedData$ = this.sharedDataSubject.asObservable();

  public isDiscountDetailsCalled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isHomeBannerDetailsCalled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isImageBannerDetailsCalled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isFootageBannerDetailsCalled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isEditorialBannerDetailsCalled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isMusicBannerDetailsCalled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isHomeCategoriesCalled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isPricingPagePackagesCalled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isCmsPageLicenceAgreementCalled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isCmsPageTermsAgreementCalled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isCmsPagePrivacyPolicyCalled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isCmsPageAboutUsCalled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isTrendingSearchCalled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    private safeRequest: SafeRequestService,
    private http: HttpClient
  ) {}

  getPromotionDetails(promotion_type) {
    const url = 'promotion/' + promotion_type;
    return this.safeRequest.sendRequest('get', url);
  }

  getSuggestionByElasticSearch(searchText) {
    const url = 'search/' + searchText;
    return this.safeRequest.sendRequest('get', url);
  }

  getSuggestionByMusicSearch(searchText) {
    const url = 'music-search/' + searchText;
    return this.safeRequest.sendRequest('get', url);
  }

  getTrendingKeywordList(searchText) {
    const url = 'get_trending_keywords' + searchText ?? '/' + searchText;
    return this.safeRequest.sendRequest('get', url);
  }

  getDiscountDetails(page_type: string): Observable<DiscountDetailsInterface> {
    const url = 'get_discount_messages/' + page_type;
    return this.safeRequest.sendRequest('get', url);
  }

  contactUs(userData) {
    const url = 'user_contactus';
    return this.safeRequest.sendRequest('post', url, userData);
  }

  storeKeywordInElasticSearch(keyword) {
    const url = 'store-elasticword';
    return this.safeRequest.sendRequest('post', url, keyword);
  }

  getTagList() {
    const url = 'categories';
    return this.safeRequest.sendRequest('get', url);
  }
  
  // For Collections
  getEditorialList() {
    const url = 'v2/editorials-v2';
    return this.safeRequest.sendRequest('get', url);
  }

  getEditorialStoryList() {
    const url = 'v2/editorials-stories-v2';
    return this.safeRequest.sendRequest('get', url);
  }

  setTrendingDataForFooter(data: any) {
    this.trendingKeywords = data;
  }

  getTrendingDataForFooter(): any {
    return this.trendingKeywords;
  }

  getCategoryDetails(slug = '',type='') {
    const url = 'category-list?slug=' + slug+'&type='+type;
    return this.safeRequest.sendRequest('get', url);
  }

  setCategoriesSlider(data: any) {
    this.categories = data;
  }

  getCategoriesSlider(): any {
    return this.categories;
  }

  setSearchTrendingData(data: any) {
    this.trendingKeyword = data;
  }

  getSearchTrendingData(): any {
    return this.trendingKeyword;
  }

  setProductApiData(data: string) {
    this.sharedDataSubject.next(data);
  }

  getProductApiData(): string {
    return this.sharedDataSubject.value;
  }
}
