import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { tap, shareReplay, retryWhen, delayWhen, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { loadingChangeAction } from '../reducers/loading/loading.actions';
import { Store } from '@ngrx/store';
import { LoadingState } from '../reducers/loading/loading.models';

@Injectable({
  providedIn: 'root'
})
export class SafeRequestService {

  constructor(private http: HttpClient, private store$: Store<any>) { }
  private baseUrl = environment.url;
  sendRequest(type, url, body = {}) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    // return this.http[type]<any>('https://imagefootage.com/backend/api/' + url, body, {headers}).pipe(
    return this.http[type]<any>(this.baseUrl + url, body, { headers }).pipe(
      //return this.http[type]<any>('http://18.119.119.199/api/' + url, body, {headers}).pipe(
      //tap(() => console.log("HTTP request executed")),
      shareReplay(),
      retryWhen(errors => {

        errors.subscribe(errorMessage => {
          if (url == 'login') {
            return errorMessage.error.error
          }
        })
        return errors.pipe(
          take(url != 'login' ? 6 : 0),
          tap((errorStatus) => {
            switch (errorStatus.status) {
              case 500:
                this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: false }))
                return errorStatus

              case 404:
                this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: false }))
                return errorStatus

              case 401:
                this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: false }))
                return errorStatus

              default:
                break;
            }
          })
        );
      }
      )
    )
  }
}
