<!-- content block START -->
<div class=""  *ngIf="productType == 'image'">
<div class="image_grid">


  <section class="gallery-section" *ngIf="productType == 'image'">
    <div class="gallery-iterator">
      <div *ngFor="let item of galleryData">
      </div>
    </div>
    <ng-opengallery class="gallery-row"	[datasource]="galleryData" [config]="config" (selection)="openItemGallery($event)"></ng-opengallery>
  </section>

  <!--Code commented due to applied changes of gallery component-->
    <!-- <ng-container *ngIf="galleryData.length === 1">
        <div class="image_grid_1">

            <a class="landscape_big_image main_image" *ngFor="let item of galleryData; let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                <picture>
                    <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}" width="690"
                            height="420" />
                </picture>
            </a>

        </div>
    </ng-container>

    <ng-container *ngIf="galleryData.length === 2">
        <div class="image_grid_1">

                <a class="landscape_big_image main_image" *ngFor="let item of galleryData; let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                    <picture>
                        <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}" width="690"
                             height="420" />
                    </picture>

                </a>
        </div>
    </ng-container>

    <ng-container *ngIf="galleryData.length === 3">
        <div class="image_grid_1">
            <a class="landscape_big_image main_image" *ngFor="let item of galleryData; let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                <picture>
                    <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}" width="690"
                            height="420" />
                </picture>

            </a>
        </div>
    </ng-container>

    <ng-container *ngIf="galleryData.length === 4">
        <div class="image_grid_1">
                <a class="landscape_big_image main_image"
                 *ngFor="let item of galleryData; let i = index; let even = even" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                    <picture>
                        <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}" width="690"
                             height="420" />
                    </picture>

                </a>
        </div>
    </ng-container>

    <ng-container *ngIf="galleryData.length === 5">
        <div class="image_grid_1">
            <a (click)="openItem('image',galleryData[0].previewUrl)" class="landscape_big_image main_image">
                <picture>
                    <img class="img-tag" src="{{ galleryData[0].previewUrl }}" alt="list img"
                            width="690" height="420" />
                </picture>
            </a>
            <div class="first_image_grid">
                <div class="landscape_image_grid">
                        <a class="main_image" *ngFor="let item of galleryData.slice(1, 3); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                            <picture>
                                <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}"
                                     width="345" height="210" />
                            </picture>

                        </a>
                </div>
                <div class="landscape_image_grid">
                    <a class="main_image" *ngFor="let item of galleryData.slice(3); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                        <picture>
                            <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}"
                                    width="345" height="210" />
                        </picture>
                    </a>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="galleryData.length === 6 || galleryData.length === 7">

        <div class="image_grid_1">
            <a (click)="openItem('image',galleryData[0].previewUrl)"class="landscape_big_image main_image">
                <picture>
                    <img class="img-tag" src="{{ galleryData[0].previewUrl }}" alt="list img"
                            width="690" height="420" />
                </picture>
            </a>
            <div class="first_image_grid">
                <div class="landscape_image_grid">
                    <a class="main_image" *ngFor="let item of galleryData.slice(1, 3); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                        <picture>
                            <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}"
                                    width="345" height="210" />
                        </picture>

                    </a>
                </div>
                <div class="landscape_image_grid">
                    <a class="main_image" *ngFor="let item of galleryData.slice(3,5); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                        <picture>
                            <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}"
                                    width="345" height="210" />
                        </picture>
                    </a>
                </div>
            </div>
        </div> -->

         <!-- image second row start  -->
        <!-- <div class="image_grid_1">
            <div class="first_image_grid">
                <a class="landscape_image_grid main_image" *ngFor="let item of galleryData.slice(5); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                    <picture>
                        <img class="img-tag" src="{{item.previewUrl}}" alt="list img" width="345"
                                height="210" />
                    </picture>
                </a>
            </div>
        </div> -->
        <!-- image second row end -->
    <!-- </ng-container>

    <ng-container *ngIf="galleryData.length === 8 || galleryData.length === 9">

        <div class="image_grid_1">
            <a (click)="openItem('image',galleryData[0].previewUrl)"class="landscape_big_image main_image">
                <picture>
                    <img class="img-tag" src="{{ galleryData[0].previewUrl }}" alt="list img"
                            width="690" height="420" />
                </picture>
            </a>
            <div class="first_image_grid">
                <div class="landscape_image_grid">
                    <a class="main_image" *ngFor="let item of galleryData.slice(1, 3); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                        <picture>
                            <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}"
                                    width="345" height="210" />
                        </picture>
                    </a>
                </div>
                <div class="landscape_image_grid">
                    <a class="main_image" *ngFor="let item of galleryData.slice(3,5); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                        <picture>
                            <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}"
                                    width="345" height="210" />
                        </picture>

                    </a>
                </div>
            </div>
        </div> -->

        <!-- image second row start -->
        <!-- <div class="image_grid_1">
            <div class="first_image_grid">
                <a class="landscape_image_grid main_image" *ngFor="let item of galleryData.slice(5,7); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                    <picture>
                        <img class="img-tag" src="{{item.previewUrl}}" alt="list img" width="345"
                                height="210" />
                    </picture>
                </a>
            </div>
            <div class="first_image_grid">

                <a class="landscape_image_grid main_image" *ngFor="let item of galleryData.slice(7,9); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                    <picture>
                        <img class="img-tag" src="{{item.previewUrl}}" alt="list img" width="345"
                                height="210" />
                    </picture>
                </a>

            </div>
        </div> -->
        <!-- image second row end -->
    <!-- </ng-container>

    <ng-container *ngIf="galleryData.length === 10 || galleryData.length === 11">

        <div class="image_grid_1">
            <a (click)="openItem('image',galleryData[0].previewUrl)"class="landscape_big_image main_image">
                <picture>
                    <img class="img-tag" src="{{ galleryData[0].previewUrl }}" alt="list img"
                            width="690" height="420" />
                </picture>
            </a>
            <div class="first_image_grid">
                <div class="landscape_image_grid">
                        <a class="main_image" *ngFor="let item of galleryData.slice(1, 3); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                            <picture>
                                <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}"
                                     width="345" height="210" />
                            </picture>
                        </a>
                </div>
                <div class="landscape_image_grid">
                    <a class="main_image" *ngFor="let item of galleryData.slice(3,5); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                        <picture>
                            <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}"
                                    width="345" height="210" />
                        </picture>
                    </a>
                </div>
            </div>
        </div> -->

        <!-- image second row start -->
        <!-- <div class="image_grid_1">
            <div class="first_image_grid">

                <a class="landscape_image_grid main_image" *ngFor="let item of galleryData.slice(5,7); let i = index"(click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                    <picture>
                        <img class="img-tag" src="{{item.previewUrl}}" alt="list img" width="345"
                                height="210" />
                    </picture>
                </a>

            </div>
            <div class="first_image_grid">

                <a class="landscape_image_grid main_image" *ngFor="let item of galleryData.slice(7,9); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                    <picture>
                        <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}"
                                width="345" height="210" />
                    </picture>
                </a>

            </div>
        </div> -->
        <!-- image second row end -->

        <!-- image third row start -->
        <!-- <div class="image_grid_1">

            <a class="landscape_big_image landscape_big2  main_image" *ngFor="let item of galleryData.slice(9);" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                <picture>
                    <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}" width="690"
                            height="420" />
                </picture>
            </a>

        </div> -->
        <!-- image third row end -->
    <!-- </ng-container>

    <ng-container *ngIf="galleryData.length === 12 || galleryData.length === 13">

        <div class="image_grid_1">
            <a (click)="openItem('image',galleryData[0].previewUrl)"class="landscape_big_image main_image">
                <picture>
                    <img class="img-tag" src="{{ galleryData[0].previewUrl }}" alt="list img"
                            width="690" height="420" />
                </picture>
            </a>
            <div class="first_image_grid">
                <div class="landscape_image_grid">

                    <a class="main_image" *ngFor="let item of galleryData.slice(1, 3); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                        <picture>
                            <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}"
                                    width="345" height="210" />
                        </picture>
                    </a>
                </div>
                <div class="landscape_image_grid">

                    <a class="main_image" *ngFor="let item of galleryData.slice(3,5); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                        <picture>
                            <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}"
                                    width="345" height="210" />
                        </picture>
                    </a>
                </div>
            </div>
        </div> -->

        <!-- image second row start -->
        <!-- <div class="image_grid_1">
            <div class="first_image_grid">
                <a class="landscape_image_grid main_image" *ngFor="let item of galleryData.slice(5,7); let i = index"(click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                    <picture>
                        <img class="img-tag" src="{{item.previewUrl}}" alt="list img" width="345"
                                height="210" />
                    </picture>
                </a>
            </div>
            <div class="first_image_grid">

                <a class="landscape_image_grid main_image" *ngFor="let item of galleryData.slice(7,9); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                    <picture>
                        <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}"
                                width="345" height="210" />
                    </picture>
                </a>

            </div>
        </div> -->
        <!-- image second row end -->

        <!-- image third row start -->
        <!-- <div class="image_grid_1">

            <a class="landscape_big_image landscape_big2  main_image" *ngFor="let item of galleryData.slice(9,11);" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                <picture>
                    <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}" width="690"
                            height="420" />
                </picture>
            </a>

        </div> -->
        <!-- image third row end -->

        <!-- image four row start -->
        <!-- <div class="image_grid_1">
            <div class="first_image_grid">

                <a class="portrait_image_grid main_image" *ngFor="let item of galleryData.slice(11,13);" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                    <picture>
                        <img class="img-tag" src="{{ item.previewUrl }}" alt="{{ item.title }}"
                                width="345" height="295" />
                    </picture>
                </a>

            </div>
        </div>
    </ng-container> -->
    <!--  === 14 || galleryData === 15  -->
    <!-- <ng-container *ngIf="galleryData.length > 13">

        <div class="image_grid_1">
            <a (click)="openItem('image',galleryData[0].previewUrl)"class="landscape_big_image main_image">
                <picture>
                    <img class="img-tag" src="{{ galleryData[0].previewUrl }}" alt="list img"
                            width="690" height="420" />
                </picture>
            </a>
            <div class="first_image_grid">
                <div class="landscape_image_grid">
                    <a class="main_image" *ngFor="let item of galleryData.slice(1, 3); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                        <picture>
                            <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}"
                                    width="345" height="210" />
                        </picture>
                    </a>
                </div>
                <div class="landscape_image_grid">
                    <a class="main_image" *ngFor="let item of galleryData.slice(3,5); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                        <picture>
                            <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}"
                                    width="345" height="210" />
                        </picture>
                    </a>
                </div>
            </div>
        </div> -->

        <!-- image second row start -->
        <!-- <div class="image_grid_1">
            <div class="first_image_grid">
                <a class="landscape_image_grid main_image" *ngFor="let item of galleryData.slice(5,7); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                    <picture>
                        <img class="img-tag" src="{{item.previewUrl}}" alt="list img" width="345"
                                height="210" />
                    </picture>
                </a>
            </div>
            <div class="first_image_grid">
                    <a  class="landscape_image_grid main_image" *ngFor="let item of galleryData.slice(7,9); let i = index" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                        <picture>
                            <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}"
                                    width="345" height="210" />
                        </picture>
                    </a>
           </div>
        </div> -->
        <!-- image second row end -->

        <!-- image third row start -->
        <!-- <div class="image_grid_1">
                    <a *ngFor="let item of galleryData.slice(9,11);" class="landscape_big_image landscape_big2 main_image" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                        <picture>
                            <img class="img-tag" src="{{item.previewUrl}}" alt="{{item.title}}" width="690"
                                 height="420" />
                        </picture>
                    </a>
        </div> -->
        <!-- image third row end -->

        <!-- image four row start -->
        <!-- <div class="image_grid_1">
            <div class="first_image_grid">
                <a class="landscape_image_grid main_image" *ngFor="let item of galleryData.slice(11,13);" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                    <picture>
                        <img class="img-tag" src="{{ item.previewUrl }}" alt="{{item.title}}"
                                width="345" height="295" />
                    </picture>
                </a>
            </div>
            <div class="first_image_grid">
                <a class="landscape_image_grid main_image" *ngFor="let item of galleryData.slice(13,15);" (click)="openItem('image',item.previewUrl)" style="cursor: pointer;">
                    <picture>
                        <img class="img-tag" src="{{ item.previewUrl }}" alt="{{item.title}}"
                                width="345" height="295" />
                    </picture>
                </a>
            </div>
        </div>
    </ng-container> -->
</div>
<ng-container *ngIf="totalRecords > 0">
  <appv2-pagination [itemsPerPage]="itemsPerPage" [currentPage]="currentPage" [totalPages]="totalPages"
                (pageChanged)="onPageChange($event)"></appv2-pagination>
</ng-container>
</div>


<div class="image-list-sec-footage"*ngIf="productType == 'footage'">
<div class="image_grid" >
  <div class="filter_listpage_image" *ngFor="let item of galleryData">
    <!-- {{item | json}} -->
    <a (click)="openItem('footage',item.previewUrl)" style="cursor: pointer;">
      <picture  (mouseenter)="playVideo(item)" (mouseleave)="resetVideo(item)">
        <img
        *ngIf="!item.videoLoaded"
            class="img-tag"
            src="{{item.thumbnail}}"
            alt="list img"
            width="457"
            height="265"
        />
        <video
        *ngIf="item.videoLoaded"
          class="img-tag"
          #videoElement
          src="{{ item.previewUrl}}"
          width="457"
          height="265"
          autoplay
          style="cursor: pointer;"
        ></video>
      </picture>
    </a>

  </div>

</div>
<ng-container *ngIf="totalRecords > 0">
  <appv2-pagination [itemsPerPage]="itemsPerPage" [currentPage]="currentPage" [totalPages]="totalPages"
                (pageChanged)="onPageChange($event)"></appv2-pagination>
</ng-container>

</div>

<div class="data-not-found" *ngIf="totalRecords == 0">
  <p>No Data Found</p>
</div>
<div class="" *ngIf="productType === 'music' && galleryData.length !== 0">
<div class="image_grid" >
  <div class="music_table">
    <table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Type</th>
          <th>Duration</th>
          <th>BPM</th>
          <th>Format</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let music of galleryData; let i = index">
          <td>
            <a
              *ngIf="music['product_main_image']"
              href="javascript:void(0)"
              (click)="handleClick(i)"
              class="play_btn"
              ><img
                [src]="
                  i == currentIndex && isPlaying
                    ? 'assets/images/circle-pause.svg'
                    : 'assets/images/play.svg'
                "
                alt="play-button"
                width="30"
                height="30"
                class="play-btn"
            /></a>
            <span (click)="openItem('music',music.product_main_image)"  [ngStyle]="{'cursor': 'pointer' }">{{ music["product_title"] }}</span>
          </td>
          <td>{{ music["random_three_keywords"] }}</td>
          <td>{{ music["music_duration"] }}</td>
          <td>{{ music["music_sound_bpm"] }}</td>
          <td>{{ music["music_fileType"] }}</td>
          <td class="music_icon">
                <li class="like">
                    <a class="btn btn-primary" (click)="showModal(music)" data-toggle="modal" data-target="#likeModal"
                        class="btn_background">
                        <img *ngIf="!userWishlistedData.includes(music['product_thumbnail'])" src="assets/images/heart-icon.svg"
                            alt="like" width="24" height="24" class="white_heart" />
                        <img *ngIf="userWishlistedData.includes(music['product_thumbnail'])" src="assets/images/black_heart.svg"
                            alt="red_heart" width="24" height="24" class="black_heart" />
                    </a>
                </li>
                <div class="music_icon_image" style="cursor: pointer;">
                    <a routerLink="/music/{{music['slug']}}" *ngIf="!displayDownload"><img src="assets/images/shopping_cart.svg"
                            alt="like" width="24" height="24" /></a>
                    <a routerLink="/music/{{music['slug']}}" *ngIf="displayDownload"><img src="assets/images/download_plan.svg"
                            alt="like" width="24" height="24" /></a>
                </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
<ng-container *ngIf="totalRecords > 0">
  <appv2-pagination [itemsPerPage]="itemsPerPage" [currentPage]="currentPage" [totalPages]="totalPages"
                (pageChanged)="onPageChange($event)"></appv2-pagination>
</ng-container>
</div>

<!-- Add To Wishlist Modal -->
<add-to-wishlist [productData]="wishlistData" (dataEvent)="handleWishlistModalEvent($event)"
    *ngIf="showAddToWishListModal && productType === 'music' && galleryData.length !== 0">
</add-to-wishlist>

<div class="image_grid" *ngIf="productType == 'editorial'">
  <section class="gallery-section" *ngIf="productType == 'editorial'">
    <div class="gallery-iterator">
      <div *ngFor="let item of galleryData">
      </div>
    </div>
    <ng-opengallery class="gallery-row"	[datasource]="galleryData" [config]="config" (selection)="openItemGallery($event)"></ng-opengallery>
  </section>
</div>
<appv2-music-player [musicList]="galleryData" [currentMusicIndex]="currentIndex" [isPlaying]= "isPlaying"*ngIf="galleryData && displayMusicPlayer" (dataEvent)="handleClickPlay($event)" [userWishlistedData]="userWishlistedData" [displayDownload]="displayDownload"></appv2-music-player>


