export const urlToCacheKeyMapping = {
    'image': 'imagePageDiscountDetails',
    'footage': 'footagePageDiscountDetails',
    'home': 'homePageDiscountDetails',
    'editorial': 'editorialPageDiscountDetails',
    'music': 'musicPageDiscountDetails',
    'image-description': 'imageDescriptionDescountDetails',
    'pricing': 'pricingDetails',
    'home-page-banner': 'homePageBannerDetails',
    'image-page-banner': 'imagePageBannerDetails',
    'footage-page-banner': 'footagePageBannerDetails',
    'editorial-page-banner': 'editorialPageBannerDetails',
    'music-page-banner': 'musicPageBannerDetails',
    'home-page-categories': 'homePageCategoriesDetails',
    'pricing-page-packages': 'pricingPagePackagesDetails',
    'cms-page-licence-agreement': 'cmsPageLicenceAgreementDetails',
    'cms-page-terms-and-agreement': 'cmsPageTermsAgreementDetails',
    'cms-page-privacy-policy': 'cmsPagePrivacyPolicyDetails',
    'cms-page-about-us': 'cmsPageAboutUsDetails',
    'search-trending-keyword': 'searchTrendingKeywordDetails',
};