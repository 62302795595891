import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { selectLoadingStatus } from './reducers/loading/loading.selectors';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'appv2-root',
  templateUrl: './appv2.component.html',
  styleUrls: ['./appv2.component.sass']
})
export class Appv2Component implements OnInit, AfterContentChecked {

  constructor(private store$: Store<any>, private cdr: ChangeDetectorRef) { }

  title = 'imagefootegdev';
  loadingStatus: boolean = true
  loadingMessage: string = ''

  ngOnInit(): void {
    this.store$.pipe(select(selectLoadingStatus)).subscribe(loadingStatus => {
      this.loadingStatus = loadingStatus.loadingStatus
      this.loadingMessage = loadingStatus.loadingMessage
    })
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

}
