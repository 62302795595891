<section class="banner-sec" *ngIf="promotion">
    <picture>
        <source class="banner-img dec-img" srcset="{{ promotion?.desktop_banner_image }}" alt="Images" width="1920"
            height="524" />
        <source class="banner-img dec-img" srcset="{{ promotion?.desktop_banner_image }}" alt="Images" width="1920"
            height="524" />
        <img class="banner-img dec-img" src="{{ promotion?.desktop_banner_image }}" alt="Images" width="1920"
            height="524" />
        <source class="banner-img mob-img" srcset="{{ promotion?.mobile_banner_image }}" alt="Images" width="575"
            height="380" />
        <source class="banner-img mob-img" srcset="{{ promotion?.mobile_banner_image }}" alt="Images" width="575"
            height="380" />
        <img class="banner-img mob-img" src="{{ promotion?.mobile_banner_image }}" alt="Images" width="575"
            height="380" />
    </picture>
    <div class="container">
        <div class="banner-content">
            <h1 class="h1">{{ promotion?.event_name }}</h1>
            <p>{{ promotion?.event_des }}</p>
            <appv2-search-bar class="categories-seach-block-banner" [params]="{isHeaderPart: false}"></appv2-search-bar>
        </div>
    </div>
</section>
<!-- Information Line section start -->
<section class="information_line" *ngIf="discountDetails">
    <div class="container" *ngIf="discountDetails?.status == 1">
        <div class="information_line_inner">
            <p>{{ discountDetails?.title }}</p>
            <a target="_blank" href="{{ discountDetails?.link }}">{{ discountDetails?.button_text }}</a>
        </div>
    </div>
</section>

<!-- static section start -->
<section class="static-sec">
    <div class="container">
        <div class="image_grid_tab" *ngIf="categories.length != 0">
            <div class="cata-sub-nav">
                <div class="nav-prev arrow" (click)="prev()" *ngIf="categories.length > 10">
                    <img src="assets/images/angle-left-solid.svg" alt="left-arrow" width="24px" height="24px" />
                </div>
                <ul [ngClass]="{'slider-nav': categories.length > 10}" >
                    <li *ngFor="
                    let item of categories | keyvalue | rotate : startIndex;
                    let in = index
                  ">
                        <a class="category-link" (click)="openCategory(item.value.category_slug)">{{
                            item.value.category_name }}</a>
                    </li>
                </ul>
                <div class="nav-next arrow" (click)="next()" *ngIf="categories.length > 10">
                    <img src="assets/images/angle-right-solid.svg" alt="right-arrow" width="24px" height="24px" />
                </div>
            </div>
            <div class="image_grid_tab_btn">
                <a routerLink="/categories">View All</a>
            </div>
        </div>
        <div class="static-main-row">
            <div class="static-col">
                <div class="static-img">
                    <picture>
                        <source class="img-tag" srcset="assets/images/static-img1.webp" alt="static img" width="550"
                            height="315" />
                        <source class="img-tag" srcset="assets/images/static-img1.jpg" alt="static img" width="550"
                            height="315" />
                        <img class="img-tag" src="assets/images/static-img1.jpg" alt="static img" width="550"
                            height="315" />
                    </picture>
                </div>
                <div class="static-content">
                    <h2 class="h3">Free Colorful Clip Art to<br> Promote Sales and Discounts</h2>
                    <p>Neon-colored and easy-to-use PNGs are here to assist you with any sale or promotion
                        you’ve planned for 2023</p>
                </div>
            </div>
            <div class="static-col">
                <div class="static-img">
                    <picture>
                        <source class="img-tag" srcset="assets/images/static-img2.webp" alt="static img" width="550"
                            height="315" />
                        <source class="img-tag" srcset="assets/images/static-img2.jpg" alt="static img" width="550"
                            height="315" />
                        <img class="img-tag" src="assets/images/static-img2.jpg" alt="static img" width="550"
                            height="315" />
                    </picture>
                </div>
                <div class="static-content">
                    <h2 class="h3">Build Brand Trust Through <br> Good Design</h2>
                    <p>Reach your audience with five shortcuts for building brand trust through good design.
                    </p>
                </div>
            </div>
            <div class="static-col">
                <div class="static-img">
                    <picture>
                        <source class="img-tag" srcset="assets/images/static-img3.webp" alt="static img" width="550"
                            height="315" />
                        <source class="img-tag" srcset="assets/images/static-img3.jpg" alt="static img" width="550"
                            height="315" />
                        <img class="img-tag" src="assets/images/static-img3.jpg" alt="static img" width="550"
                            height="315" />
                    </picture>
                </div>
                <div class="static-content">
                    <h2 class="h3">Write Better Generative <br> AI Descriptions</h2>
                    <p>Get tips and tricks on how to adjust your text, so you can create imagery without
                        limits.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- trending section start -->
<section class="trending-sec" *ngIf="galleryData?.length > 0">
    <div class="container">
        <div class="title-text">
            <h2 class="h2">See what’s trending</h2>
        </div>
        <!-- layout of image grid position start -->
        <div class="image_grid" *ngIf="galleryData">
            <!-- image second row start -->
            <div class="image_grid_2">
                <div *ngIf="galleryData[0]">
                    <div class="landscape_big_image main_image" (click)="openItem(galleryData[0])" style="cursor: pointer;">
                        <picture>
                            <img src="{{galleryData[0].url}}" alt="discover-image" width="911px" height="550px">
                        </picture>
                        <a (click)="openItem(galleryData[0])" class="image_name">{{
                            galleryData[0].title
                          }}</a>
                    </div>
                </div>
                <div>
                    <div class="landscape_image_grid"  *ngIf="galleryData[1]">
                        <div class="main_image" (click)="openItem(galleryData[1])" style="cursor: pointer;">
                            <picture><img src="{{galleryData[1].url}}" alt="discover-image" width="453px"
                                    height="273px"></picture>
                            <a (click)="openItem(galleryData[1])" class="image_name">{{
                                        galleryData[1].title
                                      }}</a>
                        </div>
                        <div class="main_image" *ngIf="galleryData[2]" (click)="openItem(galleryData[2])" style="cursor: pointer;">
                            <picture><img src="{{galleryData[2].url}}" alt="discover-image" width="453px"
                                    height="273px"></picture>
                            <a (click)="openItem(galleryData[2])" class="image_name">{{
                                        galleryData[2].title
                                      }}</a>
                        </div>
                    </div>
                    <div class="landscape_image_grid">
                        <div class="main_image" *ngIf="galleryData[3]" (click)="openItem(galleryData[3])" style="cursor: pointer;">
                            <picture><img src="{{galleryData[3].url}}" alt="discover-image" width="453px"
                                    height="273px"></picture>
                            <a (click)="openItem(galleryData[3])" class="image_name">{{
                                        galleryData[3].title
                                      }}</a>
                        </div>
                        <div class="main_image" *ngIf="galleryData[4]"  (click)="openItem(galleryData[4])" style="cursor: pointer;">
                            <picture><img src="{{galleryData[4].url}}" alt="discover-image" width="453px"
                                    height="273px"></picture>
                            <a (click)="openItem(galleryData[4])" class="image_name">{{
                                        galleryData[4].title
                                      }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- image second row end -->
        </div>
        <!-- layout of image grid position end -->
    </div>
</section>

<!-- Royalty free stock images section start -->
<section class="royalty_free_stock_images_section">
    <div class="container">
        <div class="royalty_free_stock_images_section_inner">
            <div class="royalty_free_stock_images_section_content">
                <h2 class="h2">Royalty free stock images, photos, vectors and videos</h2>
                <p>Discover the best royalty free stock images, photos, vectors, footage, videos and music
                    files for your designs and projects by talented artists and contributors worldwide right
                    here. Everything you need is just a search away.
                </p>
                <a class="btn-thame" (click)="scrollTo('subscribeSectionId')">Subscribe Today</a>
            </div>
            <div class="royalty_free_stock_images_section_img">
                <img src="assets/images/royalty_free_section_image.png" alt="cartoon-character" width="416px"
                    height="398px">
            </div>
        </div>
    </div>
</section>
<!-- Royalty free stock images section end -->

<!-- checkout collections section start -->
<section class="checkout-collections-sec">
    <div class="container">
        <div class="title-text">
            <h2 class="h2">Checkout Best Collections</h2>
        </div>
        <div class="collections-main">
            <div class="collections-box">
                <div class="collections-main-img">
                    <a href="javascript:void(0)" (click)="openCategory('nature')">
                        <picture>
                            <source class="img-tag" srcset="assets/images/collections-img3.webp" alt="collections img"
                                width="580" height="360" />
                            <source class="img-tag" srcset="assets/images/collections-img3.webp" alt="collections img"
                                width="580" height="360" />
                            <img class="img-tag" src="assets/images/collections-img3.jpg" alt="collections img"
                                width="580" height="360" />
                        </picture>
                    </a>
                </div>
                <div class="collections-bottom-img">
                    <div class="collections-small-img">
                        <a href="javascript:void(0)" (click)="openCategory('nature')">
                            <picture>
                                <source class="small-img-tag" srcset="assets/images/collections-img4.webp"
                                    alt="collections img" width="183" height="140" />
                                <source class="small-img-tag" srcset="assets/images/collections-img4.webp"
                                    alt="collections img" width="183" height="140" />
                                <img class="small-img-tag" src="assets/images/collections-img4.jpg"
                                    alt="collections img" width="183" height="140" />
                            </picture>
                        </a>
                    </div>
                    <div class="collections-small-img">
                        <a href="javascript:void(0)" (click)="openCategory('nature')">
                            <picture>
                                <source class="small-img-tag" srcset="assets/images/collections-img5.webp"
                                    alt="collections img" width="183" height="140" />
                                <source class="small-img-tag" srcset="assets/images/collections-img5.webp"
                                    alt="collections img" width="183" height="140" />
                                <img class="small-img-tag" src="assets/images/collections-img5.jpg"
                                    alt="collections img" width="183" height="140" />
                            </picture>
                        </a>
                    </div>
                    <div class="collections-small-img">
                        <a href="javascript:void(0)" (click)="openCategory('nature')">
                            <picture>
                                <source class="small-img-tag" srcset="assets/images/collections-img6.webp"
                                    alt="collections img" width="183" height="140" />
                                <source class="small-img-tag" srcset="assets/images/collections-img6.webp"
                                    alt="collections img" width="183" height="140" />
                                <img class="small-img-tag" src="assets/images/collections-img6.jpg"
                                    alt="collections img" width="183" height="140" />
                            </picture>
                        </a>
                    </div>
                </div>
                <h3 class="h3">Nature</h3>
            </div>
            <div class="collections-box">
                <div class="collections-main-img">
                    <a href="javascript:void(0)" (click)="openCategory('photography')">
                        <picture>
                            <source class="img-tag" srcset="assets/images/collections-img2.webp" alt="collections img"
                                width="580" height="360" />
                            <source class="img-tag" srcset="assets/images/collections-img2.webp" alt="collections img"
                                width="580" height="360" />
                            <img class="img-tag" src="assets/images/collections-img2.jpg" alt="collections img"
                                width="580" height="360" />
                        </picture>
                    </a>
                </div>
                <div class="collections-bottom-img">
                    <div class="collections-small-img">
                        <a href="javascript:void(0)" (click)="openCategory('photography')">
                            <picture>
                                <source class="small-img-tag" srcset="assets/images/collections-img7.webp"
                                    alt="collections img" width="183" height="140" />
                                <source class="small-img-tag" srcset="assets/images/collections-img7.webp"
                                    alt="collections img" width="183" height="140" />
                                <img class="small-img-tag" src="assets/images/collections-img7.jpg"
                                    alt="collections img" width="183" height="140" />
                            </picture>
                        </a>
                    </div>
                    <div class="collections-small-img">
                        <a href="javascript:void(0)" (click)="openCategory('photography')">
                            <picture>
                                <source class="small-img-tag" srcset="assets/images/collections-img8.webp"
                                    alt="collections img" width="183" height="140" />
                                <source class="small-img-tag" srcset="assets/images/collections-img8.webp"
                                    alt="collections img" width="183" height="140" />
                                <img class="small-img-tag" src="assets/images/collections-img8.jpg"
                                    alt="collections img" width="183" height="140" />
                            </picture>
                        </a>
                    </div>
                    <div class="collections-small-img">
                        <a href="javascript:void(0)" (click)="openCategory('photography')">
                            <picture>
                                <source class="small-img-tag" srcset="assets/images/collections-img9.webp"
                                    alt="collections img" width="183" height="140" />
                                <source class="small-img-tag" srcset="assets/images/collections-img9.webp"
                                    alt="collections img" width="183" height="140" />
                                <img class="small-img-tag" src="assets/images/collections-img9.jpg"
                                    alt="collections img" width="183" height="140" />
                            </picture>
                        </a>
                    </div>
                </div>
                <h3 class="h3">Photography</h3>
            </div>
            <div class="collections-box">
                <div class="collections-main-img">
                    <a href="javascript:void(0)" (click)="openCategory('pink-kiss')">
                        <picture>
                            <source class="img-tag" srcset="assets/images/collections-img1.webp" alt="collections img"
                                width="580" height="360" />
                            <source class="img-tag" srcset="assets/images/collections-img1.webp" alt="collections img"
                                width="580" height="360" />
                            <img class="img-tag" src="assets/images/collections-img1.jpg" alt="collections img"
                                width="580" height="360" />
                        </picture>
                    </a>
                </div>
                <div class="collections-bottom-img">
                    <div class="collections-small-img">
                        <a href="javascript:void(0)" (click)="openCategory('pink-kiss')">
                            <picture>
                                <source class="small-img-tag" srcset="assets/images/collections-img10.webp"
                                    alt="collections img" width="183" height="140" />
                                <source class="small-img-tag" srcset="assets/images/collections-img10.webp"
                                    alt="collections img" width="183" height="140" />
                                <img class="small-img-tag" src="assets/images/collections-img10.jpg"
                                    alt="collections img" width="183" height="140" />
                            </picture>
                        </a>
                    </div>
                    <div class="collections-small-img">
                        <a href="javascript:void(0)" (click)="openCategory('pink-kiss')">
                            <picture>
                                <source class="small-img-tag" srcset="assets/images/collections-img11.webp"
                                    alt="collections img" width="183" height="140" />
                                <source class="small-img-tag" srcset="assets/images/collections-img11.webp"
                                    alt="collections img" width="183" height="140" />
                                <img class="small-img-tag" src="assets/images/collections-img11.jpg"
                                    alt="collections img" width="183" height="140" />
                            </picture>
                        </a>
                    </div>
                    <div class="collections-small-img">
                        <a href="javascript:void(0)" (click)="openCategory('pink-kiss')">
                            <picture>
                                <source class="small-img-tag" srcset="assets/images/collections-img12.webp"
                                    alt="collections img" width="183" height="140" />
                                <source class="small-img-tag" srcset="assets/images/collections-img12.webp"
                                    alt="collections img" width="183" height="140" />
                                <img class="small-img-tag" src="assets/images/collections-img12.jpg"
                                    alt="collections img" width="183" height="140" />
                            </picture>
                        </a>
                    </div>
                </div>
                <h3 class="h3">Pink Kiss</h3>
            </div>
        </div>
    </div>
</section>
<!-- checkout collections section start -->

<!-- weekly section start -->
<section class="weekly-sec">
    <div class="container">
        <div class="weekly-sec_inner">
            <div class="weekly-sec_content">
                <h2 class="h2">Weekly Inspiration, Just for You!</h2>
                <p>Sign up and get a free image or photo every week
                </p>
                <a routerLink="/signup">Get Started</a>
            </div>
            <div class="weekly-sec_image">
                <div class="inspiration-card">
                    <div class="inspiration-card-img">
                        <picture><img src="assets/images/weekly-img2.webp" alt="weekly image" width="581"
                                height="351" />
                        </picture>
                    </div>
                    <h3 class="h3">Personalised Photography</h3>
                </div>
                <div class="inspiration-card">
                    <div class="inspiration-card-img">
                        <picture><img src="assets/images/weekly-img1.webp" alt="weekly image" width="581"
                                height="351" />
                        </picture>
                    </div>
                    <h3 class="h3">Image for the Month</h3>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- weekly section end -->

<!-- subscribe section start -->
<section class="subscribe-sec" *ngIf="displayPricingHeader" id="subscribeSectionId">
    <div class="container">
        <div class="title-text">
            <h2 class="h2">Subscribe a plan that suits well</h2>
        </div>
        <appv2-pricing-filter [params]="{openTab:'Image',singleTab:'Image'}"
            (pricingHeaderDisplayEvent)="receivePricingHeaderDisplayEvent($event)"></appv2-pricing-filter>
    </div>
</section>
<!-- subscribe section end -->
