import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FeatureDecreaseCartCountAction, FeatureDecreaseWishlistCountAction, FeatureIncreaseWishlistCountAction } from 'src/appv2/reducers/features/features.actions';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { CartService } from 'src/appv2/services/cart.service';
import { LocationsService } from 'src/appv2/services/locations.service';
import { ToastService } from 'src/appv2/services/toast.service';
import { WishlistService } from 'src/appv2/services/wishlist.service';
import { AccountService } from 'src/appv2/services/account.service';
import { User } from 'src/appv2/models/user';
import { footage, messages } from 'src/appv2/appv2.constants';
import { image } from 'src/appv2/appv2.constants';
import { music } from 'src/appv2/appv2.constants';
import { ItemDetailsService } from 'src/appv2/services/item-details.service';
import { ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { GST } from 'src/appv2/appv2.constants';
declare var Razorpay: any;

@Component({
  selector: 'appv2-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartComponent implements OnInit {
  user:User

  constructor(private router: Router, private cartActions: CartService, private locations: LocationsService, private wishlist: WishlistService, private store$: Store<any>, private toastService: ToastService,private formBuilder: FormBuilder,private accountActions: AccountService, private itemDetails: ItemDetailsService, private renderer: Renderer2, private el: ElementRef, private cdr: ChangeDetectorRef) {
    this.user = this.accountActions.userValue;
    this.loginUserId = this.user?.userdata.Utype;
  }

  // items in cart
  cartDataImages: Array<object> = []
  cartDataFootages: Array<object> = []
  cartDataMusics: Array<object> = []
  itemData;
  display_image_price = 0;
  display_footage_price = 0;
  display_music_price = 0;
  // price of cart
  subTotalPrice: number = 0
  totalPrice: number = 0
  tax: number = 0
  footage_licence = '';
  cartLength = 0

  UserPaymentForm: FormGroup
  submitted = false;

  // user info
  userPaymentInfo: object = {}
  countryList: Array<object>
  stateList: Array<object>
  cityList: Array<object>
  wishlistData = [];
  image_licence_options = [];
  footage_licence_options = [];
  music_licence_options = [];
  showAddToWishListModal: boolean = false;
  currentMatchingPrices: { [key: string]: number } = {};
  public licence_options = []
  public removeTax : Boolean = false
  // tiltop message
  tiltop = {
    message: '',
    class: ''
  }
  public disabledDropCountry : Boolean =false
  public profileInfo : any =[]
  loginUserId: number = 0;


  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.cartPageLoading}))
    return
  }


  // Removing item from cart
  removeFromCart(itemId) {
    let itemIdObj = {
      "product": {
        "cart_id": itemId
      }
    }
    this.cartActions.removeFromCart(itemIdObj).subscribe(data => {
      if (data['status'] == '1') {
        this.store$.dispatch(new FeatureDecreaseCartCountAction)
        this.toastService.success(data['message'])
      } else {
        this.toastService.warning(data['message'])
      }
      this.getCartList();
    })
  }

  showModal(wishlist) {
    this.wishlistData['product_id'] = wishlist.product.product_id;
    this.wishlistData['type'] = wishlist.product.product_main_type;
    this.wishlistData['title'] = wishlist.product.product_title;
    this.wishlistData['itemPoster'] = wishlist.product.product_thumbnail;
    this.wishlistData['author_username'] = wishlist.product.product_owner;
    this.showAddToWishListModal = true;
  }

  // Adding item to wishlist
  removeToWishlist(itemId) {
    let temp = {
      'product': itemId,
      'tokenData': JSON.parse(localStorage.getItem('user_data'))
    }
    this.wishlist.addToWishlist(temp).subscribe(data => {
      switch (data.status) {
        case "1":
          this.store$.dispatch(new FeatureIncreaseWishlistCountAction())
          this.toastService.warning(data['message'])
          break;
        case "2":
          this.store$.dispatch(new FeatureDecreaseWishlistCountAction())
          this.toastService.warning(data['message'])
          break;
        default:
          break;
      }
    })
  }
  ngOnInit(): void {
    this.itemDetails.getLicenceDetails('').subscribe((data) => {
      this.licence_options = data.data
      this.licence_options = this.licence_options.filter(item =>{
        if(item.slug == 'digital' && item.product_type == 3){
        return false
      }else{
        return true
      }
    })

    });

    this.UserPaymentForm = new FormGroup({
      'first_name': new FormControl('', [Validators.required]),
      'last_name': new FormControl(''),
      'address': new FormControl('', [Validators.required]),
      'country': new FormControl('', [Validators.required]),
      'state': new FormControl('', [Validators.required]),
      'city': new FormControl('', [Validators.required]),
      'pincode': new FormControl('', [Validators.required]),
      'company_name': new FormControl(''),
      'gst': new FormControl('')
    })

    this.UserPaymentForm.get('company_name').valueChanges.subscribe((companyValue) => {
      const gstControl = this.UserPaymentForm.get('gst');

      // Clear existing validators
      gstControl.clearValidators();

      if (companyValue) {
        // If 'company' is filled, add the 'required' validator to 'country'
        gstControl.setValidators([Validators.required]);
      }

      // Update the validators and trigger a revalidation
      gstControl.updateValueAndValidity();
    });
    this.getCartList()
    this.getUserAddress()
  }

  onVersionChange(licence_name, size, current_price, is_premium,cart_id) {

    if (is_premium === "") {
      is_premium = 0;
    }
    const updateFootage = this.footage_licence || licence_name;
    const footageData = footage[updateFootage];
    current_price = current_price;

    if (footageData) {
      const selectedFootage = footageData.find((item) => {
        const isPremiumMatch = item.is_premium == is_premium;
        const isVersionMatch = item.version == size;


        if (isPremiumMatch && isVersionMatch) {
          return item;
        }
      });
      if (selectedFootage) {
        let foundObject :any ={}
        foundObject = this.cartDataFootages.find((obj : any) => obj.cart_id == cart_id)

        if(foundObject){
          foundObject.standard_price = selectedFootage.price;
          foundObject.standard_size = size
        }
        this.subTotalPrice = this.subTotalPrice + parseFloat(selectedFootage.price) - current_price;
        if(this.profileInfo.country.code == 'IN'){
          this.tax = this.subTotalPrice * GST / 100;
        }

        this.totalPrice = this.subTotalPrice + this.tax;
      }
    }
  }




  // Get user information necessary to payment
  getUserAddress() {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: true, loadingMessage: messages.cartPageLoading}))
    this.cartActions.getUserAddress(JSON.parse(localStorage.getItem('user_data'))).subscribe(data => {
      this.profileInfo = data.data
      this.UserPaymentForm.setValue({
        'first_name': data['data']['first_name'],
        'last_name': data['data']['last_name'],
        'address': data['data']['address'],
        'country': data['data']['country'] !== null ? data['data']['country']['name'] : '',
        'state': data['data']['state'] !== null ? data['data']['state']['state'] : '',
        'city': data['data']['city'] !== null ? data['data']['city']['id'] : '',
        'pincode': data['data']['postal_code'],
        'company_name': data['data']['company'],
        'gst': data['data']['gst'],
      })
      if(data['data']['country'] && data['data']['state']){
        this.getCity(data['data']['state']['id'])
        this.disabledDropCountry = true
      } else {
        this.getCountry()
        this.disabledDropCountry = false
      }

      if(this.profileInfo &&  this.profileInfo.country.code !== 'IN'){
        this.tax = 0
        this.totalPrice = this.subTotalPrice + this.tax

      }
      this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: false }))
    })
  }

  // Get user items list in cart
  getCartList() {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: true, loadingMessage: messages.cartPageLoading}))
    this.cartActions.getCartList(JSON.parse(localStorage.getItem('user_data'))).subscribe(data => {
      this.subTotalPrice = 0;
      this.cartDataImages = []
      this.cartDataFootages = []
      this.cartDataMusics = []
      for (let i = 0; i < data['length']; i++) {
        if (data[i]['product_web'] == 2) {
          this.cartDataImages.push(data[i])
        } else if (data[i]['product_web'] == 3 && data[i]['cart_product_type'] == 'footage') {
          this.cartDataFootages.push(data[i])
        } else if (data[i]['product_web'] == 4 && data[i]['cart_product_type'] == 'music') { // Music
          this.cartDataMusics.push(data[i])
        }
        this.subTotalPrice += data[i]['total']
      }

      if(this.profileInfo.length !== 0 && this.profileInfo.country.code !== 'IN'){
        this.tax = 0
      } else{
        this.tax = this.subTotalPrice * GST / 100
      }

      this.totalPrice = this.subTotalPrice + this.tax
      this.cartLength = data['length']
      this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: false }))
    })
  }

  onLicenceChange(licence, current_price, is_premium, category,cart_id,size='HD') {
    let licenceData = this.licence_options.find(value => value.id == licence)
    if (category == 'Footage') {
      this.footage_licence = licenceData.slug;
    }
  if (is_premium === "") {
    is_premium = 0;
  }

  const dataArray = this.getDataArrayByCategory(category);
  this.cdr.detectChanges();


  let lType = dataArray[licenceData.slug];
  let matchingItem:any = {}
  if(category == 'Footage'){
     matchingItem =lType.find((item) => {

      const isPremiumMatch = item.is_premium == is_premium;
      const isVersionMatch = item.version == size;


      if (isPremiumMatch && isVersionMatch) {
        return item;
      }
    });
  }else{
   matchingItem = lType.find(item => item.is_premium == is_premium);

  }


  if (matchingItem) {
    const matchingPrice = this.updateDisplayPrice(matchingItem, category,cart_id,licence,licenceData);
    this.cdr.detectChanges();

    const updatedCurrentPrice = parseFloat(current_price);
    this.subTotalPrice = (this.subTotalPrice - updatedCurrentPrice) + matchingPrice;
    this.currentMatchingPrices[category] = matchingPrice; // Store the updated matching value for the specific type
    this.cdr.detectChanges();
    this.updateTotalPrice();
    this.cdr.detectChanges();
  } else {
    console.error("Matching item not found");
  }
}


  getDataArrayByCategory(category) {
    switch (category) {
      case 'Image':
        return image;
      case 'Footage':
        return footage;
      case 'Music':
        return music;
      default:
        return [];
    }
  }

  updateDisplayPrice(matchingItem, category,cart_id,licence_id,licence_data) {
    let foundObject :any ={}
    switch (category) {
      case 'Image':
         foundObject = this.cartDataImages.find((obj : any) => obj.cart_id == cart_id)
        if(foundObject){
          foundObject.standard_price = matchingItem.price;
          foundObject.extended_name = licence_id
          foundObject.licence.description = licence_data.description
          foundObject.licence.licence_name = licence_data.licence_name
        }
        return this.display_image_price = parseFloat(matchingItem.price);
      case 'Footage':
        foundObject = this.cartDataFootages.find((obj : any) => obj.cart_id == cart_id)
        if(foundObject){
          foundObject.standard_price = matchingItem.price;
          foundObject.extended_name = licence_id
          foundObject.licence.description = licence_data.description
          foundObject.licence.licence_name = licence_data.licence_name

        }
        return this.display_footage_price = parseFloat(matchingItem.price);
      case 'Music':
        foundObject = this.cartDataMusics.find((obj : any) => obj.cart_id == cart_id)
        if(foundObject){
          foundObject.standard_price = matchingItem.price;
          foundObject.extended_name = licence_id
          foundObject.licence.description = licence_data.description
          foundObject.licence.licence_name = licence_data.licence_name

        }
        return this.display_music_price = parseFloat(matchingItem.price);
      default:
        return 0;
    }
  }

  updateTotalPrice() {
    if(this.profileInfo.country.code == 'IN'){
      this.tax = this.subTotalPrice * GST / 100;

    }
    this.totalPrice = this.subTotalPrice + this.tax;
    this.cdr.detectChanges();
  }


  // Get all countries
  getCountry() {
    this.locations.getCountryList().subscribe(data => {
      this.countryList = data
    })
  }

  // Get all regiones
  getState(userCountryId) {
    this.locations.getRegionList(userCountryId).subscribe(data => {
      this.stateList = data
    })
  }

  // Get all cities
  getCity(userStateId) {
    this.locations.getCityList(userStateId).subscribe(data => {
      this.cityList = data
    })
  }

  // Select payment method
  choosePaymentMethod(paymentMethod) {
    this.userPaymentInfo['type'] = paymentMethod
  }

  // Get redirect link to payment
  onSubmit() {
    this.submitted = true;
    let firstErrorField: ElementRef | null = null;

    Object.keys(this.UserPaymentForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.UserPaymentForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
        });
      }
    });

    if( this.UserPaymentForm.value['gst'] !='' && this.UserPaymentForm.value['company_name'].length == 0){
      this.loading(false)
      this.toastService.error(messages.cartCompanyNameValidation)
      return
    }

    if (this.UserPaymentForm.invalid) {
      for (const controlName of Object.keys(this.UserPaymentForm.controls)) {
        const control = this.UserPaymentForm.get(controlName);

        if (control && control.errors) {
          const errorField = this.el.nativeElement.querySelector(`[formControlName=${controlName}]`);


          if (!firstErrorField) {
            firstErrorField = errorField;
            control.markAsTouched();
            if(!['first_name','address'].includes(controlName)  ){
            errorField.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            errorField.focus();
          }
        }
      }
      return;
    }

    let formData = this.UserPaymentForm.value
    if(this.disabledDropCountry){
      formData['country'] = this.profileInfo.country.id
      formData['state']  = this.profileInfo.state.id
    }
    if(localStorage.getItem('user_data') !== null){
      formData['email'] = JSON.parse(localStorage.getItem('user_data'))['email']
      formData['mobile'] = this.profileInfo.mobile
      formData['company'] = this.UserPaymentForm.value['company_name']
      formData['postal_code'] = this.UserPaymentForm.value['pincode']
      formData['subTotalPrice'] = this.subTotalPrice;
      formData['tax'] = this.tax;
      formData['totalPrice'] = this.totalPrice;

    }
    this.accountActions.setUserInformation(formData, { Utype: this.loginUserId }).subscribe((data) => {
      this.loading(false);
      if (data.status) {

        localStorage.setItem('formData', JSON.stringify(formData));
        let payload = {'cartData' : [...this.cartDataImages, ...this.cartDataFootages, ...this.cartDataMusics]}
        this.cartActions.editToCart(payload).subscribe((list)=>{
          if(list.status == 'success'){
            this.router.navigate(['payment-method']);
          }
        })

      }
    })

  }

  get f() { return this.UserPaymentForm.controls; }

  /**
  * Handles events triggered from the Add To Wishlist Modal.
  * If the event indicates the need to close the modal, the flag to show the modal is set to false.
  * @param {object} event - The event object containing information about the modal action.
  */
  handleWishlistModalEvent = (event) => {
    if (event['close_modal']) {
      // Set the flag to false, indicating that the Add To Wishlist Modal should be closed
      this.showAddToWishListModal = false;
    }
  }

  /**
   * Redirect on product details page
   * @param item
   */
  openItem(type,item) {
    if(type == 'image'){
      for (let i = 0; i < this.cartDataImages.length; i++) {
        if (
          this.cartDataImages[i]['product']['product_thumbnail'] == item.product_thumbnail ||
          this.cartDataImages[i]['product']['product_main_image'] == item.product_thumbnail
        ) {
          this.router.navigate(['/image/'+this.cartDataImages[i]['product']['slug']]);
          break;
        }
      }
    }else if(type == 'footage'){
      for (let i = 0; i < this.cartDataFootages.length; i++) {
        if (
          this.cartDataFootages[i]['product']['product_thumbnail'] == item.product_thumbnail ||
          this.cartDataFootages[i]['product']['product_main_image'] == item.product_thumbnail
        ) {
          this.router.navigate(['/footage/'+this.cartDataFootages[i]['product']['slug']]);
          break;
        }
      }
    }else {
      for (let i = 0; i < this.cartDataMusics.length; i++) {
        if (
          this.cartDataMusics[i]['product']['product_thumbnail'] == item.product_thumbnail ||
          this.cartDataMusics[i]['product']['product_main_image'] == item.product_thumbnail
        ) {
          this.router.navigate(['/music/'+this.cartDataMusics[i]['product']['slug']]);
          break;
        }
      }
    }







  }

}
