import { Component, OnInit } from '@angular/core';
import { messages } from 'src/appv2/appv2.constants';

@Component({
  selector: 'appv2-pricing-planpage',
  templateUrl: './pricing-planpage.component.html',
  styleUrls: ['./pricing-planpage.component.scss']
})
export class PricingPlanpageComponent implements OnInit {

  appName: string;

  constructor() { }

  ngOnInit(): void {
    this.appName = messages.appName
  }
  openEmail(){
    const emailSubject = encodeURIComponent('Talk to sale team');
    const emailBody = encodeURIComponent('...');

    const mailtoLink = `mailto:info@imagefootage.com?subject=${emailSubject}&body=${emailBody}`;

    // Open default email client
    window.location.href = mailtoLink;
  }

}
