<div class="sign_in_sucess_reset_password_page">
    <!-- Sign in sucess reset password page image grid section start -->
    <div class="sign_in_page_image_grid">
       <div class="sign_in_page_grid">
          <div class="sign_in_page_grid_1">
             <div class="landscape_image_grid">
                <div>
                   <picture><img src="assets/images/sign_in_img_1.webp" alt="sign-in-image" width="307" height="220">
                   </picture>
                </div>
                <div>
                   <picture><img src="assets/images/sign_in_img_3.webp" alt="sign-in-image" width="307" height="220">
                   </picture>
                </div>
             </div>
             <div class="portrait_image_grid main_image">
                <picture><img src="assets/images/sign_in_img_2.webp" alt="sign-in-image" width="307" height="448">
                </picture>
             </div>
             <div class="landscape_image_grid">
                <div>
                   <picture><img src="assets/images/sign_in_img_4.webp" alt="sign-in-image" width="307" height="220">
                   </picture>
                </div>
                <div>
                   <picture><img src="assets/images/Placehoder_Gray.jpg" alt="sign-in-image" width="307" height="220">
                   </picture>
                </div>
             </div>
          </div>
          <div class="sign_in_page_grid_2">
             <div class="landscape_image_grid">
                <div class="landscape_image">
                   <picture><img src="assets/images/sign_in_img_7.webp" alt="sign-in-image" width="464" height="278">
                   </picture>
                </div>
                <div class="landscape_image">
                   <picture><img src="assets/images/sign_in_img_8.webp" alt="sign-in-image" width="464" height="278">
                   </picture>
                </div>
             </div>
          </div>
       </div>
       <div class="sign_in_form_content">
          <div class="grid_footer_logo">
             <a href="index.html"><img src="assets/images/Logo.png" alt="logo-image" width="216" height="39"></a>
          </div>
          <h2 class="h2">Download 10k+ Photos, Videos and editors choice</h2>
          <p>Access all the images, videos, music, and tools you need to turn ideas into achievements. All images
          </p>
       </div>
    </div>
    <!-- Sign in sucess reset password page image grid section end -->
    <!-- Sign in sucess reset password page form section start -->
    <div class="sign_in_sucess_reset_password_page_form" *ngIf="verify_screen_used_for != 'signup'">
       <div class="sucessfully_reset_password_section">
          <h3 class="h3">Password has been reset successfully</h3>
          <div class="sucess_image"><img src="assets/images/reset_password.svg" alt="successfully-reset-password"
                width="111" height="111"></div>
          <form action="sign-in.html">
             <button class="back_to_sign_in" routerLink="/signin" >Back to Sign In</button>
          </form>
       </div>
    </div>
    <div class="sign_in_sucess_reset_password_page_form" *ngIf="verify_screen_used_for == 'signup'">
       <div class="sucessfully_reset_password_section">
          <h3 class="h3">User activated successfully</h3>
          <div class="sucess_image"><img src="assets/images/reset_password.svg" alt="successfully-reset-password"
                width="111" height="111"></div>
          <form action="sign-in.html">
             <button class="back_to_sign_in" routerLink="/signin" >Back to Sign In</button>
          </form>
       </div>
    </div>
    <!-- Sign in sucess reset password page form section end -->
 </div>
