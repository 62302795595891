import { Action } from '@ngrx/store';
import { SearchState, SearchSubMenu } from './search.models';

let currentId = 1;

// Create varibles to detect on what stage we are in store debug.
export enum searchActionsType {
  write = '[Search] Search',
  writeUpdates = '[Search] Search Updated',
  changeSearchType= '[Search] Change Search Type',
  changeSearchName = '[Search] Change Search Name',
  changeSearchVisibility = '[Search] Change Visibility',
  searchWithEditorial = '[Search] Search wih Editorial'
}
 
// Action for handle if user change text in search menu
export class SearchWriteAction implements Action {
  readonly type = searchActionsType.write;

  constructor(public payload: SearchState) {
  }
}

export class SearchchangeSearchName implements Action {
  readonly type = searchActionsType.changeSearchName;

  constructor(public payload: SearchState) {
  }
}

export class SearchChangeVisibility implements Action {
  readonly type = searchActionsType.changeSearchVisibility;

  constructor(public payload: SearchState) {
  }
}

// Action for handle if user change search submenu
export class SearchChangeSearchType implements Action {
  readonly type = searchActionsType.changeSearchType;

  constructor(public payload: SearchState) {
  }
}

export class SearchChangeToEditorial implements Action {
  readonly type = searchActionsType.searchWithEditorial;

  constructor(public payload: SearchState) {
  }
}

// Exporting all user actions
export type SearchActions = SearchWriteAction|
SearchChangeSearchType|SearchchangeSearchName|SearchChangeVisibility|SearchChangeToEditorial ;
