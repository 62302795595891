import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild,HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { User } from 'src/appv2/models/user';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';
import { DownloadService } from 'src/appv2/services/download.service';
import { ToastService } from 'src/appv2/services/toast.service';
import { ItemDetailsService } from 'src/appv2/services/item-details.service';
import { messages } from 'src/appv2/appv2.constants';

@Component({
  selector: 'appv2-download-history',
  templateUrl: './download-history.component.html',
  styleUrls: ['./download-history.component.scss']
})
export class DownloadHistoryComponent implements OnInit {
  @ViewChild('downloadLink', { static: false }) downloadLink: ElementRef;

  downloadHistory: Array<any> = [];
  user: User;
  range: String = 'all';
  mediaType: String = 'All';
  licenceType: String = 'All';
  isActive: boolean;
  currentRow: String = '';
  expanded: { [key: string]: boolean } = {};
  totalRecords: Number
  page: number = 1
  currPage: number = 1
  prevPage: number = 1
  recordsPerPage: number
  IsDatePicker: Boolean = false;
  start: Date = new Date();
  end: Date = new Date();
  startDate: String = null;
  endDate: String = null;
  itemsPerPage = Number;
  selectedOptionLic: String = "Select Type"
  licence_options = [];
  media_type_all = true;
  mediaTypeCommonValue = '';
  selectedOptionMedia: String = "All";
  dropdownOpen = false;
  dropdownOpenMedia = false;
  dropdownOpenLic = false;
  options = ['All','Today', 'Last Week', 'Last Month', 'Custom Range'];
  media_options = ['All', 'Image', 'Footage', 'Music'];
  selectedOption: String = 'All';

  constructor(private store$: Store<any>, private accountActions: AccountService, private router: Router, private datePipe: DatePipe, private http: HttpClient, private download: DownloadService, private toastService: ToastService,
    private itemDetails:ItemDetailsService,private el: ElementRef
) {
    this.user = this.accountActions.userValue;
  }

  ngOnInit(): void {
    this.getDownloadHistory();
  }

  onRangeChange(value: any) {
    this.selectedOption =  this.range = value;
    this.start = new Date();
    this.end = new Date();

    this.dropdownOpen = false;
    if (this.range == 'Today') {
      this.range = 'today';
      this.IsDatePicker = false;
      this.getDownloadHistory();
    } else if (this.range == 'Last Week') {
      this.range = 'last_week';
      this.IsDatePicker = false;
      this.getDownloadHistory();
    } else if (this.range == 'Last Month') {
      this.range = 'last_month';
      this.IsDatePicker = false;
      this.getDownloadHistory();
    } else if (this.range == 'Custom Range') {
      this.range = 'custom';
      this.IsDatePicker = true;
    } else {
      this.range = 'all';
      this.IsDatePicker = false;
      this.getDownloadHistory();
    }
  }

  onMediaChange(value: any) {
    this.licence_options = [];
    this.mediaType = value;
    this.dropdownOpenMedia = false;
    this.dropdownOpenLic = false;
    if (this.mediaType == 'Image') {
      this.selectedOptionMedia = "Image"
      this.mediaTypeCommonValue = '1'
      this.licenceValue(this.mediaTypeCommonValue,"Select Type",false)

    } else if (this.mediaType == 'Footage') {
      this.selectedOptionMedia = "Footage"
      this.mediaTypeCommonValue = '2'
      this.licenceValue(this.mediaTypeCommonValue,"Select Type",false)

    } else if (this.mediaType == 'Music') {
      this.selectedOptionMedia = "Music"
      this.mediaTypeCommonValue = '3'
      this.licenceValue(this.mediaTypeCommonValue,"Select Type",false)

    } else {
      this.selectedOptionMedia = "All";
      this.licenceValue('',"Select Type",true)
      this.licenceType = 'All'
    }
    this.getDownloadHistory();
  }

  getDownloadHistory() {
    let payload = {};
    payload['user_id'] = this.user.userdata.Utype; // 22;
    payload['media_type'] = this.mediaType;
    payload['range'] = this.range;
    payload['start_date'] = this.startDate; //'2020-01-01';
    payload['end_date'] = this.endDate; //'2023-01-01';
    payload['license_type'] = this.licenceType;

    this.accountActions.getDownloadHistory(payload, this.currPage).subscribe((data) => {

      this.downloadHistory = data['data']
      this.itemsPerPage = data['data']['per_page']
      this.totalRecords = data['data']['total']
    });
  }

  toggleRow(rowId: any): void {
    this.currentRow = rowId;
    this.isActive = !this.isActive;
  }

  toNextPage() {
    this.currPage++
    this.getDownloadHistory()
  }
  toPrevPage() {
    this.currPage--
    this.getDownloadHistory()
  }

  getDater(data) {
    let rangeStartDate = new Date(data.startDate);
    let rangeEndDate = new Date(data.endDate);
    this.startDate = this.datePipe.transform(rangeStartDate, "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(rangeEndDate, "yyyy-MM-dd");
    this.getDownloadHistory();
  }

  downloadFile(user_id, media_id,web_type) {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true })
    );
    const payload = {
      id_media: media_id,
      user_id: user_id,
      type: web_type,
    };
    this.download.reDownloadImage(payload).subscribe((data) => {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: false })
      );
      if (data.status == "success") {
        this.toastService.success(messages.productRedownload);
        this.http.get(data.data.download_url, { responseType: 'blob' }).subscribe((blob: Blob) => {
          const blobUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blobUrl;
          a.click();

          window.URL.revokeObjectURL(blobUrl);
        });

      } else if (data.status == "pending"){
        this.toastService.warning(data.message);
      } else {
        this.toastService.warning("Something went wrong.");
      }
    });

  }

  get totalPages(): number {
    return Math.ceil(Number(this.totalRecords) / Number(this.itemsPerPage));
  }

  onPageChange(newPage: number): void {
    this.currPage = Number(newPage);
    this.itemsPerPage = this.itemsPerPage;

    if (this.currPage < 1) {
      this.currPage = 1;
    } else if (this.currPage > this.totalPages) {
      this.currPage = this.totalPages;
    }

    this.getDownloadHistory();
  }

  public licenceValue(mediaValue,selectOption,media_type){
    this.itemDetails.getLicenceDetails(mediaValue).subscribe((data) => {
      for (const license of data.data) {
        this.licence_options.push({
          id:license.id,
          slug: license.slug,
          name: license.licence_name
        });
      }
    this.selectedOptionLic = selectOption;
    this.media_type_all = media_type;
  });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.closeDropdown();
    }
  }

  closeDropdown(): void {
    this.dropdownOpen = false;
    this.dropdownOpenMedia = false;
    this.dropdownOpenLic = false;
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  toggleDropdownMedia() {
    this.dropdownOpenMedia = !this.dropdownOpenMedia;

  }

  toggleDropdownLic() {
    this.dropdownOpenLic = !this.dropdownOpenLic;
  }
  onLicenceChange(value, name) {
    this.selectedOptionLic = name;
    this.licenceType = value;
    this.dropdownOpenLic = false;
    this.getDownloadHistory();
  }

}
