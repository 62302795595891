<div class="pagination">
  <ol class="paginator-container">
    <li class="pagination-previous" *ngIf="currentPage > 1" (click)="navigateToPage(currentPage - 1)" style="font-size: 25px;">
      <i class="fas fa-angle-left"></i>
    </li>
    <li
      *ngFor="let page of pagination.pages; trackBy: trackByFn"
      class="paginator-number"
      [class.active]="page === pagination.currentPage"
      (click)="navigateToPage(page)"
    >
      {{ page }}
    </li>
    <li  (click)="navigateToPage(currentPage + 1)">...</li>
    <li class="pagination-previous"  style="font-size: 25px;">
      <i class="fas fa-angle-right" (click)="navigateToPage(currentPage + 1)"></i>
    </li>
  </ol>
</div>
