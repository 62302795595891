import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/appv2/services/account.service';

@Component({
  selector: 'appv2-success-reset-password',
  templateUrl: './success-reset-password.component.html',
  styleUrls: ['./success-reset-password.component.scss']
})
export class SuccessResetPasswordComponent implements OnInit {

  userdata: any;
  verify_screen_used_for: string;


  constructor(private accountActions: AccountService) { }

  ngOnInit(): void {
    this.userdata = this.accountActions.getRegisteredUserData();
    this.verify_screen_used_for = this.userdata.verify_screen_used_for;
  }

}
