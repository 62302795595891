<!-- content block START -->
<div class="gallery-iterator">
  <div
    *ngFor="
      let item of getImages
        | paginate
          : {
              id: 'listing_pagination',
              itemsPerPage: 30,
              currentPage: currPage,
              totalItems: totalRecords
            }
    "
  ></div>
</div>

<div class="image_grid" *ngIf="length > 0">

  <section class="gallery-section">
    <div class="gallery-iterator">
      <div *ngFor="let item of getImages">
      </div>
    </div>
    <ng-opengallery class="gallery-row"	[datasource]="homeData" [config]="config" (selection)="openItemGallery($event)"></ng-opengallery>
  </section>
  <!-- image first row start -->
  <!-- <div class="image_grid_1">
    <div class="first_image_grid">
      <div class="landscape_image_grid">
        <div class="main_image" *ngIf="getImages[4]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[4])">
          <img
            src="{{ getImages[4].product_thumbnail }}"
            alt="discover-image"
            width="453"
            height="273"
          />

          <li class="like">
            <a
              class="btn btn-primary"
              (click)="showModal(getImages[4])"
              data-toggle="modal"
              data-target="#likeModal"
              class="btn_background"
            >
              <img
                *ngIf="!userWishlistedData.includes(getImages[4].product_thumbnail)"
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                *ngIf="userWishlistedData.includes(getImages[4].product_thumbnail)"
                src="assets/images/red_heart.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </li>

          <a (click)="openItem(getImages[4])" class="image_name">{{
            getImages[4].product_title
          }}</a>
          <div class="licence_btn">
          </div>
        </div>

        <div class="main_image" *ngIf="getImages[5]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[5])">
          <img
            src="{{ getImages[5].product_thumbnail }}"
            alt="discover-image"
            width="453"
            height="273"
          />
          <li class="like">
            <a
              class="btn btn-primary"
              (click)="showModal(getImages[5])"
              data-toggle="modal"
              data-target="#likeModal"
              class="btn_background"
            >
              <img
                *ngIf="!userWishlistedData.includes(getImages[5].product_thumbnail)"
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                *ngIf="userWishlistedData.includes(getImages[5].product_thumbnail)"
                src="assets/images/red_heart.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </li>
          <a (click)="openItem(getImages[5])" class="image_name">{{
            getImages[5].product_title
          }}</a>
          <div class="licence_btn">
          </div>
        </div>
      </div>
      <div
        class="portrait_image_grid main_image"
        *ngIf="getImages[0]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[0])"
      >
        <img
          src="{{ getImages[0].product_thumbnail }}"
          alt="discover-image"
          width="453"
          height="550"
        />
        <li class="like">
          <a
            class="btn btn-primary"
            (click)="showModal(getImages[0])"
            data-toggle="modal"
            data-target="#likeModal"
            class="btn_background"
          >
            <img
              *ngIf="!userWishlistedData.includes(getImages[0].product_thumbnail)"
              src="assets/images/heart-icon.svg"
              alt="like"
              width="24"
              height="24"
              class="white_heart"
            />
            <img
              *ngIf="userWishlistedData.includes(getImages[0].product_thumbnail)"
              src="assets/images/red_heart.svg"
              alt="red_heart"
              width="24"
              height="24"
              class="red_heart"
            />
          </a>
        </li>
        <a (click)="openItem(getImages[0])" class="image_name">{{
          getImages[0].product_title
        }}</a>
        <div class="licence_btn">
        </div>
      </div>
    </div>
    <div class="first_image_grid">
      <div
        class="portrait_image_grid main_image"
        *ngIf="getImages[1]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[1])"
      >
        <img
          src="{{ getImages[1].product_thumbnail }}"
          alt="discover-image"
          width="453"
          height="550"
        />
        <li class="like">
          <a
            class="btn btn-primary"
            (click)="showModal(getImages[1])"
            data-toggle="modal"
            data-target="#likeModal"
            class="btn_background"
          >
            <img
              *ngIf="!userWishlistedData.includes(getImages[1].product_thumbnail)"
              src="assets/images/heart-icon.svg"
              alt="like"
              width="24"
              height="24"
              class="white_heart"
            />
            <img
              *ngIf="userWishlistedData.includes(getImages[1].product_thumbnail)"
              src="assets/images/red_heart.svg"
              alt="red_heart"
              width="24"
              height="24"
              class="red_heart"
            />
          </a>
        </li>
        <a (click)="openItem(getImages[1])" class="image_name">{{
          getImages[1].product_title
        }}</a>
        <div class="licence_btn">
        </div>
      </div>
      <div class="landscape_image_grid">
        <div class="main_image" *ngIf="getImages[6]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[6])">
          <img
            src="{{ getImages[6].product_thumbnail }}"
            alt="discover-image"
            width="453"
            height="273"
          />
          <li class="like">
            <a
              class="btn btn-primary"
              (click)="showModal(getImages[6])"
              data-toggle="modal"
              data-target="#likeModal"
              class="btn_background"
            >
              <img
                *ngIf="!userWishlistedData.includes(getImages[6].product_thumbnail)"
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                *ngIf="userWishlistedData.includes(getImages[6].product_thumbnail)"
                src="assets/images/red_heart.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </li>
          <a (click)="openItem(getImages[6])" class="image_name">{{
            getImages[6].product_title
          }}</a>
          <div class="licence_btn">
          </div>
        </div>
        <div class="main_image" *ngIf="getImages[7]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[7])">
          <img
            src="{{ getImages[7]?.product_thumbnail }}"
            alt="discover-image"
            width="453"
            height="273"
          />
          <li class="like">
            <a
              class="btn btn-primary"
              (click)="showModal(getImages[7])"
              data-toggle="modal"
              data-target="#likeModal"
              class="btn_background"
            >
              <img
                *ngIf="!userWishlistedData.includes(getImages[7]?.product_thumbnail)"
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                *ngIf="userWishlistedData.includes(getImages[7]?.product_thumbnail)"
                src="assets/images/red_heart.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </li>
          <a (click)="openItem(getImages[7])" class="image_name">{{
            getImages[7]?.product_title
          }}</a>
          <div class="licence_btn">
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- image first row end -->
  <!-- image second row start -->
  <!-- <div class="image_grid_1">
    <div *ngIf="getImages[8]?.product_thumbnail">
      <div class="landscape_big_image main_image" style="cursor: pointer;" (click)="openItem(getImages[8])">
        <img
          src="{{ getImages[8]?.product_thumbnail }}"
          alt="discover-image"
          width="911"
          height="550"
        />
        <li class="like">
          <a
            class="btn btn-primary"
            (click)="showModal(getImages[8])"
            data-toggle="modal"
            data-target="#likeModal"
            class="btn_background"
          >
            <img
              *ngIf="!userWishlistedData.includes(getImages[8]?.product_thumbnail)"
              src="assets/images/heart-icon.svg"
              alt="like"
              width="24"
              height="24"
              class="white_heart"
            />
            <img
              *ngIf="userWishlistedData.includes(getImages[8]?.product_thumbnail)"
              src="assets/images/red_heart.svg"
              alt="red_heart"
              width="24"
              height="24"
              class="red_heart"
            />
          </a>
        </li>
        <a (click)="openItem(getImages[8])" class="image_name">{{
          getImages[8]?.product_title
        }}</a>
        <div class="licence_btn">
        </div>
      </div>
    </div>
    <div class="first_image_grid">
      <div class="landscape_image_grid">
        <div class="main_image" *ngIf="getImages[9]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[9])">
          <img
            src="{{ getImages[9]?.product_thumbnail }}"
            alt="discover-image"
            width="453"
            height="273"
          />
          <li class="like">
            <a
              class="btn btn-primary"
              (click)="showModal(getImages[9])"
              data-toggle="modal"
              data-target="#likeModal"
              class="btn_background"
            >
              <img
                *ngIf="!userWishlistedData.includes(getImages[9]?.product_thumbnail)"
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                *ngIf="userWishlistedData.includes(getImages[9]?.product_thumbnail)"
                src="assets/images/red_heart.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </li>
          <a (click)="openItem(getImages[9])" class="image_name">{{
            getImages[8]?.product_title
          }}</a>
          <div class="licence_btn">
          </div>
        </div>
        <div class="main_image" *ngIf="getImages[10]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[10])">
          <img
            src="{{ getImages[10]?.product_thumbnail }}"
            alt="discover-image"
            width="453"
            height="273"
          />
          <li class="like">
            <a
              class="btn btn-primary"
              (click)="showModal(getImages[10])"
              data-toggle="modal"
              data-target="#likeModal"
              class="btn_background"
            >
              <img
                *ngIf="!userWishlistedData.includes(getImages[10]?.product_thumbnail)"
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                *ngIf="userWishlistedData.includes(getImages[10]?.product_thumbnail)"
                src="assets/images/red_heart.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </li>
          <a (click)="openItem(getImages[10])" class="image_name">{{
            getImages[10]?.product_title
          }}</a>
          <div class="licence_btn">
          </div>
        </div>
      </div>
      <div class="landscape_image_grid">
        <div class="main_image" *ngIf="getImages[11]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[11])">
          <img
            src="{{ getImages[11]?.product_thumbnail }}"
            alt="discover-image"
            width="453"
            height="273"
          />
          <li class="like">
            <a
              class="btn btn-primary"
              (click)="showModal(getImages[11])"
              data-toggle="modal"
              data-target="#likeModal"
              class="btn_background"
            >
              <img
                *ngIf="!userWishlistedData.includes(getImages[11]?.product_thumbnail)"
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                *ngIf="userWishlistedData.includes(getImages[11]?.product_thumbnail)"
                src="assets/images/red_heart.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </li>
          <a (click)="openItem(getImages[11])" class="image_name">{{
            getImages[11]?.product_title
          }}</a>
          <div class="licence_btn">
            <a href="javascript:void(0)">License</a>
          </div>
        </div>
        <div class="main_image" *ngIf="getImages[12]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[12])">
          <img
            src="{{ getImages[12]?.product_thumbnail }}"
            alt="discover-image"
            width="453"
            height="273"
          />
          <li class="like">
            <a
              class="btn btn-primary"
              (click)="showModal(getImages[12])"
              data-toggle="modal"
              data-target="#likeModal"
              class="btn_background"
            >
              <img
                *ngIf="!userWishlistedData.includes(getImages[12]?.product_thumbnail)"
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                *ngIf="userWishlistedData.includes(getImages[12]?.product_thumbnail)"
                src="assets/images/red_heart.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </li>
          <a (click)="openItem(getImages[12])" class="image_name">{{
            getImages[12]?.product_title
          }}</a>
          <div class="licence_btn">
            <a href="javascript:void(0)">License</a>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- image second row end -->
  <!-- image third row start -->
  <!-- <div class="image_grid_1">
    <div class="first_image_grid">
      <div class="landscape_image_grid">
        <div class="main_image" *ngIf="getImages[13]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[13])">
          <img
            src="{{ getImages[13]?.product_thumbnail }}"
            alt="discover-image"
            width="453"
            height="273"
          />
          <li class="like">
            <a
              class="btn btn-primary"
              (click)="showModal(getImages[13])"
              data-toggle="modal"
              data-target="#likeModal"
              class="btn_background"
            >
              <img
                *ngIf="!userWishlistedData.includes(getImages[13]?.product_thumbnail)"
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                *ngIf="userWishlistedData.includes(getImages[13]?.product_thumbnail)"
                src="assets/images/red_heart.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </li>
          <a (click)="openItem(getImages[13])" class="image_name">{{
            getImages[13]?.product_title
          }}</a>
          <div class="licence_btn">
          </div>
        </div>
        <div class="main_image" *ngIf="getImages[14]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[14])">
          <img
            src="{{ getImages[14]?.product_thumbnail }}"
            alt="discover-image"
            width="453"
            height="273"
          />
          <li class="like">
            <a
              class="btn btn-primary"
              (click)="showModal(getImages[14])"
              data-toggle="modal"
              data-target="#likeModal"
              class="btn_background"
            >
              <img
                *ngIf="!userWishlistedData.includes(getImages[14]?.product_thumbnail)"
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                *ngIf="userWishlistedData.includes(getImages[14]?.product_thumbnail)"
                src="assets/images/red_heart.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </li>
          <a (click)="openItem(getImages[14])" class="image_name">{{
            getImages[14]?.product_title
          }}</a>
          <div class="licence_btn">
          </div>
        </div>
      </div>
      <div class="portrait_image_grid main_image" *ngIf="getImages[2]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[2])">
        <img
          src="{{ getImages[2]?.product_thumbnail }}"
          alt="discover-image"
          width="453"
          height="550"
        />
        <li class="like">
          <a
            class="btn btn-primary"
            (click)="showModal(getImages[2])"
            data-toggle="modal"
            data-target="#likeModal"
            class="btn_background"
          >
            <img
              *ngIf="!userWishlistedData.includes(getImages[2]?.product_thumbnail)"
              src="assets/images/heart-icon.svg"
              alt="like"
              width="24"
              height="24"
              class="white_heart"
            />
            <img
              *ngIf="userWishlistedData.includes(getImages[2]?.product_thumbnail)"
              src="assets/images/red_heart.svg"
              alt="red_heart"
              width="24"
              height="24"
              class="red_heart"
            />
          </a>
        </li>
        <a (click)="openItem(getImages[2])" class="image_name">{{
          getImages[2]?.product_title
        }}</a>
        <div class="licence_btn">
        </div>
      </div>
    </div>
    <div class="first_image_grid">
      <div class="portrait_image_grid main_image" *ngIf="getImages[3]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[3])">
        <img
          src="{{ getImages[3]?.product_thumbnail }}"
          alt="discover-image"
          width="453"
          height="550"
        />
        <li class="like">
          <a
            class="btn btn-primary"
            (click)="showModal(getImages[3])"
            data-toggle="modal"
            data-target="#likeModal"
            class="btn_background"
          >
            <img
              *ngIf="!userWishlistedData.includes(getImages[3]?.product_thumbnail)"
              src="assets/images/heart-icon.svg"
              alt="like"
              width="24"
              height="24"
              class="white_heart"
            />
            <img
              *ngIf="userWishlistedData.includes(getImages[3]?.product_thumbnail)"
              src="assets/images/red_heart.svg"
              alt="red_heart"
              width="24"
              height="24"
              class="red_heart"
            />
          </a>
        </li>
        <a (click)="openItem(getImages[3])" class="image_name">{{
          getImages[3]?.product_title
        }}</a>
        <div class="licence_btn">
        </div>
      </div>
      <div class="landscape_image_grid">
        <div class="main_image" *ngIf="getImages[15]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[15])">
          <img
            src="{{ getImages[15]?.product_thumbnail }}"
            alt="discover-image"
            width="453"
            height="273"
          />
          <li class="like">
            <a
              class="btn btn-primary"
              (click)="showModal(getImages[15])"
              data-toggle="modal"
              data-target="#likeModal"
              class="btn_background"
            >
              <img
                *ngIf="!userWishlistedData.includes(getImages[15]?.product_thumbnail)"
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                *ngIf="userWishlistedData.includes(getImages[15]?.product_thumbnail)"
                src="assets/images/red_heart.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </li>
          <a (click)="openItem(getImages[15])" class="image_name">{{
            getImages[15]?.product_title
          }}</a>
          <div class="licence_btn">
          </div>
        </div>
        <div class="main_image" *ngIf="getImages[16]?.product_thumbnail" style="cursor: pointer;" (click)="openItem(getImages[16])">
          <img
            src="{{ getImages[16]?.product_thumbnail }}"
            alt="discover-image"
            width="453"
            height="273"
          />
          <li class="like">
            <a
              class="btn btn-primary"
              (click)="showModal(getImages[16])"
              data-toggle="modal"
              data-target="#likeModal"
              class="btn_background"
            >
              <img
                *ngIf="!userWishlistedData.includes(getImages[16]?.product_thumbnail)"
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
                *ngIf="userWishlistedData.includes(getImages[16]?.product_thumbnail)"
                src="assets/images/red_heart.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </li>
          <a (click)="openItem(getImages[16])" class="image_name">{{
            getImages[16]?.product_title
          }}</a>
          <div class="licence_btn">
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- image third row end -->
</div>
<!-- Add To Wishlist Modal -->
<add-to-wishlist
  [productData]="wishlistData"
  (dataEvent)="handleWishlistModalEvent($event)"
  *ngIf="showAddToWishListModal"
>
</add-to-wishlist>
<!-- ./Add To Wishlist Modal -->
<br><br><br>


