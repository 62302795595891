import { Component, OnInit,ElementRef } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { FeatureWriteCartAction, FeatureWriteWishlistAction } from 'src/appv2/reducers/features/features.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { CartService } from 'src/appv2/services/cart.service';
import { LocationsService } from 'src/appv2/services/locations.service';
import { ToastService } from 'src/appv2/services/toast.service';
import {FeaturesState} from 'src/appv2/reducers/features/features.models';
import { GST, messages } from 'src/appv2/appv2.constants';

declare var Razorpay: any;
@Component({
  selector: 'appv2-payment-method-page',
  templateUrl: './payment-method-page.component.html',
  styleUrls: ['./payment-method-page.component.scss']
})
export class PaymentMethodPageComponent implements OnInit {

  constructor(private cartActions: CartService, private locations: LocationsService, private store$: Store<any>, private toastService: ToastService,private el: ElementRef) { }

  subTotalPrice: number = 0
  tax: number = 0
  totalPrice: number = 0
  cartLength: number = 0

  // items in cart
  cartDataImages: Array<object> = []
  cartDataFootages: Array<object> = []

  // user info
  userPaymentInfo: object = {}
  countryList: Array<object>
  stateList: Array<object>
  cityList: Array<object>

  UserPaymentForm: FormGroup
  submitted: boolean = false;
  public profileInfo : any =[]

  ngOnInit(): void {
    this.UserPaymentForm = new FormGroup({
      'first_name': new FormControl('', [Validators.required]),
      'last_name': new FormControl(''),
      'address': new FormControl('', [Validators.required]),
      'country': new FormControl('', [Validators.required]),
      'state': new FormControl('', [Validators.required]),
      'city': new FormControl('', [Validators.required]),
      'pincode': new FormControl('', [Validators.required]),
      'company_name': new FormControl('', [Validators.required]),
      'gst': new FormControl('', [Validators.required])
    })
    this.UserPaymentForm.get('company_name').valueChanges.subscribe((companyValue) => {
      const gstControl = this.UserPaymentForm.get('gst');

      // Clear existing validators
      gstControl.clearValidators();

      if (companyValue) {
        // If 'company' is filled, add the 'required' validator to 'country'
        gstControl.setValidators([Validators.required]);
      }

      // Update the validators and trigger a revalidation
      gstControl.updateValueAndValidity();
    });

    this.getCartList()

    this.getUserAddress();
  }

  // Get user items list in cart
  getCartList() {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: true, loadingMessage: messages.cartListLoading}))
    this.cartActions.getCartList(JSON.parse(localStorage.getItem('user_data'))).subscribe(data => {

      this.cartDataImages = []
      this.cartDataFootages = []
      for (let i = 0; i < data['length']; i++) {
        if (data[i]['product_web'] == 2) {
          this.cartDataImages.push(data[i])
        } else if (data[i]['product_web'] == 3) {
          this.cartDataFootages.push(data[i])
        }
      }

        for (let i = 0; i < data['length']; i++) {
          this.subTotalPrice += data[i]['total']
        }
        if(this.profileInfo.length !== 0 && this.profileInfo.country.code !== 'IN'){
          this.tax = 0
        } else{
          this.tax = this.subTotalPrice * GST / 100
        }

        this.totalPrice = this.subTotalPrice + this.tax


      this.cartLength = data['length']

      this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: false }))
    })
  }

  // Get user information necessary to payment
  getUserAddress() {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: true, loadingMessage: messages.userAddressLoading}))

    this.cartActions.getUserAddress(JSON.parse(localStorage.getItem('user_data'))).subscribe(data => {
      this.profileInfo = data.data
      let formData:any = {}

           this.UserPaymentForm.setValue({
        'first_name': data['data']['first_name'],
        'last_name': data['data']['last_name'],
        'address': data['data']['address'],
        'country': data['data']['country'] !== null ? data['data']['country']['id'] : '',
        'state': data['data']['state'] !== null ? data['data']['state']['id'] : '',
        'city': data['data']['city'] !== null ? data['data']['city']['id'] : '',
        'pincode': data['data']['postal_code'],
        'company_name': data['data']['company'],
        'gst': data['data']['gst'],
      })


      if(this.profileInfo &&  this.profileInfo.country.code !== 'IN'){
        this.tax = 0
        this.totalPrice = this.subTotalPrice + this.tax

      }



      this.getCountry()
      this.getState(data['data']['country']['id'])
      this.getCity(data['data']['state']['id'])
      this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: false }))
    })
  }

  // Get all countries
  getCountry() {
    this.locations.getCountryList().subscribe(data => {
      this.countryList = data
    })
  }

  // Get all State
  getState(userCountryId) {
    this.locations.getRegionList(userCountryId).subscribe(data => {
      this.stateList = data
    })
  }

  // Get all cities
  getCity(userStateId) {
    this.locations.getCityList(userStateId).subscribe(data => {
      this.cityList = data
    })
  }

  onSubmit() {
    this.submitted = true;
    let firstErrorField: ElementRef | null = null;
    if( this.UserPaymentForm.value['gst'] !='' && this.UserPaymentForm.value['company_name'].length == 0){
      this.toastService.error(messages.cartCompanyNameValidation)
      return
    }

    if (this.UserPaymentForm.invalid) {
      for (const controlName of Object.keys(this.UserPaymentForm.controls)) {
        const control = this.UserPaymentForm.get(controlName);
        if (control && control.errors) {
          const errorField = this.el.nativeElement.querySelector(`[formControlName=${controlName}]`);
          if (!firstErrorField) {
            firstErrorField = errorField;
            control.markAsTouched();
            if(!['first_name','address'].includes(controlName)  ){
              errorField.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            errorField.focus();
          }
        }
      }
      return;
    }

    // payment
    this.userPaymentInfo['type'] = 'rozerpay';
    this.userPaymentInfo['usrData'] = this.UserPaymentForm.value
    this.userPaymentInfo['tokenData'] = JSON.parse(localStorage.getItem('user_data'))
    this.userPaymentInfo['cartval'] = [this.subTotalPrice]
    this.cartActions.getPaymentLink(this.userPaymentInfo).subscribe(data => {
      if (this.userPaymentInfo['type'] == 'rozerpay') {
        let options = data
        options.handler = this.razorpayRes.bind(this)
        // Boolean whether to show image inside a white frame. (default: true)
        options.theme.image_padding = false;
        options.modal = {
          ondismiss: function () {
          },
          // Boolean indicating whether pressing escape key
          // should close the checkout form. (default: true)
          escape: true,
          // Boolean indicating whether clicking translucent blank
          // space outside checkout form should close the form. (default: false)
          backdropclose: false
        };

        var rzp = new Razorpay(options);
        rzp.open();
        event.preventDefault();
      } else {
        window.location.href = data['url']
      }
    })
  }

  razorpayRes(payres) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: true, loadingMessage: messages.razorpayLoading}))
    this.cartActions.getRazorResponse(payres, this.userPaymentInfo['tokenData'])
      .subscribe(data => {
        this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: false }))
        if (data.url) {
          this.toastService.success(messages.orderPlaced)
          this.store$.dispatch(new FeatureWriteCartAction(<FeaturesState> {cartCount: 0}))
          const urlWithQueryParam = `${data.url}?tab=history`;
          // const urlWithQueryParam =  "http://localhost:4200/#/profile?tab=history";
          window.location.href = urlWithQueryParam;
        } else {
          this.toastService.warning("Something went wrong!")
        }
      });
  }

  get f() { return this.UserPaymentForm.controls; }

}
