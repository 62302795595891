<section class="my_plan_table">
    <div class="container">
        <div class="table_heading">
            <div class="cart-title-row">
                <div class="cart-col-left">
                    <h1 class="h1">Added Wishlist Own User Account</h1>
                </div>
            </div>
        </div>
        <div class="overlay"></div>
        <!-- popup start -->
        <div class="download_popup">
            <div class="download_popup_inner">
                <div class="wishlist_image_section">
                    <div class="wishlist_image_heading">
                        <h3 class="h4">Do you want to add wishlist folder is your own account ?
                        </h3>
                        <ul>
                            <li><button class="thame-btn cancel-btn" (click)="onCancel()">Cancel</button></li>
                            <li><a class="thame-btn" (click)="addedUserAccount()">Accept</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- popup end -->
    </div>
</section>

