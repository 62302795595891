<div id="tab-3">
    <div class="download-history-heading">
        <h2 class="h2">Download History</h2>
    </div>
    <div class="download-history-dropdown">
      <div class="time-range">
        <div class="cart-col-right">
          <div class="select-lice-title">Time Range:</div>
          <div class="select-lice">
            <div class="select">
              <select class="custom-select-dropdown">
                <option value="all">All</option>
                <option value="today">Today</option>
                <option value="last_week">Last Week</option>
                <option value="last_month">Last Month</option>
                <option value="custom">Custom Range</option>
              </select>
              <div class="custom-select" (click)="toggleDropdown()">{{ selectedOption }}</div>
              <ul class="select-options" [class.open]="dropdownOpen">
                <li *ngFor="let option of options" (click)="onRangeChange(option)">{{ option }}</li>
              </ul>
            </div>
          </div>
        </div>
        <link href="https://cdn.syncfusion.com/ej2/material.css" rel="stylesheet" />
        <div [style.display]="IsDatePicker ? 'block' : 'none'">
          <ejs-daterangepicker id='daterangepicker' placeholder='Select a range'
            (change)="getDater($event)"></ejs-daterangepicker>
        </div>
      </div>
      <div class="media-licence-type">
        <div class="media-type cart-col-right">
          <div class="select-lice-title">Media Type:</div>
          <div class="select-lice">
            <div class="select">
              <select class="custom-select-dropdown">
                <option value="All">All</option>
                <option value="Image">Image</option>
                <option value="Footage">Footage</option>
                <option value="Music">Music</option>
              </select>
              <div class="custom-select" (click)="toggleDropdownMedia()">{{ selectedOptionMedia }}</div>
              <ul class="select-options" [class.open]="dropdownOpenMedia">
                <li *ngFor="let moption of media_options" (click)="onMediaChange(moption)">{{ moption }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="licence-type cart-col-right">
          <div class="select-lice-title">Licence Type:</div>
          <div class="select-lice" [class.disabled]="media_type_all == true">
            <div class="select">
              <select class="custom-select-dropdown">
                <option value="" disabled>Select Type</option>
                <option [value]="loption.slug" *ngFor="let loption of licence_options">{{ loption.name }}</option>
              </select>
              <div class="custom-select" (click)="toggleDropdownLic()">{{ selectedOptionLic }}</div>
              <ul class="select-options" [class.open]="dropdownOpenLic">
                <li *ngFor="let loption of licence_options" (click)="onLicenceChange(loption.id,loption.name)">{{
                  loption.name }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="download-history-table">
        <table>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Product Type</th>
                    <th>Product Number</th>
                    <th>Product</th>
                    <th>Licence</th>
                    <th>Product version</th>
                    <th>Download</th>
                </tr>
            </thead>
            <tbody *ngFor="let download of downloadHistory['data']; let rowId = index" class="header-level"
                (click)="toggleRow(download.id)">
                <tr>
                    <td>{{ download.created_at | date: 'd MMMM yyyy'}}</td>
                    <td>{{ download.product.product_main_type }}</td>
                    <td>{{ download.product_id }}</td>
                    <td class="thumbnail">
                        <img [src]="download.product.product_thumbnail" alt="Product Thumbnail" width="50"
                            height="50" />
                    </td>
                    <td>{{download.licence?.licence_name}} Licence</td>
                    <td>{{download.product.product_main_type == 'Image'? 'XL': download.product.product_main_type == 'Music' ? 'music': download.product.product_main_type == 'Footage'? download?.product_size: ''}}</td>
                    <td class="invoice_icon">
                        <div class="invoice_icon_image">
                            <button (click)="downloadFile(download.user_id,download.id_media,download.web_type)"><img
                                    src="assets/images/download_plan.svg" alt="download" width="24" height="24" />
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody>
              <tr class="header-level" *ngIf="totalRecords == 0">
                <td colspan="6" style="text-align: center;">No downloads product found!</td>
              </tr>
            </tbody>
        </table>
        <ng-container *ngIf="totalRecords > 0">
        <appv2-pagination [itemsPerPage]="itemsPerPage" [currentPage]="currPage" [totalPages]="totalPages"
                        (pageChanged)="onPageChange($event)"></appv2-pagination>
      </ng-container>
    </div>
</div>
