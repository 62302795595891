import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { messages } from 'src/appv2/appv2.constants';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';
import { ToastService } from 'src/appv2/services/toast.service';
import { environment } from 'src/environments/environment';

const phoneEmailRegex = /^(\d{10}|[a-zA-Z0-9_+]+([\.-]?[a-zA-Z0-9_+]+)*@[a-zA-Z0-9_+]+([\.-]?[a-zA-Z0-9_+]+)*(\.[a-zA-Z0-9_+]\w{2,3}))$/;

@Component({
  selector: 'appv2-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  passwordShow: boolean;
  auth2: any;
  appName: string;
  adminSettings: any;

  @ViewChild('loginRef', { static: false }) loginElement: ElementRef;

  constructor(private store$: Store<any>, private accountActions: AccountService, private router: Router, private route: ActivatedRoute, private toastService: ToastService,private cdr: ChangeDetectorRef ) { }

  ngOnInit() {
    this.getSignInSettings();

    this.loginForm = new FormGroup({
      'password': new FormControl('', [Validators.required]),
      'email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(phoneEmailRegex),
      ])),
      'remember_me': new FormControl('')
    });
    this.googleAuthSDK();
    let check_remeber = localStorage.getItem('remember_me');
    if (check_remeber) {
      this.loginForm.patchValue({
        email: localStorage.getItem('username'),
        password: localStorage.getItem('password'),
        remember_me: localStorage.getItem('remember_me')
      });
    }
    this.appName = messages.appName
  } 

  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.signInLoading }))
    return
  }

  public onSubmit() {
    this.submitted = true;
    this.authenticate(this.loginForm.value);
  }

  get f() { return this.loginForm.controls; }


  public hideShowPassword() {
    this.passwordShow = !this.passwordShow;
  }

  public authenticate(formdata) {

    if (this.loginForm.invalid) {
      for (const control of Object.keys(this.loginForm.controls)) {
        this.loginForm.controls[control].markAsTouched();
      }
      return;
    }

    if (formdata.remember_me) {
      localStorage.setItem('username', formdata.email);
      localStorage.setItem('password', formdata.password);
      localStorage.setItem('remember_me', formdata.remember_me);
    }

    this.loading(true);
    this.accountActions.getLoginToken(formdata.email, formdata.password).pipe(first())
      .subscribe({
        next: (data) => {
          this.loading(false);
          if (data.status) {
            // get return url from query parameters or default to home page
            const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
            this.toastService.success(messages.loginSuccess)
            this.router.navigateByUrl(returnUrl);
          } else {
            this.toastService.warning(data.message)
          }
        },
        error: error => {
          this.loading(false);
          this.toastService.error(error)
        }
      });

  }

  googleAuthSDK() {
    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          client_id: environment.googleClientId,
          cookiepolicy: 'single_host_origin',
          scope: 'profile email',
          plugin_name: 'PLUGIN'
        });
        this.callLoginButton();
      });
    }

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));

  }

  callLoginButton() {
    this.auth2.attachClickHandler(this.loginElement?.nativeElement, {},
      (googleAuthUser) => {
        let profile = googleAuthUser.getBasicProfile();
        let payload = {};
        payload['provider'] = 'google';
        payload['email'] = profile.getEmail();
        payload['name'] = profile.getName();
        payload['token'] = googleAuthUser.getAuthResponse().id_token;
        payload['idToken'] = profile.getId();
        payload['image'] = profile.getImageUrl();

        this.accountActions.googleSocialLogin(payload).pipe(first())
          .subscribe({
            next: (data) => {
              this.loading(false);
              if (data.status) {
                // get return url from query parameters or default to home page
                const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                this.toastService.success(messages.loginSuccess)
                this.router.navigateByUrl(returnUrl);
              } else {
                this.toastService.warning(data.message)
              }
            },
            error: error => {
              this.loading(false);
              this.toastService.error(error)
            }
          });

      }, (error) => {
      });

  }

  fbLogin() {
    this.accountActions.fbInItlogin();
  }

  getSignInSettings() {
    this.accountActions.getSignInSettings().subscribe(
      (response: any) => {
        this.adminSettings = response.data;
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
