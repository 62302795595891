import { Component, OnInit } from '@angular/core';
import $ from 'jquery';
import { CacheService } from 'src/appv2/services/cache.service';
import { CmsPageService } from 'src/appv2/services/cms-page.service';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { urlToCacheKeyMapping } from 'src/appv2/utils/constants/discount.const';

@Component({
  selector: 'appv2-licence-agreement',
  templateUrl: './licence-agreement.component.html',
  styleUrls: ['./licence-agreement.component.scss'],
})
export class LicenceAgreementComponent implements OnInit {
  constructor(
    private cmsPage: CmsPageService,
    private landingPage: LandingPageService,
    private cacheService: CacheService
  ) {
    this.licenceAgreementCacheKey =
      urlToCacheKeyMapping[this.licenceAgreementCacheKeyName];
    if (this.licenceAgreementCacheKey) {
      const loadData = this.cacheService.getData(this.licenceAgreementCacheKey);
      if (typeof loadData !== 'undefined') {
        this.pageData = loadData;
      } else {
        this.getCmsPage(this.page);
      }
    }
  }

  page: string = 'licence-agreement';

  pageData: any;
  licenceAgreementCacheKeyName: string = 'cms-page-licence-agreement';
  licenceAgreementCacheKey: string;

  ngOnInit(): void {
    $('html, body').animate({ scrollTop: '0' }, 800);
  }

  getCmsPage(page) {
    this.cmsPage.getpagelist(page).subscribe((data) => {
      this.pageData = data.data;
      this.cacheService.setData(this.licenceAgreementCacheKey, this.pageData);
      this.landingPage.isCmsPageLicenceAgreementCalled.next(true);
    });
  }
}
