import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SafeRequestService } from './safe-request.service';

@Injectable({
  providedIn: 'root'
})
export class CmsPageService { 

  constructor(private safeRequest : SafeRequestService) { }

  getpagelist(page): Observable<any> {
    const url = 'getCustomPage/' + page
    return this.safeRequest.sendRequest('post', url)
  }
}
