<!-- banner section start -->
<section class="banner-sec" *ngIf="promotion">
  <picture>
    <source class="banner-img dec-img" srcset="{{ promotion?.desktop_banner_image }}" alt="Images" width="1920"
      height="524" />
    <source class="banner-img dec-img" srcset="{{ promotion?.desktop_banner_image }}" alt="Images" width="1920"
      height="524" />
    <img class="banner-img dec-img" src="{{ promotion?.desktop_banner_image }}" alt="Images" width="1920"
      height="524" />

    <source class="banner-img mob-img" srcset="{{ promotion?.mobile_banner_image }}" alt="Images" width="575"
      height="380" />
    <source class="banner-img mob-img" srcset="{{ promotion?.mobile_banner_image }}" alt="Images" width="575"
      height="380" />
    <img class="banner-img mob-img" src="{{ promotion?.mobile_banner_image }}" alt="Images" width="575" height="380" />
  </picture>
  <div class="container">
    <div class="banner-content">
      <h1 class="h1">{{ promotion?.event_name }}</h1>
      <p>{{ promotion?.event_des }}</p>
      <!-- <appv2-search-bar class="categories-seach-block-banner" [params]="{isHeaderPart: false}"></appv2-search-bar> -->
    </div>
  </div>
</section>
<!-- banner section end -->
<!-- Information Line section start -->
<section class="information_line bg-color" *ngIf="discountDetails">
  <div class="container" *ngIf="discountDetails?.status == 1">
    <div class="information_line_inner">
      <p>{{ discountDetails?.title }}</p>
      <a target="_blank" href="{{ discountDetails?.link }}">{{
        discountDetails?.button_text
        }}</a>
    </div>
  </div>
</section>
<!-- Information Line section end -->
<!-- Popular Categories section start -->
<appv2-category-data></appv2-category-data>



<appv2-questions></appv2-questions>
