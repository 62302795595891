import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectorRef, DoCheck } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/appv2/models/user';
import { FeatureWriteCartAction, FeatureWriteWishlistAction } from 'src/appv2/reducers/features/features.actions';
import { FeaturesState } from 'src/appv2/reducers/features/features.models';
import { selectCartCount, selectWishlistCount } from 'src/appv2/reducers/features/features.selectors';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { selectCurrentRoute } from 'src/appv2/reducers/router/router.selector';
import { SearchChangeVisibility, SearchWriteAction, SearchchangeSearchName } from 'src/appv2/reducers/search/search.actions';
import { SearchState } from 'src/appv2/reducers/search/search.models';
import { selectSearchVisibility } from 'src/appv2/reducers/search/search.selectors';
import { AccountService } from 'src/appv2/services/account.service';
import { CartService } from 'src/appv2/services/cart.service';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { WishlistService } from 'src/appv2/services/wishlist.service';
import $ from 'jquery';
import { urlToCacheKeyMapping } from 'src/appv2/utils/constants/discount.const';
import { CacheService } from 'src/appv2/services/cache.service';
import { ToastService } from 'src/appv2/services/toast.service';
import { messages } from 'src/appv2/appv2.constants';

@Component({
  selector: 'appv2-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, DoCheck {

  loggedinuser: User;
  world = 'My world';
  isActive = false;
  isMenu = true;
  withCategories: boolean

  pageName = ''
  searchType: number = 0
  prevSearchType: number = 0

  user: string = null
  userName: string
  cartCount: number
  wishlistCount: number

  destroy = new Subject();

  destroy$ = this.destroy.asObservable();

  isSearchVisible: boolean
  isSearchPage: boolean

  isUserLogined = false
  visibleTrendingSearch: boolean;
  showTrendSerach: boolean = false
  showMusicTrendSerach: boolean = false;

  recentSearches = []
  mainPageType: string = "image";
  searchtext: any;
  elasticSuggestions: any;
  musicSuggestions: any;
  trendingKeyword: any;
  page_type: string;
  imageQueryParams: any = "";
  footageQueryParams: any = "";
  hideSearchInPages: any = ['','home','image','images','footage','footages', 'editorial', 'editorials', 'music', 'musics'];
  categories = []
  categoriesCacheKeyName: string = 'home-page-categories';
  categoriesCacheKey: string;
  currentUrl: string;

  /**
   * Open class of header component.
   */
  openClass: string = '';
  timeout: any = null;
  showHeaderInSearch: boolean = true;
  toggleMobileClass: boolean = false;

  constructor(private landingPage: LandingPageService, private router: Router, private accountActions: AccountService, private store$: Store<any>, private route: ActivatedRoute, private http: HttpClient, private cart: CartService, private wishlist: WishlistService, private cacheService: CacheService, private cdr: ChangeDetectorRef, private toastService: ToastService) {
    this.loggedinuser = this.accountActions.userValue;
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {
        let url = event.url.split('/')[1];
        if (url && url.includes('?')) {
          let onlySlug = url.split('?');
          url = onlySlug[0];
        }
        this.page_type = url;
      }

      if($.inArray(this.page_type, this.hideSearchInPages) > -1) {
        this.showHeaderInSearch = false;
      } else {
        this.showHeaderInSearch = true;
      }

      if (this.page_type === "home" || this.page_type === "pricing" || !$.inArray(this.mainPageType, ['image', 'footage', 'editorial', 'music'])) {
        this.mainPageType = 'image';
      } else {
        this.mainPageType = this.page_type
      }
      // Get Categories Details
      this.categoriesCacheKey = urlToCacheKeyMapping[this.categoriesCacheKeyName];
      if (this.categoriesCacheKey) {
        const categories = this.cacheService.getData(this.categoriesCacheKey);
        if (typeof categories !== 'undefined') {
          this.categories = categories;
        } else {
          this.getCategories();
        }
      }

    })
  }

  ngOnInit(): void {
    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$)).subscribe(
      (e: Event) => {
        this.store$.dispatch(new SearchChangeVisibility(<SearchState>{ searchVisibile: window.pageYOffset >= 800 }))
      }
    )
    this.user = localStorage.getItem('user_data')

    this.store$.pipe(select(selectSearchVisibility)).subscribe(data => {
      this.isSearchVisible = data
    })
    this.store$.pipe(select(selectCurrentRoute)).subscribe(data => {
      if (data != undefined) {
        document.querySelector('.navbar')?.classList.remove('show')
        this.isSearchPage = false
        switch (data.routeConfig.path) {
          case '':
            this.pageName = 'home'
            break;

          case 'search':
            this.pageName = data.queryParams.type
            this.isSearchPage = true
            break;
          case 'image':
            this.pageName = data.routeConfig.path
            this.isActive = false
            this.isMenu = true
            break;
          case 'footage':
            this.pageName = data.routeConfig.path
            this.isActive = false
            this.isMenu = true
            break;
          case 'editorial':
            this.pageName = data.routeConfig.path
            this.isActive = false
            this.isMenu = true
            break;
          case 'item':
            this.isSearchPage = true
            this.pageName = (<string>data.queryParams.itemMainType).toLowerCase()
            break;

          default:
            this.pageName = 'nonMain'
            this.isActive = false
            this.isMenu = true
            break;
        }
      }
      window.scrollTo(0, 0)
    })
    if (this.user != null) {
      this.getCartAndWishlistInfo(this.user)
    }
    this.store$.pipe(select(selectCartCount)).subscribe(
      (vl) => {
        if (vl != undefined) {
          this.cartCount = vl
        }
      }
    )
    this.store$.pipe(select(selectWishlistCount)).subscribe(
      (vl) => {
        if (vl != undefined) {
          this.wishlistCount = vl
        }
      }
    )
    this.currentUrl = this.router.url
  }

  ngDoCheck() {
    this.cdr.detectChanges();
    this.categories = this.landingPage.getCategoriesSlider()
    this.currentUrl = this.router.url
  }

  getCategories() {
    this.landingPage.getTagList().subscribe(data => {
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          this.categories.push(data[i][j]);
        }
      }
    });
    this.cacheService.setData(this.categoriesCacheKey, this.categories);
    this.landingPage.isHomeCategoriesCalled.next(true);
  }

  logout() {
    this.toastService.success(messages.logout)
    this.accountActions.logout();
    this.store$.dispatch(new FeatureWriteCartAction(<FeaturesState> {cartCount: 0}))

  }

  /**
  * Redirect to the home page.
  */
  redirectToHomePage(): void {
    this.router.navigate(['/home']);
  }

  // Get if user cart and wishlist not empty
  getCartAndWishlistInfo(userToken) {
    this.isUserLogined = true
    this.cart.getCartList(userToken).subscribe(data => {
      this.store$.dispatch(new FeatureWriteCartAction(<FeaturesState>{ cartCount: data['length'] }))
    })
    this.wishlist.getWishlist(userToken).subscribe(data => {
      if (data['data'] && data['data']['wishlists']) {
        this.accountActions.updateUserWishlist(data['data']['wishlists']);
        if (data['data'].length != undefined) {
          this.store$.dispatch(new FeatureWriteWishlistAction(<FeaturesState>{ wishlistCount: data['data'].length }))
        } else {
          this.store$.dispatch(new FeatureWriteWishlistAction(<FeaturesState>{ wishlistCount: 0 }))
        }
      }
    })
  }

  onEnterSearch() {
    // save to local storage
    let recentSearchData = JSON.parse(localStorage.getItem('recent_search'));
    this.recentSearches = recentSearchData
    if (this.searchtext) {
      if (!recentSearchData) {
        recentSearchData = [];
      }
      if (recentSearchData.length > 4) {
        recentSearchData.shift();
      }
      if (this.searchtext && this.searchtext.trim().length > 1) {
        recentSearchData.push(this.searchtext.trim());
      }
      localStorage.setItem('recent_search', JSON.stringify(recentSearchData));
      if (this.page_type == 'image' || this.mainPageType == "image") {
        const queryParams = { search: this.searchtext };

        const navigationExtras: NavigationExtras = {
          queryParams: queryParams
        };
        this.router.navigate(['/images'], navigationExtras);
        // this.router.navigate(['/images'], { state: navigationData });
      } else {
        this.openCategory(this.searchtext);
      }
    }
  }

  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.headerLoading  }))
    return
  }

  /**
   * Store keyword in elastic search API
   * @param keyword
   */
  storeKeywordInElasticSearch(keyword) {
    const bodyParams = {
      search_field: keyword
    };
    // this.loading(true);
    this.landingPage.storeKeywordInElasticSearch(bodyParams).subscribe(data => {
      this.loading(false);
    });
  }

  /**
     * Determines whether key press on
     * @param event
     */
  onKeyPress(event: any) {
    clearTimeout(this.timeout);
    this.openClass = '';
    this.showMusicTrendSerach = false;
    this.timeout = setTimeout(() => {
      this.searchtext = event.target.value;
      if (this.searchtext && this.searchtext.trim().length != 0) {
        this.getSuggestionByElasticSearch(this.searchtext);
        this.showTrendSerach = true;
        this.visibleTrendingSearch = false;
      } else {
        this.showTrendSerach = false;
        this.visibleTrendingSearch = true;
      }
    }, 1000);
    if (event.keyCode == 13) {
      if(this.searchtext?.length > 1) {
        this.showTrendSerach = false;
        this.storeKeywordInElasticSearch(this.searchtext);
        this.onEnterSearch();
      }
    }

  }

  /**
   * Gets suggestion keyword list by elastic search
   * @param searchText
   */
  getSuggestionByElasticSearch(searchText) {
    this.searchtext = searchText;
    if (this.mainPageType == 'music') {
      this.landingPage.getSuggestionByMusicSearch(this.searchtext).subscribe(data => {
        if (data) {
          this.musicSuggestions = data.data
          this.showTrendSerach = false;
          this.showMusicTrendSerach = true;
        }
      })
    } else {
      this.landingPage.getSuggestionByElasticSearch(this.searchtext).subscribe(data => {
        if (data) {
          this.elasticSuggestions = data.data
        }
      })
    }
  }



  public onInput(event: any) {
    const inputValue = event.target.value;
    // Define a regular expression to match letters, numbers, and spaces
    const regex = /^[a-zA-Z0-9\s]*$/;

    if (!regex.test(inputValue)) {
      // If the input contains characters other than letters, numbers, and spaces,
      // remove those characters from the input value
      event.target.value = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
    }
  }



  openCategory(catName) {
    this.searchtext = catName;
    this.searchEditorial(this.mainPageType);
  }

  onMouseInSearchBox() {
    this.visibleTrendingSearch = true
  }

  onMouseOutSearchBox() {
    this.visibleTrendingSearch = false
    this.showTrendSerach = false;
  }

  toggleActiveClass() {
    this.isActive = !this.isActive;
    this.isMenu = !this.isMenu;
  }

  searchEditorial(page) {
    this.mainPageType = page;

    if(this.searchtext?.length > 1) {
      if (this.searchtext && page == "image") {
        this.imageQueryParams = { search: this.searchtext };
        const navigationExtras: NavigationExtras = {
          queryParams: this.imageQueryParams
        };
        if (this.page_type == "images") {
          this.store$.dispatch(new SearchWriteAction(<SearchState>{ searchText: 'categories ' + this.searchtext }));
        } else {
          this.router.navigate(['/images'], navigationExtras);
        }
      } else if (this.searchtext && page == "footage") {
        this.footageQueryParams = { search: this.searchtext };
        const navigationExtras: NavigationExtras = {
          queryParams: this.footageQueryParams
        };
        if (this.page_type == "footages") {
          this.store$.dispatch(new SearchWriteAction(<SearchState>{ searchText: 'categories ' + this.searchtext }));
        } else {
          this.router.navigate(['/footages'], navigationExtras);
        }
      }
      else if (this.searchtext && page == "editorial") {
        const editorialQueryParams = { search: this.searchtext };
        const navigationExtras: NavigationExtras = {
          queryParams: editorialQueryParams
        };
        if (this.page_type == "editorials") {
          this.store$.dispatch(new SearchWriteAction(<SearchState>{ searchText: 'categories ' + this.searchtext }));
        } else {
          this.router.navigate(['/editorials'], navigationExtras);
        }
      }
      else {
        this.store$.dispatch(new SearchchangeSearchName(<SearchState>{ searchType: page }));
      }
    }
  }
  navigateToHistoryTab() {
    this.router.navigate(['/profile'], { queryParams: { tab: 'history' } });
  }

  clickNavUserIcon(){
    this.toggleMobileClass = !this.toggleMobileClass;
  }

}
