<section class="main-content-sec">
  <div class="container">
    <div class="custom-row">
      <appv2-filter class="filter-block-left" (dataEmitted)="getFilterData($event)" [fromCategory] ="fromCategory"></appv2-filter>
      <div class="content-block-right">
        <div class="banner-form-sec">
          <appv2-search-bar class="categories-seach-block-banner" [params]="{isHeaderPart: false}"></appv2-search-bar>
          <div class="cart-title-row">
            <div class="cart-col-left">
              <h1 class="h2">Image</h1>
              <span>{{receivedProductCount}} Results</span>
            </div>
            <div class="cart-col-right">
              <div class="select-lice">
                <div class="select">
                  <select [(ngModel)]="selectedOption">

                    <option>Popular</option>
                    <option>Recent</option>
                  </select>
                  <div class="custom-select" (click)="toggleDropdown()">{{ selectedOption }}</div>
                  <ul class="select-options" [class.open]="dropdownOpen">
                    <li *ngFor="let option of options" (click)="selectOption(option)">{{ option }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="reset-filter-row">
            <ul>
              <li *ngFor="let filter of processedFilters">
                <ng-container *ngIf="filter.valuekey">
                  {{ filter.namekey }}:
                  <ng-container *ngFor="let value of filter.valuekey.split(','); let index=index">
                    {{ value }}
                    <span (click)="removeSpecificFilter(filter.key,index)"></span>
                  </ng-container>
                </ng-container>
              </li>
            </ul>
            <button class="thame-btn" (click)="resetFilter()" *ngIf="showReset">
              Reset Filter
            </button>
          </div>
        </div>
        <!-- image list section start -->
        <div class="image-list-sec">
          <!-- layout of image grid position start -->
          <div class="discover_image">
            <appv2-category-listing (dataEvent)="receiveDataFromChild($event)" [itemDataFromSorting] = "itemDataFromSorting" [recordsFromSorting]="recordsFromSorting" [totalFromSorting]="totalFromSorting" [processedFilters]="processedFilters" [productType] = "productType" [selectedOption] = "selectedOption"></appv2-category-listing>
          </div>
        </div>
        <!-- image list section end -->
      </div>
    </div>
  </div>
</section>
