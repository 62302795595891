<section class="main-content-sec">
    <div class="container">
        <div class="custom-row">
            <div class="filter-block-left">
                <h2 class="h4">Filters</h2>
                <div class="still_have_questions_accordian">
                    <div class="accordian_border">
                        <button class="course-accordion">Pricing</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <div class="price-range-slider">
                                    <div id="slider-range" class="range-bar"></div>
                                    <div class="range-value">
                                        <div class="input-main">
                                            <span> From</span>
                                            <input type="text" id="amount" readonly>
                                        </div>
                                        <div class="input-main">
                                            <span> To</span>
                                            <input type="text" id="amount1" readonly>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">Duration</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <div class="price-range-slider">
                                    <div id="slider-range-v1" class="range-bar"></div>
                                    <div class="range-value">
                                        <div class="input-main">
                                            <span> From</span>
                                            <input type="text" id="amount-v1" readonly>
                                        </div>
                                        <div class="input-main">
                                            <span> To</span>
                                            <input type="text" id="amount1-v1" readonly>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">Tempo</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="custom-checkbox-list">
                                    <li>
                                        <input class="custom-checkbox" id="tempo-1" type="checkbox" value="value1">
                                        <label for="tempo-1">0-70 Slow</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="tempo-2" type="checkbox" value="value2">
                                        <label for="tempo-2">80-129 Medium</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="tempo-3" type="checkbox" value="value3">
                                        <label for="tempo-3">130+ Fast</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">Mood</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="custom-checkbox-list">
                                    <li>
                                        <input class="custom-checkbox" id="mood-1" type="checkbox" value="value1">
                                        <label for="mood-1">Action</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="mood-2" type="checkbox" value="value2">
                                        <label for="mood-2">Aggressive</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="mood-3" type="checkbox" value="value3">
                                        <label for="mood-3">Cinematic</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="mood-4" type="checkbox" value="value4">
                                        <label for="mood-4">Chill</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="mood-5" type="checkbox" value="value5">
                                        <label for="mood-5">Dark</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="mood-6" type="checkbox" value="value6">
                                        <label for="mood-6">Funny</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="mood-7" type="checkbox" value="value7">
                                        <label for="mood-7">Happy</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="mood-8" type="checkbox" value="value8">
                                        <label for="mood-8">Inspirational</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="mood-9" type="checkbox" value="value9">
                                        <label for="mood-9">Romantic</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="mood-10" type="checkbox" value="value10">
                                        <label for="mood-10">Sad</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="mood-11" type="checkbox" value="value11">
                                        <label for="mood-11">Suspense</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">Genre</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="custom-checkbox-list">
                                    <li>
                                        <input class="custom-checkbox" id="genre-1" type="checkbox" value="value1">
                                        <label for="genre-1">Ambient</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="genre-2" type="checkbox" value="value2">
                                        <label for="genre-2">Orchestral</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="genre-3" type="checkbox" value="value3">
                                        <label for="genre-3">Dance / EDM</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="genre-4" type="checkbox" value="value4">
                                        <label for="genre-4">Chillout</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="genre-5" type="checkbox" value="value5">
                                        <label for="genre-5">Folk</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="genre-6" type="checkbox" value="value6">
                                        <label for="genre-6">Hip Hop/ Rap</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="genre-7" type="checkbox" value="value7">
                                        <label for="genre-7">Holiday</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="genre-8" type="checkbox" value="value8">
                                        <label for="genre-8">Jazz</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="genre-9" type="checkbox" value="value9">
                                        <label for="genre-9">Kids</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="genre-10" type="checkbox" value="value10">
                                        <label for="genre-10">Corporate</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="genre-11" type="checkbox" value="value11">
                                        <label for="genre-11">Video Game</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="genre-12" type="checkbox" value="value12">
                                        <label for="genre-12">Rock</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="genre-13" type="checkbox" value="value13">
                                        <label for="genre-13">Reggae</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="genre-14" type="checkbox" value="value14">
                                        <label for="genre-14">World</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">Artist</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="author-list">
                                    <li><a href="#">Albert Flores</a><span>(12)</span></li>
                                    <li><a href="#">Annette Black</a><span>(02)</span></li>
                                    <li><a href="#">Arlene McCoy</a><span>(16)</span></li>
                                    <li><a href="#">Bessie Cooper</a><span>(100)</span></li>
                                    <li><a href="#">Brooklyn Simmons</a><span>(32)</span></li>
                                    <li><a href="#">Cameron Williamson</a><span>(23)</span></li>
                                    <li><a href="#">Cody Fisher</a><span>(67)</span></li>
                                    <li><a href="#">Courtney Henry</a><span>(190)</span></li>
                                    <li><a href="#">Darlene Robertson</a><span>(122)</span></li>
                                    <li><a href="#">Darrell Steward</a><span>(09)</span></li>
                                    <li><a href="#">Devon Lane</a><span>(07)</span></li>
                                    <li><a href="#">Dianne Russell</a><span>(31)</span></li>
                                    <li><a href="#">Eleanor Pena</a><span>(24)</span></li>
                                    <li><a href="#">Esther Howard</a><span>(04)</span></li>
                                    <li><a href="#">Floyd Miles</a><span>(89)</span></li>
                                    <li><a href="#">Guy Hawkins</a><span>(90)</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">Format</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="custom-checkbox-list">
                                    <li>
                                        <input class="custom-checkbox" id="format-1" type="checkbox" value="value1">
                                        <label for="format-1">M4A</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="format-2" type="checkbox" value="value2">
                                        <label for="format-2">WAV</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="format-3" type="checkbox" value="value3">
                                        <label for="format-3">FLAC</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="format-4" type="checkbox" value="value4">
                                        <label for="format-4">MP3</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="format-5 " type="checkbox" value="value5  ">
                                        <label for="format-5    ">MP4</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="accordian_border">
                    <button class="course-accordion">Usage</button>
                    <div class="course-panel">
                       <div class="accordian-inner">
                          <ul class="custom-checkbox-list">
                             <li>
                                <input class="custom-checkbox" id="usage-1" type="checkbox" value="value1">
                                <label for="usage-1">Editorial</label>
                             </li>
                             <li>
                                <input class="custom-checkbox" id="usage-2" type="checkbox" value="value2">
                                <label for="usage-2">Commercial</label>
                             </li>
                          </ul>
                       </div>
                    </div>
                 </div> -->
                    <div class="accordian_border">
                        <button class="course-accordion">Additional Filters</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="custom-checkbox-list">
                                    <li>
                                        <input class="custom-checkbox" id="add-filter-1" type="checkbox" value="value1">
                                        <label for="add-filter-1">PremiumBeat</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="add-filter-2" type="checkbox" value="value2">
                                        <label for="add-filter-2">Non P.R.O</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="add-filter-3" type="checkbox" value="value3">
                                        <label for="add-filter-3">Cleared For Sampling</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="add-filter-4" type="checkbox" value="value4">
                                        <label for="add-filter-4">Free</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="add-filter-5" type="checkbox" value="value5">
                                        <label for="add-filter-5">Public Domain</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-block-right">
                <div class="banner-form-sec">
                    <div class="categories-seach-block-banner">
                        <div class="search-filter-form-main-banner">
                            <form class="search-filter-form">
                                <div class="custom-select-wrapper">
                                    <div class="custom-select">
                                        <div class="custom-select__trigger"><span><img src="assets/images/music-icon.svg"
                                      alt="Images" width="16" height="16" /> Music</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="search-input-block" id="search-input-wrapper">
                                    <button class="btn-search"><img src="assets/images/search-icon.svg" alt="search icon"
                                width="13" height="13" /></button>
                                    <input class="input-search" type="text" name="searchtext" placeholder="Search for images, footages etc..." autocomplete="off" >
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="cart-title-row">
                        <div class="cart-col-left">
                            <h1 class="h2">Music</h1>
                            <span>435,459 Results</span>
                        </div>
                        <div class="cart-col-right">
                            <div class="select-lice">
                                <select>
                          <option>Best Match</option>
                          <option>Popular</option>
                          <option>Recent</option>
                          <option>Price: Low to High</option>
                          <option>Price: High to Low</option>
                          <option>Price: High to Low</option>
                          <option>Duration: Long to Short</option>
                       </select>
                            </div>
                        </div>
                    </div>
                    <div class="reset-filter-row">
                        <ul>
                            <li>Price: ₹1000 - ₹4000 <span></span></li>
                            <li>Duration: 03:00 - 05:43 <span></span></li>
                            <li>Tempo: 0 to 70 slow <span></span></li>
                        </ul>
                        <button class="thame-btn">Reset Filter</button>
                    </div>
                </div>
                <!-- music table start -->
                <div class="music_table">
                    <table>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Type</th>
                                <th>Duration</th>
                                <th>BPM</th>
                                <th>Format</th>
                                <th>Price</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Grandious Hip Hop Electronica Action Futuristic Tech Energy Power</span>
                                </td>
                                <td>Inspire, Happy, Dreamy</td>
                                <td>06:00</td>
                                <td>180</td>
                                <td>MP3</td>
                                <td>₹3000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div> 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Driving Action Rock (15 Sec)</span>
                                </td>
                                <td>Action</td>
                                <td>00:15</td>
                                <td>320</td>
                                <td>MP4</td>
                                <td>₹8000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Sounds Of Saudi Arabia - 60S</span>
                                </td>
                                <td>WSM Music</td>
                                <td>01:43</td>
                                <td>180</td>
                                <td>WAV</td>
                                <td>₹1000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Tech House vibes</span>
                                </td>
                                <td>Electro, Mellow</td>
                                <td>2:07</td>
                                <td>180</td>
                                <td>MP3</td>
                                <td>₹10000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Sun and His Daughter</span>
                                </td>
                                <td>Drama, Experimetal</td>
                                <td>02:10</td>
                                <td>180</td>
                                <td>WAV</td>
                                <td>₹23000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Hip Hop 02</span>
                                </td>
                                <td>Sad, Mellow</td>
                                <td>01:55</td>
                                <td>180</td>
                                <td>MP4</td>
                                <td>₹2300</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Grandious Hip Hop Electronica Action Futuristic Tech Energy Power</span>
                                </td>
                                <td>Inspire, Happy, Dreamy</td>
                                <td>06:00</td>
                                <td>180</td>
                                <td>MP3</td>
                                <td>₹3000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Driving Action Rock (15 Sec)</span>
                                </td>
                                <td>Action</td>
                                <td>00:15</td>
                                <td>320</td>
                                <td>MP4</td>
                                <td>₹8000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Sounds Of Saudi Arabia - 60S</span>
                                </td>
                                <td>WSM Music</td>
                                <td>01:43</td>
                                <td>180</td>
                                <td>WAV</td>
                                <td>₹1000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Grandious Hip Hop Electronica Action Futuristic Tech Energy Power</span>
                                </td>
                                <td>Inspire, Happy, Dreamy</td>
                                <td>06:00</td>
                                <td>180</td>
                                <td>MP3</td>
                                <td>₹3000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Driving Action Rock (15 Sec)</span>
                                </td>
                                <td>Action</td>
                                <td>00:15</td>
                                <td>320</td>
                                <td>MP4</td>
                                <td>₹8000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Sounds Of Saudi Arabia - 60S</span>
                                </td>
                                <td>WSM Music</td>
                                <td>01:43</td>
                                <td>180</td>
                                <td>WAV</td>
                                <td>₹1000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Tech House vibes</span>
                                </td>
                                <td>Electro, Mellow</td>
                                <td>2:07</td>
                                <td>180</td>
                                <td>MP3</td>
                                <td>₹10000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Sun and His Daughter</span>
                                </td>
                                <td>Drama, Experimetal</td>
                                <td>02:10</td>
                                <td>180</td>
                                <td>WAV</td>
                                <td>₹23000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Hip Hop 02</span>
                                </td>
                                <td>Sad, Mellow</td>
                                <td>01:55</td>
                                <td>180</td>
                                <td>MP4</td>
                                <td>₹2300</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Grandious Hip Hop Electronica Action Futuristic Tech Energy Power</span>
                                </td>
                                <td>Inspire, Happy, Dreamy</td>
                                <td>06:00</td>
                                <td>180</td>
                                <td>MP3</td>
                                <td>₹3000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Driving Action Rock (15 Sec)</span>
                                </td>
                                <td>Action</td>
                                <td>00:15</td>
                                <td>320</td>
                                <td>MP4</td>
                                <td>₹8000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Sounds Of Saudi Arabia - 60S</span>
                                </td>
                                <td>WSM Music</td>
                                <td>01:43</td>
                                <td>180</td>
                                <td>WAV</td>
                                <td>₹1000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Grandious Hip Hop Electronica Action Futuristic Tech Energy Power</span>
                                </td>
                                <td>Inspire, Happy, Dreamy</td>
                                <td>06:00</td>
                                <td>180</td>
                                <td>MP3</td>
                                <td>₹3000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Driving Action Rock (15 Sec)</span>
                                </td>
                                <td>Action</td>
                                <td>00:15</td>
                                <td>320</td>
                                <td>MP4</td>
                                <td>₹8000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="javascript:void(0)" class="play_btn"><img src="assets/images/play.svg" alt="play-button" width="30" height="30"></a>
                                    <span>Sounds Of Saudi Arabia - 60S</span>
                                </td>
                                <td>WSM Music</td>
                                <td>01:43</td>
                                <td>180</td>
                                <td>WAV</td>
                                <td>₹1000</td>
                                <td class="music_icon">
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/music_heart.svg" alt="like" width="24" height="24" class="music_heart">
                                            <img src="assets/images/black_heart.svg" alt="like" width="24" height="24" class="black_heart">
                                        </a>
                                    </div>
                                    <div class="music_icon_image">
                                        <a href="javascript:void(0)"><img src="assets/images/shopping_cart.svg" alt="like" width="24" height="24"></a>
                                        
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="pagination">
                    <div>Items per Page</div>
                    <div><input type="number" value="20"></div>
                    <div>Page</div>
                    <div><input type="number"></div>
                    <div>of 454</div>
                </div>
                <!-- music table end -->
            </div>
        </div>
    </div>
</section>
