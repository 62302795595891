<!-- payment method section start -->
<section class="payment-method-sec">
    <div class="container">
        <div class="cart-title-row">
            <div class="cart-col-left">
                <h1 class="h1">Select Payment Method</h1>
                <ul class="breadcrumb">
                    <li><a href="index.html">Home</a></li>
                    <li><a href="#">Product</a></li>
                    <li><a routerLink="/cart">My Cart</a></li>
                    <li><span>Card Details</span></li>
                </ul>
            </div>
            <div class="cart-col-right">
                <a (click)="onSubmit()" class="thame-btn">Complete Checkout</a>
            </div>
        </div>
        <div class="cart-dateils-row">
            <div class="cart-dateils-left">
                <div class="pay-useing-card">
                    <!-- <h2 class="h4">Pay Using Card</h2> -->
                    <form action="#">
                        <!-- <div class="form_group error_field">
                            <label for="name">Name On Card *</label>
                            <input class="form-control" type="text" placeholder="Enter your name">
                            <p class="error">* Please enter valid name</p>
                        </div>
                        <div class="form_group">
                            <label for="password">Credit Card Number *</label>
                            <div class="cart-row">
                                <input class="form-control" type="tel:" placeholder="0000 1234 3432 8890">
                                <ul class="cart-list">
                                    <li><img src="assets/images/american-express-icon.svg" alt="paypal cart" width="45" height="20" /></li>
                                    <li><img src="assets/images/rupay-icon.svg" alt="paypal cart" width="45" height="20" />
                                    </li>
                                    <li><img src="assets/images/mastercard-icon.svg" alt="paypal cart" width="45" height="20" /></li>
                                    <li><img src="assets/images/visa-inc-icon.svg" alt="paypal cart" width="45" height="20" />
                                    </li>
                                </ul>
                            </div>
                            <p class="error">* Please enter valid card number</p>
                        </div>
                        <div class="form_group">
                            <label for="password">Expiration Date*</label>
                            <input class="form-control" type="tel:" placeholder="MM/YY">
                            <p class="error"> * Incorrect expiry date</p>
                        </div>
                        <div class="form_group">
                            <label for="password">CVC*</label>
                            <div class="cart-row">
                                <input class="form-control" type="password" placeholder="123">
                                <ul class="cart-list">
                                    <li><img src="assets/images/cvc-icon.svg" alt="paypal cart" width="45" height="20" /></li>
                                </ul>
                            </div>
                            <p class="error">* CVC Number not valid</p>
                        </div> -->
                        <ul class="custom-radio">
                            <!-- <li>
                                <input type="radio" id="featured-1" name="featured" checked>
                                <label for="featured-1">Pay Using Paypal <img src="assets/images/paypal-icon.svg"
                             alt="Paypal img" width="30" height="20/"></label>
                            </li> -->
                            <li>
                                <input type="radio" id="featured-2" name="featured" checked>
                                <label for="featured-2">Pay Using Razorpay <img src="assets/images/razorpay-icon.svg"
                             alt="razorpay img" width="30" height="20/"></label>
                            </li>
                        </ul>
                        <!-- <ul class="custom-checkbox-list">
                            <li>
                                <input class="custom-checkbox" id="tempo-1" type="checkbox" value="value1">
                                <label for="tempo-1">I’ve promo code</label>
                            </li>
                        </ul> -->
                    </form>
                </div>
                <ul class="price-table">
                    <li><span>Subtotal</span><span class="price-right">₹{{ subTotalPrice }}</span></li>
                    <li><span>Tax</span> <span class="price-right">₹{{ tax }}</span></li>
                    <li><span>Grand Total</span> <span class="price-totle">₹{{ totalPrice }}</span></li>
                </ul>
            </div>
            <div class="cart-dateils-right">
                <div class="billing-info-form">
                    <div class="billing-info-heading">
                        <h2 class="h2">Enter your billing information</h2>
                    </div>
                    <form [formGroup]="UserPaymentForm">
                        <div
                        class="form_group"
                        [ngClass]="{ error_field: submitted && f.first_name.errors }"
                        >
                            <label for="name">Name</label>
                            <input class="form-control" type="text" placeholder="Enter your name" formControlName="first_name">
                            <div
                                *ngIf="submitted && f.first_name.errors"
                                class="invalid-feedback"
                            >
                            <p *ngIf="f.first_name.errors.required" class="error">
                                * Please enter valid name
                            </p>
                            </div>
                        </div>
                        <div
                        class="form_group"
                        [ngClass]="{ error_field: submitted && f.address.errors }"
                        >
                            <label for="address">Address</label>
                            <input class="form-control" type="text" placeholder="Enter your address" formControlName="address">
                            <div
                                *ngIf="submitted && f.address.errors"
                                class="invalid-feedback"
                            >
                            <p *ngIf="f.address.errors.required" class="error">
                                * Please enter valid address
                            </p>
                            </div>
                        </div>
                        <div
                            class="form_group"
                            [ngClass]="{ error_field: submitted && f.country.errors }"
                        >
                            <label for="country">Country</label>
                            <div class="custom-select-main select-show">

                            <select class="form-control" formControlName="country" (change)="getState($event.target.value)"
                               required disabled>
                              <option value="" disabled>Select Country</option>
                              <option *ngFor="let country of countryList" value="{{ country.id }}">
                                {{ country.name }}
                              </option>
                            </select>
                            </div>
                            <div
                                *ngIf="submitted && f.country.errors"
                                class="invalid-feedback"
                            >
                            <p *ngIf="f.country.errors.required" class="error">
                                * Please Select Country
                            </p>
                            </div>
                        </div>
                        <div
                            class="form_group"
                            [ngClass]="{ error_field: submitted && f.state.errors }"
                        >
                            <label for="state">State</label>
                            <div class="custom-select-main">
                                <select class="form-control" formControlName="state" (change)="getCity($event.target.value)" disabled>
                                    <option>Select State</option>
                                    <option
                                        *ngFor="let region of stateList"
                                        value="{{ region.id }}"
                                    >
                                        {{ region.state }}
                                    </option>
                                </select>
                            </div>
                            <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                <p *ngIf="f.state.errors.required" class="error">
                                  * Please Select State
                                </p>
                            </div>
                        </div>
                        <div
                            class="form_group"
                            [ngClass]="{ error_field: submitted && f.city.errors }"
                        >
                            <label for="city">City</label>
                            <div class="custom-select-main">
                                <select class="form-control" formControlName="city" disabled>
                                    <option disabled>Select City</option>
                                    <option *ngFor="let city of cityList" value="{{ city.id }}">
                                        {{ city.name }}
                                    </option>
                                </select>
                            </div>
                            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                <p *ngIf="f.city.errors.required" class="error">
                                  * Please Select City
                                </p>
                            </div>
                        </div>
                        <div
                            class="form_group"
                            [ngClass]="{ error_field: submitted && f.pincode.errors }"
                        >
                            <label for="password">Postal Code</label>
                            <input class="form-control" type="number" placeholder="Enter Postal Code" formControlName="pincode">
                            <div
                                *ngIf="submitted && f.pincode.errors"
                                class="invalid-feedback"
                            >
                                <p *ngIf="f.pincode.errors.required" class="error">
                                * Please enter Postal Code
                                </p>
                            </div>
                        </div>
                        <div
                            class="form_group"
                            [ngClass]="{ error_field: submitted && f.company_name.errors }"
                        >
                            <label for="password">Company Name</label>
                            <input class="form-control" type="tel:" placeholder="Company Name" formControlName="company_name">
                            <div
                                *ngIf="submitted && f.company_name.errors"
                                class="invalid-feedback"
                            >
                                <p *ngIf="f.company_name.errors.required" class="error">
                                * Please enter company came
                                </p>
                            </div>
                        </div>
                        <div
                            class="form_group"
                            [ngClass]="{ error_field: submitted && f.gst.errors }"
                        >
                            <label for="number">Company GST Number</label>
                            <input class="form-control" type="text" placeholder="Company GST Number" formControlName="gst">
                            <div
                                *ngIf="submitted && f.gst.errors"
                                class="invalid-feedback"
                            >
                                <p *ngIf="f.gst.errors.required" class="error">
                                * Please enter company GST number
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
