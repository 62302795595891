import { Action } from '@ngrx/store';
import { LoadingState } from './loading.models';


// Create varibles to detect on what stage we are in store debug.
export enum loadingActionsType {
  loadingUpdated = '[loadingUpdated] LoadingUpdated'
}
 
// Action for handle if user change text in search menu
export class loadingChangeAction implements Action {
  readonly type = loadingActionsType.loadingUpdated;

  constructor(public payload: LoadingState) {
  }
}


// Exporting all user actions
export type LoadingActions = loadingChangeAction
