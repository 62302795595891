import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/appv2/services/toast.service';
import { messages } from 'src/appv2/appv2.constants';

const phoneEmailRegex = /^(\d{10}|[a-zA-Z0-9_+]+([\.-]?[a-zA-Z0-9_+]+)*@[a-zA-Z0-9_+]+([\.-]?[a-zA-Z0-9_+]+)*(\.[a-zA-Z0-9_+]\w{2,3}))$/;
@Component({
  selector: 'appv2-acknowledge-verify-email',
  templateUrl: './acknowledge-verify-email.component.html',
  styleUrls: ['./acknowledge-verify-email.component.scss']
})
export class AcknowledgeVerifyEmailComponent implements OnInit {

  loginForm: FormGroup;
  token: string;
  message: any;
  is_verified: boolean;
  submitted:Boolean = false

  constructor(private store$: Store<any>, private accountActions: AccountService, private router: Router, private route: ActivatedRoute,private toastService: ToastService) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      'email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(phoneEmailRegex),
      ])),
    });
    this.route.pathFromRoot[1].url.subscribe(val => this.token = val[1].path);
    this.VerifyEmailLink()
  }

  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.acknowledgeEmailLoading}))
    return
  }

  public VerifyEmailLink() {
    let payload = {};
    this.loading(true);
    this.accountActions.verifyEmailLink(this.token).subscribe((data) => {
      this.loading(false)
      if (data.status) {
        this.is_verified = true
      } else {

        this.is_verified = false
      }
      this.message = data.message
    });
  }
  public onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      for (const control of Object.keys(this.loginForm.controls)) {
        this.loginForm.controls[control].markAsTouched();
      }
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Regular expression for phone number validation
  const phoneRegex = /^\d{10}$/;
  if (emailRegex.test(this.loginForm.value['email'])) {
    this.resendEmailLink(this.loginForm.value)
  } else if (phoneRegex.test(this.loginForm.value['email'])) {
    this.resendOtp(this.loginForm.value)
  } else {
    this.toastService.warning(messages.verifyEmailValidation)
  }
  }
  get f() { return this.loginForm.controls; }

  public resendEmailLink(formData) {

    let payload = {};
    payload['user_id'] = '';
    payload['email'] = formData.email;
    this.loading(true);
    this.accountActions.resendEmailLink(payload).subscribe((data) => {
      this.loading(false)
      if (data.status) {
        this.toastService.success(data['message'])
      } else {
        this.toastService.warning(data['message'])
      }
    });
  }

  public resendOtp(formData) {
    let payload = {};
    payload['user_id'] = '';
    payload['mobile'] = formData.email;
    this.loading(true);
    this.accountActions.resendOtp(payload).subscribe((data) => {
      this.loading(false)
      if (data.status) {
        this.toastService.success(data['message'])
      } else {
        this.toastService.warning(data['message'])
      }
    });
  }

}
