import { Injectable } from '@angular/core';
import { SafeRequestService } from './safe-request.service';


@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private safeRequest: SafeRequestService) {}

  downloadItem(item) {
    const url = 'downloadindi';
    const body = item;
    return this.safeRequest.sendRequest('post', url, body);
  }
  downloadWithSubscriptionPlan(itemInfo) {
    const url = 'download';
    const body = itemInfo;
    return this.safeRequest.sendRequest('post', url, body);
  }

  downloadMultipleProducts(itemInfo) {
    const url = 'multi-download';
    const body = itemInfo;
    return this.safeRequest.sendRequest('post', url, body);
  }

  reDownloadImage(itemInfo) {
    const url = 're-download';
    const body = itemInfo;
    return this.safeRequest.sendRequest('post', url, body);
  }
}
