  <!-- My Plan Table Section start -->
  <section class="my_plan_table">
    <div class="container">
        <div class="table_heading">
            <div class="cart-title-row">
                <div class="cart-col-left">
                    <h1 class="h1">My Plan</h1>
                </div>
                <div class="cart-col-right">
                    <div class="select-lice-title">Time Range:</div>
                    <div class="select-lice">
                        <div class="select">
                            <select class="custom-select-dropdown">
                                <option value="all">All</option>
                                <option value="today">Today</option>
                                <option value="last_week">Last Week</option>
                                <option value="last_month">Last Month</option>
                                <option value="custom">Custom Range</option>
                            </select>
                            <div class="custom-select" (click)="toggleDropdown()">{{ selectedOption }}</div>
                            <ul class="select-options" [class.open]="dropdownOpen">
                                <li *ngFor="let option of options" (click)="onRangeChange(option)">{{ option }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="dropdown-and-date-picker" [style.display]="IsDatePicker ? 'block' : 'none'"></div>
                <link href="https://cdn.syncfusion.com/ej2/material.css" rel="stylesheet" />
                <div [style.display]="IsDatePicker ? 'block' : 'none'">
                    <ejs-daterangepicker id='daterangepicker' placeholder='Select a range' (change)="getDater($event)"></ejs-daterangepicker>
                </div>
            </div>
        </div>
        <div class="plan_table">
            <table>
                <thead>
                    <tr>
                        <th>Sn</th>
                        <th>Plan Name</th>
                        <th>Plan Price</th>
                        <th>Plan Count</th>
                        <th>Downloaded Count</th>
                        <th>Licence Name</th>
                        <th>Transaction ID</th>
                        <th>Plan Created Date</th>
                        <th>Plan Expired Date</th>
                        <th>Invoice</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="userPlans?.length == 0">
                        <td colspan="9">No plans found!</td>
                    </tr>
                    <tr *ngFor="let plan of userPlans; let i = index">
                        <td>{{i + 1}}</td>
                        <td>#{{userPlans[i].package_name}}<br><span *ngIf="userPlans[i].status === 1" class="active">Active</span>
                        <span *ngIf="userPlans[i].status === 0" class="inactive">Inactive</span>
                        &nbsp;<span *ngIf="userPlans[i].order_type === 3" class="carryforward">Carry Forward</span>
                        </td>
                        <td>{{userPlans[i].package_price}}</td>
                        <td>{{userPlans[i].package_products_count}}</td>
                        <td>{{userPlans[i].downloaded_product}}</td>
                        <td>{{userPlans[i]?.licence?.licence_name}}</td>
                        <td>{{userPlans[i].transaction_id}}</td>
                        <td>{{userPlans[i].updated_at}}</td>
                        <td>{{userPlans[i].package_expiry_date_from_purchage}}</td>
                        <td>
                            <button *ngIf="userPlans[i].order_type != 3" (click)="downloadFile(userPlans[i].invoice,userPlans[i].package_name)"><img
                                    src="assets/images/download_plan.svg" alt="download" width="24" height="24" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <ng-container *ngIf="totalRecords > 0">
                <appv2-pagination [itemsPerPage]="itemsPerPage" [currentPage]="currPage" [totalPages]="totalPages"
                                (pageChanged)="onPageChange($event)"></appv2-pagination>
              </ng-container>
        </div>
    </div>
</section>

