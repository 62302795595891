<section class="main-content-sec">
    <div class="container">
        <div class="custom-row">
            <div class="filter-block-left">
                <h2 class="h4">Filters</h2>
                <div class="still_have_questions_accordian">
                    <div class="accordian_border">
                        <button class="course-accordion">Pricing</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <div class="price-range-slider">
                                    <div id="slider-range" class="range-bar"></div>
                                    <div class="range-value">
                                        <div class="input-main">
                                            <span> From</span>
                                            <input type="text" id="amount" readonly>
                                        </div>
                                        <div class="input-main">
                                            <span> To</span>
                                            <input type="text" id="amount1" readonly>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">Color</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <p>License.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">Orientation</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="custom-checkbox-list">
                                    <li>
                                        <input class="custom-checkbox" id="orientation-1" type="checkbox" value="value1">
                                        <label for="orientation-1">Landscape</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="orientation-2" type="checkbox" value="value2">
                                        <label for="orientation-2">Vertical</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="orientation-3" type="checkbox" value="value3">
                                        <label for="orientation-3">Square</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="orientation-4" type="checkbox" value="value4">
                                        <label for="orientation-4">Panoramic Horizontal</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="orientation-5" type="checkbox" value="value5">
                                        <label for="orientation-5">Panoramic Vertical</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">Resolution</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="custom-checkbox-list">
                                    <li>
                                        <input class="custom-checkbox" id="resolution-1" type="checkbox" value="value1">
                                        <label for="resolution-1">141 x 94</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="resolution-2" type="checkbox" value="value2">
                                        <label for="resolution-2">148 x 98</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="resolution-3" type="checkbox" value="value3">
                                        <label for="resolution-3">297 x 198</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="resolution-4" type="checkbox" value="value4">
                                        <label for="resolution-4">360 x 240</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">People</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="custom-radio">
                                    <li>
                                        <input type="radio" id="featured-1" name="featured" checked>
                                        <label for="featured-1">With People</label>
                                    </li>
                                    <li>
                                        <input type="radio" id="featured-2" name="featured">
                                        <label for="featured-2">Without People</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">Ethnicity</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="custom-checkbox-list">
                                    <li>
                                        <input class="custom-checkbox" id="ethnicity-1" type="checkbox" value="value1">
                                        <label for="ethnicity-1">African</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="ethnicity-2" type="checkbox" value="value2">
                                        <label for="ethnicity-2">African American</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="ethnicity-3" type="checkbox" value="value3">
                                        <label for="ethnicity-3">Black</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="ethnicity-4" type="checkbox" value="value4">
                                        <label for="ethnicity-4">Brazilian</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="ethnicity-5" type="checkbox" value="value5">
                                        <label for="ethnicity-5">Chinese</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="ethnicity-6" type="checkbox" value="value6">
                                        <label for="ethnicity-6">Caucasian</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="ethnicity-7" type="checkbox" value="value7">
                                        <label for="ethnicity-7">East Asian</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="ethnicity-8" type="checkbox" value="value8">
                                        <label for="ethnicity-8">Hispanic</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="ethnicity-9" type="checkbox" value="value9">
                                        <label for="ethnicity-9">Japanese</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="ethnicity-10" type="checkbox" value="value10">
                                        <label for="ethnicity-10">Middle Eastern</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="ethnicity-11" type="checkbox" value="value11">
                                        <label for="ethnicity-11">Native American</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="ethnicity-12" type="checkbox" value="value12">
                                        <label for="ethnicity-12">Pacific Islander</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="ethnicity-13" type="checkbox" value="value13">
                                        <label for="ethnicity-13">South Asian</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="ethnicity-14" type="checkbox" value="value14">
                                        <label for="ethnicity-14">Southeast Asian</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="ethnicity-15" type="checkbox" value="value15">
                                        <label for="ethnicity-15">Other</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">No of People</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="custom-checkbox-list">
                                    <li>
                                        <input class="custom-checkbox" id="people-1" type="checkbox" value="value1">
                                        <label for="people-1">1</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="people-2" type="checkbox" value="value2">
                                        <label for="people-2">2</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="people-3" type="checkbox" value="value3">
                                        <label for="people-3">3</label>
                                    </li>
                                    <li>
                                        <input class="custom-checkbox" id="people-4" type="checkbox" value="value4">
                                        <label for="people-4">4+</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordian_border">
                        <button class="course-accordion">Author</button>
                        <div class="course-panel">
                            <div class="accordian-inner">
                                <ul class="author-list">
                                    <li><a href="#">Albert Flores</a><span>(12)</span></li>
                                    <li><a href="#">Annette Black</a><span>(02)</span></li>
                                    <li><a href="#">Arlene McCoy</a><span>(16)</span></li>
                                    <li><a href="#">Bessie Cooper</a><span>(100)</span></li>
                                    <li><a href="#">Brooklyn Simmons</a><span>(32)</span></li>
                                    <li><a href="#">Cameron Williamson</a><span>(23)</span></li>
                                    <li><a href="#">Cody Fisher</a><span>(67)</span></li>
                                    <li><a href="#">Courtney Henry</a><span>(190)</span></li>
                                    <li><a href="#">Darlene Robertson</a><span>(122)</span></li>
                                    <li><a href="#">Darrell Steward</a><span>(09)</span></li>
                                    <li><a href="#">Devon Lane</a><span>(07)</span></li>
                                    <li><a href="#">Dianne Russell</a><span>(31)</span></li>
                                    <li><a href="#">Eleanor Pena</a><span>(24)</span></li>
                                    <li><a href="#">Esther Howard</a><span>(04)</span></li>
                                    <li><a href="#">Floyd Miles</a><span>(89)</span></li>
                                    <li><a href="#">Guy Hawkins</a><span>(90)</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-block-right">
                <div class="banner-form-sec">
                    <div class="categories-seach-block-banner">
                        <div class="search-filter-form-main-banner">
                            <form class="search-filter-form">
                                <div class="custom-select-wrapper">
                                    <div class="custom-select">
                                        <div class="custom-select__trigger"><span><img src="assets/images/img-icon.svg"
                                      alt="Images" width="16" height="16" /> Images</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="search-input-block">
                                    <button class="btn-search"><img src="assets/images/search-icon.svg" alt="search icon"
                                width="13" height="13" /></button>
                                    <input class="input-search" type="text" name="searchtext" placeholder="Search for images, footages etc..." autocomplete="off" >
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="cart-title-row">
                        <div class="cart-col-left">
                            <h1 class="h2">Image</h1>
                            <span>435,459 Results</span>
                        </div>
                        <div class="cart-col-right">
                            <div class="select-lice">
                                <select>
                          <option>Best Match</option>
                          <option>Popular</option>
                          <option>Recent</option>
                          <option>Price: Low to High</option>
                          <option>Price: High to Low</option>
                          <option>Price: High to Low</option>
                          <option>Duration: Long to Short</option>
                       </select>
                            </div>
                        </div>
                    </div>
                    <div class="reset-filter-row">
                        <ul>
                            <li>Price: ₹1000 - ₹4000 <span></span></li>
                            <li>Color: #345732, #375463 <span></span></li>
                            <li>Orientation: Landscape <span></span></li>
                            <li>Resolution: 360 X 240 <span></span></li>
                            <li>People: With People <span></span></li>
                        </ul>
                        <button class="thame-btn">Reset Filter</button>
                    </div>
                </div>
                <!-- image list section start -->
                <div class="image-list-sec">
                    <!-- layout of image grid position start -->
                    <div class="image_grid">
                        <!-- image first row start -->
                        <div class="image_grid_1">
                            <div>
                                <div class="landscape_big_image main_image">
                                    <picture>
                                        <source class="img-tag" srcset="assets/images/list-img1.webp" alt="list img" width="690" height="420" />
                                        <source class="img-tag" srcset="assets/images/list-img1.jpg" alt="list img" width="690" height="420" />
                                        <img class="img-tag" src="assets/images/list-img1.jpg" alt="list img" width="690" height="420" />
                                    </picture>
                                </div>
                            </div>
                            <div class="first_image_grid">
                                <div class="landscape_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img2.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img2.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img2.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img4.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img4.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img4.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                </div>
                                <div class="landscape_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img3.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img3.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img3.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img9.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img9.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img9.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- image first row end -->
                        <!-- image second row start -->
                        <div class="image_grid_1">
                            <div class="first_image_grid">
                                <div class="landscape_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img5.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img5.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img5.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                </div>
                                <div class="landscape_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img6.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img6.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img6.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                </div>
                            </div>
                            <div class="first_image_grid">
                                <div class="landscape_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img7.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img7.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img7.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                </div>
                                <div class="landscape_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img8.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img8.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img8.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- image second row end -->
                        <!-- image third row start -->
                        <div class="image_grid_1">
                            <div>
                                <div class="landscape_big_image landscape_big2  main_image">
                                    <picture>
                                        <source class="img-tag" srcset="assets/images/list-img10.webp" alt="list img" width="690" height="420" />
                                        <source class="img-tag" srcset="assets/images/list-img10.jpg" alt="list img" width="690" height="420" />
                                        <img class="img-tag" src="assets/images/list-img10.jpg" alt="list img" width="690" height="420" />
                                    </picture>
                                </div>
                            </div>
                            <div>
                                <div class="landscape_big_image landscape_big2 main_image">
                                    <picture>
                                        <source class="img-tag" srcset="assets/images/list-img11.webp" alt="list img" width="690" height="420" />
                                        <source class="img-tag" srcset="assets/images/list-img11.jpg" alt="list img" width="690" height="420" />
                                        <img class="img-tag" src="assets/images/list-img11.jpg" alt="list img" width="690" height="420" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                        <!-- image third row end -->
                        <!-- image four row start -->
                        <div class="image_grid_1">
                            <div class="first_image_grid">
                                <div class="portrait_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img12.webp" alt="list img" width="345" height="295" />
                                            <source class="img-tag" srcset="assets/images/list-img12.jpg" alt="list img" width="345" height="295" />
                                            <img class="img-tag" src="assets/images/list-img12.jpg" alt="list img" width="345" height="295" />
                                        </picture>
                                    </div>
                                </div>
                                <div class="portrait_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img13.webp" alt="list img" width="345" height="295" />
                                            <source class="img-tag" srcset="assets/images/list-img13.jpg" alt="list img" width="345" height="295" />
                                            <img class="img-tag" src="assets/images/list-img13.jpg" alt="list img" width="345" height="295" />
                                        </picture>
                                    </div>
                                </div>
                            </div>
                            <div class="first_image_grid">
                                <div class="portrait_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img15.webp" alt="list img" width="345" height="295" />
                                            <source class="img-tag" srcset="assets/images/list-img15.jpg" alt="list img" width="345" height="295" />
                                            <img class="img-tag" src="assets/images/list-img15.jpg" alt="list img" width="345" height="295" />
                                        </picture>
                                    </div>
                                </div>
                                <div class="portrait_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img14.webp" alt="list img" width="345" height="295" />
                                            <source class="img-tag" srcset="assets/images/list-img14.jpg" alt="list img" width="345" height="295" />
                                            <img class="img-tag" src="assets/images/list-img14.jpg" alt="list img" width="345" height="295" />
                                        </picture>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- image four row end -->
                        <!-- image five row start -->
                        <div class="image_grid_1">
                            <div>
                                <div class="landscape_big_image main_image">
                                    <picture>
                                        <source class="img-tag" srcset="assets/images/list-img20.webp" alt="list img" width="690" height="420" />
                                        <source class="img-tag" srcset="assets/images/list-img20.jpg" alt="list img" width="690" height="420" />
                                        <img class="img-tag" src="assets/images/list-img20.jpg" alt="list img" width="690" height="420" />
                                    </picture>
                                </div>
                            </div>
                            <div class="first_image_grid">
                                <div class="landscape_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img23.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img23.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img23.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img24.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img24.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img24.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                </div>
                                <div class="landscape_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img21.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img21.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img21.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img22.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img22.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img22.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- image five row end -->
                        <!-- image six row start -->
                        <div class="image_grid_1">
                            <div class="first_image_grid">
                                <div class="landscape_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img16.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img16.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img16.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                </div>
                                <div class="landscape_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img17.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img17.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img17.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                </div>
                            </div>
                            <div class="first_image_grid">
                                <div class="landscape_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img18.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img18.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img18.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                </div>
                                <div class="landscape_image_grid">
                                    <div class="main_image">
                                        <picture>
                                            <source class="img-tag" srcset="assets/images/list-img19.webp" alt="list img" width="345" height="210" />
                                            <source class="img-tag" srcset="assets/images/list-img19.jpg" alt="list img" width="345" height="210" />
                                            <img class="img-tag" src="assets/images/list-img19.jpg" alt="list img" width="345" height="210" />
                                        </picture>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- image six row end -->
                    </div>
                    <!-- layout of image grid position end -->
                    <div class="pagination">
                        <div>Items per Page</div>
                        <div><input type="number" value="20"></div>
                        <div>Page</div>
                        <div><input type="number"></div>
                        <div>of 454</div>
                    </div>
                </div>
                <!-- image list section end -->
            </div>
        </div>
    </div>
</section>
