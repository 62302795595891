import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/appv2/models/user';
import { AccountService } from 'src/appv2/services/account.service';
import { WishlistService } from 'src/appv2/services/wishlist.service';
import { ToastService } from 'src/appv2/services/toast.service';
import $ from 'jquery';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { Store } from '@ngrx/store';

@Component({
  selector: 'wishlist-form-modal',
  templateUrl: './wishlist-form-modal.component.html',
  styleUrls: ['./wishlist-form-modal.component.scss'],
})
export class FormModalComponent implements OnInit, OnDestroy {
  wishlistForm: FormGroup;
  wishlistModalTitle: string = 'Create Wishlist Collection';
  user: User;
  @Input() wishlistData: any; // Define an input property to receive data
  @Output() dataEvent = new EventEmitter<any>(); // Define an output property to emit events
  wishlistName: string = '';

  validationMessages = {
    wishlistName: {
      required: 'Wishlist name is required.',
      // Add more error messages as needed
    },
    // Add more controls and their error messages as needed
  };
  showValidationError: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private wishlist: WishlistService,
    private accountActions: AccountService,
    private toastService: ToastService,
    private store$: Store<any>,
  ) {}

  ngOnInit(): void {
    if (
      this.wishlistData &&
      this.wishlistData['id'] &&
      this.wishlistData['name']
    ) {
      this.wishlistModalTitle = 'Edit Wishlist Collection';
      this.wishlistName = this.wishlistData['name'];
    }
    this.initForm();
    this.openDeletePopup();
  }

  /**
   * Initialize the form with its controls and validators.
   */
  initForm() {
    this.wishlistForm = this.formBuilder.group({
      wishlistName: [this.wishlistName, [Validators.required, Validators.minLength(3)]], // Define form control with initial value and validators
    });
    this.wishlistForm.get('wishlistName').valueChanges.subscribe(value => {
      // Remove leading spaces and update the form control
      this.wishlistForm.get('wishlistName').setValue(value.trimStart(), { emitEvent: false });
    });
  }

  /**
   * Open the wishlist form popup by showing it, displaying the overlay, and adding a CSS class to the body.
   */
  openDeletePopup() {
    $('.wishlist_form_popup').toggle();
    $('.overlay').show();
    $('body').addClass('overflow_hidden');
  }

  /**
   * Hide the styling of the wishlist form popup by hiding it, hiding the overlay, and removing a CSS class from the body.
   */
  hideModelStyling = () => {
    $('.wishlist_form_popup').toggle();
    $('.overlay').hide();
    $('body').removeClass('overflow_hidden');
  };

  /**
   * Handle the action when the cancel button is clicked.
   * Emit data to the parent component to indicate cancellation and pass the parent data.
   * Finally, hide the styling of the popup.
   */
  onCancel() {
    let data = {};
    data['confirm'] = false;
    data['parent_passed_data'] = this.wishlistData;
    this.emitDataToParent(data);
    this.hideModelStyling();
  }

  /**
   * Emit data to the parent component using the dataEvent output property.
   * Used to communicate user actions or information back to the parent component.
   * @param {object} data - The data to be emitted to the parent component.
   */
  emitDataToParent(data = {}) {
    this.dataEvent.emit(data);
  }

  /**
   * Handle the form submission.
   * If the form is valid, create or update the wishlist based on the input data.
   * Handle success and error responses accordingly.
   */
  onSubmit() {
    if (this.wishlistForm.invalid) {
      this.showValidationError = true;
      for (const control of Object.keys(this.wishlistForm.controls)) {
        this.wishlistForm.controls[control].markAsTouched();
      }
      return;
    }
    if (this.wishlistForm.valid) {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: true })
      );
      const wishlistName = this.wishlistForm.value.wishlistName;

      let user = localStorage.getItem('user_data');
      let postData = JSON.parse(user);

      if (this.wishlistData && this.wishlistData['id']) {
        postData['wishlist_id'] = this.wishlistData['id'];
      }
      postData['wishlist_name'] = wishlistName;

      this.wishlist.createOrUpdateWishlist(postData).subscribe(
        (res) => {
          this.store$.dispatch(
            new loadingChangeAction(<LoadingState>{ loadingStatus: false })
          );
          if (res['error'] && res['message']) {
            this.validationMessages.wishlistName.required = res['message'];
            this.showValidationError = true;
          } else {
            this.toastService.success(res['message']);
            this.wishlistForm.reset();
            this.showValidationError = false;

            let emitData = {};
            emitData['success'] = true;
            emitData['parent_passed_data'] = this.wishlistData;
            this.emitDataToParent(emitData);
          }
        },
        (error) => {
           console.log('error : ', error);
        }
      );
    } else {
      this.showValidationError = true;
    }
  }

  ngOnDestroy(): void {
    this.hideModelStyling();
  }
}
