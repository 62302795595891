import { Component, OnInit,ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DiscountDetailsInterface } from 'src/appv2/interfaces/discount-details.interface';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { SearchService } from 'src/appv2/services/search.service';
import { HttpClient } from '@angular/common/http';
import { SearchchangeSearchName } from 'src/appv2/reducers/search/search.actions';
import { SearchState } from 'src/appv2/reducers/search/search.models';
import { CacheService } from 'src/appv2/services/cache.service';
import { urlToCacheKeyMapping } from 'src/appv2/utils/constants/discount.const';
import { messages } from 'src/appv2/appv2.constants';


@Component({
  selector: 'appv2-category-data',
  templateUrl: './category-data.component.html',
  styleUrls: ['./category-data.component.scss']
})
export class CategoryDataComponent implements OnInit {

  constructor(private landingPage: LandingPageService, private store$: Store<any>, private route: ActivatedRoute, private router: Router, private mysearch: SearchService, private http: HttpClient, private elementRef: ElementRef) { }
  public page_type: string;
  public categoryData =[]
  public typeValue:string = ''

  ngOnInit(): void {
   this.typeValue = this.router.url
    this.getCategoryList(this.typeValue)
  }
  getCategoryList(typeValue){
  let value = ''
    if(typeValue){
      if(typeValue == '/image'){
        value = '1'
      }else if(typeValue == '/footage'){
        value = '2'
      }else{
        value = '3'
      }
    
      this.loading(true)

      this.landingPage.getCategoryDetails('',typeValue == '/categories' ? '':value).subscribe(data => {
        this.loading(false);
        this.categoryData = data.data

      })
    }
  }
  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.categoryDataLoading  }))
    return
  }

}
