<div class="sign_up_verify_email_page">
  <!-- Sign up verify email page image grid section start -->
  <div class="sign_up_page_single_image_grid">
    <div class="sign_in_page_single_image">
      <picture>
        <img src="assets/images/sign_in_img_9.webp" alt="sign-in-image" width="953" height="1005" />
      </picture>
    </div>
    <div class="sign_in_form_content">
      <div class="grid_footer_logo">
        <a href="index.html"><img src="assets/images/Logo.png" alt="logo-image" width="216" height="39" /></a>
      </div>
      <h2 class="h2">Download 10k+ Photos, Videos and editors choice</h2>
      <p>
        Access all the images, videos, music, and tools you need to turn ideas
        into achievements. All images
      </p>
    </div>
  </div>
  <!-- Sign up verify email page image grid section end -->
  <!-- Sign up verify email page form section start -->
  <div class="sign_up_verify_email_page_form">
    <div class="verify_your_email_section">
      <h2 class="h2" *ngIf="is_email">Verify Your Email</h2>
      <h2 class="h2" *ngIf="!is_email">Verify Your Mobile</h2>
      <div class="verify_image">
        <img [src]="
            is_email
              ? 'assets/images/verify_email.svg'
              : 'assets/images/verify_email.svg'
          " alt="verify-email" width="111" height="111" />
      </div>
      <form [formGroup]="otpForm" (ngSubmit)="onSubmitVerifyOtp()">
        <div class="acknowledge_section_enter_password" *ngIf="!is_email">
          <input type="text" maxlength="1" formControlName="otpInput1" placeholder="-" (keyup)="handleOtp($event, 1)"
            (paste)="handleOnPasteOtp($event)" />
          <input type="text" maxlength="1" formControlName="otpInput2" placeholder="-" (keyup)="handleOtp($event, 2)"
            (paste)="handleOnPasteOtp($event)" />
          <input type="text" maxlength="1" formControlName="otpInput3" placeholder="-" (keyup)="handleOtp($event, 3)"
            (paste)="handleOnPasteOtp($event)" />
          <input type="text" maxlength="1" formControlName="otpInput4" placeholder="-" (keyup)="handleOtp($event, 4)"
            (paste)="handleOnPasteOtp($event)" />
        </div>
        <button class="verify_email" *ngIf="!is_email">Verify</button>
      </form>
      <a (click)="resendEmailLink()" class="get_email" *ngIf="is_email">Didn’t get email? Send it Again</a>
      <a (click)="resendOtp()" class="get_email" *ngIf="!is_email">Didn’t get OTP? Send it Again</a>
    </div>
  </div>
  <!-- Sign up verify email page form section end -->
</div>