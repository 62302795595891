import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  HostListener
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import $ from 'jquery';
import { Config, LayoutStyle, Media } from 'ng-opengallery';
import { Observable } from 'rxjs';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import {
  selectCurrentRoute,
  selectSearchKey,
} from 'src/appv2/reducers/router/router.selector';
import {
  SearchChangeSearchType,
  SearchWriteAction,
} from 'src/appv2/reducers/search/search.actions';
import { SearchState } from 'src/appv2/reducers/search/search.models';
import {
  selectIsSearchEditorial,
  selectSearchName,
  selectSearchText,
  selectSearchType,
} from 'src/appv2/reducers/search/search.selectors';
import { SearchService } from 'src/appv2/services/search.service';
import { ToastService } from 'src/appv2/services/toast.service';
import { WishlistService } from 'src/appv2/services/wishlist.service';
import { CartService } from 'src/appv2/services/cart.service';
import { DownloadService } from 'src/appv2/services/download.service';
import { ItemDetailsService } from 'src/appv2/services/item-details.service';
import { AccountService } from 'src/appv2/services/account.service';
import {messages} from 'src/appv2/appv2.constants'



@Component({
  selector: 'appv2-category-listing',
  templateUrl: './category-listing.component.html',
  styleUrls: ['./category-listing.component.scss'],
})
export class CategoryListingComponent implements OnInit {
  @Input() processedFilters;
  @Input() itemDataFromSorting;
  @Input() recordsFromSorting;
  @Input() totalFromSorting;
  @Output() dataEvent = new EventEmitter<string>();
  @Input() productType;
  @Input() selectedOption;

  public search$: Observable<string> = this.store$.pipe(
    select(selectSearchKey)
  );

  pageName = '';
  wishlistData = [];
  userWishlistedData: string[] = [];
  userSubscriptionPlans: Array<object> = []
  showAddToWishListModal: boolean = false;
  config: Config = {
    diaporamaDuration: 3,
    layout: LayoutStyle.MASONRY,
    prefMediaHeight: 400,
    spacing: 0.2,
    viewerEnabled: true,
    enableAutoPlay: false,
  };
  galleryData: Array<any>;
  itemData: Array<object>;
  totalRecords: Number;
  page: number = 1;
  prevPage: number = 0;
  recordsPerPage: number;
  itemsPerPage;
  currentPage = 1;
  recentSearches = [];
  page_type: string;
  elasticSuggestions: any;
  trendingKeyword: any;
  searchtext: string = '';
  filter_active: string;

  isRedirect: boolean;
  searchType: number = 1;
  prevSearchType: number = 1;

  prevSearchKey: string = '';
  searchKey: string = '';

  pageGallery: string;
  isEditorial: boolean;

  prevFilters;
  filters;
  user = '';

  selectSearchNameObserver: any;
  selectCurrentRouteObserver: any;
  selectSearchTextObserver: any;

  searchDebounceInterval: any = null;
  public category_id:string = ''
  clearDebounceInterval() {
    clearTimeout(this.searchDebounceInterval);
  }
  currentlyPlayingIndex = -1;

  showCartPopup: boolean = false;
  cartData:Array<any>;
  public productMainType:string = ''
  ifUserHaveCreditedBalance: boolean = false;
  creditedPackageId: number;
  displayDownload:Boolean=false
  emptyUserPlansList = true
  plansChecked: boolean = false
  public selectedValue:string = ''
  public getAlreadyDownloadedMusicFlag: boolean = false;
  isPlaying: boolean = false;
  currentIndex: number;
  audioObj = new Audio();
  public displayMusicPlayer:boolean =false
  public displayExtendedLoader:boolean = false

  playVideo(videoElement) {
    videoElement.videoLoaded = true;

  }

  resetVideo(item){
    item.videoLoaded = false;
  }

  // Link to item
  openItem(type,item) {


    const objectValues = Object.values(this.itemData);

  if(type == 'image'){
    const foundObject = objectValues.find(
      (value) => (value as any).product_thumbnail === item
    );


    this.router.navigate(['/image/'+foundObject['slug']]);
  } else {
    const foundObject = objectValues.find(
      (value) => (value as any).product_main_image === item
    );
    let path = type == 'footage'? '/footage/'+foundObject['slug'] : '/music/'+foundObject['slug']

    this.router.navigate([path]);
  }
  }
  scrollToGalleryStart() {
    window.scrollTo(0, 800);
  }
  constructor(
    private store$: Store<any>,
    private mysearch: SearchService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private toastService: ToastService,
    private wishlist: WishlistService,
    private cartActions: CartService,
    private userData: AccountService,
    private download:DownloadService,
    private itemDetails: ItemDetailsService,

  ) {
    this.galleryData = new Array<any>();
    let search = this?.activatedRouter?.snapshot?.queryParamMap?.get('search');
    if (typeof search !== 'undefined' && search != '') {
      this.searchKey = search
    }
  }

  // Subscribe to Store
  starSearch() {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.imagePageLoading  })
    );
    this.store$.pipe(select(selectSearchType)).subscribe((vl) => {
      if (vl != undefined) {
        this.isRedirect = vl;

      }
    });
    this.selectSearchTextObserver = this.store$
      .pipe(select(selectSearchText))
      .subscribe((vl) => {
        this.clearDebounceInterval();
        this.searchDebounceInterval = setTimeout(() => {
          if (vl != undefined) {
            if (vl.startsWith('category')) {
              this.searchKey = vl.replace('category ', '');
              this.getGalleryContent({
                type: this.filters['type'],
                search: this.searchKey,
              });
            } else {
              this.searchKey = vl;
            }
          }
        }, 200);
      });
    this.store$.pipe(select(selectIsSearchEditorial)).subscribe((vl) => {
      if (this.isRedirect) {
        this.isEditorial = vl;
      }
    });
    this.selectCurrentRouteObserver = this.store$
      .pipe(select(selectCurrentRoute))
      .subscribe((vl) => {
        this.clearDebounceInterval();
        this.searchDebounceInterval = setTimeout(() => {
          if (vl != undefined) {
            this.store$.dispatch(
              new SearchWriteAction(<SearchState>{ searchText: ' ' })
            );
            let searchPage = {};
            this.pageName = vl.routeConfig.path;

            if (vl.routeConfig.path == 'account-activated/:email') {
              this.toastService.success(
                'Your account is activited successfully.'
              );
            }
            switch (vl.routeConfig.path) {
              case '':
                this.searchType = 1;
                searchPage['type'] = 'image';

                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: true,
                  })
                );
                this.filters = searchPage;
                break;

              case 'image':
                this.searchType = 1;
                searchPage['type'] = 'image';

                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: false,
                  })
                );
                break;

              case 'footage':
                this.searchType = 2;
                searchPage['type'] = 'footage';
                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: false,
                  })
                );
                break;

              case 'editorial':
                searchPage['product_editorial'] = 1;
                this.searchType = 1;
                searchPage['type'] = 'image';
                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: true,
                  })
                );
                break;

              case 'search':
                this.filters = vl.queryParams;
                break;

              default:
                this.searchType = 1;
                searchPage['type'] = 'image';
                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: false,
                  })
                );
            }

            this.getGalleryContent(this.filters);
          }
        }, 200);
      });

    this.selectSearchNameObserver = this.store$
      .pipe(select(selectSearchName))
      .subscribe((vl) => {
        this.clearDebounceInterval();
        this.searchDebounceInterval = setTimeout(() => {

            this.pageGallery = vl;


            if (this.pageName == '' || this.pageName == 'editorial') {
              this.filters = {
                type: this.pageGallery,
                product_editorial: +this.isEditorial,
              };

              this.store$.dispatch(
                new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.imagePageLoading  })
              );
              this.getGalleryContent(this.filters);
            }

        }, 500);
      });
  }
  ngDoCheck() {
    if (this.galleryData.length > 0) {
      let v = document.querySelectorAll('.gallery video');
      for (let i = 0; i < v.length; i++) {
        v[i]['muted'] = true;
      }
      let footages = $('.gallery video');
      for (let i = 0; i < footages.length; i++) {
        $(footages[i]).mouseout((event) => {
          $(event.target)[0].load();
        });
        $(footages[i]).mouseover((event) => {
          $(event.target)[0].play();
        });
        footages[i].setAttribute(
          'poster',
          this.itemData[i]['product_thumbnail']
        );
      }
    }
  }
  fileExtension: string;
  // Get search result
  getGalleryContent(filters, currentPage = 1) {
    this.galleryData = []
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.imagePageLoading  })
    );

    let search = this?.activatedRouter?.snapshot?.queryParamMap?.get('search');
    let category_id = this.activatedRouter?.snapshot?.paramMap?.get('slug')
    if (typeof search !== 'undefined' && search != '') {
      this.searchKey = search;
    }
    if(category_id == undefined || category_id == ''){
      this.category_id = ''
    }else{
      this.category_id = category_id
    }

    filters['type'] = this.productType
    filters['sort'] = this.selectedOption
    this.displayExtendedLoader = true

    if (Object.keys(filters).length !== 0 && filters.constructor === Object) {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.imagePageLoading  })
      );

      const apiCall =this.mysearch
        .getSearchWithFiltersResult(
          filters,
          currentPage,
          this.searchKey,
          this.processedFilters,
          this.category_id
        )
      setTimeout(() => {
        if(this.galleryData.length == 0 && this.displayExtendedLoader){

          this.store$.dispatch(
            new loadingChangeAction(<LoadingState>{ loadingStatus: true, loadingMessage: messages.delayAPIMessage})
          );
        }
      }, 2000);



      apiCall.subscribe((data) => {
        if(data.imgfootage?.length ==0){
          this.displayExtendedLoader = false
          this.store$.dispatch(
            new loadingChangeAction(<LoadingState>{ loadingStatus: false })
          );
          this.totalRecords = 0
        }

          if (data.imgfootage?.length >0) {

            this.galleryData = [];
            this.currentPage = currentPage
            let filtereddata = data.imgfootage.filter(function (tag) {
              return tag.api_product_id;
            });
            this.store$.dispatch(
              new loadingChangeAction(<LoadingState>{ loadingStatus: false })
            );
            if(filters['type'] == 'image' || filters['type'] == 'footage'){
              for (let i = 0; i < filtereddata.length; i++) {
                if (filters['type'] == 'image') {
                  const parts = filtereddata[i]['product_thumbnail'].split('.');
                  // Get the last part of the array (the file extension)
                  this.fileExtension = parts[parts.length - 1];
                  this.galleryData.push(
                    new Media(
                      filtereddata[i]['product_thumbnail'],
                      filtereddata[i]['product_title']
                    )
                  );
                } else {
                  const parts = filtereddata[i]['product_main_image'].split('.');
                  // Get the last part of the array (the file extension)
                  this.fileExtension = parts[parts.length - 1];

                  let data = {
                    previewUrl:  filtereddata[i]['product_main_image'],
                    title: filtereddata[i]['product_title'],
                    type: "video",
                    url: filtereddata[i]['product_main_image'],
                    thumbnail:filtereddata[i]['product_thumbnail']
                };
                this.galleryData.push(data);

                }
              }
            } else{
              this.galleryData = filtereddata
            }
            if (this.itemDataFromSorting == undefined) {
              this.itemData = filtereddata;
              this.recordsPerPage = this.itemsPerPage = data.perpage;
              this.totalRecords = data.total;
              this.dataEvent.emit(data.total);
            } else {
              this.itemData = this.itemDataFromSorting;
              this.recordsPerPage = this.itemsPerPage = this.recordsFromSorting;
              this.totalRecords = this.totalFromSorting;
              this.dataEvent.emit(this.totalFromSorting);
            }
          }else{

            this.store$.dispatch(
              new loadingChangeAction(<LoadingState>{ loadingStatus: false })
            );
            this.toastService.error('Please try again')
            this.totalRecords = 0
          }

        });
    } else {
      this.mysearch.getSearchResult(1, currentPage).subscribe((data) => {
        this.galleryData = [];
        for (let i = 0; i < data.imgfootage.length; i++) {
          if (data.imgfootage[i]['product_main_type'] == 'Image') {
            this.galleryData.push(
              new Media(data.imgfootage[i]['product_thumbnail'], '')
            );
          } else {
            this.galleryData.push(
              new Media(data.imgfootage[i]['product_main_image'], '', 'video')
            );
          }
        }
        this.itemData = data.imgfootage;
        this.recordsPerPage = this.itemsPerPage = data.imgfootage.length;
        this.totalRecords = data.total;
        this.dataEvent.emit(data.total);
        this.store$.dispatch(
          new loadingChangeAction(<LoadingState>{ loadingStatus: false })
        );
      });
    }
    if (this.itemData != undefined) {
    }
  }

  userWishlistData = () => {
    let postData = JSON.parse(this.user);
    this.wishlist.getUserWishlist(postData).subscribe(
      (res) => {
        this.userWishlistedData = res.data.map((item) =>
          String(item.product_thumbnail)
        );
      },
      (error) => {
         console.log('error : ', error);
      }
    );
  };

  toNextPage() {
    this.currentPage++;
    this.getGalleryContent(this.filters, this.currentPage);
  }
  toPrevPage() {
    this.currentPage--;
    this.getGalleryContent(this.filters, this.currentPage);
  }
  ngOnInit() {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.imagePageLoading  })
    );
    this.currentPage =1
    this.user = localStorage.getItem('user_data');
    if (this.user) {
      this.userWishlistData();
      this.getUserInfo()
    }
    this.starSearch();
  }

  ngAfterViewInit() {
    let searchInput = document.querySelector('.search-container input');
    if (searchInput) {
      searchInput['value'] = this.searchKey;
    }
  }

  ngOnDestroy() {
    this.selectCurrentRouteObserver?.unsubscribe();
    this.selectSearchNameObserver?.unsubscribe();
    this.selectSearchTextObserver?.unsubscribe();
    this.clearDebounceInterval();
  }

  get totalPages(): number {
    return Math.ceil(Number(this.totalRecords) / Number(this.itemsPerPage));
  }

  // Get the event for pagination from child component
  onPageChange(newPage: number): void {
    this.currentPage = Number(newPage);
    this.itemsPerPage = this.itemsPerPage;

    if (this.currentPage < 1) {
      this.currentPage = 1;
    }

    this.getGalleryContent(this.filters, this.currentPage);
  }

  showModal(wishlist) {
    this.wishlist.getWishlistData(wishlist).subscribe(
      (res) => {
        if (res['status'] == 'success') {
          // Reload user wishlists and show success message
          this.wishlistData['product_id'] = res.data.product_id;
          this.wishlistData['type'] = res.data.product_main_type;
          this.wishlistData['title'] = res.data.product_title;
          this.wishlistData['itemPoster'] = res.data.product_thumbnail;
          this.wishlistData['author_username'] = res.data.product_owner;
          this.showAddToWishListModal = true;
        } else {
          this.showAddToWishListModal = false;
          this.toastService.error(messages.categoryListProductValidation);
        }
      },
      (error) => {
        // Handle API request error
         console.log('error : ', error);
      }
    );
  }

  handleWishlistModalEvent = (event) => {
    if (event['close_modal']) {
      // Set the flag to false, indicating that the Add To Wishlist Modal should be closed
      this.showAddToWishListModal = false;
      if(this.user){
        this.userWishlistData()
      }
    }
  };

  getUserInfo() {
    this.userData.getUserInformation(JSON.parse(localStorage.getItem('user_data'))['Utype']).subscribe(data => {
      let userInfoData = data['data']['plans'];
      for (let i = 0; i < userInfoData.length; i++) {
        if (userInfoData[i]['package_type'].toLowerCase() == this.productMainType.toLowerCase()) {
          if ( userInfoData[i]['downloaded_product'] < userInfoData[i]['package_products_count']) {
            this.ifUserHaveCreditedBalance = true;
            this.creditedPackageId = userInfoData[i]['id']
          }
          this.userSubscriptionPlans.push(userInfoData[i])
          this.displayDownload = true
        }
      }
      this.emptyUserPlansList = (this.userSubscriptionPlans.length == 0)
    })
    this.plansChecked = true
  }

  // Get item info
  async getItemInfo(musicItemData) {

    let downloadData =  {
      product: {
        product_info: {
          0: {
            flv_base: musicItemData.product_thumbnail,
            clip_data: {
              pic_objectid: musicItemData.product_id,
              pic_description: musicItemData.product_description,
              n: musicItemData.product_title,
            }
          },
          1: musicItemData.product_thumbnail,
          2: musicItemData.product_thumbnail,
          selected_product: {

          },
          media: {
            id: musicItemData.api_product_id,
          },
        },
        type: 4
      }
    }
    let addToCartData = downloadData
    let versions = musicItemData.options;
    let getVersion = musicItemData.options[versions.length-1]
    this.selectedValue = getVersion.name;

    let vl = JSON.stringify(getVersion, null, 2).replace(
      /(\r\n|\n|\r)/gm,
      ''
    );

  }

  public getDownloadedMusic(user_id,product_id): Observable<any>{
    return this.itemDetails.getAlreadyDownloadedImage(user_id, product_id);

  }
  handleClick($index,slug = 'startEvent')
  {
    if(slug == 'startEvent'){
      if(this.currentIndex != $index){
        this.isPlaying = true
      }else{
        this.isPlaying = !this.isPlaying
      }
      this.currentIndex = $index

      this.displayMusicPlayer = true
    }else {
      this.currentIndex = $index
      this.isPlaying = !this.isPlaying
    }
  }
  play($musicUrl) {
    this.audioObj.src = $musicUrl
    this.audioObj.load();
    this.audioObj.play();
  }

  pause() {
    this.audioObj.pause();

  }

  handleClickPlay(event){
    this.isPlaying = event['playing']
    this.currentIndex = event['index']
    if(event['hidePlayer']){
      this.displayMusicPlayer = false
    }
    if(event['wishlist'] && !event['hidePlayer']){
      let wishlist = this.galleryData[this.currentIndex]
      this.showModal(wishlist)
    }

  }

  @HostListener('document:click',['$event','$event.target'])

  public onClick(event: MouseEvent, targetElement): void {
    if (!targetElement) {
      return;
    }
    let classNameList = ['previous','playon','pause','next','name','range','time','volume-full','mute','heart-null','heart-fill','cart','close-icon','play-btn']
    if(!classNameList.includes(targetElement.className)){
      if(this.displayMusicPlayer){
        this.handleClick(-1,'closeEvent')
      }

    }
  }

  openItemGallery(item) {

    const objectValues = Object.values(this.itemData);
    const foundObject = objectValues.find(
      (value) => (value as any).product_thumbnail === item.previewUrl
    );


    this.router.navigate(['/image/'+foundObject['slug']]);

}

}
