  <section class="music-player">
    <!-- <div class="container"> -->
      <div class="audio-player">
        <div class="audio-playlist">
          <div class="play-btn">
            <a class="previous" (click)="previous()">
              <img src="assets/images/previous.svg" alt="previous" width="20" height="20" class="previous">
            </a>
            <a  class="play" (click)="playPause()">
             <img src="assets/images/play-player.svg" alt="play" width="32" height="32" class="playon" *ngIf="!isPlaying">

              <img src="assets/images/pause.svg" alt="pause" width="32" height="32" class="pause" *ngIf="isPlaying">
            </a>
            <a  class="next" (click)="next()">
              <img src="assets/images/next.svg" alt="next" width="20" height="20" class="next">
            </a>
          </div>
          <div class="music-name">
            <p class="name">{{currentMusicPlay?.product_title}}</p>
          </div>
          <div class="music-chart">
            <div class="range">
              <input type="range" [value]="currentTime" [max]="totalTime" (input)="seekTo($event.target.value)" class="range">
            </div>
          </div>
          <div class="music-duration">
            <p class="time"> {{ formatTime(currentTime) }} / {{ formatTime(totalTime) }}</p>
          </div>
          <div class="volume">
            <a (click)="toggleMute()">
              <img src="assets/images/volume.svg" alt="volume" width="32" height="32" class="volume-full" *ngIf="!isMuted">
              <img src="assets/images/mute.svg" alt="mute" width="32" height="32" class="mute" *ngIf="isMuted">
            </a>
          </div>
          <div class="other-icons" >
            <a (click)="addWishlist()">
              <img  *ngIf="!userWishlistedData.includes(currentMusicPlay?.product_thumbnail)"  src="assets/images/heart.svg" alt="heart" width="32" height="32" class="heart-null">
              <img  *ngIf="userWishlistedData.includes(currentMusicPlay?.product_thumbnail)" src="assets/images/red_heart.svg" alt="heart-fill" width="32" height="32" class="heart-fill">
            </a>


              <a routerLink="/music/{{currentMusicPlay['slug']}}" *ngIf="!displayDownload"><img src="assets/images/cart.svg"
                alt="like" width="32" height="32"  class="cart"/></a>
        <a routerLink="/music/{{currentMusicPlay['slug']}}" *ngIf="displayDownload"><img src="assets/images/dwonload.png"
                alt="like" width="32" height="32" class="cart" /></a>
          </div>
        </div>
      </div>
    <!-- </div> -->
  </section>

