<div class="sign_in_forget_password_page">
  <!-- Sign in forget password page image grid section start -->
  <div class="sign_in_page_single_image_grid">
    <div class="sign_in_page_single_image">
      <picture>
        <img src="assets/images/sign_in_img_6.webp" alt="sign-in-image" width="953" height="1005" />
      </picture>
    </div>
    <div class="sign_in_form_content">
      <div class="grid_footer_logo">
        <a href="index.html"><img src="assets/images/Logo.png" alt="logo-image" width="216" height="39" /></a>
      </div>
      <h2 class="h2">Download 10k+ Photos, Videos and editors choice</h2>
      <p>
        Access all the images, videos, music, and tools you need to turn ideas
        into achievements. All images
      </p>
    </div>
  </div>
  <!-- Sign in forget password page image grid section end -->
  <!-- Sign in forget password page form section start -->
  <div class="sign_in_forget_password_page_form">
    <div class="forget_password_section">
      <div class="forget_password_section_heading">
        <div class="left-arrow">
          <a routerLink="/signin"><img src="assets/images/left_arrow.svg" alt="left-arrow" width="59" height="59" /></a>
        </div>
        <h2 class="h2">Forgot Password?🤦</h2>
      </div>
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
        <div class="form_group" [ngClass]="{ 'error_field': submitted && f.email.errors }">
          <label for="email">{{email_label}}</label>
          <input type="text" formControlName="email" placeholder="Enter your email or mobile no" />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <p *ngIf="f.email.errors.required" class="error">
              * Please enter valid email address or mobile number
            </p>
            <p *ngIf="f.email.errors.pattern" class="error">
              * Please enter valid email address or mobile number
            </p>
          </div>
        </div>
        <div *ngIf="showEmailInput" class="form_group" [ngClass]="{ 'error_field': submitted && f.op_email.errors }">
          <label for="op_email">Email</label>
          <input type="text" formControlName="op_email" placeholder="Enter your email" />
          <div *ngIf="submitted && f.op_email.errors" class="invalid-feedback">
            <p *ngIf="f.op_email.errors.required" class="error">
              * Please enter a valid email address
            </p>
            <p *ngIf="f.op_email.errors.pattern" class="error">
              * Please enter a valid email address
            </p>
          </div>
        </div>
        <button class="submit_btn">Submit</button>
      </form>
    </div>
  </div>
  <!-- Sign in forget password page form section end -->
</div>
