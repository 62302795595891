import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SafeRequestService } from './safe-request.service';

@Injectable({
  providedIn: 'root',
})
export class WishlistService {
  constructor(private safeRequest: SafeRequestService) {}
  addToWishlist(data) {
    const url = 'addto_wishlist';
    const body = data;
    return this.safeRequest.sendRequest('post', url, body);
  }

  removeFromWishlist(data) {
    const url = 'delete_wishlist_product';
    const body = data;
    return this.safeRequest.sendRequest('post', url, body);
  }

  acceptWishlistCollection = (data) => {
    const url = 'accept-wishlist-link';
    const body = data;
    return this.safeRequest.sendRequest('post', url, body);
  };

  // Fetch wishlist data from the server
  getWishlist(user): Observable<any> {
    const url = 'wishlist-app-v2';
    const body = user;
    return this.safeRequest.sendRequest('post', url, body);
  }

  // Add products to a wishlist on the server
  addProductsToWishlist(postData): Observable<any> {
    const url = 'add-products-to-wishlist';
    const body = postData;
    return this.safeRequest.sendRequest('post', url, body);
  }

  getUserWishlist(postData): Observable<any> {
    const url = 'get-user-wishlist-products';
    const body = postData;
    return this.safeRequest.sendRequest('post', url, body);
  }

  // Create or update a wishlist on the server
  createOrUpdateWishlist(postData): Observable<any> {
    const url = 'create-update-wishlist';
    const body = postData;
    return this.safeRequest.sendRequest('post', url, body);
  }

  // Remove products from a wishlist on the server
  removeProductFromWishlist(postData): Observable<any> {
    const url = 'remove-products-from-wishlist';
    const body = postData;
    return this.safeRequest.sendRequest('post', url, body);
  }

  getWishlistData(product) {
    const url = 'get-wishlist-data';
    const body = product;
    return this.safeRequest.sendRequest('post', url, body);
  }

  deleteWishlist(product) {
     const url = 'delete-wishlist-data';
     const body = product;
     return this.safeRequest.sendRequest('post', url, body);
  }
}
