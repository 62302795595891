import { MyWishlistComponent } from './my-wishlist/my-wishlist.component';
import { PricingPlanpageComponent } from './pricing-planpage/pricing-planpage.component';
import { PaymentMethodPageComponent } from './payment-method-page/payment-method-page.component';
import { PagesComponent } from './pages.component';
import { PricingPageComponent } from './pricing-page/pricing-page.component';
import { EditorialPageComponent } from './editorial-page/editorial-page.component';
import { ImagePageComponent } from './image-page/image-page.component';
//import { PricingPComponent } from './../../app/pricing-p/pricing-p.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CartComponent } from './cart/cart.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FootagePageComponent } from './footage-page/footage-page.component';
import { MusicPageComponent } from './music-page/music-page.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CmsPageComponent } from './cms-page/cms-page.component';
import { ImagePopupComponent } from './image-popup/image-popup.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { MyPlanComponent } from './my-plan/my-plan.component';
import { MyWishlistInnerComponent } from './my-wishlist-inner/my-wishlist-inner.component';
import { MusicListingComponent } from './music-listing/music-listing.component';
import { MusicFilterComponent } from './music-filter/music-filter.component';
import { MusicDetailsComponent } from './music-details/music-details.component';
import { ImageListingComponent } from './image-listing/image-listing.component';
import { ImageFilterComponent } from './image-filter/image-filter.component';
import { ImageDescriptionComponent } from './image-description/image-description.component';
import { FootageHomeComponent } from './footage-home/footage-home.component';
import { FootageFilterComponent } from './footage-filter/footage-filter.component';
import { FootageDetailsComponent } from './footage-details/footage-details.component';
import { HomePageComponent } from './home-page/home-page.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { LicenceAgreementComponent } from './licence-agreement/licence-agreement.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FootageListingComponent } from './footage-listing/footage-listing.component';
import { FootageListingV1Component } from './footage-listing-v1/footage-listing-v1.component';
import { AuthGuard } from '../helpers/auth.guard';
import { AddedWishlistOwnAccountComponent } from './added-wishlist-own-account/added-wishlist-own-account.component';
import { EditorialListingComponent } from './editorial-listing/editorial-listing.component';
import { ProfileCompleteGuard } from '../helpers/profile-complete.guard';
import { CategoryComponent } from './category/category.component';
import { CategoryDetailsComponent } from './category-details/category-details.component';

const routes: Routes = [
  {
    path: '', component: PagesComponent, children: [
      { path: 'home', component: HomePageComponent },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'image', component: ImagePageComponent },
      { path: 'images', component: ImageListingComponent },
      { path: 'editorials', component: EditorialListingComponent },
      { path: 'footage', component: FootagePageComponent },
      { path: 'editorial', component: EditorialPageComponent },
      { path: 'pricing', component: PricingPageComponent },
      { path: 'music', component: MusicPageComponent },
      { path: 'cart', component: CartComponent, canActivate: [AuthGuard, ProfileCompleteGuard] },
      //{ path: 'pricing', component: PricingPComponent },
      { path: 'payment-method', component: PaymentMethodPageComponent, canActivate: [AuthGuard, ProfileCompleteGuard] },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'cms', component: CmsPageComponent },
      { path: 'image-popup', component: ImagePopupComponent },
      { path: 'profile', component: ProfilePageComponent, canActivate: [AuthGuard] },
      { path: 'pricing-plan', component: PricingPlanpageComponent },
      { path: 'myplan', component: MyPlanComponent, canActivate: [AuthGuard]  },
      { path: 'mywishlist', component: MyWishlistComponent, canActivate: [AuthGuard]  },
      { path: 'mywishlist-inner/:wishlist-id', component:MyWishlistInnerComponent, canActivate: [AuthGuard] },
      { path: 'added-wishlist-own-account/:wishlist-id', component: AddedWishlistOwnAccountComponent, canActivate: [AuthGuard] },
      { path: 'musics', component: MusicListingComponent },
      { path: 'music-filter', component: MusicFilterComponent },
      { path: 'music/:slug', component: MusicDetailsComponent },
      { path: 'image-filter', component: ImageFilterComponent },
      { path: 'image/:slug', component: ImageDescriptionComponent },
      { path: 'footage-listing-v1', component: FootageListingV1Component },
      { path: 'footages', component: FootageListingComponent },
      { path: 'footage-home', component: FootageHomeComponent },
      { path: 'footage-filter', component: FootageFilterComponent },
      { path: 'footage/:slug', component: FootageDetailsComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'licence-agreement', component: LicenceAgreementComponent },
      { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'categories', component: CategoryComponent },
      { path: 'categories/:type/:slug', component:CategoryDetailsComponent},

    ]
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
