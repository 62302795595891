<div id="tab-1">
    <form [formGroup]="ProfileForm" (ngSubmit)="onSubmit()">
        <div class="my-profile">
            <h2 class="h2">My Profile</h2>
        </div>
        <div class="user_section">
            <div class="user_info">
                <div class="user_icon">
                    <img src="assets/images/tab_user_icon.svg" alt="user" width="40" height="40">
                </div>
                <div class="user_name">
                    <span class="name">{{displayName}}</span>
                    <span class="user_id_name">Username: {{displayUserName}}</span>
                    <p class="email_id">{{displayEmailMobile}}</p>
                </div>
            </div>
            <div class="edit_info" [ngClass]="{'show_back_save_btn':profileSaveBtn == true}">
                <div class="edit_btn">
                    <a href="javascript:void(0)" class="edit_icon" (click)="toggleProfileSaveBtn('edit')">
                        <span><img src="assets/images/tab_edit.svg" alt="edit" width="24" height="24"></span>
                        <span class="edit">Edit</span>
                    </a>
                </div>
                <div class="back_save_btn" *ngIf="this.profileSaveBtn">
                    <button class="back" (click)="toggleProfileSaveBtn('back')">Back</button>
                    <button class="save">Save</button>
                </div>
            </div>
        </div>
        <div formGroupName="userData" class="user_form">
            <div class="user_input"
                [ngClass]="submittedProfileForm && ProfileForm.get('userData.first_name').errors?.required ? 'error_field' : ''">
                <label for="first_name">Name</label>
                <input id="first_name" type="text" placeholder="Enter Your Name" formControlName="first_name" required tabIndex='1'
                    [readOnly]="isFormReadOnly">
                <p class="error"
                    *ngIf="submittedProfileForm && ProfileForm.get('userData.first_name').errors?.required">* Please
                    enter valid name</p>
            </div>
            <div class="user_input"
                [ngClass]="submittedProfileForm && ProfileForm.get('userData.email').errors?.required ? 'error_field' : ''">
                <label for="email">Email</label>
                <input type="email" placeholder="Enter Your Email" formControlName="email" required
                    [readOnly]="isFormReadOnly" tabIndex='2'>
                <p class="error" *ngIf="submittedProfileForm && ProfileForm.get('userData.email').errors?.required">*
                    Please enter valid email address</p>
                <p class="error" *ngIf="submittedProfileForm && ProfileForm.get('userData.email').errors?.pattern">*
                    Please enter valid email address</p>
                <p class="error" *ngIf="submittedProfileForm && ProfileForm.get('userData.email').errors?.unique">*
                    This email is already exists.</p>
            </div>
            <div class="user_input"
                [ngClass]="submittedProfileForm && ProfileForm.get('userData.mobile').errors?.required ? 'error_field' : ''">
                <label for="contact">Contact No</label>
                <input id="mobile" type="number" placeholder="Enter Your Contact Number" formControlName="mobile"
                    required [readOnly]="isFormReadOnly" tabIndex='3'>
                <p class="error" *ngIf="submittedProfileForm && ProfileForm.get('userData.mobile').errors?.required">*
                    Please enter contact number</p>
                <p class="error" *ngIf="submittedProfileForm && ProfileForm.get('userData.mobile').errors?.pattern">*
                    Please enter valid contact number</p>
                <p class="error" *ngIf="submittedProfileForm && ProfileForm.get('userData.mobile').errors?.unique">*
                    This contact number is already exists.</p>
            </div>
            <div class="user_input"
                [ngClass]="submittedProfileForm && ProfileForm.get('userData.company').errors?.required ? 'error_field' : ''">
                <label for="text">Company Name</label>
                <input id="company" type="text" placeholder="Enter Your Company Name" formControlName="company"
                    [readOnly]="isFormReadOnly" tabIndex='4'>
                <p class="error" *ngIf="submittedProfileForm && ProfileForm.get('userData.company').errors?.required">*
                    Please enter company name</p>
            </div>

            <div class="user_input"
                [ngClass]="submittedProfileForm && ProfileForm.get('userData.gst').errors?.required ? 'error_field' : ''">
                <label for="text">Company GST Number</label>
                <input id="company_gst" type="text" placeholder="Enter Your Company GST Number" formControlName="gst"
                    [readOnly]="isFormReadOnly" tabIndex='5'>
                <p class="error" *ngIf="submittedProfileForm && ProfileForm.get('userData.gst').errors?.required">*
                    Please enter company GST number</p>
            </div>

            <div class="user_input"
                [ngClass]="submittedProfileForm && ProfileForm.get('userData.country').errors?.required ? 'error_field' : ''">
                <label for="text">Country</label>
                <a href="javascript:void(0)" class="info_img" *ngIf="!displayCountry">
                    <img src="assets/images/info.svg" alt="info" width="15" height="15">
                    <div class="popup_show">
                        <p>You can not be able to change country</p>
                    </div>
                </a>
                <div class="custom-select-main select-show">
                    <div *ngIf="!displayCountry" class="select">
                        <input id="country" type="text" placeholder="Enter Your country Name" formControlName="country" required
                            readonly tabIndex='6'>
                    </div>
                    <div class="select" *ngIf="displayCountry">
                        <select formControlName="country" [ngClass]="isCountryDisabled ? 'select-options disabled' : 'select-options'" (ngModelChange)="getStates($event)"
                            placeholder="Select Country" required tabIndex='6'>
                            <option value="" disabled>Select Country</option>
                            <option *ngFor="let country of countries_list" [value]="country.id">{{ country.name }}</option>
                        </select>
                        <div class="custom-select" (click)="toggleDropdownCountry()">
                            {{ selectedCountry || 'Select Country' }}
                        </div>
                        <ul class="select-options" [class.open]="dropdownOpenCountry">
                            <li *ngFor="let country of countries_list" (click)="selectCountry(country)">{{ country.name }}</li>
                        </ul>
                    </div>
                </div>
                <p class="error" *ngIf="submittedProfileForm && ProfileForm.get('userData.country').errors?.required">*
                    Please Select Country</p>
            </div>
            <div class="user_input"
                [ngClass]="submittedProfileForm && ProfileForm.get('userData.state').errors?.required ? 'error_field' : ''">
                <label for="text">State</label>
                <div class="custom-select-main select-show">
                    <div *ngIf="!displayState" class="select">
                        <input id="state" type="text" placeholder="Enter Your State Name" formControlName="state" required readonly tabIndex='7'>
                    </div>
                    <div class="select" *ngIf="displayState">
                        <select formControlName="state" [ngClass]="isStateDisabled ? 'select-options disabled' : 'select-options'" placeholder="Select State" required tabIndex='7'>
                            <option value="" disabled>Select State</option>
                            <option *ngFor="let state of states_list" [value]="state.id">{{ state.state }}</option>
                        </select>
                        <div class="custom-select" (click)="toggleDropdownState()">
                            {{ selectedState || 'Select State' }}
                        </div>
                        <ul class="select-options" [class.open]="dropdownOpenState">
                            <li *ngFor="let state of states_list" (click)="selectState(state)">{{ state.state }}</li>
                        </ul>
                    </div>
                </div>
                <p class="error" *ngIf="submittedProfileForm && ProfileForm.get('userData.state').errors?.required">*
                    Please Select State</p>
            </div>
            <div class="user_input"
                [ngClass]="submittedProfileForm && ProfileForm.get('userData.city').errors?.required ? 'error_field' : ''">
                <label for="text">City</label>
                <div class="custom-select-main select-show">
                    <div *ngIf="!displayCity" class="select">
                        <input id="city" type="text" placeholder="Enter Your city Name" formControlName="city" required readonly tabIndex='8'>
                    </div>
                    <div class="select" *ngIf="displayCity">
                        <select formControlName="city" [ngClass]="isCityDisabled ? 'select-options disabled' : 'select-options'"
                            placeholder="Select City" required tabIndex='8'>
                            <option value="" disabled>Select City</option>
                            <option *ngFor="let city of cities_list" [value]="city.id">{{ city.name }}</option>
                        </select>
                        <div class="custom-select" (click)="toggleDropdown()">
                            {{ selectedCity || 'Select City' }}
                        </div>
                        <ul class="select-options" [class.open]="dropdownOpen">
                            <li *ngFor="let city of cities_list" (click)="selectCity(city)">{{ city.name }}</li>
                        </ul>
                    </div>
                </div>
                <p class="error" *ngIf="submittedProfileForm && ProfileForm.get('userData.city').errors?.required">*
                    Please Select City</p>
            </div>
            <div class="user_input"
                [ngClass]="submittedProfileForm && ProfileForm.get('userData.address').errors?.required ? 'error_field' : ''">
                <label for="text">Address</label>
                <input id="address" type="text" placeholder="Enter Your Address" formControlName="address" required
                    [readOnly]="isFormReadOnly" tabIndex='9'>
                <p class="error" *ngIf="submittedProfileForm && ProfileForm.get('userData.address').errors?.required">*
                    Please enter Address</p>
            </div>
            <div class="user_input"
                [ngClass]="submittedProfileForm && ProfileForm.get('userData.address2').errors ? 'error_field' : ''">
                <label for="text">Address2</label>
                <input id="address2" type="text" placeholder="Enter Your Address2" formControlName="address2"
                    [readOnly]="isFormReadOnly" tabIndex='10'>
            </div>
            <div class="user_input"
                [ngClass]="submittedProfileForm && ProfileForm.get('userData.pincode').errors?.required ? 'error_field' : ''">
                <label for="text">Postal Code</label>
                <input id="pincode" type="number" placeholder="Enter Your Postal Code" formControlName="pincode"
                    required [readOnly]="isFormReadOnly" tabIndex='11'>
                <p class="error" *ngIf="submittedProfileForm && ProfileForm.get('userData.pincode').errors?.required">*
                    Please
                    enter Postal Code</p>
            </div>
        </div>
    </form>
</div>
