<section class="main-content-sec">
  <div class="container" >
    <div class="custom-row">
      <appv2-filter
        class="filter-block-left"
        (dataEmitted)="getFilterData($event)" [fromCategory] = "fromCategory"
      ></appv2-filter>
      <div class="content-block-right">
        <div class="banner-form-sec">
          <appv2-search-bar class="categories-seach-block-banner" [params]="{isHeaderPart: false}"></appv2-search-bar>

          <div class="cart-title-row">
            <div class="cart-col-left">
              <h1 class="h2">Music</h1>
              <span>{{ totalRecords }} Results</span>
            </div>
            <div class="cart-col-right">
              <div class="select-lice">
                <div class="select">
                  <select [(ngModel)]="selectedOption">
                    <option>Popular</option>
                    <option>Recent</option>
                  </select>
                  <div class="custom-select" (click)="toggleDropdown()">{{ selectedOption }}</div>
                  <ul class="select-options" [class.open]="dropdownOpen">
                    <li *ngFor="let option of options" (click)="selectOption(option)">{{ option }} </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="reset-filter-row">
            <ul>
              <li *ngFor="let filter of processedFilters">
                <ng-container *ngIf="filter.valuekey">
                  {{ filter.namekey }}:
                  <ng-container *ngFor="let value of filter.valuekey.split(','); let index=index">
                    {{ value }}
                    <span (click)="removeSpecificFilter(filter.key,index)"></span>
                  </ng-container>
                </ng-container>
              </li>
            </ul>
            <button class="thame-btn" (click)="resetFilter()" *ngIf="showReset">
              Reset Filter
            </button>
          </div>
        </div>
        <!-- music table start -->
        <div class="music_table" >
          <table *ngIf="totalRecords > 0" >
            <thead>
              <tr>
                <th>Title</th>
                <th>Type</th>
                <th>Duration</th>
                <th>BPM</th>
                <th>Format</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let music of musicData; let i = index" >
                <td>
                  <a
                    *ngIf="music['product_main_image']"
                    href="javascript:void(0)"
                    (click)="handleClick(i)"
                    class="play_btn"
                    ><img
                      [src]="
                        i == currentIndex && isPlaying
                          ? 'assets/images/circle-pause.svg'
                          : 'assets/images/play.svg'
                      "
                      alt="play-button"
                      width="30"
                      height="30"
                      class = 'play-btn'
                  /></a>
                  <span (click)="redirectToDetailsPage(music.slug)" [ngStyle]="{'cursor': 'pointer' }">{{ music["product_title"] }}</span>
                </td>
                <td>{{ music["random_three_keywords"] }}</td>
                <td>{{ music["music_duration"] }}</td>
                <td>{{ music["music_sound_bpm"] }}</td>
                <td>{{ music["music_fileType"] }}</td>
                <td class="music_icon">
                  <li class="like">
                    <a
                      class="btn btn-primary"
                      (click)="showModal(music)"
                      data-toggle="modal"
                      data-target="#likeModal"
                      class="btn_background"
                      style="cursor: pointer;"
                    >
                      <img *ngIf="!userWishlistedData.includes(music['product_thumbnail'])" src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart" />
                      <img *ngIf="userWishlistedData.includes(music['product_thumbnail'])" src="assets/images/black_heart.svg" alt="red_heart" width="24" height="24" class="black_heart"/>
                    </a>
                  </li>
                  <div class="music_icon_image" style="cursor: pointer;">
                    <a routerLink="/music/{{music['slug']}}" *ngIf="!displayDownload"
                  ><img
                    src="assets/images/shopping_cart.svg"
                    alt="like"
                    width="24"
                    height="24"
                /></a>
                <a routerLink="/music/{{music['slug']}}" *ngIf="displayDownload"
                  ><img
                    src="assets/images/download_plan.svg"
                    alt="like"
                    width="24"
                    height="24"
                /></a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="cart_pop_up" *ngIf="showCartPopup">
          <div class="cart_top">
            <h4 class="h4">Item Added To a Cart</h4>
            <span class="add_items">1/1</span>
          </div>
          <div class="cart_middle">
            <div class="pop_up_image">
              <picture>
                <source
                  srcset="assets/images/cart_pop_up.webp"
                  alt="cart-popup-image"
                  width="339"
                  height="140"
                />
                <source
                  srcset="assets/images/cart_pop_up.png"
                  alt="cart-popup-image"
                  width="339"
                  height="140"
                />
                <img
                  src="assets/images/cart_pop_up.png"
                  alt="cart-popup-image"
                  width="339"
                  height="140"
                />
              </picture>
            </div>
            <div class="plan_details">
              <div class="plan_details_left">
                <h4 class="h4">
                  {{

                    cartData?.product_title

                  }}
                </h4>
                <p>
                  {{

                    cartData?.music_duration

                  }}
                  |
                  {{
                    cartData?.music_sound_bpm?.length > 0
                      ? cartData?.music_sound_bpm
                      : "0"
                  }}
                  BPM
                </p>
              </div>
              <div class="plan_details_right">
                <span class="value"
                  >₹{{
                    cartData?.music_price?.length > 0
                      ? cartData?.music_price
                      : ""
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="cart_bottom">
            <h4 class="h4">
              Cart Subtotal: ₹{{
                cartData?.music_price?.length > 0
                  ? cartData?.music_price
                  : ""
              }}
            </h4>
            <div class="checkout">
              <a routerLink="/payment-method">Checkout</a>
            </div>
            <div class="view_cart">
              <a routerLink="/cart">View My Cart</a>
            </div>
          </div>
        </div>

        <div class="data-not-found" *ngIf="totalRecords == 0">
          <p>No Data Found</p>
        </div>

        <ng-container *ngIf="totalRecords > 0">
          <appv2-pagination [itemsPerPage]="itemsPerPage" [currentPage]="currPage" [totalPages]="totalPages"
                              (pageChanged)="onPageChange($event)"></appv2-pagination>
        </ng-container>
        <!-- music table end -->
      </div>
    </div>
  </div>
</section>
<!-- Add To Wishlist Modal -->
<add-to-wishlist
  [productData]="wishlistData"
  (dataEvent)="handleWishlistModalEvent($event)"
  *ngIf="showAddToWishListModal"
>
</add-to-wishlist>
<!-- ./Add To Wishlist Modal -->

<!--music player-->
<appv2-music-player [musicList]="musicData" [currentMusicIndex]="currentIndex" [isPlaying]= "isPlaying"*ngIf="musicData && displayMusicPlayer" (dataEvent)="handleClickPlay($event)" [userWishlistedData]="userWishlistedData" [displayDownload] = "displayDownload"></appv2-music-player>
