<!-- Simple and Transparent Pricing for All section start -->
<appv2-simple-pricing></appv2-simple-pricing>
<!-- Simple and Transparent Pricing for All section end -->
<!-- Subscription plan section start -->
<section class="pricing_subscription_plan" *ngIf="displayPricingHeader">
    <div class="container">
        <appv2-pricing-filter [params]="{openTab:'Image',singleTab:''}" (pricingHeaderDisplayEvent)="receivePricingHeaderDisplayEvent($event)"></appv2-pricing-filter>
    </div>
</section>

<!-- Are you a team section start -->
<appv2-team-pricing></appv2-team-pricing>
<!-- Are you a team section end -->
<!-- Easy and secure payment section start -->
<appv2-secure-info-pricing></appv2-secure-info-pricing>
<!-- Easy and secure payment section end -->
<!-- Still have questions section start -->
<appv2-questions></appv2-questions>