<button class="btn-search" id="mobile-search" (click)="toggleDisplay()">
  <img
    id="search-btn-head"
    src="assets/images/search-icon.svg"
    alt="search icon"
    width="14"
    height="14"
  />
</button>
<div #searchButton [ngClass]="isHeaderPart ? 'search-filter-form-main' : 'search-filter-form-main-banner'">
  <form class="search-filter-form">
    <div class="custom-select-wrapper">
      <div
        class="custom-select"
        [ngClass]="openClass"
        (click)="clickOpenMenu()"
      >
        <div class="custom-select__trigger">
          <span *ngIf="mainPageType?.includes('image')"
            ><img
              src="assets/images/img-icon.svg"
              alt="Images"
              width="16"
              height="16"
            />
            Images</span
          >
          <span *ngIf="mainPageType?.includes('footage')"
            ><img
              src="assets/images/footages-icon.svg"
              alt="Footages"
              width="16"
              height="16"
            />
            Footages</span
          >
          <span *ngIf="mainPageType?.includes('editorial')"
            ><img
              src="assets/images/editorial-icon.svg"
              alt="Editorial"
              width="16"
              height="16"
            />
            Editorial</span
          >
          <span *ngIf="mainPageType?.includes('music')"
            ><img
              src="assets/images/music-icon.svg"
              alt="Music"
              width="16"
              height="16"
            />
            Music</span
          >
        </div>
        <div class="custom-options custom-select__trigger">
          <span
            class="custom-option"
            data-value="images"
            (click)="searchEditorial('image')"
            ><img
              src="assets/images/img-icon.svg"
              alt="Images"
              width="16"
              height="16"
            />
            Images</span
          >
          <span
            class="custom-option"
            data-value="footages"
            (click)="searchEditorial('footage')"
            ><img
              src="assets/images/footages-icon.svg"
              alt="Footages"
              width="16"
              height="16"
            />
            Footages</span
          >
          <span
            class="custom-option"
            data-value="editorial"
            (click)="searchEditorial('editorial')"
            ><img
              src="assets/images/editorial-icon.svg"
              alt="Editorial"
              width="16"
              height="16"
            />
            Editorial</span
          >
          <span
            class="custom-option"
            data-value="music"
            (click)="searchEditorial('music')"
            ><img
              src="assets/images/music-icon.svg"
              alt="Music"
              width="16"
              height="16"
            />
            Music</span
          >
        </div>
      </div>
    </div>
    <div class="search-input-block" id="search-input-wrapper">
      <button class="btn-search">
        <a (click)="onEnterSearch()"
          ><img
            src="assets/images/search-icon.svg"
            alt="search icon"
            width="13"
            height="13"
        /></a>
      </button>
      <input
        class="input-search"
        type="text"
        name="searchtext"
        [(ngModel)]="searchtext"
        placeholder="Search for images, footages etc..."
        (keyup)="onKeyPress($event)"
        (click)="onMouseInSearchBox()"
        (input)="onInput($event)"
        autocomplete="off"
      />
    </div>

    <div id="trending-search-wrapper" (mouseleave)="onMouseOutSearchBox()">
      <div id="main-search-area" *ngIf="visibleTrendingSearch && ((recentSearches && recentSearches.length > 0) || (trendingKeyword && trendingKeyword.length > 0))">
        <div class="search-category-list" *ngIf="recentSearches && recentSearches.length > 0">
          <span class="title-heading">Recent Searches</span>
          <ul class="category-list">
            <li *ngFor="let words of recentSearches">
              <a (click)="openCategory(words)"
                ><span>{{ words }}</span></a
              >
            </li>
          </ul>
        </div>
        <div class="search-category-list" *ngIf="trendingKeyword.length > 0">
          <span class="title-heading">Trending Searches</span>
          <ul class="category-list">
            <li *ngFor="let trendingWord of trendingKeyword">
              <a (click)="openCategory(trendingWord.name)"
                ><img
                  src="assets/images/trending-icon.svg"
                  alt="trending icon"
                  width="16"
                  height="16"
                /><span>{{ trendingWord.name }}</span></a
              >
            </li>
          </ul>
        </div>
      </div>
      <ul class="category-serch-list" *ngIf="showTrendSerach">
        <li *ngFor="let suggestion of filterElasticSuggestion">
          <a (click)="openCategory(suggestion._source.search)">
            <span>{{ suggestion._source.search }}</span>
          </a>
        </li>
      </ul>
      <ul class="category-serch-list" *ngIf="showMusicTrendSerach">
        <li *ngFor="let suggestion of filterElasticMusicSuggestion">
          <a (click)="openCategory(suggestion)">
            <span>{{ suggestion }}</span>
          </a>
        </li>
      </ul>
    </div>
  </form>
  <div class="input-image-popup">
    <div class="upper-section">
      <span>Visual Search</span>
      <span><a href="javascript:void(0)">Need help?</a></span>
    </div>
    <div class="middle-section">
      <div class="popup-image-box">
        <a href="javascript:void()">
          <input type="file" />
          <div class="popup-image">
            <picture>
              <source
                srcset="assets/images/input-popup-image.webp"
                alt="input-popup-image"
                width="236"
                height="178"
              />
              <source
                srcset="assets/images/input-popup-image.png"
                alt="input-popup-image"
                width="236"
                height="178"
              />
              <img
                src="assets/images/input-popup-image.png"
                alt="input-popup-image"
                width="236"
                height="178"
              />
            </picture>
          </div>
          <div class="popup-content">
            <p>
              Drag and drop your image here or
              <span class="browse">Browse</span> to choose a file.
            </p>
          </div>
        </a>
      </div>
      <div class="or">or</div>
      <div class="popup-input">
        <form action="">
          <button>
            <img
              src="assets/images/attach-file.svg"
              alt="attach-file"
              width="20"
              height="20"
            />
          </button>
          <input type="text" placeholder="Paste an image or a URL" />
        </form>
      </div>
    </div>
    <div class="bottom-image-section">
      <span>Or try one of the examples below:</span>
      <div class="bottom-image">
        <div class="image-example">
          <a href="javascript:void(0)">
            <picture>
              <source
                srcset="assets/images/wishlist-collection3.webp"
                alt="input-popup-image"
                width="82"
                height="56"
              />
              <source
                srcset="assets/images/wishlist-collection3.jpg"
                alt="input-popup-image"
                width="82"
                height="56"
              />
              <img
                src="assets/images/wishlist-collection3.jpg"
                alt="input-popup-image"
                width="82"
                height="56"
              />
            </picture>
          </a>
        </div>
        <div class="image-example">
          <a href="javascript:void(0)">
            <picture>
              <source
                srcset="assets/images/wishlist-collection19.webp"
                alt="input-popup-image"
                width="82"
                height="56"
              />
              <source
                srcset="assets/images/wishlist-collection19.jpg"
                alt="input-popup-image"
                width="82"
                height="56"
              />
              <img
                src="assets/images/wishlist-collection19.jpg"
                alt="input-popup-image"
                width="82"
                height="56"
              />
            </picture>
          </a>
        </div>
        <div class="image-example">
          <a href="javascript:void(0)">
            <picture>
              <source
                srcset="assets/images/similar_footages_11.webp"
                alt="input-popup-image"
                width="82"
                height="56"
              />
              <source
                srcset="assets/images/similar_footages_11.jpg"
                alt="input-popup-image"
                width="82"
                height="56"
              />
              <img
                src="assets/images/similar_footages_11.jpg"
                alt="input-popup-image"
                width="82"
                height="56"
              />
            </picture>
          </a>
        </div>
        <div class="image-example">
          <a href="javascript:void(0)">
            <picture>
              <source
                srcset="assets/images/list-img4.webp"
                alt="input-popup-image"
                width="82"
                height="56"
              />
              <source
                srcset="assets/imageslist-img4.jpg"
                alt="input-popup-image"
                width="82"
                height="56"
              />
              <img
                src="assets/images/list-img4.jpg"
                alt="input-popup-image"
                width="82"
                height="56"
              />
            </picture>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
