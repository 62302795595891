<div class="sign_in_reset_password_page">
  <!-- Sign in reset password page image grid section start -->
  <div class="sign_in_page_image_grid">
    <div class="sign_in_page_grid">
      <div class="sign_in_page_grid_1">
        <div class="landscape_image_grid">
          <div>
            <picture
              ><img
                src="assets/images/sign_in_img_1.webp"
                alt="sign-in-image"
                width="307"
                height="220"
              />
            </picture>
          </div>
          <div>
            <picture
              ><img
                src="assets/images/sign_in_img_3.webp"
                alt="sign-in-image"
                width="307"
                height="220"
              />
            </picture>
          </div>
        </div>
        <div class="portrait_image_grid main_image">
          <picture
            ><img
              src="assets/images/sign_in_img_2.webp"
              alt="sign-in-image"
              width="307"
              height="448"
            />
          </picture>
        </div>
        <div class="landscape_image_grid">
          <div>
            <picture
              ><img
                src="assets/images/sign_in_img_4.webp"
                alt="sign-in-image"
                width="307"
                height="220"
              />
            </picture>
          </div>
          <div>
            <picture
              ><img
                src="assets/images/Placehoder_Gray.jpg"
                alt="sign-in-image"
                width="307"
                height="220"
              />
            </picture>
          </div>
        </div>
      </div>
      <div class="sign_in_page_grid_2">
        <div class="landscape_image_grid">
          <div class="landscape_image">
            <picture
              ><img
                src="assets/images/sign_in_img_7.webp"
                alt="sign-in-image"
                width="464"
                height="278"
              />
            </picture>
          </div>
          <div class="landscape_image">
            <picture
              ><img
                src="assets/images/sign_in_img_8.webp"
                alt="sign-in-image"
                width="464"
                height="278"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
    <div class="sign_in_form_content">
      <div class="grid_footer_logo">
        <a href="index.html"
          ><img
            src="assets/images/Logo.png"
            alt="logo-image"
            width="216"
            height="39"
        /></a>
      </div>
      <h2 class="h2">Download 10k+ Photos, Videos and editors choice</h2>
      <p>
        Access all the images, videos, music, and tools you need to turn ideas
        into achievements. All images
      </p>
    </div>
  </div>
  <!-- Sign in reset password page image grid section end -->
  <!-- Sign in reset password page form section start -->
  <div class="sign_in_reset_password_page_image_form">
    <div class="reset_password_section">
      <h2 class="h2">Reset Password</h2>
      <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
        <div class="form_group" [ngClass]="{ 'error_field': submitted && f.password.errors }">
          <label for="email">Password</label>
          <div class="eye_icon">
            <input
              [type]="passwordShow ? 'text' : 'password'"
              placeholder="Enter your password"
              formControlName="password"

            />
            <a (click)="hideShowPassword()"
              ><img
                [src]="
                  passwordShow
                    ? 'assets/images/eyeopen.svg'
                    : 'assets/images/eyeclose.svg'
                "
                class="eyeclose"
                alt="eyeclose"
                width="21"
                height="11"
            /></a>
            <div *ngIf="!submitted" class="invalid-feedback">
              <p class="hint">
                * Password contain at least one letter, one number, one
                special character and 8 characters or longer.
              </p>
            </div>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <p *ngIf="f.password.errors.required" class="error">
                * Please enter valid password
              </p>
              <p *ngIf="f.password.errors.pattern" class="error">
                * Please enter valid password
              </p>
            </div>
          </div>
        </div>
        <div class="form_group form_group_lastchild" [ngClass]="{ 'error_field': submitted && f.passwordConfirm.errors }">
          <label for="email">Confirm Password</label>
          <div class="eye_icon">
            <input
              [type]="cpasswordShow ? 'text' : 'password'"
              placeholder="Enter your password"
              formControlName="passwordConfirm"

            />
            <a (click)="hideShowConfirmPassword()"
              ><img
                [src]="
                  cpasswordShow
                    ? 'assets/images/eyeopen.svg'
                    : 'assets/images/eyeclose.svg'
                "
                class="eyeclose"
                alt="eyeclose"
                width="21"
                height="11"
            /></a>
            <div *ngIf="submitted && f.passwordConfirm.errors" class="invalid-feedback">
              <p *ngIf="f.passwordConfirm.errors.required" class="error">
                * Please enter valid confirm password
              </p>
              <p *ngIf="f.passwordConfirm.errors.mustMatch" class="error">
                * Password and confirm password must match
              </p>
            </div>
          </div>
        </div>
        <button class="submit_btn">Reset Password</button>
      </form>
      <a routerLink="/signin" class="back_to_sign_in">Back to Sign In</a>
    </div>
  </div>
  <!-- Sign in reset password page form section end -->
</div>
