import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { messages } from 'src/appv2/appv2.constants';
import { User } from 'src/appv2/models/user';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';
import { ToastService } from 'src/appv2/services/toast.service';

const passwordRegex = /^(?=.*?[a-zA-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&+*-.=<>()|^]).{8,}$/;
@Component({
  selector: 'appv2-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {

  @Output() setTabName = new EventEmitter<any>();

  user: User;

  data: Array<any>;
  message: any = { general: "" }
  token: string | undefined;
  loginUserId: number = 0;
  tokenData: any = { Utype: this.loginUserId };
  SecurityForm: FormGroup;
  isOldPasswordVisible: boolean = false;
  isNewPasswordVisible: boolean = false;
  isCPasswordVisible: boolean = false;
  submittedSecurityForm: Boolean = false;
  passwordsDoNotMatch: Boolean = false;

  constructor(private store$: Store<any>, private accountActions: AccountService, private router: Router, private toastService: ToastService) {
    this.data = new Array<any>()
    this.token = undefined;
    this.user = this.accountActions.userValue;
    this.loginUserId = this.user?.userdata.Utype;
  }

  ngOnInit(): void {
    this.initSecurityForm()
  }

  initSecurityForm(): void {
    this.SecurityForm = new FormGroup({
      'userData': new FormGroup({
        'old_pass': new FormControl('', [Validators.required]),
        'password': new FormControl('', [Validators.required, Validators.pattern(passwordRegex)]),
        'cpassword': new FormControl('', [Validators.required])
      })
    });
  }
  onConfirmPasswordTouched() {
    if (this.SecurityForm.value['userData']['password'] != this.SecurityForm.value['userData']['cpassword']) {
      this.passwordsDoNotMatch = true
    } else {
      this.passwordsDoNotMatch = false
    }
  }
  toggleOldPasswordVisibility() {
    this.isOldPasswordVisible = !this.isOldPasswordVisible;
  }
  toggleNewPasswordVisibility() {
    this.isNewPasswordVisible = !this.isNewPasswordVisible;
  }
  toggleCPasswordVisibility() {
    this.isCPasswordVisible = !this.isCPasswordVisible;
  }

  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.securityLoading}))
    return
  }

  public onSecurityFormSubmit() {
    this.submittedSecurityForm = true;
    this.updateSecurityForm(this.SecurityForm.value['userData']);
  }

  updateSecurityForm(formData): void {
    if (this.SecurityForm.valid) {
      if (this.SecurityForm.value['userData']['password'] != this.SecurityForm.value['userData']['cpassword']) {
        this.passwordsDoNotMatch = true
      } else {
        this.passwordsDoNotMatch = false
        this.loading(true);
        this.accountActions.passwordChange(formData, this.loginUserId).subscribe((data) => {
          this.loading(false);
          if (data.status == 1) {
            this.SecurityForm.patchValue({
              userData: {
                old_pass: '',
                password: '',
                cpassword: ''
              }
            });
            this.submittedSecurityForm = false;
            this.toastService.success(data['message'])
          } else {
            this.toastService.warning(data['message'])
          }

          Object.keys(data).forEach((item) => {
            this.message[item] = Array.isArray(data[item]) ? data[item][0] : data[item];
          });
        });
      }
    }
  }

  public emitTabName(name = '') {
    this.setTabName.emit(name);
  }

}
