import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { messages } from 'src/appv2/appv2.constants';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { SearchWriteAction, SearchchangeSearchName } from 'src/appv2/reducers/search/search.actions';
import { SearchState } from 'src/appv2/reducers/search/search.models';
import { LandingPageService } from 'src/appv2/services/landing-page.service';

@Component({
  selector: 'appv2-footage-listing-v1',
  templateUrl: './footage-listing-v1.component.html',
  styleUrls: ['./footage-listing-v1.component.scss']
})
export class FootageListingV1Component implements OnInit {

  /**
 * Open class of header component.
 */
  openClass: string = '';

  visibleTrendingSearch: boolean;
  showTrendSerach: boolean = false
  mainPageType: string = 'footage';
  recentSearches = []
  page_type: string;
  elasticSuggestions: any;
  trendingKeyword: any;
  searchtext: string = "";
  timeout: any = null;

  constructor(private router: Router, private route: ActivatedRoute, private landingPage: LandingPageService, private store$: Store<any>) {

    this.route.queryParams.subscribe(params => {
      const search = params['search'];
      this.openCategory(search);
    });
    // this.storeKeywordInElasticSearch(this.searchtext);
  }

  ngOnInit(): void {
    const apiResponse = {
      "status": "success",
      "message": "List of elastic search words",
      "data": [
        { "_index": "elasticsearch", "_type": "_doc", "_id": "_h8asYkBt8TcZzn_jNiT", "_score": 0.6103343, "_source": { "search": "flowers" } },
        { "_index": "elasticsearch", "_type": "_doc", "_id": "_x8dsYkBt8TcZzn_cNhj", "_score": 0.45859373, "_source": { "search": "beautiful flowers" } },
        { "_index": "elasticsearch", "_type": "_doc", "_id": "AB8lsYkBt8TcZzn_Mtnr", "_score": 0.45859373, "_source": { "search": "beautiful flowers" } }
      ]
    };

    this.elasticSuggestions = apiResponse.data;
    this.recentSearches = JSON.parse(localStorage.getItem('recent_search'));

  }

  /**
   * Loading footage listing component
   * @param status
   */
  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.footagePageV1Loading}))
    return
  }

  /**
   * Store keyword in elastic search API
   * @param keyword
   */
  storeKeywordInElasticSearch(keyword) {
    const bodyParams = {
      search_field: keyword
    };
    // this.loading(true);
    this.landingPage.storeKeywordInElasticSearch(bodyParams).subscribe(data => {
      this.loading(false);
    });
  }



  onMouseInSearchBox() {
    this.visibleTrendingSearch = true
  }

  onMouseOutSearchBox() {
    this.visibleTrendingSearch = false
    this.showTrendSerach = false;
  }


  /**
     * Determines whether key press on
     * @param event
     */
  onKeyPress(event: any) {
    clearTimeout(this.timeout);
    this.openClass = '';
    this.timeout = setTimeout(() => {
      this.searchtext = event.target.value;
      if (this.searchtext && this.searchtext.trim().length != 0) {
        // this.getSuggestionByElasticSearch(this.searchtext);
        this.showTrendSerach = true;
        this.visibleTrendingSearch = false;
      } else {
        this.showTrendSerach = false;
        this.visibleTrendingSearch = true;
      }
    }, 1000);
    if (event.keyCode == 13) {
      this.showTrendSerach = false;
      this.storeKeywordInElasticSearch(this.searchtext);
      this.onEnterSearch();
    }

  }

  onEnterSearch() {
    // save to local storage
    let recentSearchData = JSON.parse(localStorage.getItem('recent_search'));
    this.recentSearches = recentSearchData
    if (this.searchtext) {
      if (!recentSearchData) {
        recentSearchData = [];
      }
      if (recentSearchData.length > 4) {
        recentSearchData.shift();
      }
      if (this.searchtext) {
        recentSearchData.push(this.searchtext);
      }
      localStorage.setItem('recent_search', JSON.stringify(recentSearchData));
      if (this.page_type == 'image' || this.mainPageType == "image") {
        const queryParams = { search: this.searchtext };

        const navigationExtras: NavigationExtras = {
          queryParams: queryParams
        };
        this.router.navigate(['/images'], navigationExtras);
        // this.router.navigate(['/images'], { state: navigationData });
      } else {
        this.storeKeywordInElasticSearch(this.searchtext);
        this.openCategory(this.searchtext);
      }
    }
  }
  clickOpenMenu() {
    this.visibleTrendingSearch = false;
    this.showTrendSerach = false;
    if (this.openClass === 'open') {
      this.openClass = '';
    } else {
      this.openClass = 'open';
    }
  }

  searchEditorial(page) {
    this.mainPageType = page;
    this.store$.dispatch(new SearchchangeSearchName(<SearchState>{ searchType: page }));
    //this.router.navigate(['/search'], { queryParams: { type: page, product_editorial: 1 } });
  }

  /**
   * Open category.
   * @param catName
   */
  openCategory(catName) {
    this.searchtext = catName;
    this.store$.dispatch(new SearchWriteAction(<SearchState>{ searchText: 'category ' + catName }));
  }

}
