import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { messages } from 'src/appv2/appv2.constants';
import { DiscountDetailsInterface } from 'src/appv2/interfaces/discount-details.interface';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { CacheService } from 'src/appv2/services/cache.service';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { urlToCacheKeyMapping } from 'src/appv2/utils/constants/discount.const';

@Component({
  selector: 'appv2-footage-home',
  templateUrl: './footage-home.component.html',
  styleUrls: ['./footage-home.component.scss']
})
export class FootageHomeComponent implements OnInit {


  promotion: any;
  discountDetailsResponse: any;
  public discountDetails = new DiscountDetailsInterface();
  page_type: string;
  cacheKey: string;
  page: string;

  constructor(private landingPage: LandingPageService, private store$: Store<any>, private route: ActivatedRoute, private cacheService: CacheService) { }

  ngOnInit(): void {
    const urlSegments = this.route.snapshot.url;
    // Get the last segment (the segment at the end of the URL)
    const lastSegment = urlSegments[urlSegments.length - 1];
    // Access the last segment's path
    const lastSegmentPath = lastSegment.path;
    // Now you can use the lastSegmentPath variable as needed
    this.page = lastSegmentPath;

    this.page_type = lastSegmentPath + '_page';
    this.getPromotion();

    // Get Discount Details 
    this.cacheKey = urlToCacheKeyMapping[this.page];
    this.landingPage.isDiscountDetailsCalled.subscribe((isDiscountCalled: boolean) => {
      if (isDiscountCalled) {
        this.discountDetails = this.cacheService.getData(this.cacheKey);
      }
    })
  }

  getPromotion() {
    this.loading(true);
    this.landingPage.getPromotionDetails(this.page_type).subscribe(data => {
      this.loading(false);
      this.promotion = data.data

    })
  }

  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.footageHomeLoading}))
    return
  }
}
