<div id="tab-3">
  <div class="purchase-history-heading">
    <h2 class="h2">Purchase History</h2>
  </div>
  <div class="purchase-history-dropdown">
    <div class="time-range">
      <div class="cart-col-right">
        <div class="select-lice-title">Time Range:</div>
        <div class="select-lice">
          <div class="select">
            <select class="custom-select-dropdown">
              <option value="all">All</option>
              <option value="today">Today</option>
              <option value="last_week">Last Week</option>
              <option value="last_month">Last Month</option>
              <option value="custom">Custom Range</option>
            </select>
            <div class="custom-select" (click)="toggleDropdown()">{{ selectedOption }}</div>
            <ul class="select-options" [class.open]="dropdownOpen">
              <li *ngFor="let option of options" (click)="onRangeChange(option)">{{ option }}</li>
            </ul>
          </div>
        </div>
      </div>
      <link href="https://cdn.syncfusion.com/ej2/material.css" rel="stylesheet" />
      <div [style.display]="IsDatePicker ? 'block' : 'none'">
        <ejs-daterangepicker id='daterangepicker' placeholder='Select a range'
          (change)="getDater($event)"></ejs-daterangepicker>
      </div>
    </div>
    <div class="media-licence-type">
      <div class="media-type cart-col-right">
        <div class="select-lice-title">Media Type:</div>
        <div class="select-lice">
          <div class="select">
            <select class="custom-select-dropdown">
              <option value="All">All</option>
              <option value="Image">Image</option>
              <option value="Footage">Footage</option>
              <option value="Editorial">Editorial</option>
            </select>
            <div class="custom-select" (click)="toggleDropdownMedia()">{{ selectedOptionMedia }}</div>
            <ul class="select-options" [class.open]="dropdownOpenMedia">
              <li *ngFor="let moption of media_options" (click)="onMediaChange(moption)">{{ moption }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="licence-type cart-col-right">
        <div class="select-lice-title">Licence Type:</div>
        <div class="select-lice" [class.disabled]="media_type_all == true">
          <div class="select">
            <select class="custom-select-dropdown">
              <option value="" disabled>Select Type</option>
              <option [value]="loption.slug" *ngFor="let loption of licence_options">{{ loption.name }}</option>
            </select>
            <div class="custom-select" (click)="toggleDropdownLic()">{{ selectedOptionLic }}</div>
            <ul class="select-options" [class.open]="dropdownOpenLic">
              <li *ngFor="let loption of licence_options" (click)="onLicenceChange(loption.id,loption.name)">{{
                loption.name }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="gallery-iterator">
    <div *ngFor="
        let item of purchaseHistory['data']
          | paginate
            : {
                id: 'listing_pagination',
                itemsPerPage: 10,
                currentPage: currPage,
                totalItems: totalRecords
              }
      "></div>
  </div>
  <div class="purchase-history-table">
    <table>
      <thead>
        <tr>
          <th>Order No</th>
          <th>Order Date</th>
          <th>Transaction ID</th>
          <th>Price</th>
          <th>Payment Gateway</th>
          <th>Invoice</th>
        </tr>
      </thead>
      <tbody *ngFor="let purchase of purchaseHistory['data']; let rowId = index">
        <tr class="header-level">
          <td (click)="toggleRow(purchase.id)">#IMG {{ purchase.id }}</td>
          <td (click)="toggleRow(purchase.id)">{{ purchase.order_date }}</td>
          <td (click)="toggleRow(purchase.id)">{{ purchase.txn_id }}</td>
          <td (click)="toggleRow(purchase.id)">{{ purchase.order_total }}</td>
          <td (click)="toggleRow(purchase.id)">{{ purchase.paymentgatway }}</td>
          <td class="invoice_icon">
            <div class="invoice_icon_image">
              <a [href]="purchase.invoice" class="eye" target="_blank" *ngIf="purchase.invoice"
                rel="noopener noreferrer">
                <img src="assets/images/invoice_eye.svg" alt="eye" width="24" height="24" />
              </a>
            </div>
            <div class="invoice_icon_image" *ngIf="purchase.invoice">
              <button (click)="downloadFile(purchase.invoice,purchase.txn_id)"><img
                  src="assets/images/download_plan.svg" alt="download" width="24" height="24" />
              </button>
            </div>
          </td>
        </tr>
        <tr [ngClass]="purchase.id == currentRow && isActive ? '' : 'sub-level'">
          <td colspan="6">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Product ID</th>
                  <th>Media Type</th>
                  <th>Licence Type</th>
                  <th>Resolution</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of purchase.items">
                  <td class="small-image">
                    <img *ngIf="item?.product?.product_thumbnail" [src]="item.product.product_thumbnail"
                      alt="product-image" width="58" height="58" />
                  </td>
                  <td>{{ item?.product?.product_id || "-" }}</td>
                  <td>{{ item?.product?.product_main_type || "-"}}</td>
                  <td>{{ item?.licence?.licence_name || "-"}}</td>
                  <td>{{ item?.standard_size || "-" }}</td>
                  <td>{{ item?.total || "-"}}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr class="header-level" *ngIf="totalRecords == 0">
          <td colspan="6" style="text-align: center;">No purchased orders found!</td>
        </tr>
      </tbody>
    </table>
    <!-- <section class="pagination" *ngIf="totalRecords != 0 && totalRecords != undefined">
      <div class="row">
        <div class="col-md-12">
          <pagination-template #p="paginationApi" id="listing_pagination" (pageChange)="page = $event">
            <div class="custom-pagination">
              <div class="pagination-previous" [ngClass]="{'disabled': currPage === 1}">
                <i class="fas fa-angle-left"></i><span *ngIf="currPage !== 0"
                  (click)="p.previous(); toPrevPage()"></span>
              </div>
              <div class="page-number" *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                <span (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">{{ currPage }}</span>
                <div *ngIf="p.getCurrent() === page.value">
                  <span>{{ currPage }}</span>
                </div>
              </div>
              <div class="pagination-next" [class.disabled]="p.isLastPage()">
                <i class="fas fa-angle-right"></i><span *ngIf="!p.isLastPage()" (click)="p.next(); toNextPage()"></span>
              </div>
              <span class="all">of {{ p.getLastPage() }}</span>
            </div>
          </pagination-template>
        </div>
      </div>
    </section> -->
    <ng-container *ngIf="totalRecords > 0">
      <appv2-pagination [itemsPerPage]="itemsPerPage" [currentPage]="currPage" [totalPages]="totalPages"
                      (pageChanged)="onPageChange($event)"></appv2-pagination>
    </ng-container>
  </div>
</div>
