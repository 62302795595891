import { createFeatureSelector, createSelector } from '@ngrx/store';
import { searchNode } from './search.reducer';
import { SearchState } from './search.models';
export const selectSearchFeature = createFeatureSelector<SearchState>(searchNode);

// Here we create selectors to be able to show data from store in our components.

// selector fo text
export const selectSearchText = createSelector(
  selectSearchFeature,
  (state: SearchState): string => state.searchText
);


// selector for search submenu
export const selectSearchType = createSelector(
  selectSearchFeature,
  (state: SearchState): boolean => state.isWithDropdown
);
export const selectSearchName = createSelector(
  selectSearchFeature,
  (state: SearchState): string => state.searchType
);
export const selectSearchVisibility = createSelector(
  selectSearchFeature,
  (state: SearchState): boolean => state.searchVisibile
);
export const selectIsSearchEditorial = createSelector(
  selectSearchFeature,
  (state: SearchState): boolean => state.isEditorial
);