import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from 'src/appv2/models/user';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';
import { FormBuilder } from '@angular/forms';
import { ElementRef, HostListener, Renderer2 } from '@angular/core';


@Component({
  selector: 'appv2-my-plan',
  templateUrl: './my-plan.component.html',
  styleUrls: ['./my-plan.component.scss']
})
export class MyPlanComponent implements OnInit {

  userPlans:Array<object>
  user: User;
  range: String = 'all';
  IsDatePicker: Boolean = false;
  start: Date = new Date();
  end: Date = new Date();
  selectedOption: String = 'All';
  dropdownOpen = false;
  options = ['All','Today', 'Last Week', 'Last Month', 'Custom Range'];
  itemsPerPage = Number;
  totalRecords: Number
  page: number = 1
  currPage: number = 1
  prevPage: number = 1

  constructor(private store$: Store<any>, private userAccount: AccountService, private accountActions: AccountService, private datePipe: DatePipe,private http: HttpClient,private formBuilder: FormBuilder, private renderer: Renderer2, private el: ElementRef) {
    this.user = this.accountActions.userValue;
  }

  ngOnInit(): void {
    this.getMyPlan();
  }

  onRangeChange(value: any) {
    this.selectedOption = this.range = value;
    this.dropdownOpen = false;
    if (this.range === 'Today') {
      this.range = 'today';
      this.IsDatePicker = false;
      this.getMyPlan();
    } else if (this.range === 'Last Week') {
      this.range = 'last_week';
      this.IsDatePicker = false;
      this.getMyPlan();
    } else if (this.range === 'Last Month') {
      this.range = 'last_month';
      this.IsDatePicker = false;
      this.getMyPlan();
    } else if (this.range === 'Custom Range') {
      this.range = 'custom';
      this.IsDatePicker = true;
    } else {
      this.range = 'all';
      this.IsDatePicker = false;
      this.getMyPlan();
    }
  }

   toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.closeDropdown();
    }
  }

  closeDropdown(): void {
    this.dropdownOpen = false;
  }

  getMyPlan(startDate = null, endDate = null)
  {
    let payload = {};
    payload['user_id'] = this.user.userdata.Utype; //48;
    payload['range'] = this.range;
    payload['start_date'] = startDate;
    payload['end_date'] = endDate;
    this.userAccount.getMyPlan(payload,this.currPage).subscribe(data => {
      this.userPlans = data['data']['data']

      this.itemsPerPage = data['data']['per_page']
      this.totalRecords = data['data']['total']
      this.store$.dispatch(new loadingChangeAction(<LoadingState> {loadingStatus: false}))
    })
  }

  getDater(data) {
    let rangeStartDate = new Date(data.startDate);
    let rangeEndDate = new Date(data.endDate);
    let startDate = this.datePipe.transform(rangeStartDate,"yyyy-MM-dd");
    let endDate = this.datePipe.transform(rangeEndDate,"yyyy-MM-dd");
    this.getMyPlan(startDate, endDate);
  }

  downloadFile(url, name) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState> {loadingStatus: true}))
    const filename = name;

    this.http.get(url, { responseType: 'blob' }).subscribe((blob: Blob) => {
      this.store$.dispatch(new loadingChangeAction(<LoadingState> {loadingStatus: false}))
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = filename;
      a.click();

      window.URL.revokeObjectURL(blobUrl);
    });
  }
  get totalPages(): number {
    return Math.ceil(Number(this.totalRecords) / Number(this.itemsPerPage));
  }

  onPageChange(newPage: number): void {
    this.currPage = Number(newPage);
    this.itemsPerPage = this.itemsPerPage;

    if (this.currPage < 1) {
      this.currPage = 1;
    } else if (this.currPage > this.totalPages) {
      this.currPage = this.totalPages;
    }

    this.getMyPlan();
  }
}
