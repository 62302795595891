<section class="simple_plan_section">
    <div class="container">
        <h1 class="h1">Simple and Transparent Pricing for All</h1>
        <div class="simple_plan_image_setion">
            <div class="simple_plan_image_box">
                <div class="simple_plan_image">
                    <picture>
                        <source srcset="assets/images/pricing_section_img_1.webp" alt="pricing-section-image"
                            width="397" height="324" />
                        <source srcset="assets/images/pricing_section_img_1.jpg" alt="pricing-section-image" width="397"
                            height="324" />
                        <img src="assets/images/pricing_section_img_1.jpg" alt="pricing-section-image" width="397"
                            height="324" />
                    </picture>
                </div>
                <div class="simple_plan_image_content">
                    <a href="javascript:void(0)">You can download Images</a>
                </div>
            </div>
            <div class="simple_plan_image_box">
                <div class="simple_plan_image">
                    <picture>
                        <source srcset="assets/images/pricing_section_img_2.webp" alt="pricing-section-image"
                            width="397" height="324" />
                        <source srcset="assets/images/pricing_section_img_2.jpg" alt="pricing-section-image" width="397"
                            height="324" />
                        <img src="assets/images/pricing_section_img_2.jpg" alt="pricing-section-image" width="397"
                            height="324" />
                    </picture>
                </div>
                <div class="simple_plan_image_content">
                    <a href="javascript:void(0)">Let’s move to Footages</a>
                </div>
            </div>
            <div class="simple_plan_image_box">
                <div class="simple_plan_image">
                    <picture>
                        <source srcset="assets/images/pricing_section_img_3.webp" alt="pricing-section-image"
                            width="397" height="324" />
                        <source srcset="assets/images/pricing_section_img_3.jpg" alt="pricing-section-image" width="397"
                            height="324" />
                        <img src="assets/images/pricing_section_img_3.jpg" alt="pricing-section-image" width="397"
                            height="324" />
                    </picture>
                </div>
                <div class="simple_plan_image_content">
                    <a href="javascript:void(0)">Add some Music in your projects</a>
                </div>
            </div>
            <div class="simple_plan_image_box">
                <div class="simple_plan_image">
                    <picture>
                        <source srcset="assets/images/pricing_section_img_4.webp" alt="pricing-section-image"
                            width="397" height="324" />
                        <source srcset="assets/images/pricing_section_img_4.jpg" alt="pricing-section-image" width="397"
                            height="324" />
                        <img src="assets/images/pricing_section_img_4.jpg" alt="pricing-section-image" width="397"
                            height="324" />
                    </picture>
                </div>
                <div class="simple_plan_image_content">
                    <a href="javascript:void(0)">Choose Custom Packs as needs</a>
                </div>
            </div>
        </div>
    </div>
</section>