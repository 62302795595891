<section class="conatact-sec">
  <div class="container">
    <div class="cart-title-row">
      <div class="cart-col-left">
        <h1 class="h1">Connect with Us!</h1>
        <p>Kindly fill the details in a form or drop us a mail 📬</p>
        <ul>
          <li>
            <div class="icon-contect">
              <img
                src="assets/images/email-icon.svg"
                alt="emial icon"
                width="32"
                height="32"
              />
            </div>
            <div class="contect-content">
              <span>Email to us</span>
              <strong
                ><a href="mailto:info@imagefootage.com"
                  >info@imagefootage.com</a
                ></strong
              >
            </div>
          </li>
          <li>
            <div class="icon-contect">
              <img
                src="assets/images/fax-icon.svg"
                alt="call icon"
                width="32"
                height="32"
              />
            </div>
            <div class="contect-content">
              <span>Call us</span>
              <strong class=""
                ><a href="tel:">1800 419 8123</a>
                <small> (Mon to Fri 10:00 AM to 06:00 PM)</small></strong
              >
            </div>
          </li>
          <li>
            <div class="icon-contect">
              <img
                src="assets/images/fax-icon.svg"
                alt="fax icon"
                width="32"
                height="32"
              />
            </div>
            <div class="contect-content">
              <span>Locate us</span>
              <strong>PO Box 390667 Dubai - UAE</strong>
            </div>
          </li>
        </ul>
      </div>
      <div class="cart-col-right">
        <div class="sign_up_form">
          <div *ngIf="ContactUsForm.hasError('serverError')" class="error">
            {{ ContactUsForm.getError("serverError") }}
          </div>
          <form
            [formGroup]="ContactUsForm"
            (ngSubmit)="onSubmit()"
            *ngIf="!actionSubmitted"
          >
            <div
              class="form_group"
              [ngClass]="
                submitted && ContactUsForm.get('user_name').errors?.required
                  ? 'error_field'
                  : ''
              "
            >
              <label for="user_name"
                >Name<strong class="" style="color: red">*</strong></label
              >
              <input
                type="text"
                formControlName="user_name"
                placeholder="Enter your name"
                required
              />
              <p
                class="error"
                *ngIf="
                  submitted && ContactUsForm.get('user_name').errors?.required
                "
              >
                * Please enter your name. Do not include special characters
              </p>
              <p
                class="error"
                *ngIf="
                  submitted && ContactUsForm.get('user_name').errors?.pattern
                "
              >
                * Please enter your name. Do not include special characters
              </p>
              <p
                class="error"
                *ngIf="validationMessage && validationMessage['user_name']"
              >
                {{ validationMessage["user_name"] }}
              </p>
            </div>
            <div
              class="form_group"
              [ngClass]="
                submitted && ContactUsForm.get('user_email').errors?.required
                  ? 'error_field'
                  : ''
              "
            >
              <label for="user_email"
                >Email<strong class="" style="color: red">*</strong></label
              >
              <input
                type="text"
                formControlName="user_email"
                placeholder="Enter your email"
                required
              />
              <p
                class="error"
                *ngIf="
                  submitted && ContactUsForm.get('user_email').errors?.required
                "
              >
                * Please enter valid email address
              </p>
              <p
                class="error"
                *ngIf="
                  submitted && ContactUsForm.get('user_email').errors?.email
                "
              >
                * Please enter valid email address
              </p>
              <p
                class="error"
                *ngIf="validationMessage && validationMessage['user_email']"
              >
                {{ validationMessage["user_email"] }}
              </p>
            </div>
            <div
              class="form_group"
              [ngClass]="
                submitted && ContactUsForm.get('mobile_number').errors?.required
                  ? 'error_field'
                  : ''
              "
            >
              <label for="mobile_number"
                >Contact No.<strong class="" style="color: red"
                  >*</strong
                ></label
              >
              <input
                type="tel"
                maxlength="14"
                formControlName="mobile_number"
                placeholder="Enter your contact no."
                required
              />
              <p
                class="error"
                *ngIf="
                  submitted &&
                  ContactUsForm.get('mobile_number').errors?.required
                "
              >
                * Please enter valid contact No
              </p>
              <p
                class="error"
                *ngIf="
                  submitted &&
                  ContactUsForm.get('mobile_number').errors?.pattern && !ContactUsForm.get('mobile_number').errors?.required
                "
              >
                Please provide valid Contact No
              </p>
              <p
                class="error"

                *ngIf="!ContactUsForm.get('mobile_number').errors?.required && !ContactUsForm.get('mobile_number').errors?.pattern && ContactUsForm.hasError('minlength', 'mobile_number')"
              >
                Please enter a valid mobile number with at least 10 characters.
              </p>
              <p
                class="error"

                *ngIf="validationMessage && validationMessage['mobile_number'] && !ContactUsForm.get('mobile_number').errors?.required && !ContactUsForm.get('mobile_number').errors?.pattern"

              >
                {{ validationMessage["mobile_number"] }}
              </p>
            </div>
            <div
              class="form_group"
              [ngClass]="
                submitted && ContactUsForm.get('user_subject').errors?.required
                  ? 'error_field'
                  : ''
              "
            >
              <label for="user_subject"
                >Subject<strong class="" style="color: red">*</strong></label
              >
              <input
                type="text"
                formControlName="user_subject"
                placeholder="Share Subject Line"
                required
              />
              <p
                class="error"
                *ngIf="
                  submitted &&
                  ContactUsForm.get('user_subject').errors?.required
                "
              >
                * Subject Line can not be Blank
              </p>
              <p
                class="error"
                *ngIf="validationMessage && validationMessage['user_subject']"
              >
                {{ validationMessage["user_subject"] }}
              </p>
            </div>
            <div
              class="form_group form_group_lastchild"
              [ngClass]="
                submitted && ContactUsForm.get('user_message').errors?.required
                  ? 'error_field'
                  : ''
              "
            >
              <label for="user_message"
                >Message<strong class="" style="color: red">*</strong></label
              >
              <textarea
                type="text"
                formControlName="user_message"
                placeholder="Share a message"
                required
              ></textarea>
              <p
                class="error"
                *ngIf="
                  submitted &&
                  ContactUsForm.get('user_message').errors?.required
                "
              >
                Message can not be empty
              </p>
              <p
                class="error"
                *ngIf="
                  submitted &&
                  ContactUsForm.get('user_message').hasError('maxlength')
                "
              >
                Message cannot be longer than 2500 characters.
              </p>
              <p
                class="error"
                *ngIf="validationMessage && validationMessage['user_message']"
              >
                {{ validationMessage["user_message"] }}
              </p>
            </div>
            <div class="form_group form_group_lastchild">
              <re-captcha
                (resolved)="resolved($event)"
                captchaSitekey="siteKey"
                formControlName="recaptcha"
              ></re-captcha>
              <p
                class="error"
                *ngIf="
                  submitted && ContactUsForm.get('recaptcha').errors?.required
                "
              >
                * Please Complete the CAPTCHA
              </p>
            </div>
            <button class="sign_up_btn" type="submit">Send</button>
          </form>
          <div class="success-msg-block" *ngIf="actionSubmitted">
            <div class="success-msg">
              <img
                src="assets/images/success-icon.svg"
                alt="success-icon"
                width="115"
                height="115"
              />
              <h2 class="h2">Thank You for Connecting With Us</h2>
              <p>
                Our consultant will get back with a response as soon as
                possible.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
