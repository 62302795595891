import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpErrorResponse } from '@angular/common/http';
import { Observable,throwError,BehaviorSubject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AccountService } from '../services/account.service';
import { ToastService } from 'src/appv2/services/toast.service';
import { messages } from '../appv2.constants';
import { User } from '../models/user';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private toastrDisplayed = false;
  private userSubject: BehaviorSubject<User>;
    constructor(private accountService: AccountService,private router: Router,private toastService: ToastService,) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.accountService.userValue;
        if (user && user.userdata) {
            const isLoggedIn = user && user.userdata.access_token;
            const isApiUrl = request.url.startsWith(environment.url);
            if (isLoggedIn && isApiUrl) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${user.userdata.access_token}`,
                        'Login-Type': user.userdata.login_type
                    }
                });
            }
        }

        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401 && !this.toastrDisplayed) {
                this.router.navigate(['/signin'])
                this.toastrDisplayed = true;
                this.toastService.error(messages.tokenExpire);
                localStorage.removeItem('user_data')
                localStorage.removeItem('user')
                this.userSubject.next(null);

              }
              return throwError(error);

          })
        );

    }
}
