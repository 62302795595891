import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Config, LayoutStyle, Media } from 'ng-opengallery';
import { Observable } from 'rxjs';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { selectCurrentRoute, selectSearchKey } from 'src/appv2/reducers/router/router.selector';
import { SearchChangeSearchType, SearchWriteAction } from 'src/appv2/reducers/search/search.actions';
import { SearchState } from 'src/appv2/reducers/search/search.models';
import { selectIsSearchEditorial, selectSearchName, selectSearchText, selectSearchType } from 'src/appv2/reducers/search/search.selectors';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { SearchService } from 'src/appv2/services/search.service';
import { ToastService } from 'src/appv2/services/toast.service';
import {messages} from 'src/appv2/appv2.constants'
import { switchMap } from 'rxjs/operators';
import { of,timer } from 'rxjs';

@Component({
  selector: 'appv2-editorial-gallery-listing',
  templateUrl: './editorial-gallery-listing.component.html',
  styleUrls: ['./editorial-gallery-listing.component.scss'],
})
export class EditorialGalleryListingComponent implements OnInit {
  public search$: Observable<string> = this.store$.pipe(
    select(selectSearchKey)
  ); //How to output store varible in html template

  pageName = '';
  mainPageType: string = 'editorial';
  @Input() processedFilters;

  config: Config = {
    diaporamaDuration: 3,
    layout: LayoutStyle.SIMPLE,
    prefMediaHeight: 400,
    spacing: 2,
    viewerEnabled: false,
    enableAutoPlay: true,
  };
  galleryData: Array<any>;
  itemData: Array<object>;
  totalRecords: Number;
  page: number = 1;
  currPage: number = 1;
  prevPage: number = 0;
  recordsPerPage: number;
  api_product_ids;

  // searchType:number = 0
  // prevSearchType:number = 0

  isRedirect: boolean;
  searchType: number = 1;
  prevSearchType: number = 1;

  prevSearchKey: string = '';
  searchKey: string = '';
  itemsPerPage = Number;

  pageGallery: string;
  isEditorial: boolean;

  prevFilters;
  filters;

  selectSearchNameObserver: any;
  selectCurrentRouteObserver: any;
  selectSearchTextObserver: any;
  public displayExtendedLoader:boolean = false

  searchDebounceInterval: any = null;
  clearDebounceInterval() {
    clearTimeout(this.searchDebounceInterval);
  }

  currentlyPlayingIndex = -1;
  @Output() dataEmitter = new EventEmitter<any>();

  scrollToGalleryStart() {
    window.scrollTo(0, 800);
  }
  constructor(
    private store$: Store<any>,
    private mysearch: SearchService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private toastService: ToastService,
    private landingPage: LandingPageService,

  ) {
    this.galleryData = new Array<any>();
    let search = this?.activatedRouter?.snapshot?.queryParamMap?.get('search');
    if (typeof search !== 'undefined' && search != '') {
      this.searchKey = search;
    }
  }

  // Subscribe to Store
  starSearch() {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.editorialPageLoading })
    );
    this.store$.pipe(select(selectSearchType)).subscribe((vl) => {
      if (vl != undefined) {
        this.isRedirect = vl;
      }
    });
    this.selectSearchTextObserver = this.store$
      .pipe(select(selectSearchText))
      .subscribe((vl) => {
        this.clearDebounceInterval();
        this.searchDebounceInterval = setTimeout(() => {
          if (vl != undefined) {
            if (vl.startsWith('category')) {
              this.searchKey = vl.replace('category ', '');
              this.getGalleryContent({
                type: this.filters['type'],
                search: this.searchKey,
              });
            } else {
              this.searchKey = vl;
            }
          }
        }, 200);
      });
    this.store$.pipe(select(selectIsSearchEditorial)).subscribe((vl) => {
      if (this.isRedirect) {
        this.isEditorial = vl;
      }
      this.isEditorial = true
    });
    this.selectCurrentRouteObserver = this.store$
      .pipe(select(selectCurrentRoute))
      .subscribe((vl) => {
        this.clearDebounceInterval();
        this.searchDebounceInterval = setTimeout(() => {
          if (vl != undefined) {
            this.store$.dispatch(
              new SearchWriteAction(<SearchState>{ searchText: ' ' })
            );
            let searchPage = {};
            this.pageName = vl.routeConfig.path;

            if (vl.routeConfig.path == 'account-activated/:email') {
              this.toastService.success(
                'Your account is activited successfully.'
              );
            }

            switch (vl.routeConfig.path) {
              case '':
                this.searchType = 1;
                searchPage['type'] = 'image';
                searchPage['product_editorial'] = 0;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: true,
                  })
                );
                this.filters = searchPage;
                break;

              case 'image':
                this.searchType = 1;
                searchPage['product_editorial'] = 0;
                searchPage['type'] = 'image';
                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: false,
                  })
                );
                break;

              case 'footage':
                this.searchType = 2;
                searchPage['product_editorial'] = 0;
                searchPage['type'] = 'footage';
                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: false,
                  })
                );
                break;

              case 'editorial':
                searchPage['product_editorial'] = 1;
                this.searchType = 4;
                searchPage['type'] = 'editorial';
                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: true,
                  })
                );
                break;

              case 'search':
                this.filters = vl.queryParams;
                break;

              default:
                this.searchType = 1;
                searchPage['type'] = 'image';
                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: false,
                  })
                );
            }
            this.getGalleryContent(this.filters);
          }
        }, 200);
      });

    this.selectSearchNameObserver = this.store$
      .pipe(select(selectSearchName))
      .subscribe((vl) => {
        this.clearDebounceInterval();
        this.searchDebounceInterval = setTimeout(() => {

            this.pageGallery = vl;
            if (this.pageName == '' || this.pageName == 'editorial') {
              this.filters = {
                type: this.pageGallery,
                product_editorial: +this.isEditorial,
                api_product_ids: this.api_product_ids,
              };
              this.getGalleryContent(this.filters);
            }

        }, 500);
      });
  }
  ngDoCheck() {
    // if (this.galleryData.length > 0) {
    //     let v = document.querySelectorAll('.gallery video')
    //     for (let i = 0; i < v.length; i++) {
    //         v[i]['muted'] = true
    //     }
    //     let footages = $(".gallery video");
    //     for (let i = 0; i < footages.length; i++) {
    //         $(footages[i]).mouseout((event) => {
    //             $(event.target)[0].load();
    //         });
    //         $(footages[i]).mouseover((event) => {
    //             $(event.target)[0].play();
    //         })
    //         footages[i].setAttribute('poster', this.itemData[i]["product_thumbnail"]);
    //     }
    // }
  }
  fileExtension: string;
  // Get search result
  getGalleryContent(filters, currPage = 1) {
    this.galleryData = []
    this.filters['type'] = this.mainPageType;
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.editorialPageLoading })
    );
    let search = this?.activatedRouter?.snapshot?.queryParamMap?.get('search');
    if (typeof search !== 'undefined' && search != '') {
      this.searchKey = search;
    }
    this.displayExtendedLoader = true
    if (Object.keys(filters).length !== 0 && filters.constructor === Object) {


      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.editorialPageLoading  })
      );

      const apiCall =this.mysearch
        .getSearchWithFiltersResult(
          filters,
          currPage,
          this.searchKey,
          this.processedFilters
        )

        setTimeout(() => {
          if((this.galleryData == undefined || this.galleryData.length == 0) && this.displayExtendedLoader){

            this.store$.dispatch(
              new loadingChangeAction(<LoadingState>{ loadingStatus: true, loadingMessage: messages.delayAPIMessage})
            );
          }
        }, 2000);

        apiCall.subscribe((data) => {
          if (data.imgfootage?.length >0) {
            this.store$.dispatch(
              new loadingChangeAction(<LoadingState>{ loadingStatus: false })
            );
            this.displayExtendedLoader = false
            this.currPage = currPage

            let filtereddata = data.imgfootage;

            for (let i = 0; i < filtereddata.length; i++) {
              if (filtereddata[i]['product_main_type'] == 'Image') {
                const parts = filtereddata[i]['product_thumbnail'].split('.');
                // Get the last part of the array (the file extension)
                this.fileExtension = parts[parts.length - 1];
                this.galleryData.push(
                  new Media(
                    filtereddata[i]['product_thumbnail'],
                    filtereddata[i]['product_title']
                  )
                );
              } else {
                const parts = filtereddata[i]['product_main_image'].split('.');
                // Get the last part of the array (the file extension)
                this.fileExtension = parts[parts.length - 1];

                let data = {
                  previewUrl:  filtereddata[i]['product_main_image'],
                  title: filtereddata[i]['product_title'],
                  type: "video",
                  url: filtereddata[i]['product_main_image'],
                  thumbnail:filtereddata[i]['product_thumbnail']
              };
              this.galleryData.push(data);

              }
            }
            this.itemData = filtereddata;
            this.recordsPerPage = this.itemsPerPage = data.perpage;
            this.totalRecords = data.total;
            this.dataEmitter.emit(this.totalRecords);

          } else{
            this.displayExtendedLoader = false
            this.store$.dispatch(
              new loadingChangeAction(<LoadingState>{ loadingStatus: false })
            );
            this.toastService.error('Please try again')
            this.totalRecords = 0
          }

        });
    } else {
      this.mysearch.getSearchResult(1, currPage).subscribe((data) => {
        this.galleryData = [];
        this.currPage = currPage
        for (let i = 0; i < data.imgfootage.length; i++) {
          if (data.imgfootage[i]['product_main_type'] == 'Image') {
            this.galleryData.push(
              new Media(data.imgfootage[i]['product_thumbnail'], '')
            );
          } else {
            this.galleryData.push(
              new Media(data.imgfootage[i]['product_main_image'], '', 'video')
            );
          }
        }
        this.itemData = data.imgfootage;
        this.recordsPerPage = data.imgfootage.length;
        this.totalRecords = data.total;
        this.store$.dispatch(
          new loadingChangeAction(<LoadingState>{ loadingStatus: false })
        );
      });
    }
    if (this.itemData != undefined) {
    }
  }

  toNextPage() {
    this.currPage++;
    this.getGalleryContent(this.filters, this.currPage);
  }
  toPrevPage() {
    this.currPage--;
    this.getGalleryContent(this.filters, this.currPage);
  }
  ngOnInit() {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.editorialPageLoading  })
    );
    this.api_product_ids = this.landingPage.getProductApiData()
    this.starSearch();
  }

  ngAfterViewInit() {
    let searchInput = document.querySelector('.search-container input');
    if (searchInput) {
      searchInput['value'] = this.searchKey;
    }
  }

  ngOnDestroy() {
    this.selectCurrentRouteObserver?.unsubscribe();
    this.selectSearchNameObserver?.unsubscribe();
    this.selectSearchTextObserver?.unsubscribe();
    this.clearDebounceInterval();
  }

  get totalPages(): number {
    return Math.ceil(Number(this.totalRecords) / Number(this.itemsPerPage));
  }

  onPageChange(newPage: number): void {
    this.currPage = Number(newPage);
    this.itemsPerPage = this.itemsPerPage;

    if (this.currPage < 1) {
      this.currPage = 1;
    }

    this.getGalleryContent(this.filters, this.currPage);
  }

  openItem(type, item) {
    //alert("test");
    const objectValues = Object.values(this.itemData);
    if (type == 'image') {
      const foundObject = objectValues.find(
        (value) => (value as any).product_thumbnail === item
      );
      this.router.navigate(['/image/'+foundObject['slug']]);
    } else {
      const foundObject = objectValues.find(
        (value) => (value as any).product_main_image === item
      );
      let path = type == 'footage'? '/footage/'+foundObject['slug'] : '/music/'+foundObject['slug']
      this.router.navigate([path]);
    }
  }
  playVideo(videoElement) {
    videoElement.videoLoaded = true;

  }

  resetVideo(item){
    item.videoLoaded = false;
  }

  openItemGallery(item) {

   const objectValues = Object.values(this.itemData);
   let foundObject:any
   if(item.type =='video'){
    foundObject = objectValues.find(
      (value) => (value as any).product_thumbnail === item.thumbnail
    );
   }else{
     foundObject = objectValues.find(
      (value) => (value as any).product_thumbnail === item.previewUrl
    );
   }



   this.router.navigate(['/image/'+foundObject['slug']]);

}
}
