<section class="image_description_section">
  <div class="container">
    <div class="image_description_info" >
      <div class="footage_info_image_section">
        <div class="footage_image">
          <picture *ngIf="type == 2">
          <img
            src="assets/images/404-for-image.jpg"
            alt="Dummy"
            width="835px"
            height="651px"
          />
        </picture>
        <picture *ngIf="type!==2">
          <img
            src="assets/images/404-for-others.png"
            alt="Dummy"
            width="835px"
            height="651px"
          />
        </picture>
          <div class="image_icon">
            <ul>
            </ul>
          </div>
        </div>

      </div>

      <div class="music_info_content_section">
        <form
          class="col-md-5 buy-info"
        >
          <div class="music_info_heading">
            <h2 class="h2">This page is not found</h2>
            <p><a [routerLink]="dynamicLink">Click Here</a>  for other product </p><br>

          </div>
        </form>
      </div>
    </div>
  </div>
</section>
