<!-- popup start -->
<div class="wishlist_form_popup">
    <div class="wishlist_form_popup_inner">
        <div class="wishlist_image_section">
            <div class="wishlist_image_heading">
                <h2 class="h4">{{ wishlistModalTitle }}</h2>
                <div class="input_wishlist_name" [ngClass]="
                showValidationError &&
                wishlistForm.get('wishlistName').errors
                  ? ' error_field'
                  : ''
              ">
                    <input placeholder="Wishlist Collection Name" type="text" [formControl]="wishlistForm.get('wishlistName')" class="user_input">
                    <!-- Display validation error message -->
                    <div *ngIf="wishlistForm.get('wishlistName').errors && (wishlistForm.get('wishlistName').touched || showValidationError)">
                        <div class="error-message" *ngIf="wishlistForm.get('wishlistName').errors?.required">Wishlist Collection Name is required.</div>
                        <div class="error-message" *ngIf="wishlistForm.get('wishlistName').errors?.minlength">Wishlist Collection Name should contain atleast 3 characters.</div>
                    </div>
                    <!-- ./Display validation error message -->
                </div>
                <ul>
                    <li><button class="thame-btn cancel-btn" (click)="onCancel()">Cancel</button></li>
                    <li><a class="thame-btn" (click)="onSubmit()">Submit</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- popup end -->