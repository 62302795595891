<!-- my wishlist section start -->
<section class="my-wishlist-inner-sec">
  <div class="container">
    <div class="cart-title-row">
      <div class="cart-col-left">
        <h1 class="h1"><a routerLink="/mywishlist/">My Wishlist</a></h1>
      </div>
      <!-- TODO : MultiDownload functionality : Need Confirmation from client -->
      <!-- <div class="cart-col-right">
        <button (click)="multiDownload()" class="thame-btn download-btn">
          Download ({{ selectedImageProductsId.length }})
        </button>
      </div> -->
    </div>

    <div class="download_popup_no_plan">
      <div class="download_popup_no_plan_inner">
        <div class="wishlist_image_section">
          <div class="wishlist_image_heading">
            <h3 class="h4">
              Oops! You don't have any plan to download images. Kindly upgrade
              your account and download images,footages and music.
            </h3>
            <ul>
              <li>
                <button class="thame-btn cancel-btn" (click)="onNoPlanCancel()">
                  Cancel
                </button>
              </li>
              <li>
                <button (click)="upgradePlan()" class="thame-btn">
                  Upgrade Plan
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="download_popup_high_res_plan">
      <div class="download_popup_high_res_inner">
        <div class="wishlist_image_section">
          <div class="wishlist_image_heading">
            <h3 class="h4">
              You are downloading High resolution images which will be deducted
              from your existing plan.
            </h3>
            <div class="col-12">
              <h5 class="modal-title">Choose subscription plan</h5>
              <select
                id="plan"
                type="text"
                require
                (change)="downloadWithSubscriptionPlan($event.target.value)"
              >
                <option value="">choose plan</option>
                <option
                  *ngFor="let plan of userSubscriptionPlans"
                  value="{{ plan.id }}"
                >
                  {{ plan.package_name }}
                </option>
              </select>
            </div>
            <ul>
              <li>
                <button
                  class="thame-btn cancel-btn"
                  (click)="onHighResolutionCancel()"
                >
                  Cancel
                </button>
              </li>
              <li>
                <button (click)="downloadNow()" class="thame-btn">
                  Download Now
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="cart-sub-title-row">
      <div class="cart-col-left">
        <h2 class="h3">
          {{ wishlistData["name"] ? wishlistData["name"] : "" }}
        </h2>
      </div>
      <div class="cart-col-right">
        <ul class="sharign-list">
          <li>
            <a href="javascript:void(0)" (click)="editWishlistName()"
              ><img
                src="assets/images/edit.svg"
                alt="edit  "
                width="20"
                height="20"
              />Edit</a
            >
          </li>
          <li *ngIf="wishlistData && wishlistData?.products?.length > 0">
            <a
              href="javascript:void(0)"
              (click)="preConfirmForProductDeletion()"
              ><img
                src="assets/images/delete-icon.svg"
                alt="delete"
                width="20"
                height="20"
              />Delete</a
            >
          </li>
          <li *ngIf="wishlistData && wishlistData?.products?.length > 0">
            <a (click)="openSharePopup()"   style="cursor: pointer;"         
              ><img
                src="assets/images/share-icon.svg"
                alt="download"
                width="16"
                height="16"
              />Share</a
            >

          </li>
        </ul>
      </div>
    </div>
    <div class="overlay"></div>
    <!-- popup start -->
    <div class="download_popup">
      <div class="download_popup_inner">
        <div class="wishlist_image_section">
          <div class="wishlist_image_heading">
            <h3 class="h4">Share your collection with others</h3>
            <p class="wishlist-share">
              <input
                type="text"
                value="{{ wishlistLink }}"
                readonly
                #userinput
              />
            </p>
            <br />
            <ul>
              <li>
                <button class="thame-btn cancel-btn" (click)="onCancel()">
                  Cancel
                </button>
              </li>
              <li>
                <a class="thame-btn" (click)="copyLink(userinput)">{{
                  isClick == true ? "Coppied" : "Copy"
                }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- popup end -->
    <div class="image-list-sec-wishlist">
      <!-- layout of image grid position start -->
      <div class="image_grid" *ngIf="wishlistData && wishlistData?.products">
        <ng-container
          *ngFor="let product of wishlistData.products; trackBy: trackByFn"
        >
          <div class="filter_listpage_image">
            <picture>
              <source
                class="img-tag"
                [srcset]="product.product_thumbnail"
                [alt]="product.product_title ? product.product_title : ''"
                width="358"
                height="216"
              />
              <source
                class="img-tag"
                [srcset]="product.product_thumbnail"
                [alt]="product.product_title ? product.product_title : ''"
                width="358"
                height="216"
              />
              <img
                class="img-tag"
                [src]="product.product_main_image"
                [alt]="product.product_title ? product.product_title : ''"
                width="358"
                height="216"
              />
            </picture>
            <div class="hd_resolution">
              <a href="javascript:void(0)">
                <div class="video_play_icon">
                  <img src="assets/images/play_button.svg" alt="play-button" />
                </div>
                <span class="resolution">{{ product.product_main_type }}</span>
              </a>
            </div>
            <ul class="custom-checkbox-list">
              <li>
                <input
                  class="custom-checkbox"
                  [id]="'add-filter-' + product.id"
                  type="checkbox"
                  value="value1"
                  (change)="
                    onChange(
                      product.product_id,
                      $event.target.checked,
                      product.product_main_type
                    )
                  "
                  [checked]="isSelectedProduct(product.product_id)"
                />
                <label [for]="'add-filter-' + product.id"></label>
              </li>
            </ul>
            <div class="image_name" (click)="openItem(product)">
              <a href="javascript:void(0)">{{
                product.product_title ? product.product_title : ""
              }}</a>
            </div>
            <ul class="delete-dwonload-block">
              <li>
                <a
                  href="javascript:void(0)"
                  (click)="
                    preConfirmForProductDeletion(true, product.product_id)
                  "
                >
                  <img
                    src="assets/images/delete.png"
                    alt="delete"
                    width="20"
                    height="20"
                  />
                </a>
              </li>
              <li>
                <a (click)="openItem(product)" class="btn-download" style="cursor: pointer;">
                  <img
                    src="assets/images/dwonload.png"
                    alt="download"
                    width="20"
                    height="20"
                  />
                </a>
              </li>
            </ul>
          </div>
        </ng-container>
      </div>
      <!-- layout of image grid position end -->
    </div>
  </div>
</section>

<!-- Pre Confirm Modal -->
<pre-confirm-modal
  [popupConfiguration]="preconfirmPopupConfigData"
  (dataEvent)="handlePreConfirmModalEvent($event)"
  *ngIf="showPreConfirmModal"
></pre-confirm-modal>
<!-- ./Pre Confirm Modal -->

<!-- Form Modal -->
<wishlist-form-modal
  [wishlistData]="wishlistData"
  (dataEvent)="handleWishlistModalEvent($event)"
  *ngIf="showWishlistModal"
></wishlist-form-modal>
<!-- ./Form Modal -->
