import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { messages } from 'src/appv2/appv2.constants';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';
import { ToastService } from 'src/appv2/services/toast.service';

@Component({
  selector: 'appv2-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  userdata: any;
  is_email: boolean;
  otpForm: FormGroup;
  message: any;
  verify_screen_used_for: string;
  verifiedOtp = false;
  inputs: { value: string }[] = [{ value: '' }, { value: '' }, { value: '' }, { value: '' }];

  constructor(private store$: Store<any>, private accountActions: AccountService, private router: Router, private toastService: ToastService) { }

  ngOnInit(): void {
    this.userdata = this.accountActions.getRegisteredUserData();
    if(this.userdata.length == 0){
    this.userdata =  JSON.parse(localStorage.getItem('registered_info'))
    }
    this.is_email = this.userdata.data.is_email;
    //this.is_email = true;

    this.verify_screen_used_for = this.userdata.verify_screen_used_for;
    this.otpForm = new FormGroup({
      'otpInput1': new FormControl('', [Validators.required]),
      'otpInput2': new FormControl('', [Validators.required]),
      'otpInput3': new FormControl('', [Validators.required]),
      'otpInput4': new FormControl('', [Validators.required])
    });
  }

  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.verifyEmailLoading  }))
    return
  }

  public onSubmitVerifyOtp() {
    if (!this.verifiedOtp) {
      this.verifiedOtp = true;
      this.verifyOtp(this.otpForm.value);
    }
  }

  public resendOtp() {
    let payload = {};
    payload['user_id'] = this.userdata.data.user_id;
    payload['mobile'] = this.userdata.data.mobile;
    this.loading(true);
    this.accountActions.resendOtp(payload).subscribe((data) => {
      this.loading(false)
      if (data.status) {
        this.toastService.success(data['message'])
      } else {
        this.toastService.warning(data['message'])
      }
    });
  }

  public verifyOtp(formdata) {
    if (formdata.otpInput1 != '' && formdata.otpInput2 != '' && formdata.otpInput3 != '' && formdata.otpInput4) {
      let payload = {};
      payload['user_id'] = this.userdata.data.user_id;
      payload['mobile'] = this.userdata.data.mobile;
      payload['otp'] = `${formdata.otpInput1}${formdata.otpInput2}${formdata.otpInput3}${formdata.otpInput4}`;
      this.loading(true);
      this.accountActions.verifyOtp(payload).subscribe((data) => {
        this.loading(false)
        if (data.status) {
          if (this.verify_screen_used_for == 'signup') {
            this.toastService.success(data['message'])
            if(localStorage.getItem('registered_info')){
              localStorage.removeItem('registered_info')
            }
            setTimeout(() => {
              data.verify_screen_used_for = 'signup';
              this.accountActions.setRegisteredUserData(data);
              this.router.navigate(['/resetpasswordsuccess'])
            }, 1000);
          } else if (this.verify_screen_used_for == 'forgot') {
            this.toastService.success(data['message'])
            setTimeout(() => {
              this.router.navigate([`/resetpassword/${this.userdata.data.token}`])
            }, 2000);
          } else if (this.verify_screen_used_for == 'login') {

          }

        } else {
          this.verifiedOtp = false
          this.toastService.warning(data['message'])
          this.otpForm.patchValue({ otpInput1: null });
          this.otpForm.patchValue({ otpInput2: null });
          this.otpForm.patchValue({ otpInput3: null });
          this.otpForm.patchValue({ otpInput4: null });
        }
      });
    } else {
      this.message = {
        otpInput1: formdata?.otpInput1 ? null : "Otp input is required",
        otpInput2: formdata?.otpInput2 ? null : "Otp input is required",
        otpInput3: formdata?.otpInput3 ? null : "Otp input is required",
        otpInput4: formdata?.otpInput4 ? null : "Otp input is required",
      }

      for (let i = 0; i < document.querySelectorAll('.ng-invalid').length; i++) {
        (<HTMLElement>document.querySelectorAll('.ng-invalid')[i]).style.borderColor = '#FF8080'
      }
    }

  }

  public resendEmailLink() {
    let payload = {};
    payload['user_id'] = this.userdata.data.user_id;
    payload['email'] = this.userdata.data.email;
    this.loading(true);
    this.accountActions.resendEmailLink(payload).subscribe((data) => {
      this.loading(false)
      if (data.status) {
        this.toastService.success(data['message'])
      } else {
        this.toastService.warning(data['message'])
      }
    });
  }

  public handleOtp(event: any, index: number) {
    const input = event.target;
    let value = input.value;
    let isValidInput = value.match(/[0-9a-z]/gi);
    input.value = '';
    input.value = isValidInput ? value[0] : '';
    let nextElement = event.srcElement.nextElementSibling;
    let prevElement = event.srcElement.previousElementSibling;
    if (nextElement == null)
      return;
    if (index < 4 && isValidInput) {
      nextElement.focus();
    }
    if (event.key === "Backspace" && index > 0) {
      prevElement.focus();
    }
    if (index == 4 && isValidInput) {
      if (!this.verifiedOtp) {
        this.verifiedOtp = true;
        this.verifyOtp(this.otpForm.value);
      }
    }
  }

  public handleOnPasteOtp(event: any) {
    const data = event.clipboardData.getData("text");
    const value = data.split("");
    if (value.length === this.inputs.length) {
      this.otpForm.patchValue({ otpInput1: value[0] });
      this.otpForm.patchValue({ otpInput2: value[1] });
      this.otpForm.patchValue({ otpInput3: value[2] });
      this.otpForm.patchValue({ otpInput4: value[3] });
      if (!this.verifiedOtp) {
        this.verifiedOtp = true;
        this.verifyOtp(this.otpForm.value);
      }
    }
  }
}
