<!-- <section class="csm-content-sec">
    <div class="container">
        <div class="csm-content-inner"> -->
            <!-- <div *ngIf="pageData?.page_content" [innerHtml]="pageData.page_content"></div> -->
        <!-- </div>
    </div>
</section> -->
<!-- banner section start -->
<section class="about_us_banner">
    <div class="container">
        <div class="about_us_heading">
            <h1 class="h1">Empowering <span class="heading_content">Creators</span> with Curated <span
                      class="heading_content">Content</span></h1>
        </div>
        <div class="banner_image">
            <picture>
                <source srcset="assets/images/about_us_banner.webp" alt="banner img" width="1759"
                        height="766" />
                <source srcset="assets/images/about_us_banner.jpg" alt="banner img" width="1759"
                        height="766" />
                <img src="assets/images/about_us_banner.jpg" alt="banner img" width="1759" height="766" />
            </picture>
            <div class="banner_badge">
                <picture>
                    <source srcset="assets/images/about_us_badge.webp" alt="banner badge img" width="146"
                            height="146" />
                    <source srcset="assets/images/about_us_badge.png" alt="banner badge img" width="146"
                            height="146" />
                    <img src="assets/images/about_us_badge.png" alt="banner badge img" width="146"
                         height="146" />
                </picture>
            </div>
        </div>
        <div class="banner_content">
            <h2 class="h2">World’s Best curated content on Demand</h2>
            <p>We fuel the visual storytelling of the world by providing them with content, like stock
                imagery, stock footage & Stock Music. We support and enable creativity and communications
                across the full spectrum of the world’s growing creative
                community.
            </p>
        </div>
    </div>
</section>
<!-- banner section end -->

<!-- what we do section start -->
<section class="what_we_do">
    <div class="container">
        <div class="what_we_do_section_inner">
            <div class="what_we_do_section_image">
                <picture>
                    <source srcset="assets/images/about_us_info.webp" alt="about img" width="829"
                            height="830" />
                    <source srcset="assets/images/about_us_info.png" alt="about img" width="829"
                            height="830" />
                    <img src="assets/images/about_us_info.png" alt="about img" width="829" height="830" />
                </picture>
            </div>
            <div class="what_we_do_section_content">
                <h2 class="h2">What we do</h2>
                <p class="about_content">We fuel the visual storytelling of the world by providing them with
                    content, like stock imagery, stock footage & Stock Music. We support and enable creativity
                    and communications across the full spectrum of the world’s growing creative
                    community.
                </p>
                <div class="what_we_do_section_content_info">
                    <div class="info_part">
                        <div class="info_part_img diamond">
                            <img src="assets/images/diamond.svg" alt="diamond" width="26" height="26">
                        </div>
                        <div class="info_part_content">
                            This Website is the medium to provide some of the best stock content, but our
                            services doesnot End here. We bring to you content from anywhere in the world.
                        </div>
                    </div>
                    <div class="info_part">
                        <div class="info_part_img camera_1">
                            <img src="assets/images/camera_1.svg" alt="camera" width="24" height="24">
                        </div>
                        <div class="info_part_content">
                            Whether it is Hollywood film production house, International music, archival
                            history, News Archive, Personal production content.
                        </div>
                    </div>
                    <div class="info_part">
                        <div class="info_part_img camera_2">
                            <img src="assets/images/camera _2.svg" alt="camera" width="24" height="24">
                        </div>
                        <div class="info_part_content">
                            We bring to you the correct license, the required content to make sure you create
                            the best Film, Web series, Documentary, Commercial Ad or just a corporate video.
                        </div>
                    </div>
                </div>
                <div class="what_we_do_section_contact_us">
                    <h3 class="h3">Connect us at:</h3>
                    <div class="contact_info">
                        <div class="contact_info_inner">
                            <span class="contact_info_icon">
                                <a href="tel:18004198123"><img src="assets/images/telephone.svg"
                                         alt="telephone" width="28" height="28"></a>
                            </span>
                            <span>
                                <a href="tel:18004198123">1800 4198 123</a>
                            </span>
                        </div>
                        <div class="contact_info_inner">
                            <span class="contact_info_icon">
                                <a href="mailto:sales@imagefootage.com"><img src="assets/images/envelop.svg"
                                         alt="mail" width="28" height="28"></a>
                            </span>
                            <span>
                                <a href="mailto:sales@imagefootage.com">sales@imagefootage.com</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- what we do section end -->

<!-- How we do It section start -->
<section class="how_we_do" style="background-image: url(assets/images/about_us_bg_img.jpg);">
    <div class="container">
        <div class="how_we_do_section_inner">
            <h2 class="h2">How we do It</h2>
            <p class="how_we_do_section_content">We have a team of Visual Experts who can help you with
                precise Picture research for the exact content that you need. You share your storyboard and We
                will bring to your table the right content you need to complete your project.
            </p>
        </div>
    </div>
</section>
<!-- How we do It section end -->

<!-- Our Clients section start -->
<section class="our_clients">
    <div class="container">
        <h2 class="h2">Our Clients</h2>
        <div class="our_clients_logo">
            <ul>
                <li>
                    <a href="javascript:void(0)">
                        <picture>
                            <source srcset="assets/images/about_us_logo_1.webp" alt="client logo img"
                                    width="188" height="50" />
                            <source srcset="assets/images/about_us_logo_1.png" alt="client logo img"
                                    width="188" height="50" />
                            <img src="assets/images/about_us_logo_1.png" alt="client logo img" width="188"
                                 height="50" />
                        </picture>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)">
                        <picture>
                            <source srcset="assets/images/about_us_logo_2.webp" alt="client logo img"
                                    width="188" height="44" />
                            <source srcset="assets/images/about_us_logo_2.png" alt="client logo img"
                                    width="188" height="44" />
                            <img src="assets/images/about_us_logo_2.png" alt="client logo img" width="188"
                                 height="44" />
                        </picture>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)">
                        <picture>
                            <source srcset="assets/images/about_us_logo_3.webp" alt="client logo img"
                                    width="233" height="35" />
                            <source srcset="assets/images/about_us_logo_3.png" alt="client logo img"
                                    width="233" height="35" />
                            <img src="assets/images/about_us_logo_3.png" alt="client logo img" width="233"
                                 height="35" />
                        </picture>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)">
                        <picture>
                            <source srcset="assets/images/about_us_logo_4.webp" alt="client logo img"
                                    width="158" height="54" />
                            <source srcset="assets/images/about_us_logo_4.png" alt="client logo img"
                                    width="158" height="54" />
                            <img src="assets/images/about_us_logo_4.png" alt="client logo img" width="158"
                                 height="54" />
                        </picture>
                    </a>
                </li>
            </ul>
            <p class="our_clients_info">Even Before we launched this website, we have been in this business
                for the last 15 yrs, providing Content to the <span class="creative">Creative</span> , <span
                      class="marketing">Marketing</span> , <span class="advertising">Advertising</span> and
                <span class="film_community">Film community</span> ine the EMEA & South Asia. Our Client
                believe in us and that has made us stronger over the years.</p>
        </div>
    </div>
</section>