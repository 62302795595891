import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/appv2/services/account.service';
import { ToastService } from 'src/appv2/services/toast.service';
import { WishlistService } from 'src/appv2/services/wishlist.service';
import { environment } from 'src/environments/environment';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { Store } from '@ngrx/store';


import $ from 'jquery';

@Component({
  selector: 'appv2-my-wishlist',
  templateUrl: './my-wishlist.component.html',
  styleUrls: ['./my-wishlist.component.scss'],
})
export class MyWishlistComponent implements OnInit {
  wishlistData: any = [];
  user = '';
  showWishlistModal: boolean = false;
  showPreConfirmModal: boolean = false;
  wishlistLink: String;
  private baseUrl = environment.front_url;
  isClick: boolean = false;
  selectedWishlistData: any = [];
  private router: Router;
  isWishlistPopupActive = false;
  public preconfirmPopupConfigData = []
  public deleteCollection : any

  constructor(
    private accountActions: AccountService,
    private wishlist: WishlistService,
    private toastService: ToastService,
    private renderer: Renderer2,
    private store$: Store<any>,
  ) {}

  ngOnInit(): void {
    this.user = localStorage.getItem('user_data');
    this.loadUserWishlists();

  }

  toggleWishlistPopup(id) {
    const wishlistPopup = document.querySelector('.list' + id);
    if (wishlistPopup) {
      const inactiveClass = 'wishlist-popup';
      const activeClass = 'wishlist-popup-active';
      this.isWishlistPopupActive = !this.isWishlistPopupActive;
      if(this.isWishlistPopupActive) {
        this.renderer.removeClass(wishlistPopup, inactiveClass);
        this.renderer.addClass(wishlistPopup, activeClass);
      } else {
        this.renderer.removeClass(wishlistPopup, activeClass);
        this.renderer.addClass(wishlistPopup, inactiveClass);
      }
    }
  }

  loadUserWishlists = () => {
    this.wishlist.getWishlist(this.user).subscribe((data) => {
      this.accountActions.updateUserWishlist(data['data']['wishlists']);
      this.accountActions.userWishLists.subscribe((wishlistData) => {
        this.wishlistData = wishlistData;
      });
      this.showWishlistModal = false;
      this.selectedWishlistData = [];
    });
  };

  addWishlist = () => {
    this.showWishlistModal = true;
  };

  handleWishlistModalEvent = (event) => {
    if (event['success']) {
      this.loadUserWishlists();
    } else {
      this.showWishlistModal = false;
      this.selectedWishlistData = [];
    }
  };

  openSharePopup(wishlistId) {
    let payload = {};
    payload['wishlist_id'] = wishlistId;
    this.accountActions.getShareLink(payload).subscribe((data) => {
      this.wishlistLink =
        this.baseUrl + '#/added-wishlist-own-account/' + data.data;
    });

    $('.download_popup').toggle();
    $('.overlay').toggle();
    $('body').addClass('overflow_hidden');
  }

  copyLink(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.isClick = true;
  }

  onCancel() {
    $('.download_popup').toggle();
    $('.overlay').toggle();
    $('body').removeClass('overflow_hidden');
  }

  editWishlistName = (wishlist) => {
    this.showWishlistModal = true;
    this.selectedWishlistData = wishlist;
  };

  onDelete(collection){
    this.deleteCollection = collection
    this.preconfirmPopupConfigData['popup_message'] = 'Are you sure you want to delete wishlist?'
    this.preconfirmPopupConfigData['confirm_button_text']= 'Delete'
    this.preconfirmPopupConfigData['cancel_button_text'] = 'Cancel'
    this.showPreConfirmModal = true;
  }

  handlePreConfirmModalEvent = (event) => {
    if (event['confirm']) {
      this.collectionDelete(this.deleteCollection)

    } else {
      // Cancel the operation by hiding the pre-confirm modal
      this.showPreConfirmModal = false;
      this.toggleWishlistPopup(this.deleteCollection.id)
      this.deleteCollection = []
    }
  };

  collectionDelete(collection) {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true })
    );
    this.wishlist.deleteWishlist(collection).subscribe((data) => {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: false })
      );
      if (data.status == 'success') {
        this.loadUserWishlists()
        this.toastService.success(data.message);
      } else {
        this.toastService.error(data.message);
      }
    });
  }
}
