<section class="csm-content-sec">
    <div class="container">
        <div class="csm-content-inner">
            <h1>Introduction</h1>
            <p>Dribbble Holdings Limited (the “Dribbble Group”) is comprised of several companies, which together provide tools to help the world’s designers to create, develop and promote their talents (each a “Service” and collectively, the “Services”).
                The companies within the Dribbble Group each act as the data controller for personal data processed in respect of their Services (each a “Group Company” and together the “Group Companies”) and referred to as “our,” “we,” or “us” below.
                The data controllers for each Service are:</p>
            <ul>
                <li>Dribbble - Dribbble Holdings Ltd</li>
                <li>Creative Market - Creative Market Labs, Inc.</li>
                <li>Fontspring - Creative Market Labs, Inc.</li>
                <li>Font Squirrel - Creative Market Labs, Inc.</li>
            </ul>
            <p>We know that you care about how your information is used and shared. This Privacy Policy provides details of the way in which the Group Companies process personal data in line with their obligations under relevant data protection law,
                including the European Union’s General Data Protection Regulation (the “GDPR”), the California Consumer Privacy Act (the “CCPA”), and other applicable laws (collectively, “Data Protection Law”).</p>
            <p>This Privacy Policy explains what information of yours will be collected by the Group Companies when you use our Services, how the information will be used, and how you can control the collection, correction and/or deletion of information.
                Note that certain third parties may be able to identify you across sites and services and over time using the information they process, however, any such processing not done at the direction of us is outside the scope of this Privacy
                Policy. We are not responsible for the privacy policies, content or security of any linked third party websites or services. We recommend that you check the privacy and security policies of each and every website and service that you
                visit.
            </p>
            <div class="information-content">
                <h2>Information We Process</h2>
                <p>The types of information we process depends on how you use our Services. Many of our Services require users to set up an account, which involves the collection and processing of your name and email address. Other Services may require
                    the collection of additional information. For example, Dribbble helps to connect its community of designers with people and companies in need of their skills. In order to do so, Dribbble collects payments data and other information
                    required to comply with relevant legal obligations. The following table explains the types of information we collect and how we collect it. These categories of data may collectively be referred to as “Your Information”.
                </p>
                <div class="small-list">
                    <h3>Business Purposes,</h3>
                    <p>which include auditing, security, debugging/repair, certain short-term uses, performing services, internal research for technical development, quality and safety maintenance and verification, as well as the use of Your Information
                        for our operational purposes, or other notified purposes that use Your Information as reasonably necessary and proportionate to achieve the operational purpose; and</p>
                </div>
                <div class="small-list">
                    <h3>Commercial Purposes</h3>
                    <p>which include use of Your Information to advance our commercial or economic interests, such as encouraging others to buy, rent, lease, join, subscribe to, provide, or exchange products, goods, property, information, or services,
                        or enabling or effecting, directly or indirectly, a commercial transaction.</p>
                </div>
            </div>
        </div>
    </div>
</section>