import { Component, Input, OnInit,Output, EventEmitter, OnDestroy } from '@angular/core';
import { AccountService } from 'src/appv2/services/account.service';
import { WishlistService } from 'src/appv2/services/wishlist.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/appv2/services/toast.service';
import $ from 'jquery';
import { User } from 'src/appv2/models/user';
import { Router } from '@angular/router';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { Store } from '@ngrx/store';
import { messages } from 'src/appv2/appv2.constants';

@Component({
  selector: 'add-to-wishlist',
  templateUrl: './add-to-wishlist.component.html',
  styleUrls: ['./add-to-wishlist.component.scss']
})
export class AddToWishlistComponent implements OnInit, OnDestroy {

  @Input() productData: any = []; // Declare an input property
  @Output() dataEvent = new EventEmitter<any>(); // Define an output property to emit events

  loginUser: User;
  userWishlistsData: any = [];
  currrentProductId: string = "";
  user = "";
  wishlistForm: FormGroup;
  showValidationError:boolean = false;
  errorMessage:string = "Wishlist name is required."

  constructor(
    private accountActions: AccountService,
    private wishlist: WishlistService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private router: Router,
    private store$: Store<any>,
  ) {
    this.loginUser = this.accountActions.userValue;
  }

  /**
   * Initializes the component with necessary data and configurations when it is created.
   * Retrieves user data from local storage, extracts the current product ID from input data,
   * initializes the wishlist form with validation rules, loads the user's wishlists, and opens the modal dialog.
   */
  ngOnInit(): void {
    this.user = localStorage.getItem('user_data');
    this.currrentProductId = this.productData['product_id'];

    this.wishlistForm = this.formBuilder.group({
      wishlistName: ['', Validators.required]
    });
    this.loadUserWishlists(true);
    this.openModal();
  }

  /**
   * Displays the "add to wishlist" modal along with its overlay.
   * Also has a commented line for adding a CSS class to the body element to prevent scrolling.
   */
  openModal = () => {
    $('body').find('.wrapper').first().css("position", "static");
    $(".add_to_wishlist").show();
    $(".overlay").show();
    // Uncomment the next line to prevent scrolling when the modal is open
    $("body").addClass("overflow_hidden");
  }

  /**
   * Closes the "add to wishlist" modal and emits an event to the parent component
   * to indicate that the modal should be closed. Optionally passes data to the parent component.
   */
  closeModal = () => {
    let data = {};
    data['close_modal'] = true;
    if (this.productData) {
      data['parent_passed_data'] = this.productData;
    }
    this.emitDataToParent(data);
  }

  /**
   * Hides the styling of the "add to wishlist" modal and its overlay.
   * Also has a commented line for removing a CSS class from the body element to restore scrolling.
   */
  hideModelStyling = () => {
    $('body').find('.wrapper').first().css("position", "relative");
    $(".add_to_wishlist").hide();
    $(".overlay").hide();
    // Uncomment the next line to allow scrolling when the modal is closed
    $("body").removeClass("overflow_hidden");
  }

  /**
   * Checks if the current product is already in the given wishlist.
   * @param {Object} wishList - The wishlist object containing product information.
   * @returns {boolean} - Returns true if the current product is in the wishlist, otherwise false.
   */
  isProductOfWishlist = (wishList) => {
    if (wishList && wishList.products && Array.isArray(wishList.products)) {
      for (let index in wishList.products) {
        if (wishList.products[index]['product_id'] == this.currrentProductId) {
          return true;
        }
      }
    }
  }

  /**
   * Adds the current product to the specified wishlist.
   * @param {string} wishlistId - The ID of the wishlist to which the product will be added.
   */
  addProductToWishList = (wishlistId) => {
    let postData = JSON.parse(this.user);
    postData['wishlist_id'] = wishlistId;
    postData['products'] = []; // For adding multiple products, an array is used

    let product = {};
    product['product_id'] = this.currrentProductId;
    product['type'] = 'image';

    postData['products'].push(product);

    this.wishlist.addProductsToWishlist(postData).subscribe((res) => {
      if (!res['error']) {
        this.loadUserWishlists(true);

        this.toastService.success(res['message']);
      } else {
        this.toastService.error(res['message']);
      }
    // }, (error) => {
    //   console.log("error : ", error);
    });
  }

  /**
   * Removes the current product from the specified wishlist.
   * @param {string} wishlistId - The ID of the wishlist from which the product will be removed.
   */
  removeProductFromWishList = (wishlistId) => {
    let postData = JSON.parse(this.user);
    postData['wishlist_id'] = wishlistId;
    postData['product_id'] = [this.currrentProductId];

    // Send API request to remove the product from the wishlist
    this.wishlist.removeProductFromWishlist(postData).subscribe((res) => {
      if (!res['error']) {
        // Reload user wishlists and show success message
        this.loadUserWishlists(true);
        this.toastService.success(res['message']);
      } else {
        // Show error message if removing the product failed
        this.toastService.error(res['message']);
      }
    }, (error) => {
      // Handle API request error
       console.log("error : ", error);
    });
  }

  /**
   * Loads the user's wishlists and updates the displayed data.
   * @param {boolean} refresh_from_server - If true, fetches the wishlists from the server; otherwise, uses cached data.
   */
  loadUserWishlists = (refresh_from_server = false) => {
    if (refresh_from_server) {
      // Check if user data is available
      if (this.user) {
        // Fetch wishlists data from the server
        this.wishlist.getWishlist(this.user).subscribe(data => {
          // Update user wishlists data using the account service
          this.accountActions.updateUserWishlist(data['data']['wishlists']);
          // Subscribe to user wishlist changes

          this.accountActions.userWishLists.subscribe((wishlistData) => {
            this.userWishlistsData = wishlistData;
          });
        });
      }
    } else {
      // Subscribe to user wishlist changes
      this.accountActions.userWishLists.subscribe((wishlistData) => {
        this.userWishlistsData = wishlistData;
      });
    }
  }

  /**
   * Handles the form submission for creating or updating a wishlist.
   * If the form is valid, constructs and sends an API request for wishlist creation or update.
   */
  onSubmit() {
    if(!this.loginUser?.userdata) {
      this.toastService.error(messages.loginMustForWishlist)
      setTimeout(() => {
        this.router.navigate(['/signin']);
      }, 4000);
    }
    if (this.wishlistForm.valid) {
      // Extract wishlist name from the form
      const wishlistName = this.wishlistForm.value.wishlistName;

      // Prepare data for API request
      let postData = JSON.parse(this.user);
      postData['wishlist_name'] = wishlistName;
      postData['products'] = [];

      // Prepare product data for the wishlist
      let product = {};
      product['product_id'] = this.currrentProductId;
      product['type'] = 'image';

      postData['products'].push(product);

      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: true })
      );
      // Send API request for wishlist creation or update
      this.wishlist.createOrUpdateWishlist(postData).subscribe((res) => {
        this.store$.dispatch(
          new loadingChangeAction(<LoadingState>{ loadingStatus: false })
        );
        if (res['error'] && res['message']) {
          this.errorMessage = res['message'];
          this.showValidationError = true;

        } else {
          // Display success message, reload wishlists, and reset form
          this.errorMessage = "";
          this.toastService.success(res['message']);
          this.loadUserWishlists(true);
          this.wishlistForm.reset();
          this.closeModal()
        }
      }, (error) => {
        // Handle API request error
         console.log("error : ", error);
      });
    } else {
      // Display validation error if form is not valid
      this.errorMessage = "Wishlist name is required."
      this.showValidationError = true;
    }
  }

  /**
   * A function to provide a unique identifier for ngFor rendering of items in a list.
   * @param {number} index - The index of the item in the list.
   * @param {any} item - The item being tracked in the ngFor loop.
   * @returns {any} - The unique identifier for the item.
   */
  trackByFn = (index: number, item: any): any => {
    return item.id; // Use a unique identifier for each item
  }

  /**
   * Emits data to the parent component using the dataEvent output property.
   * @param {object} data - The data to be emitted to the parent component.
   */
  emitDataToParent(data = {}) {
    this.dataEvent.emit(data);
  }

  /**
   * Lifecycle hook that gets called when the component is about to be destroyed.
   * Calls the hideModelStyling function to hide modal styling.
   */
  ngOnDestroy(): void {
    this.hideModelStyling();
  }
}
