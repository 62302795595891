import {
  Component,
  DoCheck,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import $ from 'jquery';
import { Config, LayoutStyle, Media } from 'ng-opengallery';
import { Observable } from 'rxjs';
import { messages } from 'src/appv2/appv2.constants';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import {
  selectCurrentRoute,
  selectSearchKey,
} from 'src/appv2/reducers/router/router.selector';
import {
  SearchChangeSearchType,
  SearchWriteAction,
} from 'src/appv2/reducers/search/search.actions';
import { SearchState } from 'src/appv2/reducers/search/search.models';
import {
  selectIsSearchEditorial,
  selectSearchName,
  selectSearchText,
  selectSearchType,
} from 'src/appv2/reducers/search/search.selectors';
import { ItemDetailsService } from 'src/appv2/services/item-details.service';
import { SearchService } from 'src/appv2/services/search.service';
import { ToastService } from 'src/appv2/services/toast.service';
import { WishlistService } from 'src/appv2/services/wishlist.service';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GalleryComponent implements OnInit, DoCheck {
  public search$: Observable<string> = this.store$.pipe(
    select(selectSearchKey)
  ); //How to output store varible in html template

  length;
  getImages;
  pageName = '';

  config: Config = {
    diaporamaDuration: 3,
    layout: LayoutStyle.MASONRY,
    prefMediaHeight: 400,
    spacing: 0.25,
    viewerEnabled: true,
    enableAutoPlay: false,
  };
  galleryData: Array<any>;
  userWishlistedData: string[] = []; // Initialize it as an empty array
  itemData: Array<object>;
  totalRecords: Number;
  page: number = 1;
  currPage: number = 1;
  prevPage: number = 0;
  recordsPerPage: number;
  user = '';
  // searchType:number = 0
  // prevSearchType:number = 0

  isRedirect: boolean;
  searchType: number = 1;
  prevSearchType: number = 1;

  prevSearchKey: string = '';
  searchKey: string = '';

  pageGallery: string;
  isEditorial: boolean;

  prevFilters;
  filters;
  wishlistData = [];
  selectSearchNameObserver: any;
  selectCurrentRouteObserver: any;
  selectSearchTextObserver: any;

  searchDebounceInterval: any = null;
  clearDebounceInterval() {
    clearTimeout(this.searchDebounceInterval);
  }

  currentlyPlayingIndex = -1;
  showAddToWishListModal: boolean = false;
  homeData:Array<any>

  playVideo(videoElement: HTMLVideoElement) {
    if (this.currentlyPlayingIndex !== -1) {
      const previousVideo = this.galleryData[this.currentlyPlayingIndex];
      const previousVideoElement = document.getElementById(
        previousVideo.previewUrl
      ) as HTMLVideoElement;
      if (previousVideoElement) {
        previousVideoElement.pause();
        previousVideoElement.currentTime = 0;
      }
    }

    this.currentlyPlayingIndex = this.galleryData.findIndex(
      (item) => item.previewUrl === videoElement.src
    );
    videoElement.play();
  }

  // Link to item
  openItem(item) {
    this.router.navigate(['/image/'+item['slug']]);
  }
  scrollToGalleryStart() {
    window.scrollTo(0, 800);
  }
  constructor(
    private store$: Store<any>,
    private mysearch: SearchService,
    private itemDetails: ItemDetailsService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private toastService: ToastService,
    private wishlist: WishlistService
  ) {
    this.galleryData = new Array<any>();
    let search = this?.activatedRouter?.snapshot?.queryParamMap?.get('search');
    if (typeof search !== 'undefined' && search != '') {
      this.searchKey = search;
    }
  }

  // Subscribe to Store
  starSearch() {
    this.store$.pipe(select(selectSearchType)).subscribe((vl) => {
      if (vl != undefined) {
        this.isRedirect = vl;
      }
    });
    this.selectSearchTextObserver = this.store$
      .pipe(select(selectSearchText))
      .subscribe((vl) => {
        this.clearDebounceInterval();
        this.searchDebounceInterval = setTimeout(() => {
          if (vl != undefined) {
            if (vl.startsWith('category')) {
              this.searchKey = vl.replace('category ', '');
              this.getGalleryContent({
                type: this.filters['type'],
                search: this.searchKey,
              });
            } else {
              this.searchKey = vl;
            }
          }
        }, 200);
      });
    this.store$.pipe(select(selectIsSearchEditorial)).subscribe((vl) => {
      if (this.isRedirect) {
        this.isEditorial = vl;
      }
    });
    this.selectCurrentRouteObserver = this.store$
      .pipe(select(selectCurrentRoute))
      .subscribe((vl) => {
        this.clearDebounceInterval();
        this.searchDebounceInterval = setTimeout(() => {
          if (vl != undefined) {
            this.store$.dispatch(
              new SearchWriteAction(<SearchState>{ searchText: ' ' })
            );
            let searchPage = {};
            this.pageName = vl.routeConfig.path;

            if (vl.routeConfig.path == 'account-activated/:email') {
              this.toastService.success(
                'Your account is activited successfully.'
              );
            }

            switch (vl.routeConfig.path) {
              case '':
                this.searchType = 1;
                searchPage['type'] = 'image';
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: true,
                  })
                );
                this.filters = searchPage;
                break;

              case 'image':
                this.searchType = 1;
                searchPage['type'] = 'image';
                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: false,
                  })
                );
                break;

              case 'footage':
                this.searchType = 2;
                searchPage['type'] = 'footage';
                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: false,
                  })
                );
                break;

              case 'editorial':
                searchPage['product_editorial'] = 1;
                this.searchType = 1;
                searchPage['type'] = 'image';
                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: true,
                  })
                );
                break;

              case 'search':
                this.filters = vl.queryParams;
                break;

              default:
                this.searchType = 1;
                searchPage['type'] = 'image';
                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: false,
                  })
                );
            }
            this.getGalleryContent(this.filters);
          }
        }, 200);
      });

    this.selectSearchNameObserver = this.store$
      .pipe(select(selectSearchName))
      .subscribe((vl) => {
        this.clearDebounceInterval();
        this.searchDebounceInterval = setTimeout(() => {
          if (this.isRedirect) {
            this.pageGallery = vl;
            if (this.pageName == '' || this.pageName == 'editorial') {
              this.filters = {
                type: this.pageGallery,
                product_editorial: +this.isEditorial,
              };
              this.getGalleryContent(this.filters);
            }
          }
        }, 200);
      });
  }
  ngDoCheck() {
    if (this.galleryData.length > 0) {
      let v = document.querySelectorAll('.gallery video');
      for (let i = 0; i < v.length; i++) {
        v[i]['muted'] = true;
      }
      let footages = $('.gallery video');
      for (let i = 0; i < footages.length; i++) {
        $(footages[i]).mouseout((event) => {
          $(event.target)[0].load();
        });
        $(footages[i]).mouseover((event) => {
          $(event.target)[0].play();
        });
        footages[i].setAttribute(
          'poster',
          this.itemData[i]['product_thumbnail']
        );
      }
    }
  }
  fileExtension: string;
  // Get search result
  getGalleryContent(filters, currPage = 1) {
  filters.type = 'image'
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true })
    );
    if (Object.keys(filters).length !== 0 && filters.constructor === Object) {
      this.mysearch
        .getSearchWithFiltersResult(filters, currPage, this.searchKey, [])
        .subscribe((data) => {
          if (data.imgfootage != null && data.imgfootage != undefined) {
            this.galleryData = [];
            let filtereddata = data.imgfootage.filter(function (tag) {
              return tag.api_product_id;
            });
            for (let i = 0; i < filtereddata.length; i++) {
              if (filters['type'] == 'image') {
                const parts = filtereddata[i]['product_thumbnail'].split('.');
                // Get the last part of the array (the file extension)
                this.fileExtension = parts[parts.length - 1];
                this.galleryData.push(
                  new Media(
                    filtereddata[i]['product_thumbnail'],
                    filtereddata[i]['product_title']
                  )
                );
              } else {
                const parts = filtereddata[i]['product_main_image'].split('.');
                // Get the last part of the array (the file extension)
                this.fileExtension = parts[parts.length - 1];
                this.galleryData.push(
                  new Media(
                    filtereddata[i]['product_main_image'],
                    filtereddata[i]['product_title'],
                    'video'
                  )
                );
              }
            }

            this.itemData = filtereddata;
            this.recordsPerPage = filtereddata.length;
            this.totalRecords = data.total;
          }
          this.store$.dispatch(
            new loadingChangeAction(<LoadingState>{ loadingStatus: false })
          );
        });
    } else {
      this.mysearch.getSearchResult(1, currPage).subscribe((data) => {
        this.galleryData = [];
        for (let i = 0; i < data.imgfootage.length; i++) {
          if (data.imgfootage[i]['product_main_type'] == 'Image') {
            this.galleryData.push(
              new Media(data.imgfootage[i]['product_thumbnail'], '')
            );
          } else {
            this.galleryData.push(
              new Media(data.imgfootage[i]['product_main_image'], '', 'video')
            );
          }
        }
        this.itemData = data.imgfootage;
        this.recordsPerPage = data.imgfootage.length;
        this.totalRecords = data.total;
        this.store$.dispatch(
          new loadingChangeAction(<LoadingState>{ loadingStatus: false })
        );
      });
    }
    if (this.itemData != undefined) {
    }
  }

  // Get related to item data
  getLayoutImages() {
    this.itemDetails.getHomePageProducts()
      .subscribe((data) => {
        this.homeData = []
        this.getImages = data.imgfootage;
        this.length = data.total;
        for (let i = 0; i < this.getImages.length; i++) {

            const parts = this.getImages[i]['product_thumbnail'].split('.');
            // Get the last part of the array (the file extension)
            this.fileExtension = parts[parts.length - 1];
            this.homeData.push(
              new Media(
                this.getImages[i]['product_thumbnail'],
                this.getImages[i]['product_title']
              )
            );

        }

      });
  }
  /**
   * Display the "Add to Wishlist" modal.
   */
  showModal(wishlist) {
    this.wishlist.getWishlistData(wishlist).subscribe(
      (res) => {
        if (res['status'] == 'success') {
          // Reload user wishlists and show success message
          this.wishlistData['product_id'] = res.data.product_id;
          this.wishlistData['type'] = res.data.product_main_type;
          this.wishlistData['title'] = res.data.product_title;
          this.wishlistData['itemPoster'] = res.data.product_thumbnail;
          this.wishlistData['author_username'] = res.data.product_owner;
          this.showAddToWishListModal = true;
        } else {
          this.toastService.error(messages.categoryListProductValidation);
          this.showAddToWishListModal = false;
        }
      },
      (error) => {
        // Handle API request error
         console.log('error : ', error);
      }
    );
  }


  userWishlistData = () => {
    let postData = JSON.parse(this.user);
    this.wishlist.getUserWishlist(postData).subscribe(
      (res) => {
        this.userWishlistedData = res.data.map((item) =>
          String(item.product_thumbnail)
        );
      },
      (error) => {
         console.log('error : ', error);
      }
    );
  };

  toNextPage() {
    this.currPage++;
    this.getGalleryContent(this.filters, this.currPage);
  }
  toPrevPage() {
    this.currPage--;
    this.getGalleryContent(this.filters, this.currPage);
  }
  ngOnInit() {
    this.user = localStorage.getItem('user_data');
    this.getLayoutImages();
    this.starSearch();
    if (this.user) {
      this.userWishlistData();
    }
  }

  ngAfterViewInit() {
    let searchInput = document.querySelector('.search-container input');
    if (searchInput) {
      searchInput['value'] = this.searchKey;
    }
  }

  ngOnDestroy() {
    this.selectCurrentRouteObserver?.unsubscribe();
    this.selectSearchNameObserver?.unsubscribe();
    this.selectSearchTextObserver?.unsubscribe();
    this.clearDebounceInterval();
  }

  /**
   * Handles events triggered from the Add To Wishlist Modal.
   * If the event indicates the need to close the modal, the flag to show the modal is set to false.
   * @param {object} event - The event object containing information about the modal action.
   */
  handleWishlistModalEvent = (event) => {
    if (event['close_modal']) {
      // Set the flag to false, indicating that the Add To Wishlist Modal should be closed
      this.showAddToWishListModal = false;
      if(this.user){
        this.userWishlistData()
      }
    }
  };
  openItemGallery(item) {

   const objectValues = Object.values(this.getImages);
   const foundObject = objectValues.find(
     (value) => (value as any).product_thumbnail === item.previewUrl
   );


   this.router.navigate(['/image/'+foundObject['slug']]);

}
}
