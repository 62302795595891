<!-- banner section start -->
<section class="banner-sec" *ngIf="promotion">
  <picture *ngIf="promotion?.media_type != 'Footage' && false">
    <source class="banner-img dec-img" srcset="{{ promotion?.desktop_banner_image }}" alt="Images" width="1920"
      height="524" />
    <source class="banner-img dec-img" srcset="{{ promotion?.desktop_banner_image }}" alt="Images" width="1920"
      height="524" />
    <img class="banner-img dec-img" src="{{ promotion?.desktop_banner_image }}" alt="Images" width="1920"
      height="524" />

    <source class="banner-img mob-img" srcset="{{ promotion?.mobile_banner_image }}" alt="Images" width="575"
      height="380" />
    <source class="banner-img mob-img" srcset="{{ promotion?.mobile_banner_image }}" alt="Images" width="575"
      height="380" />
    <img class="banner-img mob-img" src="{{ promotion?.mobile_banner_image }}" alt="Images" width="575" height="380" />
  </picture>  
  <video class="banner-img dec-img" [muted]="'muted'" loop autoplay src="./../assets/img/worldMotionNonBranded.mp4"></video>
  <video class="banner-img mob-img" [muted]="'muted'" loop autoplay src="./../assets/img/worldMotionNonBranded.mp4"></video>
  <div class="container" *ngIf="promotion?.media_type != 'Footage'">
    <div class="banner-content">
      <h1 class="h1">{{ promotion?.event_name }}</h1>
      <p>{{ promotion?.event_des }}</p>
      <appv2-search-bar class="categories-seach-block-banner" [params]="{isHeaderPart: false}"></appv2-search-bar>
    </div>
  </div>
  <picture class="video-banner" *ngIf="promotion?.media_type == 'Footage'">
    <video #videoElement class="img-tag" (mouseenter)="playVideo(videoElement)" src="{{ promotion?.media_url }}"
      height="554" autoplay></video>
  </picture>
</section>
<!-- banner section end -->
<!-- Information Line section start -->
<section class="information_line bg-color" *ngIf="discountDetails">
  <div class="container" *ngIf="discountDetails?.status == 1">
    <div class="information_line_inner">
      <p>{{ discountDetails?.title }}</p>
      <a target="_blank" href="{{ discountDetails?.link }}">{{
        discountDetails?.button_text
        }}</a>
    </div>
  </div>
</section>
<!-- Information Line section end -->
<!-- Popular Categories section start -->
<appv2-category-data></appv2-category-data>
<!-- Popular Categories section end -->
<!-- Turn ideas into stories with high-quality videos section start -->
<section class="turn_ideas_into_story">
  <div class="container">
    <div class="turn_ideas_into_story_heading">
      <h2 class="h2">Turn ideas into stories with high-quality videos</h2>
      <p>
        Whether you’re a marketer, content creator or, designer, Freepik’s video
        collection will meet your needs
      </p>
    </div>
    <div class="turn_ideas_into_story_inner">
      <div class="turn_ideas_into_story_inner_content">
        <div class="story_icon">
          <a href="javascript:void(0)"><img src="assets/images/download_icon.svg" alt="download-icon" width="72"
              height="72" /></a>
        </div>
        <div class="story_icon_heading">
          <h3 class="h3">Ready-to-download</h3>
          <p>
            Save time and effort with videos made to be used on the spot. No
            need for additional editing.
          </p>
        </div>
      </div>
      <div class="turn_ideas_into_story_inner_content">
        <div class="story_icon">
          <a href="javascript:void(0)"><img src="assets/images/youtube_icon.svg" alt="youtube-icon" width="72"
              height="72" /></a>
        </div>
        <div class="story_icon_heading">
          <h3 class="h3">Diverse and versatile</h3>
          <p>
            Access thousands of high-quality, free and Premium videos available
            in a wide range of categories to fit any project.
          </p>
        </div>
      </div>
      <div class="turn_ideas_into_story_inner_content">
        <div class="story_icon">
          <a href="javascript:void(0)"><img src="assets/images/shield.svg" alt="shield-icon" width="72"
              height="72" /></a>
        </div>
        <div class="story_icon_heading">
          <h3 class="h3">Suitable for commercial use</h3>
          <p>
            Create stories with worry-free licensing. All videos can be used for
            both personal and commercial purposes.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Turn ideas into stories with high-quality videos section end -->
<!-- Find the perfect video clip section start -->
<section class="find_the_perfect_video_clip_section">
  <div class="container">
    <div class="find_the_perfect_video_clip_inner">
      <div class="find_the_perfect_video_clip_section_content">
        <h2 class="h2">Find the perfect video clip</h2>
        <p>
          Discover the best royalty free stock images, photos, vectors, footage,
          videos and music files for your designs and projects by talented
          artists and contributors worldwide right here. Everything you need is
          just a search away.
        </p>
      </div>
      <div class="find_the_perfect_video_clip_section_img_1">
        <picture>
          <source srcset="assets/images/videoclip_1.webp" alt="videoclip-image" width="410" height="370" />
          <source srcset="assets/images/videoclip_1.png" alt="videoclip-image" width="410" height="370" />
          <img src="assets/images/videoclip_1.png" alt="videoclip-image" width="410" height="370" />
        </picture>
      </div>
    </div>
  </div>
  <div class="find_the_perfect_video_clip_section_img_2">
    <picture>
      <source srcset="assets/images/videoclip_2.webp" alt="videoclip-image" width="109" height="92" />
      <source srcset="assets/images/videoclip_2.png" alt="videoclip-image" width="109" height="92" />
      <img src="assets/images/videoclip_2.png" alt="videoclip-image" width="109" height="92" />
    </picture>
  </div>
</section>
<!-- Find the perfect video clip section end -->
<!-- Professionally curated section start -->
<section class="professionally_curated" *ngIf="isProfessionallyCuratedShow">
  <div class="container">
    <h2 class="h2">Professionally curated</h2>
    <div class="professionally_curated_box">
      <div class="professionally_curated_image_box">
        <div class="professionally_curated_image">
          <div class="professionally_image">
            <picture>
              <source srcset="assets/images/professionaly_curated.webp" alt="professionaly-curated" width="592"
                height="283" />
              <source srcset="assets/images/professionaly_curated.jpg" alt="professionaly-curated" width="592"
                height="283" />
              <img src="assets/images/professionaly_curated.jpg" alt="professionaly-curated" width="592" height="283" />
            </picture>
          </div>
          <div class="professionally_curated_image_name">
            <a href="javascript:void(0)">Neon Loop</a>
          </div>
        </div>
      </div>
      <div class="professionally_curated_image_box">
        <div class="professionally_curated_image">
          <div class="professionally_image">
            <picture>
              <source srcset="assets/images/professionaly_curated_1.webp" alt="professionaly-curated" width="592"
                height="283" />
              <source srcset="assets/images/professionaly_curated_1.jpg" alt="professionaly-curated" width="592"
                height="283" />
              <img src="assets/images/professionaly_curated_1.jpg" alt="professionaly-curated" width="592"
                height="283" />
            </picture>
          </div>
          <div class="professionally_curated_image_name">
            <a href="javascript:void(0)">Morning</a>
          </div>
        </div>
      </div>
      <div class="professionally_curated_image_box">
        <div class="professionally_curated_image">
          <div class="professionally_image">
            <picture>
              <source srcset="assets/images/professionaly_curated_2.webp" alt="professionaly-curated" width="592"
                height="283" />
              <source srcset="assets/images/professionaly_curated_2.jpg" alt="professionaly-curated" width="592"
                height="283" />
              <img src="assets/images/professionaly_curated_2.jpg" alt="professionaly-curated" width="592"
                height="283" />
            </picture>
          </div>
          <div class="professionally_curated_image_name">
            <a href="javascript:void(0)">Black is Beautiful</a>
          </div>
        </div>
      </div>
      <div class="professionally_curated_image_box">
        <div class="professionally_curated_image">
          <div class="professionally_image">
            <picture>
              <source srcset="assets/images/professionaly_curated_3.webp" alt="professionaly-curated" width="592"
                height="283" />
              <source srcset="assets/images/professionaly_curated_3.jpg" alt="professionaly-curated" width="592"
                height="283" />
              <img src="assets/images/professionaly_curated_3.jpg" alt="professionaly-curated" width="592"
                height="283" />
            </picture>
          </div>
          <div class="professionally_curated_image_name">
            <a href="javascript:void(0)">For All</a>
          </div>
        </div>
      </div>
      <div class="professionally_curated_image_box">
        <div class="professionally_curated_image">
          <div class="professionally_image">
            <picture>
              <source srcset="assets/images/professionaly_curated_4.webp" alt="professionaly-curated" width="592"
                height="283" />
              <source srcset="assets/images/professionaly_curated_4.jpg" alt="professionaly-curated" width="592"
                height="283" />
              <img src="assets/images/professionaly_curated_4.jpg" alt="professionaly-curated" width="592"
                height="283" />
            </picture>
          </div>
          <div class="professionally_curated_image_name">
            <a href="javascript:void(0)">Background</a>
          </div>
        </div>
      </div>
      <div class="professionally_curated_image_box">
        <div class="professionally_curated_image">
          <div class="professionally_image">
            <picture>
              <source srcset="assets/images/professionaly_curated_5.webp" alt="professionaly-curated" width="592"
                height="283" />
              <source srcset="assets/images/professionaly_curated_5.jpg" alt="professionaly-curated" width="592"
                height="283" />
              <img src="assets/images/professionaly_curated_5.jpg" alt="professionaly-curated" width="592"
                height="283" />
            </picture>
          </div>
          <div class="professionally_curated_image_name">
            <a href="javascript:void(0)">On Fire</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Professionally curated section end -->
<!-- Information section start -->
<section class="information_section">
  <div class="container">
    <div class="information_section_inner">
      <div class="information_section_content">
        <div class="information_content">
          <h3 class="h3">Broadly compatible</h3>
          <p>
            When you’re making videos for social media, you need to move fast.
            Save time by choosing from music tracks already formatted for a
            perfect match, with a variety of styles and moods in :15 and :30
            second options.
          </p>
        </div>
        <div class="information_content">
          <h3 class="h3">4K and HD resolution</h3>
          <p>
            When you’re making videos for social media, you need to move fast.
            Save time by choosing from music tracks already formatted for a
            perfect match, with a variety of styles and moods in :15 and :30
            second options.
          </p>
        </div>
        <div class="information_content">
          <h3 class="h3">Preview before downloading</h3>
          <p>
            When you’re making videos for social media, you need to move fast.
            Save time by choosing from music tracks already formatted for a
            perfect match, with a variety of styles and moods in :15 and :30
            second options.
          </p>
        </div>
        <div class="information_btn">
          <a href="javascript:void(0)" (click)="scrollTo('subscribeSectionId')">Subscribe Today</a>
        </div>
      </div>
      <div class="information_section_image">
        <picture>
          <source srcset="assets/images/story.webp" alt="popular-category-image" width="781" height="760" />
          <source srcset="assets/images/story.png" alt="popular-category-image" width="781" height="760" />
          <img src="assets/images/story.png" alt="popular-category-image" width="781" height="760" />
        </picture>
      </div>
    </div>
  </div>
</section>
<!-- Information section end -->
<!-- Subscribe a plan that suits well section start -->
<section class="subscribe-section" id="subscribeSectionId">
  <div class="container">
    <h2 class="h2" *ngIf="displayPricingHeader">
      Subscribe a plan that suits well
    </h2>
    <appv2-pricing-filter [params]="{ openTab: 'Footage', singleTab: 'Footage' }"
      (pricingHeaderDisplayEvent)="receivePricingHeaderDisplayEvent($event)"></appv2-pricing-filter>
    <div class="request_here">
      <p>
        Can't find your Licence type or the pack you are looking for? Kindly
        submit your
        <a href="javascript:void(0)">Request Here</a>
      </p>
    </div>
  </div>
</section>
<!-- Subscribe a plan that suits well section end -->
<!-- Still have questions section start -->
<appv2-questions></appv2-questions>