import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import $ from 'jquery';
import { User } from 'src/appv2/models/user';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';
import { ToastService } from 'src/appv2/services/toast.service';

@Component({
  selector: 'appv2-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {

  user: User;

  constructor(private store$: Store<any>, private userAccount: AccountService, private accountActions: AccountService, private toastService: ToastService) {
    this.user = this.accountActions.userValue;
  }

  ngOnInit(): void {
  }

  deleteAccount() {
    let payload = this.user.userdata.Utype;
    this.userAccount.deleteAccount(payload).subscribe(data => {
      this.onCancel();
      if (data.success) {
        this.toastService.success(data.message)
        this.accountActions.logout(); 
      }
      if (data.error) {
        this.toastService.warning(data.message)
      }
      this.store$.dispatch(new loadingChangeAction(<LoadingState> {loadingStatus: false}))
    })
  }

  openDeletePopup()
  {
    $(".download_popup").toggle();
    $(".overlay").toggle();
    $("body").addClass("overflow_hidden");
  }

  onCancel() {
    $(".download_popup").toggle();
    $(".overlay").toggle();
    $("body").removeClass("overflow_hidden");
  }

}
