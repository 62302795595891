import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileCompleteGuard implements CanActivate {

  constructor(
    private router: Router
) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const user = JSON.parse(localStorage.getItem('user_data'))

    if (user.profile_completed) {
      return true;
    }
    this.router.navigate(['/profile'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
