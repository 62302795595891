import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { messages } from 'src/appv2/appv2.constants';
import { DiscountDetailsInterface } from 'src/appv2/interfaces/discount-details.interface';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { SearchchangeSearchName } from 'src/appv2/reducers/search/search.actions';
import { SearchState } from 'src/appv2/reducers/search/search.models';
import { CacheService } from 'src/appv2/services/cache.service';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { SearchService } from 'src/appv2/services/search.service';
import { urlToCacheKeyMapping } from 'src/appv2/utils/constants/discount.const';

@Component({
  selector: 'appv2-footage-page',
  templateUrl: './footage-page.component.html',
  styleUrls: ['./footage-page.component.scss']
})
export class FootagePageComponent implements OnInit {

  /**TODO : keep Professionally curated section hidden for now. Need to improve in future */
  isProfessionallyCuratedShow: boolean = false;

  constructor(private landingPage: LandingPageService, private store$: Store<any>, private route: ActivatedRoute, private router: Router, private mysearch: SearchService, private http: HttpClient, private elementRef: ElementRef, private cacheService: CacheService) {
    this.getPageType();
    // Get Promotion Details
    this.promotionCacheKey = urlToCacheKeyMapping[this.promotionCacheKeyName];
    if (this.promotionCacheKey) {
      const promotion = this.cacheService.getData(this.promotionCacheKey);
      if (typeof promotion !== 'undefined') {
        this.promotion = promotion;
      } else {
        this.getPromotion();
      }
    }
  }

  promotion: any;
  discountDetailsResponse: any;
  public discountDetails = new DiscountDetailsInterface();
  page_type: string;
  searchtext: string
  mainPageType: string = 'footage';
  elasticSuggestions: any;
  openClass: string = '';
  visibleTrendingSearch: boolean = false
  showTrendSerach: boolean = false;
  recentSearches = []
  trendingKeyword: any;
  timeout: any = null;
  cacheKey: string;
  // display pricing component header text
  displayPricingHeader = true;
  page: string;
  promotionCacheKeyName: string = 'footage-page-banner';
  promotionCacheKey: string;

  ngOnInit(): void {
    // Get Discount Details
    this.cacheKey = urlToCacheKeyMapping[this.page];
    this.landingPage.isDiscountDetailsCalled.subscribe((isDiscountCalled: boolean) => {
      if (isDiscountCalled) {
        this.discountDetails = this.cacheService.getData(this.cacheKey);
      }
    })

    this.recentSearches = JSON.parse(localStorage.getItem('recent_search'));
  }

  getPageType() {
    const urlSegments = this.route.snapshot.url;
    // Get the last segment (the segment at the end of the URL)
    const lastSegment = urlSegments[urlSegments.length - 1];
    // Access the last segment's path
    const lastSegmentPath = lastSegment.path;
    this.page = lastSegmentPath;

    // Now you can use the lastSegmentPath variable as needed
    this.page_type =  'footage_page';
  }

  getPromotion() {
    this.loading(true);
    this.landingPage.getPromotionDetails(this.page_type).subscribe(data => {
      this.loading(false);
      this.promotion = data.data
      if(this.promotion){
        this.cacheService.setData(this.promotionCacheKey, this.promotion);
        this.landingPage.isFootageBannerDetailsCalled.next(true);
      }
    })
  }

  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.footagePageLoading}))
    return
  }

  receivePricingHeaderDisplayEvent(response: string) {
    this.displayPricingHeader = response == 'No' ? false : true;
  }

  openCategory(catName) {
    this.searchtext = catName;
    this.searchEditorial(this.mainPageType);
  }

  scrollTo(section: string): void {
    const targetSection = this.elementRef.nativeElement.querySelector(`#${section}`);
    if (targetSection) {
      const headerHeight = this.getHeaderHeight();
      window.scrollTo({
        top: targetSection.offsetTop - headerHeight,
        behavior: 'smooth'
      });
    }
  }

  private getHeaderHeight(): number {
    const header = document.querySelector('.header-sec');
    return header ? header.clientHeight : 0;
  }

  searchEditorial(page) {
    this.mainPageType = page;
    if (this.searchtext && page == "footage") {
      const footageQueryParams = { search: this.searchtext };
      const navigationExtras: NavigationExtras = {
        queryParams: footageQueryParams
      };
      this.router.navigate(['/footages'], navigationExtras);
    } else {
      this.store$.dispatch(new SearchchangeSearchName(<SearchState>{ searchType: page }));
    }
  }

  /**
   * Gets suggestion keyword list by elastic search
   * @param searchText
   */
  getSuggestionByElasticSearch(searchText) {
    this.searchtext = searchText;
    this.landingPage.getSuggestionByElasticSearch(this.searchtext).subscribe(data => {
      if (data) {
        this.elasticSuggestions = data.data
      }
    })
  }

  public onInput(event: any) {
    const inputValue = event.target.value;
    // Define a regular expression to match letters, numbers, and spaces
    const regex = /^[a-zA-Z0-9\s]*$/;

    if (!regex.test(inputValue)) {
      // If the input contains characters other than letters, numbers, and spaces,
      // remove those characters from the input value
      event.target.value = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
    }
  }

  // searchEditorial(page) {
  //   this.mainPageType = page;

  //   if (this.searchtext && page == "image") {
  //     const imageQueryParams = { search: this.searchtext };
  //     const navigationExtras: NavigationExtras = {
  //       queryParams: imageQueryParams
  //     };
  //     this.router.navigate(['/images'], navigationExtras);
  //   } else if (this.searchtext && page == "footage") {
  //     const footageQueryParams = { search: this.searchtext };
  //     const navigationExtras: NavigationExtras = {
  //       queryParams: footageQueryParams
  //     };
  //     this.router.navigate(['/footages'], navigationExtras);
  //   } else if (this.searchtext && page == "editorial") {
  //     const editorialQueryParams = { search: this.searchtext };
  //     const navigationExtras: NavigationExtras = {
  //       queryParams: editorialQueryParams
  //     };
  //     this.router.navigate(['/editorials'], navigationExtras);
  //   } else {
  //     this.store$.dispatch(new SearchchangeSearchName(<SearchState>{ searchType: page }));
  //   }
  // }

  clickOpenMenu() {
    this.visibleTrendingSearch = false;
    this.showTrendSerach = false;
    if (this.openClass === 'open') {
      this.openClass = '';
    } else {
      this.openClass = 'open'
    }
  }

  onEnterSearch() {
    // save to local storage
    let recentSearchData = JSON.parse(localStorage.getItem('recent_search'));
    this.recentSearches = recentSearchData
    if (this.searchtext) {
      if (!recentSearchData) {
        recentSearchData = [];
      }
      if (recentSearchData.length > 4) {
        recentSearchData.shift();
      }
      if (this.searchtext) {
        recentSearchData.push(this.searchtext);
      }
      localStorage.setItem('recent_search', JSON.stringify(recentSearchData));
      if (this.page_type == 'image' || this.mainPageType == "image") {
        const queryParams = { search: this.searchtext };

        const navigationExtras: NavigationExtras = {
          queryParams: queryParams
        };
        this.router.navigate(['/images'], navigationExtras);
        // this.router.navigate(['/images'], { state: navigationData });
      } else {
        this.storeKeywordInElasticSearch(this.searchtext);
        this.openCategory(this.searchtext);
      }
    }
  }

  onMouseInSearchBox() {
    this.visibleTrendingSearch = true
  }

  /**
   * Store keyword in elastic search api
   * @param keyword
   */
  storeKeywordInElasticSearch(keyword) {
    const bodyParams = {
      search_field: keyword
    };
    // this.loading(true);
    this.landingPage.storeKeywordInElasticSearch(bodyParams).subscribe(data => {
      this.loading(false);
    });
  }


  /**
     * Determines whether key press on
     * @param event
     */
  onKeyPress(event: any) {
    clearTimeout(this.timeout);
    this.openClass = '';
    this.timeout = setTimeout(() => {
      this.searchtext = event.target.value;
      if (this.searchtext && this.searchtext.trim().length != 0) {
        this.getSuggestionByElasticSearch(this.searchtext);
        this.showTrendSerach = true;
        this.visibleTrendingSearch = false;
      } else {
        this.showTrendSerach = false;
        this.visibleTrendingSearch = true;
      }
    }, 1000);
    if (event.keyCode == 13) {
      this.showTrendSerach = false;
      this.storeKeywordInElasticSearch(this.searchtext);
      this.onEnterSearch();
    }

  }
  onMouseOutSearchBox() {
    this.visibleTrendingSearch = false
    this.showTrendSerach = false;
  }

  playVideo(videoElement: HTMLVideoElement) {
    videoElement.play();
  }

}
