import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import $ from 'jquery';
import { Config, LayoutStyle, Media } from 'ng-opengallery';
import { Observable } from 'rxjs';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import {
  selectCurrentRoute,
  selectSearchKey,
} from 'src/appv2/reducers/router/router.selector';
import {
  SearchChangeSearchType,
  SearchWriteAction,
  SearchchangeSearchName,
} from 'src/appv2/reducers/search/search.actions';
import { SearchState } from 'src/appv2/reducers/search/search.models';
import {
  selectIsSearchEditorial,
  selectSearchName,
  selectSearchText,
  selectSearchType,
} from 'src/appv2/reducers/search/search.selectors';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { SearchService } from 'src/appv2/services/search.service';
import { ToastService } from 'src/appv2/services/toast.service';
import { WishlistService } from 'src/appv2/services/wishlist.service';
import { FilterComponent } from '../filter/filter.component';
import {messages} from 'src/appv2/appv2.constants'
import { switchMap } from 'rxjs/operators';
import { of,timer } from 'rxjs';


@Component({
  selector: 'appv2-footage-listing',
  templateUrl: './footage-listing.component.html',
  styleUrls: ['./footage-listing.component.scss'],
})
export class FootageListingComponent implements OnInit {
  /**
   * Open class of header component.
   */
  openClass: string = '';

  timeout: any = null;
  showReset = false;
  visibleTrendingSearch: boolean;
  showTrendSerach: boolean = false;
  mainPageType: string = 'footage';
  recentSearches = [];
  page_type: string;
  elasticSuggestions: any;
  trendingKeyword: any;
  searchtext: string = '';
  filter_active: string;
  wishlistData = [];
  showAddToWishListModal: boolean = false;
  isSectionActive = false;
  selectedOption = 'Popular';
  dropdownOpen = false;
  options = [

    'Popular',
    'Recent',
  ];
  shouldDisplayUl: boolean = false;

  userWishlistedData: string[] = [];
  user = '';
  fromCategory: Boolean = false;
  public displayExtendedLoader:boolean = false

  processedFilters: { key: string; value: string; hasMultipleValue: string }[] =
    [];

  @ViewChild(FilterComponent) childComponentReference: FilterComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private landingPage: LandingPageService,
    private store$: Store<any>,
    private mysearch: SearchService,
    private activatedRouter: ActivatedRoute,
    private toastService: ToastService,
    private wishlist: WishlistService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.route.queryParams.subscribe((params) => {
        this.searchtext = params['search'];
        this.store$.dispatch(
          new SearchchangeSearchName(<SearchState>{
            searchType: this.mainPageType,
          })
        );
      });
    });
    this.galleryData = new Array<any>();
    let search = this?.activatedRouter?.snapshot?.queryParamMap?.get('search');
    if (typeof search !== 'undefined' && search != '') {
      this.searchKey = search;
    }
  }

  ngOnInit(): void {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.footagePageLoading  })
    );
    this.user = localStorage.getItem('user_data');

    this.route.queryParams.subscribe((params) => {
      this.searchtext = this.searchtext ? this.searchtext : params['search'];
      this.store$.dispatch(
        new SearchchangeSearchName(<SearchState>{
          searchType: this.mainPageType,
        })
      );
    });
    this.starSearch();
    if (this.user) {
      this.userWishlistData();
    }
    this.recentSearches = JSON.parse(localStorage.getItem('recent_search'));
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  getFilterData(filterData: any) {
    const processedFilters: {
      key: string;
      value: string;
      namekey: string;
      valuekey: string;
      hasMultipleValue: string;
      isGroupValue: string;
    }[] = [];

    for (const key in filterData) {
      if (
        filterData.hasOwnProperty(key) &&
        key.endsWith('_name') &&
        Array.isArray(filterData[key])
      ) {
        const filterName = key.replace('_name', '');
        const filterValues = filterData[key].join(', ');

        const filterShowName = filterName + '_filtername';
        const filterOptionName = filterName + '_optionname';
        const hasMultipleValue = filterName + '_hasMultipleValues';
        const isGroupValue = filterName + '_isGroupValues';

        const nameValue = filterData[filterShowName][0];
        const optionValues = filterData[filterOptionName].join(', ');
        const hasMultipleValuedata = filterData[hasMultipleValue][0];
        let isGroupValuedata = '';
        if (filterData[isGroupValue][0] != null) {
          isGroupValuedata = filterData[isGroupValue].join(', ');
        }
        processedFilters.push({
          key: filterName,
          value: filterValues,
          namekey: nameValue,
          valuekey: optionValues,
          hasMultipleValue: hasMultipleValuedata,
          isGroupValue: isGroupValuedata,
        });
      }
    }
    this.processedFilters = processedFilters;

    const hasNonEmptyValue = this.processedFilters.some(filter => filter.value !== undefined && filter.value.trim() !== '');
    this.showReset = hasNonEmptyValue;

    this.processedFilters = this.processedFilters.filter(
      (filter) => filter.value !== ''
    );
    this.shouldDisplayUl = this.processedFilters.some(
      (filter) => filter.value !== ''
    );

    const navigationExtras: NavigationExtras = {
      queryParams: { search: this.searchtext },
    };

    if (this.searchtext == undefined || this.searchtext == '') {
      const navigationExtras: NavigationExtras = {
        queryParams: { search: '' },
      };
      this.router.navigate(['/footages'], navigationExtras);
      this.router.navigate(['/footages']);
    } else {
      this.router.navigate(['/footages']);
      this.router.navigate(['/footages'], navigationExtras);
    }
  }

  removeSpecificFilter(filter_type, index) {
    this.childComponentReference.removeFilters(filter_type, index);
    this.childComponentReference.checkboxChange.emit(false);
  }

  resetFilter() {
    this.childComponentReference.resetFilter();
  }

  toggleSectionActive() {
    this.isSectionActive = !this.isSectionActive;
  }

  showModal(wishlist) {
    this.wishlist.getWishlistData(wishlist).subscribe(
      (res) => {
        if (res['status'] == 'success') {
          // Reload user wishlists and show success message
          this.wishlistData['product_id'] = res.data.product_id;
          this.wishlistData['type'] = res.data.product_main_type;
          this.wishlistData['title'] = res.data.product_title;
          this.wishlistData['itemPoster'] = res.data.product_thumbnail;
          this.wishlistData['author_username'] = res.data.product_owner;
          this.showAddToWishListModal = true;
        } else {
          this.showAddToWishListModal = false;
          this.toastService.error(messages.categoryListProductValidation);
        }
      },
      (error) => {
        // Handle API request error
         console.log('error : ', error);
      }
    );
  }

  /**
   * Loading footage listing component
   * @param status
   */
  public loading(status: boolean) {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: status })
    );
    return;
  }

  /**
   * Store keyword in elastic search API
   * @param keyword
   */
  storeKeywordInElasticSearch(keyword) {
    const bodyParams = {
      search_field: keyword,
    };
    // this.loading(true);
    this.landingPage
      .storeKeywordInElasticSearch(bodyParams)
      .subscribe((data) => {
        this.loading(false);
      });
  }

  onMouseInSearchBox() {
    this.visibleTrendingSearch = true;
  }

  onMouseOutSearchBox() {
    this.visibleTrendingSearch = false;
    this.showTrendSerach = false;
  }

  /**
   * Determines whether key press on
   * @param event
   */
  onKeyPress(event: any) {
    clearTimeout(this.timeout);
    this.openClass = '';
    this.timeout = setTimeout(() => {
      this.searchtext = event.target.value;
      if (this.searchtext && this.searchtext.trim().length != 0) {
        this.getSuggestionByElasticSearch(this.searchtext);
        this.showTrendSerach = true;
        this.visibleTrendingSearch = false;
      } else {
        this.showTrendSerach = false;
        this.visibleTrendingSearch = true;
      }
    }, 1000);
    if (event.keyCode == 13) {
      this.showTrendSerach = false;
      this.storeKeywordInElasticSearch(this.searchtext);
      this.onEnterSearch();
    }
  }

  /**
   * Gets suggestion keyword list by elastic search
   * @param searchText
   */
  getSuggestionByElasticSearch(searchText) {
    this.searchtext = searchText;
    this.landingPage
      .getSuggestionByElasticSearch(this.searchtext)
      .subscribe((data) => {
        if (data) {
          this.elasticSuggestions = data.data;
        }
      });
  }

  public onInput(event: any) {
    const inputValue = event.target.value;
    // Define a regular expression to match letters, numbers, and spaces
    const regex = /^[a-zA-Z0-9\s]*$/;

    if (!regex.test(inputValue)) {
      // If the input contains characters other than letters, numbers, and spaces,
      // remove those characters from the input value
      event.target.value = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
    }
  }

  onEnterSearch() {
    // save to local storage
    let recentSearchData = JSON.parse(localStorage.getItem('recent_search'));
    this.recentSearches = recentSearchData;
    if (this.searchtext) {
      if (!recentSearchData) {
        recentSearchData = [];
      }
      if (recentSearchData.length > 4) {
        recentSearchData.shift();
      }
      if (this.searchtext) {
        recentSearchData.push(this.searchtext);
      }
      localStorage.setItem('recent_search', JSON.stringify(recentSearchData));
      if (this.page_type == 'footage' || this.mainPageType == 'footage') {
        const queryParams = { search: this.searchtext };

        const navigationExtras: NavigationExtras = {
          queryParams: queryParams,
        };
        this.router.navigate(['/footages'], navigationExtras);
        // this.router.navigate(['/images'], { state: navigationData });
      } else {
        this.openCategory(this.searchtext);
      }
    } else {
      const queryParams = { search: this.searchtext };
      const navigationExtras: NavigationExtras = {
        queryParams: queryParams,
      };
      this.router.navigate(['/footages']);
    }
  }
  clickOpenMenu() {
    this.visibleTrendingSearch = false;
    this.showTrendSerach = false;
    if (this.openClass === 'open') {
      this.openClass = '';
    } else {
      this.openClass = 'open';
    }
  }

  searchEditorial(page) {
    this.mainPageType = page;
    this.store$.dispatch(
      new SearchchangeSearchName(<SearchState>{ searchType: page })
    );
    //this.router.navigate(['/search'], { queryParams: { type: page, product_editorial: 1 } });
  }

  /**
   * Open category.
   * @param catName
   */
  openCategory(catName) {
    this.searchtext = catName;
    this.store$.dispatch(
      new SearchWriteAction(<SearchState>{ searchText: 'category ' + catName })
    );
  }

  public search$: Observable<string> = this.store$.pipe(
    select(selectSearchKey)
  ); //How to output store varible in html template

  pageName = '';

  config: Config = {
    diaporamaDuration: 3,
    layout: LayoutStyle.SIMPLE,
    prefMediaHeight: 250,
    spacing: 10,
    viewerEnabled: false,
    enableAutoPlay: false,
  };
  galleryData: Array<any>;
  itemData: Array<object>;
  totalRecords: Number;
  page: number = 1;
  currPage: number = 1;
  prevPage: number = 0;
  recordsPerPage: number;
  itemsPerPage = Number;

  // searchType:number = 0
  // prevSearchType:number = 0

  isRedirect: boolean;
  searchType: number = 1;
  prevSearchType: number = 1;

  prevSearchKey: string = '';
  searchKey: string = '';

  pageGallery: string;
  isEditorial: boolean;

  prevFilters;
  filters;

  selectSearchNameObserver: any;
  selectCurrentRouteObserver: any;
  selectSearchTextObserver: any;

  searchDebounceInterval: any = null;
  clearDebounceInterval() {
    clearTimeout(this.searchDebounceInterval);
  }

  currentlyPlayingIndex = -1;

  playVideo(videoElement) {
    videoElement.videoLoaded = true;

  }
  resetVideo(item){
    item.videoLoaded = false;
  }

  // Link to item
  openItem(item) {
    const objectValues = Object.values(this.itemData);

    const foundObject = objectValues.find(
      (value) => (value as any).product_main_image === item
    );

    this.router.navigate(['/footage/'+foundObject['slug']]);
  }
  scrollToGalleryStart() {
    window.scrollTo(0, 800);
  }

  // Subscribe to Store
  starSearch() {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.footagePageLoading })
    );
    this.store$.pipe(select(selectSearchType)).subscribe((vl) => {
      if (vl != undefined) {
        this.isRedirect = vl;

      }
    });
    this.selectSearchTextObserver = this.store$
      .pipe(select(selectSearchText))
      .subscribe((vl) => {
        this.clearDebounceInterval();
        this.searchDebounceInterval = setTimeout(() => {
          if (vl != undefined) {
            if (vl.startsWith('category')) {
              this.searchKey = vl.replace('category ', '');
              this.getGalleryContent({
                type: this.filters['type'],
                search: this.searchKey,
              });
            } else {
              this.searchKey = vl;
            }
          }
        }, 200);
      });
    this.store$.pipe(select(selectIsSearchEditorial)).subscribe((vl) => {
      if (this.isRedirect) {
        this.isEditorial = vl;
      }
    });
    this.selectCurrentRouteObserver = this.store$
      .pipe(select(selectCurrentRoute))
      .subscribe((vl) => {
        this.clearDebounceInterval();
        this.searchDebounceInterval = setTimeout(() => {
          if (vl != undefined) {
            this.store$.dispatch(
              new SearchWriteAction(<SearchState>{ searchText: ' ' })
            );
            let searchPage = {};
            this.pageName = vl.routeConfig.path;

            if (vl.routeConfig.path == 'account-activated/:email') {
              this.toastService.success(
                'Your account is activited successfully.'
              );
            }

            switch (vl.routeConfig.path) {
              case '':
                this.searchType = 1;
                searchPage['type'] = 'image';
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: true,
                  })
                );
                this.filters = searchPage;
                break;

              case 'image':
                this.searchType = 1;
                searchPage['type'] = 'image';
                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: false,
                  })
                );
                break;

              case 'footage':
                this.searchType = 2;
                searchPage['type'] = 'footage';
                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: false,
                  })
                );
                break;

              case 'editorial':
                searchPage['product_editorial'] = 1;
                this.searchType = 1;
                searchPage['type'] = 'image';
                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: true,
                  })
                );
                break;

              case 'search':
                this.filters = vl.queryParams;
                break;

              default:
                this.searchType = 1;
                searchPage['type'] = 'image';
                this.filters = searchPage;
                this.store$.dispatch(
                  new SearchChangeSearchType(<SearchState>{
                    isWithDropdown: false,
                  })
                );
            }
            this.getGalleryContent(this.filters);
          }
        }, 200);
      });

    this.selectSearchNameObserver = this.store$
      .pipe(select(selectSearchName))
      .subscribe((vl) => {
        this.clearDebounceInterval();
        this.searchDebounceInterval = setTimeout(() => {
          this.store$.dispatch(
            new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.footagePageLoading })
          );
            this.pageGallery = vl;
            if (this.pageName == '' || this.pageName == 'editorial') {
              this.filters = {
                type: this.pageGallery,
                product_editorial: +this.isEditorial,
              };
              this.getGalleryContent(this.filters);
            }

        }, 100);
      });
  }
  ngDoCheck() {
    if (this.galleryData.length > 0) {
      let v = document.querySelectorAll('.gallery video');
      for (let i = 0; i < v.length; i++) {
        v[i]['muted'] = true;
      }
      let footages = $('.gallery video');
      for (let i = 0; i < footages.length; i++) {
        $(footages[i]).mouseout((event) => {
          $(event.target)[0].load();
        });
        $(footages[i]).mouseover((event) => {
          $(event.target)[0].play();
        });
        footages[i].setAttribute(
          'poster',
          this.itemData[i]['product_thumbnail']
        );
      }
    }
  }
  fileExtension: string;
  // Get search result
  getGalleryContent(filters, currPage = 1) {
    this.itemData = undefined
    this.store$.dispatch(
            new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.footagePageLoading  })
    );

    this.filters['type'] = this.mainPageType;
    this.displayExtendedLoader = true

    let search = this?.activatedRouter?.snapshot?.queryParamMap?.get('search');
    if (typeof search !== 'undefined' && search != '') {
      this.searchKey = search;
    }
    filters['sort'] = this.selectedOption
    if (Object.keys(filters).length !== 0 && filters.constructor === Object) {

      const apiCall =this.mysearch
        .getSearchWithFiltersResult(
          filters,
          currPage,
          this.searchKey,
          this.processedFilters
        )

        setTimeout(() => {
          if(this.itemData == undefined && this.displayExtendedLoader){

            this.store$.dispatch(
              new loadingChangeAction(<LoadingState>{ loadingStatus: true, loadingMessage: messages.delayAPIMessage})
            );
          }
        }, 2000);

      apiCall.subscribe((data) => {
          if (data.imgfootage.length > 0) {
            this.galleryData = [];
            this.currPage = currPage
            this.displayExtendedLoader = false
            this.store$.dispatch(
              new loadingChangeAction(<LoadingState>{ loadingStatus: false })
            );

            this.itemData = data.imgfootage;
            this.itemData.forEach((v:any) => {v.videoLoaded = false;})
            this.recordsPerPage = this.itemsPerPage = data.perpage;
            this.totalRecords = data.total;
          } else{
            this.displayExtendedLoader = false
            this.store$.dispatch(
              new loadingChangeAction(<LoadingState>{ loadingStatus: false })
            );
            this.toastService.error('Please try again after sometimes')
            this.totalRecords = 0
          }

        });
    } else {
      this.mysearch.getSearchResult(1, currPage).subscribe((data) => {
        this.currPage = currPage
        this.galleryData = [];
        for (let i = 0; i < data.imgfootage.length; i++) {
          if (data.imgfootage[i]['product_main_type'] == 'Image') {
            this.galleryData.push(
              new Media(data.imgfootage[i]['product_thumbnail'], '')
            );
          } else {
            this.galleryData.push(
              new Media(data.imgfootage[i]['product_main_image'], '', 'video')
            );
          }
        }
        this.itemData = data.imgfootage;
        this.itemData.forEach((v:any) => {v.videoLoaded = false;})

        this.recordsPerPage = this.itemsPerPage = data.perpage;
        this.totalRecords = data.total;
        this.store$.dispatch(
          new loadingChangeAction(<LoadingState>{ loadingStatus: false })
        );
      });
    }
    if (this.itemData != undefined) {
    }
  }

  userWishlistData = () => {
    let postData = JSON.parse(this.user);
    this.wishlist.getUserWishlist(postData).subscribe(
      (res) => {
        this.userWishlistedData = res.data.map((item) =>
          String(item.product_thumbnail)
        );
      },
      (error) => {
         console.log('error : ', error);
      }
    );
  };

  toNextPage() {
    this.currPage++;
    this.getGalleryContent(this.filters, this.currPage);
  }
  toPrevPage() {
    this.currPage--;
    this.getGalleryContent(this.filters, this.currPage);
  }

  ngAfterViewInit() {
    let searchInput = document.querySelector('.search-container input');
    if (searchInput) {
      searchInput['value'] = this.searchKey;
    }
  }

  ngOnDestroy() {
    this.selectCurrentRouteObserver?.unsubscribe();
    this.selectSearchNameObserver?.unsubscribe();
    this.selectSearchTextObserver?.unsubscribe();
    this.clearDebounceInterval();
  }

  selectOption(option: string) {
    this.selectedOption = option;
    this.dropdownOpen = false;

    this.filters = {
      type: 'footage',
      product_editorial: 0,
      sort: this.selectedOption,
    };
    this.getGalleryContent(this.filters, this.currPage);
  }

  /**
   * Handles events triggered from the Add To Wishlist Modal.
   * If the event indicates the need to close the modal, the flag to show the modal is set to false.
   * @param {object} event - The event object containing information about the modal action.
   */
  handleWishlistModalEvent = (event) => {
    if (event['close_modal']) {
      // Set the flag to false, indicating that the Add To Wishlist Modal should be closed
      this.showAddToWishListModal = false;
      if(this.user){
        this.userWishlistData()
      }

    }
  };

  get totalPages(): number {
    return Math.ceil(Number(this.totalRecords) / Number(this.itemsPerPage));
  }

  onPageChange(newPage: number): void {
    this.currPage = Number(newPage);
    this.itemsPerPage = this.itemsPerPage;

    if (this.currPage < 1) {
      this.currPage = 1;
    }

    this.getGalleryContent(this.filters, this.currPage);
  }
}
