import { Injectable } from '@angular/core';
import { version } from 'process';

export const image = {
    standard:[{
        "version":"XL",
        "price":"12000",
        "is_premium":1
    },
    {
        "version":"XL",
        "price":"7500",
        "is_premium":0
    }
    ],
    extended:[{
        "version":"XL",
        "price":"17500",
        "is_premium":1
    },
    {
        "version":"XL",
        "price":"11000",
        "is_premium":0
    }]
};
export const footage = {
    commercial : [ {
        "version":"HD (1080)",
        "price":"14800",
        "is_premium":1
    },
    {
        "version":"4K",
        "price" :"22500",
        "is_premium":1
    },
    {
        "version":"HD (1080)",
        "price":"10000",
        "is_premium":0
    },
    {
        "version":"4K",
        "price":"14500",
        "is_premium":0
    }],
    full_rf:[{
        "version":"HD (1080)",
        "price":"20000",
        "is_premium":1
    },
    {
        "version":"4K",
        "price":"28600",
        "is_premium":1
    },
    {
        "version":"HD (1080)",
        "price":"13000",
        "is_premium":0
    },
    {
        "version":"4K",
        "price":"19500",
        "is_premium":0
    },
    ],
    non_commercial:[{
        "version":"HD (1080)",
        "price":"8700",
        "is_premium":1
    },
    {
        "version":"4K",
        "price":"13500",
        "is_premium":1
    },
    {
        "version":"HD (1080)",
        "price":"6500",
        "is_premium":0
    },
    {
        "version":"4K",
        "price":"9500",
        "is_premium":0
    }
    ],
    digital:[{
        "version":"HD (1080)",
        "price":"6200",
        "is_premium":1
    },
    {
        "version":"4K",
        "price":"9300",
        "is_premium":1
    },
    {
        "version":"HD (1080)",
        "price":"4870",
        "is_premium":0
    },
    {
        "version":"4K",
        "price":"7800",
        "is_premium":0
    }]
};

export const music = {
    extended : [ {
        "price":"10800",
        "is_premium":1
    },
    {
        "price" :"6900",
        "is_premium":0
    }],
    standard:[{
        "price":"4300",
        "is_premium":1
    },
    {
        "price":"2820",
        "is_premium":0
    }],
    digital:[{
        "price":"4300",
        "is_premium":1
    },
    {
        "price":"2820",
        "is_premium":0
    }]
};

export const messages = {
    logout: 'Logged out successfully..',
    acknowledgeEmailLoading: 'Processing, please wait...',
    forgotPasswordLoading: 'Processing forgot password request...',
    resetPasswordLoading: 'Resetting password, please wait...',
    signInLoading: 'Signing in, please wait...',
    signUpLoading: 'Signing up, please wait...',
    verifyEmailLoading: 'Verifying email, please wait...',
    categoryDataLoading: 'Loading category data, please wait...',
    securityLoading: 'Processing security measures, please wait...',
    myProfileLoading: 'Loading your profile, please wait...',
    pricingFilterLoading: 'Applying pricing filters, please wait...',
    cartListLoading: 'Fetching cart items, please wait...',
    userAddressLoading: 'Loading user addresses, please wait...',
    razorpayLoading: 'Processing payment, please wait...',
    wishlistInnerLoading: 'Loading wishlist items, please wait...',
    musicPageLoading: 'Loading music data, please wait...',
    musicDetailLoading: 'Loading music details, please wait...',
    imagePageLoading: 'Loading image data, please wait...',
    homepageLoading: 'Loading homepage content, please wait...',
    footagePageLoading: 'Loading footage data, please wait...',
    footagePageV1Loading: 'Loading footage data, please wait...',
    footageHomeLoading: 'Loading data, please wait...',
    contactLoading: 'Processing contact request, please wait...',
    categoryPageLoading: 'Loading category page, please wait...',
    cartAddLoading: 'Adding product to cart, please wait...',
    headerLoading: 'Loading, please wait...',
    cartPageLoading: 'Loading cart page, please wait...',
    delayAPIMessage: 'Extending results, please wait...',
    editorialPageLoading: 'Loading editorial page, please wait...',
    imageDetailLoading: 'Loading image details, please wait...',
    downloadAddLoading: 'Adding product for download, please wait...',
    redownloadLoading: 'Redownloading, please wait...',
    footageDetailLoading: 'Loading footage details, please wait...',
    verifyEmailValidation: 'Please enter valid input',
    resetPasswordFail : 'Filing to reset your password.',
    loginSuccess: 'Logged in successfully.',
    loginMustForWishlist: 'Please login first for wishlist.',
    tokenExpire: 'Your token is expired, Please login again',
    cartCompanyNameValidation: 'Please fill company name as you have mentioned GST',
    categoryListProductValidation: 'Product is not available',
    footagePremiumProduct: 'This is premium product, you have to purchase it',
    chooseSize: 'Please choose size',
    chooseDownloaPack: 'Please select download pack or choose another licence',
    downloadSoon: 'Your product will be downloaded soon!',
    musicDownloadSigninMust:  'Please sign in to download this music',
    signin: 'Please sign in',
    musicDataNotFound: 'Music data not found',
    wishlistMultiDownload: 'Please select atleast one Image type product.',
    orderPlaced: 'Your order has been successfully placed!',
    planPurchase: 'Plan has been purchased successfully!',
    orderSignin: 'To order any package, Please signin first!',
    productRedownload: 'Product redownloaded successfully !!',
    appName: 'Imagefootage'
};

export const GST = 12;
