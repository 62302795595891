<div class="sign_in_page">
  <!-- Sign in page image grid section start -->
  <div class="sign_in_page_image_grid">
    <div class="sign_in_page_grid">
      <div class="sign_in_page_grid_1">
        <div class="landscape_image_grid">
          <div>
            <picture><img src="assets/images/sign_in_img_1.webp" alt="sign-in-image" width="307" height="220" />
            </picture>
          </div>
          <div>
            <picture><img src="assets/images/sign_in_img_3.webp" alt="sign-in-image" width="307" height="220" />
            </picture>
          </div>
        </div>
        <div class="portrait_image_grid main_image">
          <picture><img src="assets/images/sign_in_img_2.webp" alt="sign-in-image" width="307" height="448" />
          </picture>
        </div>
        <div class="landscape_image_grid">
          <div>
            <picture><img src="assets/images/sign_in_img_4.webp" alt="sign-in-image" width="307" height="220" />
            </picture>
          </div>
          <div>
            <picture><img src="assets/images/Placehoder_Gray.jpg" alt="sign-in-image" width="307" height="220" />
            </picture>
          </div>
        </div>
      </div>
      <div class="sign_in_page_grid_2">
        <div class="landscape_image_grid">
          <div class="landscape_image">
            <picture><img src="assets/images/sign_in_img_7.webp" alt="sign-in-image" width="464" height="278" />
            </picture>
          </div>
          <div class="landscape_image">
            <picture><img src="assets/images/sign_in_img_8.webp" alt="sign-in-image" width="464" height="278" />
            </picture>
          </div>
        </div>
      </div>
    </div>
    <div class="sign_in_form_content">
      <div class="grid_footer_logo">
        <a routerLink="/"><img src="assets/images/Logo.png" alt="logo-image" width="216" height="39" /></a>
      </div>
      <h2 class="h2">Download 10k+ Photos, Videos and editors choice</h2>
      <p>
        Access all the images, videos, music, and tools you need to turn ideas
        into achievements. All images
      </p>
    </div>
  </div>
  <!-- Sign in page image grid section end -->
  <!-- Sign in page form section start -->
  <div class="sign_in_page_form">
    <div class="sign_in_form">
      <div class="sign_in_form_heading">
        <h2 class="h2">Let’s Login to your {{appName}} Account</h2>
        <p>
          Kindly Login with your account credentials. or
          <a routerLink="/signup">Create An Account</a>
        </p>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form_group" [ngClass]="{ error_field: submitted && f.email.errors }">
          <label for="email">Email/Mobile no</label>
          <input type="text" placeholder="Enter your email or mobile no" formControlName="email" />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <p *ngIf="f.email.errors.required" class="error">
              * Please enter valid email address or mobile number
            </p>
            <p *ngIf="f.email.errors.pattern" class="error">
              * Please enter valid email address or mobile number
            </p>
          </div>
        </div>
        <div class="form_group form_group_lastchild" [ngClass]="{ error_field: submitted && f.password.errors }">
          <label for="password">Password</label>
          <div class="eye_icon">
            <input [type]="passwordShow ? 'text' : 'password'" placeholder="Enter your password"
              formControlName="password" />
            <a (click)="hideShowPassword()"><img [src]="
                  passwordShow
                    ? 'assets/images/eyeopen.svg'
                    : 'assets/images/eyeclose.svg'
                " class="{{ passwordShow ? 'eyeopen' : 'eyeclose' }}" alt="{{ passwordShow ? 'eyeopen' : 'eyeclose' }}"
                width="{{ passwordShow ? '21' : '21' }}" height="{{ passwordShow ? '21' : '11' }}" /></a>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <p *ngIf="f.password.errors.required" class="error">
                * Please enter valid password
              </p>
            </div>
          </div>
        </div>
        <div class="checkbox_area">
          <div class="checkbox">
            <input type="checkbox" id="check" aria-label="checkbox" formControlName="remember_me" />
            <label for="text">Remember Me</label>
          </div>
          <div><a routerLink="/forgpass">Forgot Password</a></div>
        </div>
        <button class="sign_in_btn">Sign In</button>
      </form>
      <p class="or" *ngIf="adminSettings && (adminSettings.google_signin_enabled || adminSettings.facebook_signin_enabled)"><span>OR</span></p>
      <div class="form_badges_btn">
        <div class="badges_btn" *ngIf="adminSettings && adminSettings.google_signin_enabled">
          <a #loginRef>
            <img src="assets/images/google.svg" alt="google" width="24" height="24" />
            Continue With Google
          </a>
        </div>
        <div class="badges_btn" *ngIf="adminSettings && adminSettings.facebook_signin_enabled">
          <a (click)="fbLogin()">
            <img src="assets/images/fb-sign-in.svg" alt="facebook" width="24" height="24" />
            Continue With Facebook
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Sign in page form section end -->
</div>
