<section class="banner-sec" *ngIf="promotion">
  <!-- style="background: url(assets/images/home-banner.jpg);" -->
  <img
    class="banner-img dec-img"
    src="assets/images/footage-page-bg.jpg"
    alt="Images"
    width="1920"
    height="524"
  />
  <img
    class="banner-img mob-img"
    src="assets/images/footage-page-bg.jpg"
    alt="Images"
    width="575"
    height="380"
  />
  <div class="container">
    <div class="banner-content">
      <h1 class="h1">{{ promotion?.event_name }}</h1>
      <p>{{ promotion?.event_des }}</p>
      <div class="categories-seach-block-banner">
        <button class="btn-search" id="mobile-search">
          <img
            id="search-btn-head"
            src="assets/images/search-icon.svg"
            alt="search icon"
            width="14"
            height="14"
          />
        </button>
        <div class="search-filter-form-main-banner">
          <form class="search-filter-form">
            <div class="custom-select-wrapper">
              <div class="custom-select">
                <div class="custom-select__trigger">
                  <!-- <span>All Categories</span> -->
                </div>
                <div class="custom-options">
                  <!-- <span class="custom-option selected" data-value="all-catego">
                    All Categories</span
                  > -->
                  <span class="custom-option" data-value="images"
                    ><img
                      src="assets/images/img-icon.svg"
                      alt="Images"
                      width="16"
                      height="16"
                    />
                    Images</span
                  >
                  <span class="custom-option" data-value="footages"
                    ><img
                      src="assets/images/footages-icon.svg"
                      alt="Footages"
                      width="16"
                      height="16"
                    />
                    Footages</span
                  >
                  <span class="custom-option" data-value="editorial"
                    ><img
                      src="assets/images/editorial-icon.svg"
                      alt="Editorial"
                      width="16"
                      height="16"
                    />
                    Editorial</span
                  >
                </div>
              </div>
            </div>
            <div class="search-input-block" id="search-input-wrapper">
              <button class="btn-search">
                <img
                  src="assets/images/search-icon.svg"
                  alt="search icon"
                  width="13"
                  height="13"
                />
              </button>
              <input
                class="input-search"
                type="text"
                name="searchtext"
                placeholder="Search for images, footages etc..."
                autocomplete="off" 
              />
            </div>
            <!-- <div class="search-popup-block">
                            <img src="assets/images/search-popup-icon.svg" alt="search icon" width="21" height="21" />
                        </div> -->
            <div id="trending-search-wrapper">
              <div id="main-search-area">
                <div class="search-category-list">
                  <span class="title-heading">Recent Searches</span>
                  <ul class="category-list">
                    <li>
                      <a href="#"><span>Gradient</span></a>
                    </li>
                    <li>
                      <a href="#"><span>Beauty</span></a>
                    </li>
                    <li>
                      <a href="#"><span>Abstract Wallpaper</span></a>
                    </li>
                    <li>
                      <a href="#"><span>UI Gradient</span></a>
                    </li>
                    <li>
                      <a href="#"><span>Doctor</span></a>
                    </li>
                  </ul>
                </div>
                <div class="search-category-list">
                  <span class="title-heading">Recent Searches</span>
                  <ul class="category-list">
                    <li>
                      <a href="#"
                        ><img
                          src="assets/images/trending-icon.svg"
                          alt="trending icon"
                          width="16"
                          height="16"
                        /><span>Web Development</span></a
                      >
                    </li>
                    <li>
                      <a href="#"
                        ><img
                          src="assets/images/trending-icon.svg"
                          alt="trending icon"
                          width="16"
                          height="16"
                        /><span>iphone</span></a
                      >
                    </li>
                    <li>
                      <a href="#"
                        ><img
                          src="assets/images/trending-icon.svg"
                          alt="trending icon"
                          width="16"
                          height="16"
                        /><span>new year images</span></a
                      >
                    </li>
                    <li>
                      <a href="#"
                        ><img
                          src="assets/images/trending-icon.svg"
                          alt="trending icon"
                          width="16"
                          height="16"
                        /><span>accounting</span></a
                      >
                    </li>
                    <li>
                      <a href="#"
                        ><img
                          src="assets/images/trending-icon.svg"
                          alt="trending icon"
                          width="16"
                          height="16"
                        /><span>flying bird</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ul class="category-serch-list">
              <li><a href="#">Gradient</a></li>
              <li><a href="#">Beauty</a></li>
              <li><a href="#">Abstract Wallpaper</a></li>
              <li><a href="#">UI Gradient</a></li>
              <li><a href="#">Doctor</a></li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Information Line section start -->
<section class="information_line bg-color" *ngIf="discountDetails">
  <div class="container" *ngIf="discountDetails?.status == 1">
    <div class="information_line_inner">
      <p>{{ discountDetails?.title }}</p>
      <a target="_blank" href="{{ discountDetails?.link }}">{{
        discountDetails?.button_text
      }}</a>
    </div>
  </div>
</section>
<!-- Information Line section end -->

<!-- Popular Categories section start -->
<section class="popular_categories">
  <div class="container">
    <h2 class="h2">Popular Categories</h2>
    <div class="popular_categories_image">
      <div class="popular_categories_image_section">
        <div class="catagory_image">
          <div class="catagory_image_picture">
            <a href="javascript:void(0)" class="catagory_image_inner">
              <picture>
                <source
                  srcset="assets/images/popular_category.webp"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <source
                  srcset="assets/images/popular_category.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <img
                  src="assets/images/popular_category.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
              </picture>
              <span class="catagory_image_picture_name">Business</span>
            </a>
          </div>
        </div>
        <div class="catagory_image">
          <div class="catagory_image_picture">
            <a href="javascript:void(0)" class="catagory_image_inner">
              <picture>
                <source
                  srcset="assets/images/popular_category_1.webp"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <source
                  srcset="assets/images/popular_category_1.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <img
                  src="assets/images/popular_category_1.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
              </picture>
              <span class="catagory_image_picture_name">Corporate</span>
            </a>
          </div>
        </div>
        <div class="catagory_image">
          <div class="catagory_image_picture">
            <a href="javascript:void(0)" class="catagory_image_inner">
              <picture>
                <source
                  srcset="assets/images/popular_category_2.webp"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <source
                  srcset="assets/images/popular_category_2.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <img
                  src="assets/images/popular_category_2.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
              </picture>
              <span class="catagory_image_picture_name">Money</span>
            </a>
          </div>
        </div>
        <div class="catagory_image">
          <div class="catagory_image_picture">
            <a href="javascript:void(0)" class="catagory_image_inner">
              <picture>
                <source
                  srcset="assets/images/popular_category_3.webp"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <source
                  srcset="assets/images/popular_category_3.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <img
                  src="assets/images/popular_category_3.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
              </picture>
              <span class="catagory_image_picture_name">Nature</span>
            </a>
          </div>
        </div>
        <div class="catagory_image">
          <div class="catagory_image_picture">
            <a href="javascript:void(0)" class="catagory_image_inner">
              <picture>
                <source
                  srcset="assets/images/popular_category_4.webp"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <source
                  srcset="assets/images/popular_category_4.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <img
                  src="assets/images/popular_category_4.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
              </picture>
              <span class="catagory_image_picture_name">Yoga</span>
            </a>
          </div>
        </div>
        <div class="catagory_image">
          <div class="catagory_image_picture">
            <a href="javascript:void(0)" class="catagory_image_inner">
              <picture>
                <source
                  srcset="assets/images/popular_category_5.webp"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <source
                  srcset="assets/images/popular_category_5.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <img
                  src="assets/images/popular_category_5.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
              </picture>
              <span class="catagory_image_picture_name">Couple</span>
            </a>
          </div>
        </div>

        <div class="catagory_image">
          <div class="catagory_image_picture">
            <a href="javascript:void(0)" class="catagory_image_inner">
              <picture>
                <source
                  srcset="assets/images/popular_category_6.webp"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <source
                  srcset="assets/images/popular_category_6.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <img
                  src="assets/images/popular_category_6.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
              </picture>
              <span class="catagory_image_picture_name">Rain</span>
            </a>
          </div>
        </div>
        <div class="catagory_image">
          <div class="catagory_image_picture">
            <a href="javascript:void(0)" class="catagory_image_inner">
              <picture>
                <source
                  srcset="assets/images/popular_category_7.webp"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <source
                  srcset="assets/images/popular_category_7.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <img
                  src="assets/images/popular_category_7.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
              </picture>
              <span class="catagory_image_picture_name">Animated</span>
            </a>
          </div>
        </div>
        <div class="catagory_image">
          <div class="catagory_image_picture">
            <a href="javascript:void(0)" class="catagory_image_inner">
              <picture>
                <source
                  srcset="assets/images/popular_category_8.webp"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <source
                  srcset="assets/images/popular_category_8.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <img
                  src="assets/images/popular_category_8.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
              </picture>
              <span class="catagory_image_picture_name">Backgrounds</span>
            </a>
          </div>
        </div>
        <div class="catagory_image">
          <div class="catagory_image_picture">
            <a href="javascript:void(0)" class="catagory_image_inner">
              <picture>
                <source
                  srcset="assets/images/popular_category_9.webp"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <source
                  srcset="assets/images/popular_category_9.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <img
                  src="assets/images/popular_category_9.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
              </picture>
              <span class="catagory_image_picture_name">Cooking</span>
            </a>
          </div>
        </div>
        <div class="catagory_image">
          <div class="catagory_image_picture">
            <a href="javascript:void(0)" class="catagory_image_inner">
              <picture>
                <source
                  srcset="assets/images/popular_category_10.webp"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <source
                  srcset="assets/images/popular_category_10.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <img
                  src="assets/images/popular_category_10.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
              </picture>
              <span class="catagory_image_picture_name">Car</span>
            </a>
          </div>
        </div>
        <div class="catagory_image">
          <div class="catagory_image_picture">
            <a href="javascript:void(0)" class="catagory_image_inner">
              <picture>
                <source
                  srcset="assets/images/popular_category_11.webp"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <source
                  srcset="assets/images/popular_category_11.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
                <img
                  src="assets/images/popular_category_11.jpg"
                  alt="popular-category-image"
                  width="284"
                  height="157"
                />
              </picture>
              <span class="catagory_image_picture_name">Green Screen</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Popular Categories section end -->

<!-- Turn ideas into stories with high-quality videos section start -->
<section class="turn_ideas_into_story">
  <div class="container">
    <div class="turn_ideas_into_story_heading">
      <h2 class="h2">Turn ideas into stories with high-quality videos</h2>
      <p>
        Whether you’re a marketer, content creator or, designer, Freepik’s video
        collection will meet your needs
      </p>
    </div>
    <div class="turn_ideas_into_story_inner">
      <div class="turn_ideas_into_story_inner_content">
        <div class="story_icon">
          <a href="javascript:void(0)"
            ><img
              src="assets/images/download_icon.svg"
              alt="download-icon"
              width="72"
              height="72"
          /></a>
        </div>
        <div class="story_icon_heading">
          <h3 class="h3">Ready-to-download</h3>
          <p>
            Save time and effort with videos made to be used on the spot. No
            need for additional editing.
          </p>
        </div>
      </div>
      <div class="turn_ideas_into_story_inner_content">
        <div class="story_icon">
          <a href="javascript:void(0)"
            ><img
              src="assets/images/youtube_icon.svg"
              alt="youtube-icon"
              width="72"
              height="72"
          /></a>
        </div>
        <div class="story_icon_heading">
          <h3 class="h3">Diverse and versatile</h3>
          <p>
            Access thousands of high-quality, free and Premium videos available
            in a wide range of categories to fit any project.
          </p>
        </div>
      </div>
      <div class="turn_ideas_into_story_inner_content">
        <div class="story_icon">
          <a href="javascript:void(0)"
            ><img
              src="assets/images/shield.svg"
              alt="shield-icon"
              width="72"
              height="72"
          /></a>
        </div>
        <div class="story_icon_heading">
          <h3 class="h3">Suitable for commercial use</h3>
          <p>
            Create stories with worry-free licensing. All videos can be used for
            both personal and commercial purposes.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Turn ideas into stories with high-quality videos section end -->

<!-- Find the perfect video clip section start -->
<section class="find_the_perfect_video_clip_section">
  <div class="container">
    <div class="find_the_perfect_video_clip_inner">
      <div class="find_the_perfect_video_clip_section_content">
        <h2 class="h2">Find the perfect video clip</h2>
        <p>
          Discover the best royalty free stock images, photos, vectors, footage,
          videos and music files for your designs and projects by talented
          artists and contributors worldwide right here. Everything you need is
          just a search away.
        </p>
      </div>
      <div class="find_the_perfect_video_clip_section_img_1">
        <picture>
          <source
            srcset="assets/images/videoclip_1.webp"
            alt="videoclip-image"
            width="410"
            height="370"
          />
          <source
            srcset="assets/images/videoclip_1.png"
            alt="videoclip-image"
            width="410"
            height="370"
          />
          <img
            src="assets/images/videoclip_1.png"
            alt="videoclip-image"
            width="410"
            height="370"
          />
        </picture>
      </div>
    </div>
  </div>
  <div class="find_the_perfect_video_clip_section_img_2">
    <picture>
      <source
        srcset="assets/images/videoclip_2.webp"
        alt="videoclip-image"
        width="109"
        height="92"
      />
      <source
        srcset="assets/images/videoclip_2.png"
        alt="videoclip-image"
        width="109"
        height="92"
      />
      <img
        src="assets/images/videoclip_2.png"
        alt="videoclip-image"
        width="109"
        height="92"
      />
    </picture>
  </div>
</section>
<!-- Find the perfect video clip section end -->

<!-- Professionally curated section start -->
<section class="professionally_curated">
  <div class="container">
    <h2 class="h2">Professionally curated</h2>
    <div class="professionally_curated_box">
      <div class="professionally_curated_image_box">
        <div class="professionally_curated_image">
          <div class="professionally_image">
            <picture>
              <source
                srcset="assets/images/professionaly_curated.webp"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
              <source
                srcset="assets/images/professionaly_curated.jpg"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
              <img
                src="assets/images/professionaly_curated.jpg"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
            </picture>
          </div>
          <div class="professionally_curated_image_name">
            <a href="javascript:void(0)">Neon Loop</a>
          </div>
        </div>
      </div>
      <div class="professionally_curated_image_box">
        <div class="professionally_curated_image">
          <div class="professionally_image">
            <picture>
              <source
                srcset="assets/images/professionaly_curated_1.webp"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
              <source
                srcset="assets/images/professionaly_curated_1.jpg"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
              <img
                src="assets/images/professionaly_curated_1.jpg"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
            </picture>
          </div>
          <div class="professionally_curated_image_name">
            <a href="javascript:void(0)">Morning</a>
          </div>
        </div>
      </div>
      <div class="professionally_curated_image_box">
        <div class="professionally_curated_image">
          <div class="professionally_image">
            <picture>
              <source
                srcset="assets/images/professionaly_curated_2.webp"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
              <source
                srcset="assets/images/professionaly_curated_2.jpg"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
              <img
                src="assets/images/professionaly_curated_2.jpg"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
            </picture>
          </div>
          <div class="professionally_curated_image_name">
            <a href="javascript:void(0)">Black is Beautiful</a>
          </div>
        </div>
      </div>
      <div class="professionally_curated_image_box">
        <div class="professionally_curated_image">
          <div class="professionally_image">
            <picture>
              <source
                srcset="assets/images/professionaly_curated_3.webp"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
              <source
                srcset="assets/images/professionaly_curated_3.jpg"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
              <img
                src="assets/images/professionaly_curated_3.jpg"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
            </picture>
          </div>
          <div class="professionally_curated_image_name">
            <a href="javascript:void(0)">For All</a>
          </div>
        </div>
      </div>
      <div class="professionally_curated_image_box">
        <div class="professionally_curated_image">
          <div class="professionally_image">
            <picture>
              <source
                srcset="assets/images/professionaly_curated_4.webp"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
              <source
                srcset="assets/images/professionaly_curated_4.jpg"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
              <img
                src="assets/images/professionaly_curated_4.jpg"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
            </picture>
          </div>
          <div class="professionally_curated_image_name">
            <a href="javascript:void(0)">Background</a>
          </div>
        </div>
      </div>
      <div class="professionally_curated_image_box">
        <div class="professionally_curated_image">
          <div class="professionally_image">
            <picture>
              <source
                srcset="assets/images/professionaly_curated_5.webp"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
              <source
                srcset="assets/images/professionaly_curated_5.jpg"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
              <img
                src="assets/images/professionaly_curated_5.jpg"
                alt="professionaly-curated"
                width="592"
                height="283"
              />
            </picture>
          </div>
          <div class="professionally_curated_image_name">
            <a href="javascript:void(0)">On Fire</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Professionally curated section end -->

<!-- Information section start -->
<section class="information_section">
  <div class="container">
    <div class="information_section_inner">
      <div class="information_section_content">
        <div class="information_content">
          <h3 class="h3">Broadly compatible</h3>
          <p>
            When you’re making videos for social media, you need to move fast.
            Save time by choosing from music tracks already formatted for a
            perfect match, with a variety of styles and moods in :15 and :30
            second options.
          </p>
        </div>
        <div class="information_content">
          <h3 class="h3">4K and HD resolution</h3>
          <p>
            When you’re making videos for social media, you need to move fast.
            Save time by choosing from music tracks already formatted for a
            perfect match, with a variety of styles and moods in :15 and :30
            second options.
          </p>
        </div>
        <div class="information_content">
          <h3 class="h3">Preview before downloading</h3>
          <p>
            When you’re making videos for social media, you need to move fast.
            Save time by choosing from music tracks already formatted for a
            perfect match, with a variety of styles and moods in :15 and :30
            second options.
          </p>
        </div>
        <div class="information_btn">
          <a href="javascript:void(0)">Subscribe Today</a>
        </div>
      </div>
      <div class="information_section_image">
        <picture>
          <source
            srcset="assets/images/story.webp"
            alt="popular-category-image"
            width="781"
            height="760"
          />
          <source
            srcset="assets/images/story.png"
            alt="popular-category-image"
            width="781"
            height="760"
          />
          <img
            src="assets/images/story.png"
            alt="popular-category-image"
            width="781"
            height="760"
          />
        </picture>
      </div>
    </div>
  </div>
</section>
<!-- Information section end -->

<!-- Subscribe a plan that suits well section start -->
<section class="subscribe-section">
  <div class="container">
    <h2 class="h2">Subscribe a plan that suits well</h2>
    <div class="subscribe-main">
      <div class="subscribe-col">
        <div>
          <div class="subscribe-col-top subscribe_border">
            <div class="subscribe-plan-left">
              <h3 class="h3">Download Pack</h3>
              <span>HD Footages</span>
            </div>
            <div class="subscribe-plan-right col-right-tp">
              <a href="#">Download Within a Year</a>
            </div>
          </div>
          <div class="select_licence_type">
            <h4 class="h4">Select Licence Type</h4>
            <form action="">
              <ul class="licence_type_list">
                <li>
                  <div class="licence_plan">
                    <input
                      type="radio"
                      name="radio-group"
                      aria-label="custom_plan_choose"
                      id="plan5"
                    />
                    <label for="plan5">Full RF Licence</label>
                  </div>
                </li>
                <li>
                  <div class="licence_plan">
                    <input
                      type="radio"
                      name="radio-group"
                      aria-label="custom_plan_choose"
                      id="plan6"
                    />
                    <label for="plan6">Commercial</label>
                  </div>
                </li>
                <li>
                  <div class="licence_plan">
                    <input
                      type="radio"
                      name="radio-group"
                      aria-label="custom_plan_choose"
                      id="plan7"
                    />
                    <label for="plan7">Media Non-commercial (Editorial)</label>
                  </div>
                </li>
                <li>
                  <div class="licence_plan">
                    <input
                      type="radio"
                      name="radio-group"
                      aria-label="custom_plan_choose"
                      id="plan8"
                    />
                    <label for="plan8">Digital</label>
                  </div>
                </li>
              </ul>
            </form>
          </div>
          <form action="#">
            <ul class="subscribe-list">
              <li>
                <div class="plan-select">
                  <input
                    type="radio"
                    id="checkbtn1"
                    name="radio-group"
                    aria-label="custom_plan_choose"
                  />
                  <label for="checkbtn1">5 Footages/year</label>
                </div>
                <span class="plan-price">₹3,550.00</span>
              </li>
              <li>
                <div class="plan-select">
                  <input
                    type="radio"
                    id="checkbtn2"
                    name="radio-group"
                    aria-label="custom_plan_choose"
                  />
                  <label for="checkbtn2">25 Footages/year</label>
                </div>
                <span class="plan-price">₹16,500.00</span>
              </li>
              <li>
                <div class="plan-select">
                  <input
                    type="radio"
                    id="checkbtn3"
                    name="radio-group"
                    aria-label="custom_plan_choose"
                  />
                  <label for="checkbtn3">50 Footages/year</label>
                </div>
                <span class="plan-price">₹31,500.00</span>
              </li>
              <li>
                <div class="plan-select">
                  <input
                    type="radio"
                    id="checkbtn4"
                    name="radio-group"
                    aria-label="custom_plan_choose"
                  />
                  <label for="checkbtn4">99 Footages/year</label>
                </div>
                <span class="plan-price">₹56,800.00</span>
              </li>
            </ul>
          </form>
        </div>
        <a class="btn-thame" href="#">Order Now</a>
      </div>
      <div class="subscribe-col">
        <div>
          <div class="subscribe-col-top subscribe_border">
            <div class="subscribe-plan-left">
              <h3 class="h3">Download Pack</h3>
              <span>4K Footages</span>
            </div>
            <div class="subscribe-plan-right col-right-tp">
              <a href="#">Download Within a Year</a>
            </div>
          </div>
          <div class="select_licence_type">
            <h4 class="h4">Select Licence Type</h4>
            <form action="">
              <ul class="licence_type_list">
                <li>
                  <div class="licence_plan">
                    <input
                      type="radio"
                      name="radio-group"
                      aria-label="custom_plan_choose"
                      id="plan1"
                    />
                    <label for="plan1">Full RF Licence</label>
                  </div>
                </li>
                <li>
                  <div class="licence_plan">
                    <input
                      type="radio"
                      name="radio-group"
                      aria-label="custom_plan_choose"
                      id="plan2"
                    />
                    <label for="plan2">Commercial</label>
                  </div>
                </li>
                <li>
                  <div class="licence_plan">
                    <input
                      type="radio"
                      name="radio-group"
                      aria-label="custom_plan_choose"
                      id="plan3"
                    />
                    <label for="plan3">Media Non-commercial (Editorial)</label>
                  </div>
                </li>
                <li>
                  <div class="licence_plan">
                    <input
                      type="radio"
                      name="radio-group"
                      aria-label="custom_plan_choose"
                      id="plan4"
                    />
                    <label for="plan4">Digital</label>
                  </div>
                </li>
              </ul>
            </form>
          </div>
          <form action="#">
            <ul class="subscribe-list">
              <li>
                <div class="plan-select">
                  <input
                    type="radio"
                    id="checkbtn5"
                    name="radio-group"
                    aria-label="custom_plan_choose"
                  />
                  <label for="checkbtn5">5 Footages/year</label>
                </div>
                <span class="plan-price">₹3,550.00</span>
              </li>
              <li>
                <div class="plan-select">
                  <input
                    type="radio"
                    id="checkbtn6"
                    name="radio-group"
                    aria-label="custom_plan_choose"
                  />
                  <label for="checkbtn6">25 Footages/year</label>
                </div>
                <span class="plan-price">₹16,500.00</span>
              </li>
              <li>
                <div class="plan-select">
                  <input
                    type="radio"
                    id="checkbtn7"
                    name="radio-group"
                    aria-label="custom_plan_choose"
                  />
                  <label for="checkbtn7">50 Footages/year</label>
                </div>
                <span class="plan-price">₹31,500.00</span>
              </li>
              <li>
                <div class="plan-select">
                  <input
                    type="radio"
                    id="checkbtn8"
                    name="radio-group"
                    aria-label="custom_plan_choose"
                  />
                  <label for="checkbtn8">99 Footages/year</label>
                </div>
                <span class="plan-price">₹56,800.00</span>
              </li>
            </ul>
          </form>
        </div>
        <a class="btn-thame" href="#">Order Now</a>
      </div>
    </div>
    <div class="request_here">
      <p>
        Can't find your Licence type or the pack you are looking for? Kindly
        submit your
        <a href="javascript:void(0)">Request Here</a>
      </p>
    </div>
  </div>
</section>
<!-- Subscribe a plan that suits well section end -->

<!-- Still have questions section start -->
<appv2-questions></appv2-questions>
