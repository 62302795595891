import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'appv2-image-filter',
  templateUrl: './image-filter.component.html',
  styleUrls: ['./image-filter.component.scss']
})
export class ImageFilterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
