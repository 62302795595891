<div *ngIf="loadingStatus" class="loader-bg">
    <div class="loader2"></div>
    <div *ngIf="loadingMessage" class="loader-msg">{{loadingMessage}}</div>
</div>
<div class="wrapper">
    <router-outlet></router-outlet>
</div>



