<!-- popup start -->
<div class="preconfirm_popup">
    <div class="preconfirm_popup_inner">
        <div class="wishlist_image_section">
            <div class="wishlist_image_heading">
                <h3 class="h4">{{ popupMessage }}</h3>
                <ul>
                    <li><button class="thame-btn cancel-btn" (click)="onCancel()">{{ cancelButtonText }}</button></li>
                    <li><a class="thame-btn" (click)="onConfirm()">{{ confirmButtonText }}</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- popup end -->