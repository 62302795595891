<div class="pricing_subscription_tabs">
    <div class="tabs">
        <div class="tab_bar">
            <ul class="tabs-nav" *ngIf="singleTab == ''">
                <li [ngClass]="{'tab-active': openTab === 'Image'}"><a (click)="clicked('Image')">Image</a>
                </li>
                <li [ngClass]="{'tab-active': openTab == 'Footage'}"><a
                       (click)="clicked('Footage')">Footage</a>
                </li>
                <li [ngClass]="{'tab-active': openTab == 'Music'}"><a (click)="clicked('Music')">Music</a>
                </li>
            </ul>
        </div>
        <div class="tabs-stage">
            <ng-container *ngIf="(openTab == 'Image') || (singleTab == 'Image')">
                <div id="tab-1">
                    <div class="subscribe-mian"
                         *ngIf="filteredPlansImage?.plans?.length || filteredPlansImageDownload?.plans?.length;">
                        <div class="subscribe-col" *ngIf="filteredPlansImage">
                            <form [formGroup]="ImageSubscriptionPlanForm"
                                  (ngSubmit)="onImageSubscriptionPlanFormSubmit()">
                                <div>
                                    <div class="subscribe-col-top">
                                        <div class="subscribe-plan-left">
                                            <h3 class="h3">Subscription Plan</h3>
                                            <span>Royalty Free Images</span>
                                        </div>
                                        <div class="subscribe-plan-right">
                                            <div class="toggle-inner">
                                                <input id="ce-toggle" type="checkbox"
                                                       aria-label="custom-toggle"
                                                       (click)="filterYearly($event, filteredPlansImage.key)"
                                                       formControlName="toggle">
                                                <span class="custom-toggle"></span>
                                                <label class="t-month" for="ce-toggle">Yearly</label>
                                                <label class="t-year" for="ce-toggle">Monthly</label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="select_licence_type">
                                        <h4 class="h4">Select Licence Type</h4>
                                        <ul class="licence_type_list">

                                            <li *ngFor="let licence of licenceData;let i =index">

                                                <div class="licence_plan" *ngIf="licence.product_type == 1">
                                                    <input type="radio" name="radio-group" [checked] = "licence.id == 1"
                                                           aria-label="custom_plan_choose" id="plan1"
                                                           (click)="imageFilterFootageTier(licence.id)">
                                                    <label for="plan1">{{licence.licence_name}}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <ul class="subscribe-list" *ngIf="imageData.length >0">
                                        <li *ngFor="let plan of imageData" [ngClass]="{ 'active': plan.package_id === activeSubscriptionId }">
                                            <div class="plan-select">
                                            <input [formControlName]="'imageSubscriptionPlan'"
                                                    class="custom-checkbox-input" type="radio"
                                                    value="{{plan['plans_stringify']}}"
                                                    name="imageSubscriptionPlan"
                                                    id="{{plan['package_id']}}"
                                                    (click)="selcetPricing(plan['package_id'])">
                                            <label for="{{plan['package_id']}}"
                                                    (click)="imagePlanSelect($event, plan['package_id'])">{{plan['package_name']}}</label>
                                            </div>
                                            <span class="plan-price">{{plan['package_price'] | currency:'INR':true}}</span>
                                        </li>
                                    </ul>
                                    <br>
                                    <ul *ngIf="imageData.length == 0" class="h4"> Please select another option</ul>
                                </div>
                                <p class="error"
                                   *ngIf="onImgSubscription && ImageSubscriptionPlanForm.get('imageSubscriptionPlan').hasError('required')">
                                    Please select an option.
                                </p>
                                <button *ngIf="isUserLogined && imageData.length > 0" type="submit" class="btn-thame">Order
                                    Now</button>
                                <button *ngIf="!isUserLogined" (click)="routeToSignIn()" class="btn-thame">Order
                                    Now</button>
                            </form>
                        </div>
                        <div class="subscribe-col" *ngIf="filteredPlansImageDownload">
                            <form [formGroup]="ImageDownloadPlanForm"
                                  (ngSubmit)="onImageDownloadPlanFormSubmit()">
                                <div>
                                    <div class="subscribe-col-top">
                                        <div class="subscribe-plan-left">
                                            <h3 class="h3">Download Pack</h3>
                                            <span>Royalty Free Images</span>
                                        </div>
                                        <div class="subscribe-plan-right col-right-tp">
                                            <a href="javascript:void(0)">Download Within a Year</a>
                                        </div>
                                    </div>
                                    <div class="select_licence_type">
                                        <h4 class="h4">Select Licence Type</h4>
                                        <ul class="licence_type_list">

                                            <li *ngFor="let licence of licenceData">

                                                <div class="licence_plan" *ngIf="licence.product_type == 1">
                                                    <input type="radio" name="radio-group" [checked] = "licence.id == 1"
                                                           aria-label="custom_plan_choose" id="plan1"
                                                           (click)="imageFilterDownloadTier(licence.id)">
                                                    <label for="plan1">{{licence.licence_name}}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- <form action="#"> -->
                                    <ul class="subscribe-list" *ngIf="imageDataDownload.length > 0">
                                        <li *ngFor="let plan of imageDataDownload" [ngClass]="{ 'active': plan.package_id === activeDownloadId }">
                                            <div class="plan-select">
                                                <!-- <input type="radio" formControlName="imageDownloadPlan"
                                                       [value]="plan['package_id']"
                                                       id="{{filteredPlansImageDownload.key}}{{plan['package_id']}}"
                                                       [checked]="selectedImageOption == plan['package_id']"> -->
                                                <input type="radio" formControlName="imageDownloadPlan"
                                                       [value]="plan['plans_stringify']"
                                                       id="{{plan['package_id']}}"
                                                       (click)="selcetDownloadPricing(plan['package_id'])">
                                                <label for="{{plan['package_id']}}"
                                                       (click)="imagePlanSelect($event, plan['package_id'])">{{plan['package_name']}}</label>
                                            </div>
                                            <span class="plan-price">{{plan['package_price'] |
                                                currency:'INR':true}}</span>
                                        </li>
                                    </ul>
                                    <br>
                                    <ul *ngIf="imageDataDownload.length == 0" class="h4"> Please select another option</ul>

                                    <!-- </form> -->
                                </div>
                                <!-- <a class="btn-thame" (click)="choosePaymentMethod('rozerpay')">Order Now</a> -->
                                <p class="error"
                                   *ngIf="onImgDownload && ImageDownloadPlanForm.get('imageDownloadPlan').hasError('required')">
                                    Please select an option.
                                </p>
                                <button *ngIf="isUserLogined && imageDataDownload.length > 0" type="submit" class="btn-thame">Order
                                    Now</button>
                                <button *ngIf="!isUserLogined" (click)="routeToSignIn()" class="btn-thame">Order
                                        Now</button>
                            </form>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="(openTab == 'Footage') || (singleTab == 'Footage')">
                <div id="tab-2">
                    <div class="subscribe-main" *ngIf="filteredPlansFootages?.plans?.length || filteredHdPlansFootages?.plans?.length;">
                        <div class="subscribe-col">
                            <form [formGroup]="FootageDownloadFirstForm"
                                  (ngSubmit)="onFootageDownloadFirstFormSubmit()">
                                <div>
                                    <div class="subscribe-col-top subscribe_border">
                                        <div class="subscribe-plan-left">
                                            <h3 class="h3">Download Pack</h3>
                                            <span>{{filteredPlansFootages?.name ? filteredPlansFootages?.name : '4K Footages'}}</span>
                                        </div>
                                        <div class="subscribe-plan-right col-right-tp">
                                            <a href="javascript:void(0)">Download Within a Year</a>
                                        </div>
                                    </div>
                                    <div class="select_licence_type">
                                        <h4 class="h4">Select Licence Type</h4>
                                        <ul class="licence_type_list">
                                            <li *ngFor="let licence of licenceData">

                                                <div class="licence_plan" *ngIf="licence.product_type == 2">
                                                    <input type="radio" name="radio-group" [checked] = "licence.id == 3"
                                                           aria-label="custom_plan_choose" id="plan9"
                                                           (click)="filterFootageTier(filterFootageTier?.key, licence.id)">
                                                    <label for="plan9">{{licence.licence_name}}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <ul class="subscribe-list" *ngIf="footageData.length > 0">
                                        <li *ngFor="let plan of footageData" [ngClass]="{ 'active': plan.package_id === activeFootage4kId }">
                                            <div class="plan-select">
                                                <input [formControlName]="'footageDownloadFirst'"
                                                       class="custom-checkbox-input" type="radio"
                                                       value="{{plan['plans_stringify']}}"
                                                       name="footageDownloadFirst"
                                                       id="{{plan['package_id']}}"
                                                       (click)="selcetFootagePricing(plan['package_id'])"
                                                       >

                                                <label for="{{plan['package_id']}}"
                                                       (click)="footagePlanSelect($event, plan['package_id'])">{{plan['package_name']}}</label>
                                            </div>
                                            <span class="plan-price">{{plan['package_price'] |
                                                currency:'INR':true}}</span>
                                        </li>
                                    </ul>
                                    <br>
                                    <ul *ngIf="footageData.length == 0" class="h4"> Please select another option</ul>

                                </div>
                                <p class="error"
                                   *ngIf="onFootageDownloadFirst && FootageDownloadFirstForm.get('footageDownloadFirst').hasError('required')">
                                    Please select an option.
                                </p>
                                <button *ngIf="isUserLogined && footageData.length > 0" type="submit" class="btn-thame">Order
                                    Now</button>
                                <button *ngIf="!isUserLogined" (click)="routeToSignIn()" class="btn-thame">Order
                                        Now</button>
                            </form>
                        </div>
                        <div class="subscribe-col">
                            <form [formGroup]="FootageDownloadSecondForm"
                                  (ngSubmit)="onFootageDownloadSecondFormSubmit()">
                                <div>
                                    <div class="subscribe-col-top subscribe_border">
                                        <div class="subscribe-plan-left">
                                            <h3 class="h3">Download Pack</h3>
                                            <span>{{filteredHdPlansFootages?.name ? filteredHdPlansFootages?.name : 'HD(1080) Footages'}}</span>
                                        </div>
                                        <div class="subscribe-plan-right col-right-tp">
                                            <a href="javascript:void(0)">Download Within a Year</a>
                                        </div>
                                    </div>
                                    <div class="select_licence_type">
                                        <h4 class="h4">Select Licence Type</h4>
                                        <ul class="licence_type_list">
                                            <li *ngFor="let licence of licenceData">

                                                <div class="licence_plan" *ngIf="licence.product_type == 2">
                                                    <input type="radio" name="radio-group" [checked] = "licence.id == 3"
                                                           aria-label="custom_plan_choose" id="plan9"
                                                           (click)="hdFilterFootageTier(hdFilterFootageTier?.key, licence.id)">
                                                    <label for="plan9">{{licence.licence_name}}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <ul class="subscribe-list" *ngIf="hdFootageData.length > 0">
                                        <li *ngFor="let plan of hdFootageData" [ngClass]="{ 'active': plan.package_id === activeFootageHdId }">
                                            <div class="plan-select">
                                                <input [formControlName]="'footageDownloadSecond'"
                                                       class="custom-checkbox-input" type="radio"
                                                       value="{{plan['plans_stringify']}}"
                                                       name="footageDownloadSecond"
                                                       id="{{plan['package_id']}}"
                                                       (click)="selcetFootageHdPricing(plan['package_id'])">
                                                <label for="{{plan['package_id']}}"
                                                       (click)="footagePlanSelect($event, plan['package_id'])">{{plan['package_name']}}</label>
                                            </div>
                                            <span class="plan-price">{{plan['package_price'] |
                                                currency:'INR':true}}</span>
                                        </li>
                                    </ul>
                                    <br>
                                    <ul *ngIf="hdFootageData.length == 0" class="h4"> Please select another option</ul>
                                </div>
                                <p class="error"
                                   *ngIf="onFootageDownloadSecond && FootageDownloadSecondForm.get('footageDownloadSecond').hasError('required')">
                                    Please select an option.
                                </p>
                                <button *ngIf="isUserLogined && hdFootageData.length > 0" type="submit" class="btn-thame">Order
                                    Now</button>
                                <button *ngIf="!isUserLogined" (click)="routeToSignIn()" class="btn-thame">Order
                                    Now</button>
                            </form>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="(openTab == 'Music') || (singleTab == 'Music')">
                <div id="tab-3">
                    <div class="subscribe-mian"
                         *ngIf="filteredPlansMusic?.plans?.length || filteredPlansMusicDownload?.plans?.length;">
                        <!-- <div class="subscribe-col" *ngIf="filteredPlansMusic">
                            <form [formGroup]="MusicSubscriptionPlanForm"
                                  (ngSubmit)="onMusicSubscriptionPlanFormSubmit()">
                                <div>
                                    <div class="subscribe-col-top">
                                        <div class="subscribe-plan-left">
                                            <h3 class="h3">Subscription Plan</h3>
                                            <span>Music Tracks</span>
                                        </div>
                                        <div class="subscribe-plan-right">
                                            <div class="toggle-inner">
                                                <input id="ce-toggle" type="checkbox"
                                                       aria-label="custom-toggle"
                                                       (click)="filterYearlyMusic($event, filteredPlansMusic.key)">
                                                <span class="custom-toggle"></span>
                                                <label class="t-month" for="ce-toggle">Yearly</label>
                                                <label class="t-year" for="ce-toggle">Monthly</label>
                                            </div>
                                        </div>
                                    </div>
                                    <ul class="subscribe-list">
                                        <li *ngFor="let plan of musicData" [ngClass]="{ 'active': plan.package_id === activeMusicSubscriptionId }">
                                            <div class="plan-select">
                                                <input type="radio" formControlName="musicSubscriptionPlan"
                                                       [value]="plan['plans_stringify']"
                                                       id="{{plan['package_id']}}"
                                                       (click)="selcetMusicSubPricing(plan['package_id'])">
                                                <label for="{{plan['package_id']}}"
                                                       (click)="musicPlanSelect($event, plan['package_id'])">{{plan['package_name']}}</label>
                                            </div>
                                            <span class="plan-price">{{plan['package_price'] |
                                                currency:'INR':true}}</span>
                                        </li>
                                    </ul>
                                </div>
                                <p class="error"
                                   *ngIf="onMusicSubscriptionPlan && MusicSubscriptionPlanForm.get('musicSubscriptionPlan').hasError('required')">
                                    Please select an option.
                                </p>
                                <button *ngIf="isUserLogined" type="submit" class="btn-thame">Order
                                    Now</button>
                            </form>
                        </div> -->
                        <div class="subscribe-col" *ngIf="filteredPlansMusicDownload">
                            <form [formGroup]="MusicDownloadPlanForm"
                                  (ngSubmit)="onMusicDownloadPlanFormSubmit()">
                                <div>
                                    <div class="subscribe-col-top subscribe_border">
                                        <div class="subscribe-plan-left">
                                            <h3 class="h3">Download Pack</h3>
                                            <span>Music Tracks</span>
                                        </div>
                                        <div class="subscribe-plan-right col-right-tp">
                                            <a href="javascript:void(0)">Download Within a Year</a>
                                        </div>
                                    </div>

                                    <div class="select_licence_type">
                                        <h4 class="h4">Select Licence Type</h4>
                                        <ul class="licence_type_list">

                                            <li *ngFor="let licence of licenceData">

                                                <div class="licence_plan" *ngIf="licence.product_type == 3">
                                                    <input type="radio" name="radio-group" [checked] = "licence.id == 7"
                                                           aria-label="custom_plan_choose" id="plan1"
                                                           (click)="filterLicenceTypeMusic(licence.id)">
                                                    <label for="plan1">{{licence.licence_name}}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>


                                    <ul class="subscribe-list" *ngIf="musicDataDownload.length > 0">
                                        <li *ngFor="let plan of musicDataDownload" [ngClass]="{ 'active': plan.package_id === activeMusicDownloadId }">
                                            <div class="plan-select">
                                                <input type="radio" formControlName="musicDownloadPlan"
                                                       [value]="plan['plans_stringify']"
                                                       id="{{plan['package_id']}}"
                                                       (click)="selcetMusicDownPricing(plan['package_id'])">
                                                <label for="{{plan['package_id']}}"
                                                       (click)="musicPlanSelect($event, plan['package_id'])">{{plan['package_name']}}</label>
                                            </div>
                                            <span class="plan-price">{{plan['package_price'] |
                                                currency:'INR':true}}</span>
                                        </li>
                                    </ul>
                                    <br>
                                    <ul *ngIf="musicDataDownload.length == 0" class="h4"> Please select another option</ul>
                                </div>
                                <p class="error"
                                   *ngIf="onMusicDownloadPlan && MusicDownloadPlanForm.get('musicDownloadPlan').hasError('required')">
                                    Please select an option.
                                </p>
                                <br>
                                <button *ngIf="isUserLogined && musicDataDownload.length > 0" type="submit" class="btn-thame">Order
                                    Now</button>
                                <button *ngIf="!isUserLogined" (click)="routeToSignIn()" class="btn-thame">Order
                                    Now</button>
                            </form>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
