import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WishlistService } from 'src/appv2/services/wishlist.service';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { ToastService } from 'src/appv2/services/toast.service';

@Component({
  selector: 'appv2-added-wishlist-own-account',
  templateUrl: './added-wishlist-own-account.component.html',
  styleUrls: ['./added-wishlist-own-account.component.scss']
})
export class AddedWishlistOwnAccountComponent implements OnInit {

  user:any = "";
  wishlistId:any = "";

  constructor(
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private wishlist: WishlistService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.wishlistId = this.activatedRouter.snapshot.paramMap.get('wishlist-id');
    this.user = localStorage.getItem('user_data')
    $(".download_popup").toggle();
    $(".overlay").toggle();
    $("body").addClass("overflow_hidden");
  }

  onCancel() {
    $(".download_popup").toggle();
    $(".overlay").toggle();
    $("body").removeClass("overflow_hidden");
  }

  addedUserAccount = () => {
    let postData = JSON.parse(this.user);
    postData['wishlist_id'] = this.wishlistId;
    this.wishlist.acceptWishlistCollection(postData).subscribe(
      (res) => {
        if (res['error']) {
          this.toastService.warning(res['message']);
          setTimeout(() => {
            this.router.navigate(['/mywishlist']);
          }, 3000);
        } else {
          this.toastService.success(res['message']);
          setTimeout(() => {
            this.router.navigate(['/mywishlist']);
          }, 3000);
        }
      },
      (error) => {
        this.toastService.warning('Oops, Something went wrong. Please try after some time!');
        setTimeout(() => {
          this.router.navigate(['/mywishlist']);
        }, 3000);
      }
    );
  };
}
