import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { User } from 'src/appv2/models/user';
import { AccountService } from 'src/appv2/services/account.service';
import { ItemDetailsService } from 'src/appv2/services/item-details.service';
import { ElementRef, HostListener, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';


@Component({
  selector: 'appv2-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.scss']
})
export class PurchaseHistoryComponent implements OnInit {

  purchaseHistory: Array<any> = [];
  user: User;
  range: String = 'all';
  mediaType: String = 'All';
  licenceType: String = 'All';
  isActive: boolean;
  currentRow: String = '';
  expanded: {[key: string]: boolean} = {};
  totalRecords: Number
  page: number = 1
  currPage: number = 1
  prevPage: number = 1
  recordsPerPage: number
  IsDatePicker: Boolean = false;
  start: Date = new Date();
  end: Date = new Date();
  startDate: String = null;
  endDate: String = null;
  selectedOption: String = 'All';
  selectedOptionLic: String = "Select Type"
  selectedOptionMedia: String = "All";
  dropdownOpen = false;
  dropdownOpenMedia = false;
  dropdownOpenLic = false;
  options = ['All','Today', 'Last Week', 'Last Month', 'Custom Range'];
  media_options = ['All', 'Image', 'Footage', 'Music'];
  licence_options = [];
  media_type_all = true;
  mediaTypeCommonValue = '';
  itemsPerPage = Number;


  constructor(private store$: Store<any>, private accountActions: AccountService, private router: Router, private datePipe: DatePipe,private http: HttpClient,private itemDetails: ItemDetailsService, private formBuilder: FormBuilder, private renderer: Renderer2, private el: ElementRef
) {
    this.user = this.accountActions.userValue;
  }

  ngOnInit(): void {
    this.getPurchaseOrderHistory();
  }

  onRangeChange(value: any) {
    this.start = new Date();
    this.end = new Date();
    this.selectedOption = this.range = value;
    this.dropdownOpen = false;
    if (this.range === 'Today') {
      this.range = 'today';
      this.IsDatePicker = false;
      this.getPurchaseOrderHistory();
    } else if (this.range === 'Last Week') {
      this.range = 'last_week';
      this.IsDatePicker = false;
      this.getPurchaseOrderHistory();
    } else if (this.range === 'Last Month') {
      this.range = 'last_month';
      this.IsDatePicker = false;
      this.getPurchaseOrderHistory();
    } else if (this.range === 'Custom Range') {
      this.range = 'custom';
      this.IsDatePicker = true;
    } else {
      this.range = 'all';
      this.IsDatePicker = false;
      this.getPurchaseOrderHistory();
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.closeDropdown();
    }
  }

  closeDropdown(): void {
    this.dropdownOpen = false;
    this.dropdownOpenMedia = false;
    this.dropdownOpenLic = false;
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  toggleDropdownMedia() {
    this.dropdownOpenMedia = !this.dropdownOpenMedia;

  }

  toggleDropdownLic() {
    this.dropdownOpenLic = !this.dropdownOpenLic;
  }

  onMediaChange(value: any) {
    this.licence_options = [];
    this.mediaType = value;
    this.dropdownOpenMedia = false;
    this.dropdownOpenLic = false;

    if (this.mediaType == 'Image') {
      this.selectedOptionMedia = "Image"
      this.mediaTypeCommonValue = '1'
      this.itemDetails.getLicenceDetails(this.mediaTypeCommonValue).subscribe((data) => {
        for (const license of data.data) {
          this.licence_options.push({
            id:license.id,
            slug: license.slug,
            name: license.licence_name
          });
        }
      this.selectedOptionLic = "Select Type";
      this.media_type_all = false;
    });
    } else if (this.mediaType == 'Footage') {
      this.selectedOptionMedia = "Footage"
      this.mediaTypeCommonValue = '2'
      this.itemDetails.getLicenceDetails(this.mediaTypeCommonValue).subscribe((data) => {
        for (const license of data.data) {
          this.licence_options.push({
            id:license.id,
            slug: license.slug,
            name: license.licence_name
          });
        }
      this.selectedOptionLic = "Select Type";
      this.media_type_all = false;
    });
    } else if (this.mediaType == 'Music') {
      this.selectedOptionMedia = "Music"
      this.mediaTypeCommonValue = '3'
      this.itemDetails.getLicenceDetails(this.mediaTypeCommonValue).subscribe((data) => {
        for (const license of data.data) {
          this.licence_options.push({
            id:license.id,
            slug: license.slug,
            name: license.licence_name
          });
        }
      this.selectedOptionLic = "Select Type";
      this.media_type_all = false;
    });
    } else {
      this.selectedOptionMedia = "All";
      this.itemDetails.getLicenceDetails().subscribe((data) => {
        for (const license of data.data) {
          this.licence_options.push({
            id:license.id,
            slug: license.slug,
            name: license.licence_name
          });
        }
      });
      this.selectedOptionLic = "Select Type";
      this.media_type_all = true;
      this.licenceType = 'All'
    }
    this.getPurchaseOrderHistory();
  }

  onLicenceChange(value, name) {
    this.selectedOptionLic = name;
    this.licenceType = value;
    this.dropdownOpenLic = false;
    this.getPurchaseOrderHistory();
  }

  getPurchaseOrderHistory()
  {
    let payload = {};
    payload['user_id'] = this.user.userdata.Utype; // 22;
    payload['media_type'] = this.mediaType;
    payload['license_type'] = this.licenceType;
    payload['range'] = this.range;
    payload['start_date'] = this.startDate; //'2020-01-01';
    payload['end_date'] = this.endDate; //'2023-01-01';

    this.accountActions.getPurchaseHistory(payload, this.currPage).subscribe((data) => {
      this.purchaseHistory = data['data']
      this.itemsPerPage = data['data']['per_page']
      this.totalRecords = data['data']['total']
    });
  }

  downloadFile(url, name) {
    const filename = name;

    this.http.get(url, { responseType: 'blob' }).subscribe((blob: Blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = filename;
      a.click();

      window.URL.revokeObjectURL(blobUrl);
    });
  }

  toggleRow(rowId: any): void {
    this.currentRow = rowId;
    this.isActive = !this.isActive;
  }

  toNextPage() {
    this.currPage++
    this.getPurchaseOrderHistory()
  }
  toPrevPage() {
    this.currPage--
    this.getPurchaseOrderHistory()
  }

  getDater(data) {
    let rangeStartDate = new Date(data.startDate);
    let rangeEndDate = new Date(data.endDate);
    this.startDate = this.datePipe.transform(rangeStartDate,"yyyy-MM-dd");
    this.endDate = this.datePipe.transform(rangeEndDate,"yyyy-MM-dd");
    this.getPurchaseOrderHistory();
  }
  onPageChange(newPage: number): void {
    this.currPage = Number(newPage);
    this.itemsPerPage = this.itemsPerPage;

    if (this.currPage < 1) {
      this.currPage = 1;
    } else if (this.currPage > this.totalPages) {
      this.currPage = this.totalPages;
    }

    this.getPurchaseOrderHistory();
  }
  get totalPages(): number {
    return Math.ceil(Number(this.totalRecords) / Number(this.itemsPerPage));
  }

}
