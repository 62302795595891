import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { SuccessResetPasswordComponent } from './success-reset-password/success-reset-password.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { SignInComponent } from './sign-in/sign-in.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AcknowledgeVerifyEmailComponent } from './acknowledge-verify-email/acknowledge-verify-email.component';
import { TermsAndConditionsComponent } from './../pages/terms-and-conditions/terms-and-conditions.component';


const routes: Routes = [
  { path: 'resetpassword/:token', component: ResetPasswordComponent },
  { path: 'signin', component: SignInComponent },
  { path: 'signup', component: SignUpComponent },
  { path: 'forgpass', component: ForgetPasswordComponent },
  { path: 'resetpasswordsuccess', component: SuccessResetPasswordComponent },
  { path: 'verify-email', component: VerifyEmailComponent },
  { path: 'account-verification/:token', component: AcknowledgeVerifyEmailComponent },
  { path: 'signup/terms-and-conditions', component: TermsAndConditionsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
