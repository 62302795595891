import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Appv2Module } from './appv2/appv2.module';
import { environment } from './environments/environment';

if (environment.production) {
  // Enable production mode
  enableProdMode();

  // Dynamically inject the Google Tag Manager script
  const gtagScript = document.createElement('script');
  gtagScript.async = true;
  gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleTagManagerId}`;
  document.head.appendChild(gtagScript);

  const inlineScript = document.createElement('script');
  inlineScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${environment.googleTagManagerId}');
  `;
  document.head.appendChild(inlineScript);
}

platformBrowserDynamic().bootstrapModule(Appv2Module)
  .catch(err => console.error(err));
