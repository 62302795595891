import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import $ from 'jquery';
import { User } from 'src/appv2/models/user';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';
import { ToastService } from 'src/appv2/services/toast.service';
import { ElementRef, HostListener, Renderer2 } from '@angular/core';
import { messages } from 'src/appv2/appv2.constants';


const emailRegex = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
const mobileRegex = /^(\d{10})$/;

@Component({
  selector: 'appv2-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  user: User;

  success: string = ''
  message: any = { general: "" }
  ProfileForm: FormGroup;
  data: Array<any>;
  show: boolean = false;
  token: string | undefined;
  loginUserId: number = 0;
  countries_list: [] = [];
  states_list: [] = [];
  cities_list: [] = [];
  isEmail: boolean = true;
  profileSaveBtn: boolean = false
  displayId: string = ''
  displayName: string = ''
  displayUserName: string = ''
  displayEmailMobile: string = ''
  submittedProfileForm: Boolean = false;
  isCountryDisabled: Boolean = true;
  isStateDisabled: Boolean = true;
  isCityDisabled: Boolean = true;
  defaultCountry: string = ''
  defaultState: string = ''
  defaultCity: string = ''
  isFormReadOnly: Boolean = true;
  selectedCountry:string = ''
  selectedState:string = ''
  selectedCity:string = ''
  public displayCountry:Boolean = false
  public displayState :Boolean = false
  public displayCity: Boolean =false
  public profileData: any = []
  dropdownOpen = false;
  dropdownOpenCountry = false;
  dropdownOpenState = false;

  constructor(private store$: Store<any>, private accountActions: AccountService, private router: Router, private toastService: ToastService,private formBuilder: FormBuilder, private renderer: Renderer2, private el: ElementRef,private route: ActivatedRoute) {
    this.data = new Array<any>()
    this.token = undefined;
    this.user = this.accountActions.userValue;
    this.loginUserId = this.user?.userdata.Utype;
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      // Check if 'returnUrl' parameter exists
      if (params['returnUrl']) {
        // Do something with the returnUrl value
       this.toastService.warning('Please comeplete your profile')
        // You can perform further actions based on the returnUrl
        // For example, redirect to the returnUrl or update some component state
      }
    });
    this.initProfileForm()
    this.fetchProfile()


    $(".user_form input, .user_form select, .user_form .user_input").css('cursor', 'not-allowed');
  }

  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.myProfileLoading}))
    return
  }

  toggleProfileSaveBtn(btn = ""): void {
    this.profileSaveBtn = !this.profileSaveBtn
    if(btn == "edit") {
      this.isFormReadOnly = false;
      this.displayCity = true
      this.isCityDisabled = false
      if (this.ProfileForm.get('userData.country').value.length > 0) {
        this.isCountryDisabled = true;
        this.isStateDisabled = true;
        this.getCities(this.profileData.state.id,true)
        this.ProfileForm.patchValue({
          userData: {
            city: this.profileData.city.id,
          }
        })
      } else {

        this.getCountryData()
        this.isCountryDisabled = false;
        this.isStateDisabled = false;
        this.isCityDisabled = false;
      }

    } else {
      this.isFormReadOnly = true;
      if (this.ProfileForm.get('userData.country').value.length > 0) {
        this.isCountryDisabled = true;
        this.isStateDisabled = true;
        this.isCityDisabled = true;
      } else {
        this.isCountryDisabled = true;
        this.isStateDisabled = true;
        this.isCityDisabled = true;
      }
      this.isStateDisabled = true;
      this.isCityDisabled = true;
      this.displayCity = false;
    }
    if(this.profileSaveBtn) {
      $(".user_form input, .user_form .user_input").css('cursor', 'text');
      $(".user_form select").css('cursor', 'default');
    } else {
      $(".user_form input, .user_form select, .user_form .user_input").css('cursor', 'not-allowed');
    }
  }

  initProfileForm(): void {
    this.ProfileForm = new FormGroup({
      'userData': new FormGroup({
        'first_name': new FormControl('', [Validators.required]),
        'email': new FormControl('', Validators.compose([
          Validators.required,
          Validators.pattern(emailRegex),
        ])),
        'mobile': new FormControl('', Validators.compose([
          Validators.required,
          Validators.pattern(mobileRegex),
        ])),
        'company': new FormControl('', []),
        'country': new FormControl('', [Validators.required]),
        'state': new FormControl('', [Validators.required]),
        'city': new FormControl('', [Validators.required]),
        'address': new FormControl('', [Validators.required]),
        'address2': new FormControl('', []),
        'pincode': new FormControl('', [Validators.required, Validators.minLength(6)]),
        'gst':new FormControl('', [])
      })

    });
    this.ProfileForm.get('userData.company').valueChanges.subscribe((companyValue) => {
      const gstControl = this.ProfileForm.get('userData.gst');

      // Clear existing validators
      gstControl.clearValidators();

      if (companyValue) {
        // If 'company' is filled, add the 'required' validator to 'country'
        gstControl.setValidators([Validators.required]);
      }

      // Update the validators and trigger a revalidation
      gstControl.updateValueAndValidity();
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.closeDropdown();
    }
  }

  closeDropdown(): void {
    this.dropdownOpen = false;
    this.dropdownOpenState = false;
    this.dropdownOpenCountry = false;
  }

  fetchProfile(): void {
    this.accountActions.getUserInformation(this.loginUserId).subscribe((res) => {
      if (res.status) {
        this.profileData = res.data
        if (!res.data.email) {
          this.isEmail = false
          this.displayEmailMobile = res.data.mobile
        } else {
          this.displayEmailMobile = res.data.email
        }
        this.displayId = res.data.id
        this.displayName = res.data.first_name
        this.displayUserName = res.data.user_name

        this.ProfileForm.patchValue({
          userData: {
            first_name: res.data.first_name,
            email: res.data.email,
            mobile: res.data.mobile,
            company: res.data.company,
            country: res.data.country ? res.data.country.name : this.defaultCountry,
            state: res.data.state ? res.data.state.id : this.defaultState,
            city: res.data.city ? res.data.city?.id : this.defaultCity,
            address: res.data.address,
            address2: res.data.address2,
            pincode: res.data.postal_code,
            gst:res.data.gst
          }
        })
        if (res.data.country && res.data.country !== null) {
          this.displayCountry = false
          this.displayState = false
          this.displayCity = false
          this.ProfileForm.get('userData.country').setValue(res.data.country.name)
          this.ProfileForm.get('userData.state').setValue(res.data.state.state)
          this.ProfileForm.get('userData.city').setValue(res.data.city.name)
          if (this.ProfileForm.get('userData.city')) {
            this.selectedCity = this.ProfileForm.get('userData.city').value;
          }
        }else if(res.data.country == null && this.profileSaveBtn == false){
          this.displayCountry = true
          this.displayCity = true
          this.displayState = true
        }else{
          this.displayCountry = true
          this.displayCity = true
          this.displayState = true
        }
      }
    });
  }

  getStates(country_id: number): void {
    this.cities_list = []
    this.ProfileForm.patchValue({
      userData: {
        city: '',
        state:''
      }
    })
    this.accountActions.getStates(country_id).subscribe((res) => {
      if (res.status) {
        this.states_list = res.data
      }
    });
  }

  getCities(state_id: number,forEdit:Boolean = false): void {
    if(!forEdit){
      this.ProfileForm.patchValue({
        userData: {
          city: '',
        }
      })
    }

    this.accountActions.getCities(state_id).subscribe((res) => {
      if (res.status) {
        this.cities_list = res.data
      }
    });
  }

  public onSubmit() {
    this.loading(true);
    this.submittedProfileForm = true;
    this.updateProfile(this.ProfileForm.value['userData']);
  }

  selectCity(city: any) {
        this.selectedCity = city.name;
        this.ProfileForm.get('userData.city').setValue(city.id);
        this.toggleDropdown();
  }

  selectCountry(country: any) {
        this.selectedCountry = country.name;
        this.ProfileForm.get('userData.country').setValue(country.id);
        this.toggleDropdownCountry();
    }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }
  toggleDropdownCountry() {
    this.dropdownOpenCountry = !this.dropdownOpenCountry;
  }
  toggleDropdownState() {
    this.dropdownOpenState = !this.dropdownOpenState;
  }

  selectState(state) {
    this.selectedState = state.state;
    this.ProfileForm.get('userData.state').setValue(state.id);
    this.toggleDropdownState();
    this.getCities(state.id);
  }


  updateProfile(userData): void {


    if (userData.gst && !userData.company) {
      this.loading(false)
      this.toastService.error(messages.cartCompanyNameValidation)
      return
    }

    if (this.ProfileForm.invalid) {
      for (const control of Object.keys(this.ProfileForm.controls)) {
        this.ProfileForm.controls[control].markAsTouched();
      }
      this.loading(false);
      return;
    }
    if(!this.displayCountry){

      userData.country= this.profileData.country.id,
      userData.state= this.profileData.state.id

    }

    if (this.ProfileForm.valid) {
      this.accountActions.setUserInformation(userData, { Utype: this.loginUserId }).subscribe((data) => {
        this.loading(false);
        if (data.status) {
          this.profileData = data.data
          localStorage.setItem('user_data',JSON.stringify(data.user_data))
          this.isFormReadOnly = true;
          this.ProfileForm.get('userData.country').setValue(data.data.country.name)
          this.ProfileForm.get('userData.state').setValue(data.data.state.state)
          this.ProfileForm.get('userData.city').setValue(data.data.city.name)
          this.profileSaveBtn = false
          this.displayCountry = false
          this.displayState = false
          this.displayCity = false
          this.toastService.success(data['message'])
          $(".user_form input, .user_form select, .user_form .user_input").css('cursor', 'not-allowed');
        } else {
          this.toastService.warning(data['message'])
        }

        Object.keys(data).forEach((item) => {
          this.message[item] = Array.isArray(data[item]) ? data[item][0] : data[item];
        });
      });

    }
  }

  getCountryData(){
    this.accountActions.getCountries().subscribe((res) => {
      if (res.status) {
        this.countries_list = res.data
      }
    });
  }
}
