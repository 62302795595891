<!-- my wishlist section start -->
<section class="my-wishlist-sec">
  <div class="container">
    <div class="cart-title-row">
      <div class="cart-col-left">
        <h2 class="h1">My Wishlist</h2>
      </div>
      <div class="cart-col-right">
        <a href="javascript:void(0)" (click)="addWishlist()" class="thame-btn"
          >Add Wishlist Collection</a
        >
      </div>
    </div>
    <div class="wishlist-main-row">
      <ng-container *ngFor="let collection of wishlistData">
        <ng-container *ngIf="collection && collection.name">
          <div class="wishlist-box">
            <a
              [routerLink]="['/mywishlist-inner', collection.id]"
              class="wish-bg"
            >
              <div class="wishlist-top-title">
                <h3 class="h5">
                  {{ collection.name }}
                </h3>
                <p>{{ collection.products_count }} Items</p>
              </div>
              <div class="wishlist-bottom-title">
                <span
                  >Created on
                  {{ collection.created_at | date : "longDate" }}</span
                >
              </div>
            </a>

            <div class="wishlist-popup-dot" (click)="toggleWishlistPopup(collection.id)">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <ul class="wishlist-popup list{{collection.id}}" style="cursor: pointer;">
                <li>
                <a

                  (click)="editWishlistName(collection)"
                  ><img
                    src="assets/images/edit.svg"
                    alt="edit"
                    width="20"
                    height="20"
                  />Edit</a
                >
              </li>
              <li>
                <a
                  (click)="onDelete(collection)"
                  ><img
                    src="assets/images/delete-icon.svg"
                    alt="delete"
                    width="20"
                    height="20"
                  />Delete</a
                >
              </li>
              <li>
                <a (click)="openSharePopup(collection.id)"
                  ><img
                    src="assets/images/share-v2.svg"
                    alt="Share"
                    width="20"
                    height="20"
                  />Share</a
                >
              </li>
            </ul>
            <div class="overlay"></div>
            <!-- popup start -->
            <div class="download_popup">
              <div class="download_popup_inner">
                <div class="wishlist_image_section">
                  <div class="wishlist_image_heading">
                    <h3 class="h4">Share your collection with others</h3>
                    <p class="wishlist-share">
                      <input
                        type="text"
                        value="{{ wishlistLink }}"
                        readonly
                        #userinput
                      />
                    </p>
                    <br />
                    <ul>
                      <li>
                        <button
                          class="thame-btn cancel-btn"
                          (click)="onCancel()"
                        >
                          Cancel
                        </button>
                      </li>
                      <li>
                        <a class="thame-btn" (click)="copyLink(userinput)">{{
                          isClick == true ? "Coppied" : "Copy"
                        }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- popup end -->
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</section>
<!-- wishlist popup js start -->

<!-- Form Modal -->
<wishlist-form-modal
  [wishlistData]="selectedWishlistData"
  (dataEvent)="handleWishlistModalEvent($event)"
  *ngIf="showWishlistModal"
></wishlist-form-modal>
<!-- ./Form Modal -->
<div class="overlay"></div>

<!-- Pre Confirm Modal -->
<pre-confirm-modal
  [popupConfiguration]="preconfirmPopupConfigData"
  (dataEvent)="handlePreConfirmModalEvent($event)"
  *ngIf="showPreConfirmModal"
></pre-confirm-modal>
<!-- ./Pre Confirm Modal -->
