<!-- banner section start -->
<section class="banner-sec" *ngIf="promotion">
  <picture>
    <source
      class="banner-img dec-img"
      srcset="{{ promotion?.desktop_banner_image }}"
      alt="Images"
      width="1920"
      height="524"
    />
    <source
      class="banner-img dec-img"
      srcset="{{ promotion?.desktop_banner_image }}"
      alt="Images"
      width="1920"
      height="524"
    />
    <img
      class="banner-img dec-img"
      src="{{ promotion?.desktop_banner_image }}"
      alt="Images"
      width="1920"
      height="524"
    />

    <source
      class="banner-img mob-img"
      srcset="{{ promotion?.mobile_banner_image }}"
      alt="Images"
      width="575"
      height="380"
    />
    <source
      class="banner-img mob-img"
      srcset="{{ promotion?.mobile_banner_image }}"
      alt="Images"
      width="575"
      height="380"
    />
    <img
      class="banner-img mob-img"
      src="{{ promotion?.mobile_banner_image }}"
      alt="Images"
      width="575"
      height="380"
    />
  </picture>
  <div class="container">
    <div class="banner-content">
      <h1 class="h1">{{ promotion?.event_name }}</h1>
      <p>{{ promotion?.event_des }}</p>
      <appv2-search-bar class="categories-seach-block-banner" [params]="{isHeaderPart: false}"></appv2-search-bar>
    </div>
  </div>
</section>

<!-- Information Line section start -->
<section class="information_line bg-color" *ngIf="discountDetails">
  <div class="container" *ngIf="discountDetails?.status == 1">
    <div class="information_line_inner">
      <p>{{ discountDetails?.title }}</p>
      <a target="_blank" href="{{ discountDetails?.link }}">{{
        discountDetails?.button_text
      }}</a>
    </div>
  </div>
</section>
<!-- Information Line section end -->

<!-- Popular Categories section start -->
<appv2-category-data></appv2-category-data>
<!-- Popular Categories section end -->

<!-- Turn ideas into stories with high-quality videos section start -->
<section class="turn_ideas_into_story">
  <div class="container">
    <div class="turn_ideas_into_story_heading">
      <h2 class="h2">Turn ideas into stories with high-quality music</h2>
      <p>
        Whether you’re a marketer, content creator or, designer, Freepik’s video
        collection will meet your needs
      </p>
    </div>
    <div class="turn_ideas_into_story_inner">
      <div class="turn_ideas_into_story_inner_content">
        <div class="story_icon">
          <a href="javascript:void(0)"
            ><img
              src="assets/images/download_icon.svg"
              alt="download-icon"
              width="72"
              height="72"
          /></a>
        </div>
        <div class="story_icon_heading">
          <h3 class="h3">Ready-to-download</h3>
          <p>
            Save time and effort with videos made to be used on the spot. No
            need for additional editing.
          </p>
        </div>
      </div>
      <div class="turn_ideas_into_story_inner_content">
        <div class="story_icon">
          <a href="javascript:void(0)"
            ><img
              src="assets/images/music_play.svg"
              alt="music_play"
              width="72"
              height="72"
          /></a>
        </div>
        <div class="story_icon_heading">
          <h3 class="h3">Diverse and versatile</h3>
          <p>
            Access thousands of high-quality, free and Premium videos available
            in a wide range of categories to fit any project.
          </p>
        </div>
      </div>
      <div class="turn_ideas_into_story_inner_content">
        <div class="story_icon">
          <a href="javascript:void(0)"
            ><img
              src="assets/images/shield.svg"
              alt="shield-icon"
              width="72"
              height="72"
          /></a>
        </div>
        <div class="story_icon_heading">
          <h3 class="h3">Suitable for commercial use</h3>
          <p>
            Create stories with worry-free licensing. All videos can be used for
            both personal and commercial purposes.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Turn ideas into stories with high-quality videos section end -->

<!-- Royalty free stock Music section start -->
<section class="royalty_free_stock_music_section">
  <div class="container">
    <div class="royalty_free_stock_music_inner">
      <div class="royalty_free_stock_music_section_content">
        <h2 class="h2">Royalty free stock Music</h2>
        <p>
          Discover the best royalty free stock images, photos, vectors, footage,
          videos and music files for your designs and projects by talented
          artists and contributors worldwide right here. Everything you need is
          just a search away.
        </p>
      </div>
      <div class="royalty_free_stock_music_section_img_1">
        <picture>
          <source
            srcset="assets/images/headphone.webp"
            alt="headphone-image"
            width="411"
            height="338"
          />
          <source
            srcset="assets/images/headphone.png"
            alt="headphone-image"
            width="411"
            height="338"
          />
          <img
            src="assets/images/headphone.png"
            alt="headphone-image"
            width="411"
            height="338"
          />
        </picture>
      </div>
    </div>
  </div>
  <div class="royalty_free_stock_music_section_img_2">
    <picture>
      <source
        srcset="assets/images/gitar.webp"
        alt="videoclip-image"
        width="99"
        height="122"
      />
      <source
        srcset="assets/images/gitar.png"
        alt="videoclip-image"
        width="99"
        height="122"
      />
      <img
        src="assets/images/gitar.png"
        alt="videoclip-image"
        width="99"
        height="122"
      />
    </picture>
  </div>
</section>
<!-- Royalty free stock Music section end -->

<!-- Information section start -->
<section class="music_information_section">
  <div class="container">
    <div class="information_section_inner">
      <div class="information_section_content">
        <div class="information_content">
          <h3 class="h3">Timed for Social</h3>
          <p>
            When you’re making videos for social media, you need to move fast.
            Save time by choosing from music tracks already formatted for a
            perfect match, with a variety of styles and moods in :15 and :30
            second options.
          </p>
        </div>
        <div class="information_content">
          <h3 class="h3">Match music with mood</h3>
          <p>
            When you’re making videos for social media, you need to move fast.
            Save time by choosing from music tracks already formatted for a
            perfect match, with a variety of styles and moods in :15 and :30
            second options.
          </p>
        </div>
        <div class="information_content">
          <h3 class="h3">Music In Trends for you</h3>
          <p>
            When you’re making videos for social media, you need to move fast.
            Save time by choosing from music tracks already formatted for a
            perfect match, with a variety of styles and moods in :15 and :30
            second options.
          </p>
        </div>
        <div class="information_btn">
          <a routerLink="/pricing/">Subscribe Today</a>
        </div>
      </div>
      <div class="information_section_image">
        <picture>
          <source
            srcset="assets/images/info_section.webp"
            alt="info-section-image"
            width="997"
            height="617"
          />
          <source
            srcset="assets/images/info_section.png"
            alt="info-section-image"
            width="997"
            height="617"
          />
          <img
            src="assets/images/info_section.png"
            alt="info-section-image"
            width="997"
            height="617"
          />
        </picture>
      </div>
    </div>
  </div>
</section>
<!-- Information section end -->

<!-- Still have questions section start -->
<appv2-questions></appv2-questions>
