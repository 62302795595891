import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import $ from 'jquery';
import { messages } from 'src/appv2/appv2.constants';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { environment } from 'src/environments/environment';

const trimValidator = (control: AbstractControl) => {
  if (control.value && typeof control.value === 'string') {
    // Trim leading and trailing spaces if needed
    const trimmedValue = control.value.trim();

    // Check if trimming is necessary
    if (control.value !== trimmedValue) {
      // Update the form control value with the trimmed value
      control.setValue(trimmedValue);
    }
  }

  // Return null to indicate that the validation passed
  return null;
};

@Component({
  selector: 'appv2-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  ContactUsForm: FormGroup;
  submitted: Boolean = false;
  captchaSitekey: string;
  token: string | undefined;
  response: any;
  actionSubmitted: boolean = false;
  constructor(private formBuilder: FormBuilder, private landingPage: LandingPageService, private store$: Store<any>) {
    this.captchaSitekey = environment.recaptcha.siteKey
  }

  ngOnInit() {
    $('html, body').animate({ scrollTop: '0' }, 400);
    this.ContactUsForm = this.formBuilder.group({
      user_name: ['', [Validators.required,Validators.pattern("^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)?$")]],
      user_email: ['', [Validators.required, Validators.email]],
      mobile_number: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(15), Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]],
      user_subject: ['', [Validators.required,trimValidator]],
      user_message: ['', [Validators.required, Validators.maxLength(2500),trimValidator]],
      recaptcha: ['', Validators.required],
    });
  }

  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.contactLoading}))
    return
  }

  validation: boolean = false;
  validationMessage: any;
  public onSubmit() {
    this.submitted = true;

    if (this.ContactUsForm.valid) {
      this.loading(true);
      this.landingPage.contactUs(this.ContactUsForm.value).subscribe(data => {
        this.loading(false);

        if (data.status) {
          this.actionSubmitted = true;
          $('html, body').animate({ scrollTop: '0' }, 400);
          this.response = data.data;
        } else {
          this.validation = true;
          this.validationMessage = data.message;
        }
      });
    } else {
    }
  }

  public resolved(captchaResponse: string) {
    this.token = captchaResponse;
  }
}
